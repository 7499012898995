import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProductUID } from '../Dashboard/reducer';

const ProductUID = ({ productUID }) => {
  if (!productUID) {
    return null;
  }

  return (
    <div>
      <span>
        Product UID:
        <br />
        {productUID}
      </span>
    </div>
  );
};

ProductUID.defaultProps = {
  productUID: '',
};

ProductUID.propTypes = {
  productUID: PropTypes.string,
};

const mapStateToProps = state => ({
  productUID: getProductUID(state),
});

export default connect(mapStateToProps)(ProductUID);
