import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const style = {
  cardContainer: {
    padding: [0, 38],
  },
};

const CardContainer = ({ children, classes }) => (
  <div className={classes.cardContainer}>{children}</div>
);

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(CardContainer);
