import identity from 'lodash/fp/identity';
import { getAppURL } from '../../config';
import { formErrors } from '../../Form/normalizers';
import { checkRequiredKeys, tableDecoder } from '../utils';

const setTableParams = (sortKey, params) => ({
  count: true,
  order: 'asc',
  sort: sortKey,
  ...params,
});

const decodeOrganization = data => {
  checkRequiredKeys(data, ['uid', 'name', 'created_at', 'current_user_role']);
  return {
    uid: data.uid,
    name: data.name,
    createdAt: data.created_at,
    myRole: data.current_user_role,
  };
};

const decodeMember = data => {
  checkRequiredKeys(data, ['uid', 'name', 'role', 'email']);
  return {
    uid: data.uid,
    name: data.name,
    role: data.role,
    email: data.email,
  };
};

const decodeInvitation = data => {
  checkRequiredKeys(data, ['invited_by', 'organization']);
  checkRequiredKeys(data.organization, ['name']);

  return {
    invitedBy: data.invited_by,
    organization: {
      name: data.organization.name,
    },
  };
};

const decodeInvite = data => {
  checkRequiredKeys(data, ['uid', 'role', 'email']);

  return {
    uid: data.uid,
    role: data.role,
    email: data.email,
  };
};

const decodeOrganizationInvites = tableDecoder('invites', decodeInvite);

const decodeOrganizationMembers = tableDecoder('members', decodeMember);

export const decodeFormErrors = response => formErrors(response.errors);

const decodeAdministratorMembersOptions = data => {
  checkRequiredKeys(data, ['members', 'total_count']);

  const members = data.members.filter(user => user.role === 'administrator');
  return {
    options: members.map(member => ({
      label: member.name,
      value: member.name,
      uid: member.uid,
    })),
    total_count: data.total_count,
  };
};

export const fetchOrganizations = params => ({
  path: '/organizations',
  params: setTableParams('name', params),
  accepts: [200],
  decodeSuccess: tableDecoder('organizations', decodeOrganization),
});

export const fetchOrganization = organizationUID => ({
  path: `/organizations/${organizationUID}`,
  method: 'GET',
  accepts: [200],
  decodeSuccess: decodeOrganization,
});

export const createOrganization = name => ({
  path: '/organizations',
  method: 'POST',
  accepts: [201],
  decodeSuccess: identity,
  decodeError: decodeFormErrors,
  data: {
    name,
  },
});

export const deleteOrganization = organizationUID => ({
  path: `/organizations/${organizationUID}`,
  method: 'DELETE',
  accepts: [200],
  decodeSuccess: identity,
});

export const leaveOrganization = organizationUID => ({
  path: `/organizations/${organizationUID}/leave`,
  method: 'PUT',
  accepts: [200],
  decodeSuccess: identity,
});

export const fetchOrganizationMembers = (organizationUID, params) => ({
  path: `/organizations/${organizationUID}/members`,
  method: 'GET',
  accepts: [200],
  params: setTableParams('name', params),
  decodeSuccess: decodeOrganizationMembers,
});

export const fetchAdministratorMembers = (
  organizationUID,
  { packageStatus = 'done' } = {}
) => ({
  method: 'GET',
  path: `/organizations/${organizationUID}/members`,
  params: {
    product_uid: organizationUID,
    count: true,
    package_status: packageStatus,
  },
  decodeSuccess: decodeAdministratorMembersOptions,
});

export const updateOrganizationMember = (organizationUID, memberUID, role) => ({
  path: `/organizations/${organizationUID}/members/${memberUID}`,
  method: 'PUT',
  data: { role },
  accepts: [200],
  decodeSuccess: identity,
  decodeError: decodeFormErrors,
});

export const removeOrganizationMember = (organizationUID, memberUID) => ({
  path: `/organizations/${organizationUID}/members/${memberUID}`,
  method: 'DELETE',
  accepts: [200],
  decodeSuccess: identity,
});

export const fetchOrganizationInvites = (organizationUID, params) => ({
  path: `/organizations/${organizationUID}/invites`,
  method: 'GET',
  accepts: [200],
  params: setTableParams('email', params),
  decodeSuccess: decodeOrganizationInvites,
});

export const deleteOrganizationInvite = (organizationUID, inviteUID) => ({
  path: `/organizations/${organizationUID}/invites/${inviteUID}`,
  method: 'DELETE',
  accepts: [200],
  decodeSuccess: identity,
});

export const createOrganizationInvite = (
  organizationUID,
  memberEmail,
  memberRole
) => ({
  method: 'POST',
  path: `/organizations/${organizationUID}/invites`,
  decodeSuccess: identity,
  decodeError: decodeFormErrors,
  data: {
    role: memberRole,
    email: memberEmail,
    token_url: `${getAppURL()}/settings/organizations/${organizationUID}/invites/`,
  },
});

export const fetchOrganizationInvite = inviteToken => ({
  method: 'GET',
  path: `/organizations/invitations/${inviteToken}`,
  decodeSuccess: decodeInvitation,
});

export const acceptOrganizationInvite = inviteToken => ({
  method: 'PUT',
  path: `/organizations/invitations/${inviteToken}/accept`,
  decodeSuccess: identity,
});

export const rejectOrganizationInvite = inviteToken => ({
  method: 'PUT',
  path: `/organizations/invitations/${inviteToken}/reject`,
  decodeSuccess: identity,
});

export const transferOrganization = (organizationUID, memberUID) => ({
  path: `/organizations/${organizationUID}/transfer`,
  method: 'PUT',
  data: { member_uid: memberUID },
  accepts: [200],
  decodeSuccess: identity,
  decodeError: decodeFormErrors,
});
