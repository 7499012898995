import RolloutModel from './models';
import * as types from './types';

/* eslint-disable function-paren-newline */
const reducer = (state = RolloutModel(), { type, payload }) => {
  switch (type) {
    case types.GOT_ROLLOUT:
      return RolloutModel(payload.rollout);
    case types.OPEN_ARCHIVE_ROLLOUT_MODAL:
      return state.set('showArchiveModal', true);
    case types.CLOSE_ARCHIVE_ROLLOUT_MODAL:
      return state.set('showArchiveModal', false);
    case types.SET_FORCE_SUBMIT:
      return state.set('forceSubmit', payload);
    case types.SET_CONFLICT_ERROR:
      return state.withMutations(oldState =>
        oldState.set('conflictError', payload).set('showConflictModal', true)
      );
    case types.UPDATE_ROLLOUT_STATUS:
    case types.CLOSE_CONFLICT_MODAL:
      return state.withMutations(oldState =>
        oldState
          .set('conflictError', null)
          .set('showConflictModal', false)
          .set('forceSubmit', false)
      );
    default:
      return state;
  }
};

export default reducer;

// Selectors
export const getRollout = state => state.getIn(['rolloutDetail']);

export const getTasks = state => state.getIn(['rolloutDetail', 'tasks']);

export const getShowConflictModal = state =>
  state.getIn(['rolloutDetail', 'showConflictModal']);

export const getForceSubmit = state =>
  state.getIn(['rolloutDetail', 'forceSubmit']);

export const getConflictError = state =>
  state.getIn(['rolloutDetail', 'conflictError']);
