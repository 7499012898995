import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { getDetails } from './reducer';
import AmountPrice from './AmountPrice';
import AmountDetail from './AmountDetail';
import Bar from './Bar';

const styles = {
  content: {
    height: 245,
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 12px 0 #E5E6E8',
    padding: '46px 10% 0 10%',
    marginBottom: 13,
  },
};

const AmountBox = ({ classes, details }) => (
  <div className={classes.content}>
    <AmountPrice {...details} />
    <Bar {...details} />
    <AmountDetail {...details} />
  </div>
);

AmountBox.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
};

const reduxConnector = connect(state => ({
  details: getDetails(state).toJS(),
}));

export default reduxConnector(injectSheet(styles)(AmountBox));
