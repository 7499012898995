import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { buildGravatarUrl } from '../../Header/Avatar';

const styles = {
  avatar: {
    width: 100,
    height: 100,
    borderRadius: '50%',
    overflow: 'hidden',
    border: '2px solid white',
  },
};

const Avatar = ({ classes, gravatarHash }) => (
  <div className={classes.avatar}>
    <img src={buildGravatarUrl(gravatarHash, 100)} alt="avatar" />
  </div>
);

Avatar.propTypes = {
  classes: PropTypes.object.isRequired,
  gravatarHash: PropTypes.string.isRequired,
};

export default injectSheet(styles)(Avatar);
