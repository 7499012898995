import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

const styles = {
  button: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: '14px !important',
    height: '14px !important',
    cursor: 'pointer',
  },
};

const RemoveTaskButton = ({ classes, onClick }) => (
  <CloseIcon className={classes.button} onClick={onClick} />
);

RemoveTaskButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(RemoveTaskButton);
