import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import CancelButton from './CancelButton';
import SubmitButton from './SubmitButton';
import SubmitAndStartButton from './SubmitAndStartButton';

const style = {
  box: {
    display: 'flex',
  },
};

const ActionButtons = ({ classes }) => (
  <div className={classes.box}>
    <CancelButton />
    <SubmitButton />
    <SubmitAndStartButton />
  </div>
);

ActionButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ActionButtons);
