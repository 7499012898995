import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../../Theme/Typography';
import { red, grey800 } from '../../Theme/colors';
import Card from '../../Theme/Card';

const style = {
  flexContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  box: {
    extend: 'flexContainer',
    height: 203,
  },
  circle: {
    extend: 'flexContainer',
    borderRadius: '50%',
    boxShadow: '0 0 12px 0 #E6E6E9',
    color: red,
    fontSize: 24,
    height: 53,
    marginBottom: 18,
    width: 53,
  },
  description: {
    color: grey800,
    fontSize: 14,
    fontWeight: weight.light,
  },
};

const ContentError = ({ classes }) => (
  <Card className={classes.box}>
    <div className={classes.flexContainer}>
      <div className={classes.circle}>!</div>
      <div className={classes.description}>
        Something went wrong. Try again later.
      </div>
    </div>
  </Card>
);

ContentError.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ContentError);
