import { formErrors } from '../../Form/normalizers';
import { checkRequiredKeys } from '../utils';

const productListDecoder = data => {
  checkRequiredKeys(data, ['products', 'total_pages']);
  const products = data.products.map(product => {
    checkRequiredKeys(product, [
      'name',
      'rollout_status',
      'uid',
      'total_active_rollouts',
      'namespace',
    ]);
    checkRequiredKeys(product.namespace, ['uid']);
    return {
      namespaceUID: product.namespace.uid,
      name: product.name,
      status: product.rollout_status,
      uid: product.uid,
      activeRollouts: product.total_active_rollouts,
    };
  });

  return {
    products,
    pages: data.total_pages,
  };
};

const productDecoder = data => {
  checkRequiredKeys(data, ['uid', 'name']);
  return {
    uid: data.uid,
    name: data.name,
  };
};

const createProductDecoder = data => {
  checkRequiredKeys(data, ['uid']);
  return data;
};

const formErrorsDecoder = data => formErrors(data.errors);

export const fetchProducts = (namespaceUID, page) => ({
  path: '/products',
  params: {
    page,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: productListDecoder,
});

export const createProduct = (namespaceUID, name) => ({
  accepts: [201, 422],
  path: '/products',
  method: 'post',
  headers: { 'UH-Namespace': namespaceUID },
  data: { name },
  decodeSuccess: createProductDecoder,
  decodeError: formErrorsDecoder,
});

export const fetchProduct = (namespaceUID, uid) => ({
  path: `/products/${uid}`,
  accepts: [200],
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: productDecoder,
});
