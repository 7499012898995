import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import BaseCopiable from './BaseCopiable';
import { CopyIcon } from '../Icons';
import { humanizeHash } from '../updatehub/humanizer';

const style = {
  box: {
    cursor: 'pointer',
    display: 'inline-block',
  },
  label: {
    marginRight: 6,
  },
};

const CopiableHash = ({ classes, text }) => (
  <BaseCopiable text={text}>
    <div className={classes.box}>
      <span className={classes.label}>{humanizeHash(text)}</span>
      <CopyIcon />
    </div>
  </BaseCopiable>
);

CopiableHash.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

export default injectSheet(style)(CopiableHash);
