import React from 'react';
import organizationIcon from '../../Icons/svg/organization-namespace-icon.svg';

const organizationStyle = {
  width: 20,
  height: 18,
};

const OrganizationsIcon = () => (
  <img
    style={organizationStyle}
    src={organizationIcon}
    alt="Organization icon"
  />
);

export default OrganizationsIcon;
