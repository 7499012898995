import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { percent } from './graph-utils';

const style = {
  bar: {
    background: 'linear-gradient(0deg, #F4F4F4 53%, #EEEEEE 71%, #ECECEC 87%)',
    borderRadius: 20,
    height: 20,
    marginBottom: 34,
    overflow: 'hidden',
    width: '100%',
  },
  smallBar: {
    extend: 'bar',
    width: 230,
    borderRadius: 3,
    height: 6,
    marginBottom: null,
  },
  progress: {
    float: 'left',
    height: '100%',
  },
  greenBar: {
    extend: 'progress',
    background: 'linear-gradient(0deg, #51E475, #93F09A)',
  },
  redBar: {
    extend: 'progress',
    background: 'linear-gradient(0deg, #F8533D, #FF7664)',
  },
};

const makeBarStyle = (value, total) => ({
  width: `${percent(value, total)}%`,
});

const RolloutTaskProgressBar = ({ updated, failed, total, classes, small }) => (
  <div className={small ? classes.smallBar : classes.bar}>
    <div
      style={{ ...makeBarStyle(updated, total) }}
      className={classes.greenBar}
    />
    <div
      style={{ ...makeBarStyle(failed, total) }}
      className={classes.redBar}
    />
  </div>
);

RolloutTaskProgressBar.defaultProps = {
  small: false,
};

RolloutTaskProgressBar.propTypes = {
  failed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  updated: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  small: PropTypes.bool,
};

export default injectSheet(style)(RolloutTaskProgressBar);
