import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Page from '../Page';
import { requestRolloutDetailPage } from './actions';
import { getRollout } from './reducer';
import Content from './Content';
import urls from '../updatehub/urls';

const RolloutDetailPage = ({ rollout, onLoad, match }) => (
  <Page
    onLoad={onLoad}
    goBackURL={urls('product:rollouts', {
      namespaceUID: match.params.namespaceUID,
      productUID: match.params.productUID,
    })}
  >
    <Content rollout={rollout} />
  </Page>
);

RolloutDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  rollout: PropTypes.object.isRequired,
  onLoad: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  rollout: getRollout(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onLoad() {
    const { params } = match;
    const { rolloutUID, namespaceUID } = params;
    dispatch(requestRolloutDetailPage(rolloutUID, namespaceUID));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RolloutDetailPage)
);
