import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Modal, ModalTitle, ModalContent, ModalFooter } from '../Modal';
import { OutlineButton, Button } from '../Buttons';
import {
  cancelSubscription,
  closeCancelSubscriptionModal,
  enableCancelButton,
} from './actions';
import {
  getCancelSubscriptionModal,
  getCancelButton,
  getDetails,
  getNamespace,
} from './reducer';
import { humanizePrice } from '../updatehub/humanizer';

const styles = {
  costLabel: {
    marginBottom: 17,
  },
  cost: {
    fontWeight: 500,
  },
  check: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  checkLabel: {
    marginLeft: 11,
    color: '#3E3E40',
    fontFamily: 'Fira Sans',
    fontSize: 14,
  },
  footer: {
    textAlign: 'right',
    width: '100%',
  },
};

const CancelSubscriptionModal = ({
  classes,
  cancel,
  closeCancelModal,
  modalCancel,
  disabled,
  enableCancelSubscription,
  payment,
  nameSubscription,
}) => {
  const title = `Cancel subscription for ${nameSubscription}?`;
  return (
    <Modal isVisible={modalCancel}>
      <ModalContent>
        <ModalTitle title={title} />
        <div className={classes.costLabel}>
          <span>
            When cancelling the subscription the resources used during the
            current period are charged. The order will be of approximately
          </span>
          <span className={classes.cost}>{humanizePrice(payment)}</span>
          <span>.</span>
        </div>
        <div className={classes.check}>
          <input type="checkbox" onClick={enableCancelSubscription} />
          <span className={classes.checkLabel}>
            Are you sure do you want to proceed?
          </span>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className={classes.footer}>
          <Button label="close" onClick={closeCancelModal} />
          <OutlineButton label="confirm" disabled={disabled} onClick={cancel} />
        </div>
      </ModalFooter>
    </Modal>
  );
};

CancelSubscriptionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  closeCancelModal: PropTypes.func.isRequired,
  enableCancelSubscription: PropTypes.func.isRequired,
  modalCancel: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  payment: PropTypes.number.isRequired,
  nameSubscription: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  modalCancel: getCancelSubscriptionModal(state),
  disabled: getCancelButton(state),
  payment: getDetails(state).total,
  nameSubscription: getNamespace(state),
});

const mapDispatchToProps = dispatch => ({
  enableCancelSubscription() {
    dispatch(enableCancelButton());
  },
  closeCancelModal() {
    dispatch(closeCancelSubscriptionModal());
  },
  cancel() {
    dispatch(cancelSubscription());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(CancelSubscriptionModal));
