import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 42,
  },
  title: {
    display: 'flex',
    height: 22,
    color: '#3E3E40',
    fontFamily: 'Fira Sans',
    fontSize: 18,
    fontWeight: 300,
  },
  icon: {
    display: 'flex',
    width: 24,
    height: 24,
    marginRight: 6,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
};

const SectionHeader = ({ classes, icon, title }) => (
  <div className={classes.header}>
    <h2 className={classes.title}>
      <div className={classes.icon}>{icon}</div>
      {title}
    </h2>
  </div>
);

SectionHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default injectSheet(styles)(SectionHeader);
