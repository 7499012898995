import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { humanizePrice } from '../updatehub/humanizer';
import { barColors } from './Bar';

const styles = {
  detail: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  device: {
    display: 'inline-flex',
    height: 8,
    width: 8,
    borderRadius: '50%',
    backgroundColor: barColors.device,
    marginRight: 9,
  },
  bandwidth: {
    extend: 'device',
    backgroundColor: barColors.bandwidth,
  },
  storage: {
    extend: 'device',
    backgroundColor: barColors.storage,
  },
  activation: {
    extend: 'device',
    backgroundColor: barColors.activation,
  },
  update: {
    extend: 'device',
    backgroundColor: barColors.update,
  },
  priceDetail: {
    color: '#3E3E40',
    fontSize: 16,
    fontWeight: 500,
  },
  labelDetail: {
    display: 'block',
    color: '#3E3E40',
    fontSize: 14,
    marginLeft: 17,
  },
};

const AmountDetail = ({
  classes,
  devicesPrice,
  transferPrice,
  storagePrice,
  activationsPrice,
  deploymentsPrice,
}) => (
  <div>
    <div className={classes.detail}>
      <div>
        <div className={classes.device} />
        <span className={classes.priceDetail}>
          {humanizePrice(devicesPrice)}
        </span>
        <span className={classes.labelDetail}>Device</span>
      </div>
      <div>
        <div className={classes.bandwidth} />
        <span className={classes.priceDetail}>
          {humanizePrice(transferPrice)}
        </span>
        <span className={classes.labelDetail}>Bandwidth</span>
      </div>
      <div>
        <div className={classes.storage} />
        <span className={classes.priceDetail}>
          {humanizePrice(storagePrice)}
        </span>
        <span className={classes.labelDetail}>Storage</span>
      </div>
      <div>
        <div className={classes.activation} />
        <span className={classes.priceDetail}>
          {humanizePrice(activationsPrice)}
        </span>
        <span className={classes.labelDetail}>Device Activation</span>
      </div>
      <div>
        <div className={classes.update} />
        <span className={classes.priceDetail}>
          {humanizePrice(deploymentsPrice)}
        </span>
        <span className={classes.labelDetail}>Update Fee</span>
      </div>
    </div>
  </div>
);

AmountDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  devicesPrice: PropTypes.number.isRequired,
  transferPrice: PropTypes.number.isRequired,
  storagePrice: PropTypes.number.isRequired,
  activationsPrice: PropTypes.number.isRequired,
  deploymentsPrice: PropTypes.number.isRequired,
};

export default injectSheet(styles)(AmountDetail);
