import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = {
  section: {
    marginBottom: 14,
  },
};

const Section = ({ classes, children }) => (
  <div className={classes.section}>{children}</div>
);

Section.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default injectSheet(styles)(Section);
