import React from 'react';
import humanize from 'humanize-plus';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import {
  WaitingIcon,
  RunningIconSmall,
  PauseIconRollout,
  AbortedIcon,
  ArchiveIcon,
  PendingIcon,
} from '../Icons';

const styles = {
  icon: {
    display: 'inline-block',
  },
  status: {
    marginLeft: 10,
    display: 'inline-block',
  },
};

const RolloutStatusIcons = ({ classes, status }) => {
  const textStatus = humanize.capitalize(status);

  let icon;
  switch (status) {
    case 'paused':
      icon = <PauseIconRollout />;
      break;
    case 'waiting':
      icon = <WaitingIcon width={14} />;
      break;
    case 'aborted':
      icon = <AbortedIcon width={12} />;
      break;
    case 'archived':
      icon = <ArchiveIcon />;
      break;
    case 'running':
      icon = <RunningIconSmall />;
      break;
    default:
      icon = <PendingIcon width={12} />;
  }

  return (
    <div>
      <div className={classes.icon}>{icon}</div>
      <div className={classes.status}>{textStatus}</div>
    </div>
  );
};

RolloutStatusIcons.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default injectSheet(styles)(RolloutStatusIcons);
