import React from 'react';
import PropTypes from 'prop-types';
import { DeviceCompletedIcon, DeviceErrorIcon } from '../../../Icons';

const IconStyle = {
  display: 'inline-block',
  width: 14,
  height: 14,
};

const StatusIcon = ({ status }) => {
  switch (status) {
    case 'done':
      return (
        <img
          style={IconStyle}
          src={DeviceCompletedIcon}
          alt="Done status icon"
        />
      );
    case 'error':
      return (
        <img style={IconStyle} src={DeviceErrorIcon} alt="Error status icon" />
      );
    default:
      return (
        <img
          style={IconStyle}
          src={DeviceCompletedIcon}
          alt="Loading status icon"
        />
      );
  }
};

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusIcon;
