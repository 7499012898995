export const SET_FORCE_CREATION = 'ROLLOUT_CREATE/SET_FORCE_CREATION';
export const SET_VERSION = 'ROLLOUT_CREATE/SET_VERSION';
export const SET_SCOPE = 'ROLLOUT_CREATE/SET_SCOPE';
export const SET_DEVICES_COUNT = 'ROLLOUT_CREATE/SET_DEVICES_COUNT';
export const SET_FILTERS = 'ROLLOUT_CREATE/SET_FILTERS';
export const SET_REMAINING_DEVICES = 'ROLLOUT_CREATE/SET_REMAINING_DEVICES';
export const TOGGLE_ADVANCED_MODE = 'ROLLOUT_CREATE/TOGGLE_ADVANCED_MODE';
export const UPDATE_GRAPH_TITLE = 'ROLLOUT_CREATE/UPDATE_GRAPH_TITLE';
export const UPDATE_GRAPH_DATA = 'ROLLOUT_CREATE/UPDATE_GRAPH_DATA';

// Tasks
export const CHANGE_SELECTED_TASK = 'ROLLOUT_CREATE/CHANGE_SELECTED_TASK';
export const ADD_TASK = 'ROLLOUT_CREATE/ADD_TASK';
export const UPDATE_TASK = 'ROLLOUT_CREATE/UPDATE_TASK';
export const UPDATE_TASK_FILTER = 'ROLLOUT_CREATE/UPDATE_TASK_FILTER';
export const UPDATE_TASK_FILTERS = 'ROLLOUT_CREATE/UPDATE_TASK_FILTERS';
export const REQUEST_REMOVE_TASK = 'ROLLOUT_CREATE/REQUEST_REMOVE_TASK';
export const REMOVE_TASK = 'ROLLOUT_CREATE/REMOVE_TASK';
export const RESET_TASKS = 'ROLLOUT_CREATE/RESET_TASKS';

// Submit
export const SUBMIT = 'ROLLOUT_CREATE/SUBMIT';
export const SUBMIT_START = 'ROLLOUT_CREATE/SUBMIT_START';
export const SUBMIT_SUCCESS = 'ROLLOUT_CREATE/SUBMIT_SUCCESS';

// Error Modal
export const SET_CREATE_ERROR_MODAL = 'ROLLOUT_CREATE/SET_CREATE_ERROR_MODAL';
export const SET_CONFLICT_ERROR = 'ROLLOUT_CREATE/SET_CONFLICT_ERROR';
export const SET_ERROR = 'ROLLOUT_CREATE/SET_ERROR';
export const CLOSE_ERROR_MODAL = 'ROLLOUT_CREATE/CLOSE_ERROR_MODAL';
