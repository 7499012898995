import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Record } from 'immutable';
import Snackbar from 'material-ui/Snackbar';

// Types

export const CLOSE_NOTIFICATION = 'NOTIFICATION/CLOSE';
export const OPEN_NOTIFICATION = 'NOTIFICATION/OPEN';

// Actions

export const openNotification = message => ({
  type: OPEN_NOTIFICATION,
  payload: message,
});

export const closeNotification = () => ({
  type: CLOSE_NOTIFICATION,
});

// Model

export const NotificationModel = Record({
  isOpened: false,
  message: '',
});

// Reducer

export const reducer = (state = NotificationModel(), action) => {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      return state.withMutations(newState =>
        newState.set('isOpened', true).set('message', action.payload));
    case CLOSE_NOTIFICATION:
      return state.withMutations(newState =>
        newState.set('isOpened', false).set('message', ''));
    default:
      return state;
  }
};

// Selectors

export const getNotificationStatus = state =>
  state.getIn(['notification', 'isOpened']);

export const getNotificationMessage = state =>
  state.getIn(['notification', 'message']);

// Component

const Notification = ({ message, isOpened, onClose, ...props }) => (
  <Snackbar
    open={isOpened}
    onRequestClose={onClose}
    message={message}
    autoHideDuration={3000}
    {...props}
  />
);

Notification.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isOpened: getNotificationStatus(state),
  message: getNotificationMessage(state),
});

const mapDispatchToProps = dispatch => ({
  onClose() {
    dispatch(closeNotification());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
