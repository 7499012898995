import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { pluralize } from 'humanize-plus';
import { grey800 } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';
import RemoveTaskButton from './RemoveTaskButton';

export const styles = {
  taskBox: {
    width: 120,
    padding: 10,
    userSelect: 'none',
    cursor: 'pointer',
    position: 'relative',
    outline: 'none',
    background: 'none',
    border: 'none',
    textAlign: 'start',

    '&.active': {
      borderBottom: '2px solid #0016AE',
      cursor: 'default',
    },
  },
  span: {
    fontSize: 12,
    marginBottom: 2,
    display: 'inline-block',
  },
  taskName: {
    width: '80%',
    minHeight: 19,
    fontSize: 16,
    color: grey800,
    fontWeight: weight.medium,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const Task = ({
  classes,
  name,
  devicesCount,
  active,
  onClick,
  onRemoveClick,
}) => (
  <button
    tabIndex="-1"
    className={active ? `${classes.taskBox} active` : classes.taskBox}
    onClick={active ? null : onClick}
  >
    <h2 className={classes.taskName}>{name}</h2>
    <span className={classes.span}>
      {devicesCount} {pluralize(devicesCount, 'device')}
    </span>
    {active && <RemoveTaskButton onClick={onRemoveClick} />}
  </button>
);

Task.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  devicesCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(Task);
