export const REQUEST_ORGANIZATION = 'ORGANIZATION_DETAIL/REQUEST_ORGANIZATION';
export const GOT_ORGANIZATION = 'ORGANIZATION_DETAIL/GOT_ORGANIZATION';

export const REQUEST_MEMBERS = 'ORGANIZATION_DETAIL/REQUEST_MEMBERS';
export const GOT_MEMBERS = 'ORGANIZATION_DETAIL/GOT_MEMBERS';
export const GOT_MEMBERS_FAILED = 'ORGANIZATION_DETAIL/GOT_MEMBERS_FAILED';
export const RESET_MEMBERS_STORE = 'ORGANIZATION_DETAIL/RESET_MEMBERS_STORE';
export const SET_MEMBER_TO_EDIT = 'ORGANIZATION_DETAIL/SET_MEMBER_TO_EDIT';
export const SET_MEMBER_TO_REMOVE = 'ORGANIZATION_DETAIL/SET_MEMBER_TO_REMOVE';

export const REQUEST_INVITES = 'ORGANIZATION_DETAIL/REQUEST_INVITES';
export const GOT_INVITES = 'ORGANIZATION_DETAIL/GOT_INVITES';
export const GOT_INVITES_FAILED = 'ORGANIZATION_DETAIL/GOT_INVITES_FAILED';
export const RESET_INVITES_STORE = 'ORGANIZATION_DETAIL/RESET_INVITES_STORE';
export const SET_INVITE_TO_CANCEL = 'ORGANIZATION_DETAIL/SET_INVITE_TO_CANCEL';

export const OPEN_CREATE_INVITE_MODAL =
  'ORGANIZATION_DETAIL/OPEN_CREATE_INVITE_MODAL';
export const CLOSE_CREATE_INVITE_MODAL =
  'ORGANIZATION_DETAIL/CLOSE_CREATE_INVITE_MODAL';

export const OPEN_DELETE_ORGANIZATION_MODAL =
  'ORGANIZATION_DETAIL/OPEN_DELETE_ORGANIZATION_MODAL';
export const CLOSE_DELETE_ORGANIZATION_MODAL =
  'ORGANIZATION_DETAIL/CLOSE_DELETE_ORGANIZATION_MODAL';

export const DELETE_ORGANIZATION = 'ORGANIZATION_DETAIL/DELETE_ORGANIZATION';

export const OPEN_TRANSFER_ORGANIZATION_MODAL =
  'ORGANIZATION_DETAIL/OPEN_TRANSFER_ORGANIZATION_MODAL';
export const CLOSE_TRANSFER_ORGANIZATION_MODAL =
  'ORGANIZATION_DETAIL/CLOSE_TRANSFER_ORGANIZATION_MODAL';

export const SET_MEMBER_TO_TRANSFER =
  'ORGANIZATION_DETAIL/SET_MEMBER_TO_TRANSFER';

export const TRANSFER_ORGANIZATION =
  'ORGANIZATION_DETAIL/TRANSFER_ORGANIZATION';

export const SET_BILLING_TO_TRANSFER =
  'ORGANIZATION_DETAIL/SET_BILLING_TO_TRANSFER';

export const CLOSE_CONFIRM_TRANSFER_ORGANIZATION_MODAL =
  'ORGANIZATION_DETAIL/CLOSE_CONFIRM_TRANSFER_ORGANIZATION_MODAL';

export const CANCEL_TRANSFER_ORGANIZATION_MODAL =
  'ORGANIZATION_DETAIL/CANCEL_TRANSFER_ORGANIZATION_MODAL';
