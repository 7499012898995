import { combineReducers } from 'redux-immutable';
import * as types from './types';
import { PAGE_STATUS } from '../Page/models';
import { ProductModel, ProductModalModel } from '../models';

export const status = (state = PAGE_STATUS.LOADING, action) => {
  switch (action.type) {
    case types.SET_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export const product = (state = ProductModel(), action) => {
  switch (action.type) {
    case types.SET_PRODUCT:
      return state.merge(action.payload);
    case types.UNSET_PRODUCT:
      return ProductModel();
    default:
      return state;
  }
};

export const modal = (state = ProductModalModel(), action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return state.set('isVisible', true);
    case types.CLOSE_MODAL:
      return ProductModalModel();
    default:
      return state;
  }
};

export const namespaceUID = (state = '', action) => {
  switch (action.type) {
    case types.REQUEST_LOAD:
      return action.payload.namespaceUID;
    default:
      return state;
  }
};

export default combineReducers({
  status,
  product,
  modal,
  namespaceUID,
});

export const getPageStatus = state => state.getIn(['dashboard', 'status']);

export const getProduct = state => state.getIn(['dashboard', 'product']);

export const getModal = state => state.getIn(['dashboard', 'modal']);

export const getProductUID = state =>
  state.getIn(['dashboard', 'product', 'uid']);

export const getNamespaceUID = state =>
  state.getIn(['dashboard', 'namespaceUID']);
