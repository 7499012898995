import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import qs from 'qs';

const SIZE = 32;

const styles = {
  avatar: {
    width: SIZE,
    height: SIZE,
    overflow: 'hidden',
    borderRadius: '50%',

    '& > img': {
      width: SIZE,
      height: SIZE,
    },
  },
};

export const buildGravatarUrl = (hash, size = SIZE) => {
  const params = qs.stringify({
    d: 'mm',
    s: size,
  });

  return `https://www.gravatar.com/avatar/${hash}?${params}`;
};

const Avatar = ({ classes, gravatarHash }) => (
  <div className={classes.avatar}>
    <img alt="avatar" src={buildGravatarUrl(gravatarHash)} />
  </div>
);

Avatar.propTypes = {
  classes: PropTypes.object.isRequired,
  gravatarHash: PropTypes.string.isRequired,
};

export default injectSheet(styles)(Avatar);
