import * as types from './types';

export const requestInvitation = payload => ({
  type: types.REQUEST_INVITATION,
  payload,
});

export const receiveInvitation = payload => ({
  type: types.RECEIVE_INVITATION,
  payload,
});

export const acceptInvite = () => ({
  type: types.ACCEPT_INVITE,
});

export const rejectInvite = () => ({
  type: types.REJECT_INVITE,
});
