import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { OutlineButton } from '../../Buttons';
import * as actions from '../actions';
import { getDisabled } from '../reducer';

const style = {
  marginLeft: 'auto',
  marginRight: '10px',
};

const SubmitButton = ({ disabled, onClick }) => (
  <OutlineButton
    label="Save"
    style={style}
    onClick={onClick}
    disabled={disabled}
  />
);

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  disabled: getDisabled(state),
});

const mapDispatchToProps = dispatch => ({
  onClick() {
    dispatch(actions.submit());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitButton);
