import { takeEvery, select, put, call } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import request from '../updatehub/effects';
import * as api from '../updatehub/api';
import * as page from '../Page/actions';
import * as actions from './actions';
import * as types from './types';
import { getNamespaceUID } from './reducer';
import { openNotification } from '../Notification';

export const cancelSubscriptionReportError =
  'Error sending the subscription cancellation request';
export const fetchOrderReportError = 'Error on fetching order report';
export function* getNamespaces() {
  const uid = yield select(getNamespaceUID);
  const { result } = yield request(api.fetchNamespaces());
  const name = result.find(namespace => {
    if (namespace.uid === uid) {
      return namespace;
    }
    return false;
  });

  return name.name;
}

export function* fetchDetails() {
  const namespaceUID = yield select(getNamespaceUID);
  const namespace = yield call(getNamespaces);
  yield put(actions.setNamespace(namespace));
  const { result } = yield request(api.fetchBillingDetails(namespaceUID));
  if (result) {
    yield put(actions.setDetails(result));
    yield put(page.loaded());
    return;
  }
  yield put(page.loaded());
}

export function* fetchOrderReport({ payload }) {
  const namespaceUID = yield select(getNamespaceUID);
  const { uid } = payload;
  const { result } = yield request(api.fetchOrderReport(namespaceUID, uid));
  yield put(actions.downloadedOrderReport());
  if (!result) {
    yield put(openNotification(fetchOrderReportError));
    return;
  }

  const file = new File([result], `order-${uid}.pdf`, {
    type: 'application/pdf',
  });
  window.open(URL.createObjectURL(result), '_blank');
  saveAs(file);
}

export function* cancelSubscription() {
  const namespaceUID = yield select(getNamespaceUID);
  const { result } = yield request(api.cancelSubscription(namespaceUID));
  if (!result) {
    yield put(openNotification(cancelSubscriptionReportError));
    yield put(actions.closeCancelSubscriptionModal());
  } else {
    yield put(actions.openConfirmCancelModal());
  }
}

export default function* rootSaga() {
  yield takeEvery(types.REQUEST_DETAILS, fetchDetails);
  yield takeEvery(types.DOWNLOAD_ORDER_REPORT, fetchOrderReport);
  yield takeEvery(types.CANCEL_SUBSCRIPTION, cancelSubscription);
}
