import React from 'react';
import { weight } from '../Theme/Typography';
import { bgStripe } from '../Theme/colors';
import productIcon from '../Icons/svg/empty-product.svg';
import OpenModalButton from './ButtonOpenCreateProductModal';

const viewStyle = { textAlign: 'center' };

const h1Style = {
  fontWeight: weight.light,
  margin: '60px 0 46px',
};

const boxStyle = {
  backgroundImage: `url(${productIcon}), ${bgStripe}`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100px center, left',
  borderRadius: 26,
  margin: '0 auto',
  maxWidth: 1021,
  overflow: 'auto',
  paddingTop: 90,
  position: 'relative',
};

const leadStyle = {
  fontSize: 22,
  fontWeight: weight.medium,
  lineHeight: '32px',
  marginBottom: 16,
};

const subLeadStyle = {
  fontSize: 18,
  fontStyle: 'italic',
  lineHeight: '19px',
  marginBottom: 29,
};

const modalButtonStyle = {
  display: 'inline-block',
  marginBottom: 87,
  width: 244,
};

const EmptyProductsView = () => (
  <div style={viewStyle}>
    <h1 style={h1Style}>Welcome to UpdateHub!</h1>
    <div style={boxStyle}>
      <p style={leadStyle}>Your product list is empty</p>
      <p style={subLeadStyle}>Click the button bellow to start out</p>
      <div style={modalButtonStyle}>
        <OpenModalButton outline fullWidth />
      </div>
    </div>
  </div>
);

export default EmptyProductsView;
