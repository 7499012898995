import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import ArrowIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';
import Divider from 'material-ui/Divider';
import Menu from 'material-ui/Menu';
import Popover from 'material-ui/Popover';
import {
  getUserNameOrEmail,
  getGravatarHash,
  isAppLoading,
} from '../App/reducer';
import { weight } from '../Theme/Typography';
import { blue, white, grey800 } from '../Theme/colors';
import LogoutButton from './LogoutButton';
import Avatar from './Avatar';
import SettingsButton from './SettingsButton';
import BillingButton from './BillingButton';

const style = {
  baseBox: {
    alignItems: 'center',
    display: 'flex',
  },
  box: {
    extend: 'baseBox',
    cursor: 'pointer',
    outline: 'none',
  },
  button: {
    alignItems: 'center',
    borderRadius: 32,
    display: 'flex',
    color: white,
    flex: 'none',
    justifyContent: 'space-around',
    marginLeft: 5,
    paddingLeft: 8,
  },
  buttonActive: {
    extend: 'button',
    background: '#EFF0F4',
    color: grey800,
  },
  polygon: {
    background: white,
  },
  circle: {
    extend: 'polygon',
    borderRadius: '50%',
    height: 33,
    width: 33,
  },
  rectangle: {
    extend: 'polygon',
    borderRadius: 18,
    height: 18,
    marginLeft: 8,
    width: 118,
  },
  displayName: {
    fontSize: 14,
    fontWeight: weight.regular,
    letterSpacing: 0,
  },
};

const EmptyButton = ({ classes }) => (
  <div className={classes.baseBox}>
    <div className={classes.circle} />
    <div className={classes.rectangle} />
  </div>
);

EmptyButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

// Material UI Popover position
const anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
const targetOrigin = { horizontal: 'right', vertical: 'top' };

const getButtonClass = (classes, isOpened) => {
  if (isOpened) {
    return classes.buttonActive;
  }
  return classes.button;
};

class UserMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpened: false,
      anchor: null, // To right align menu items  with menu button
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onOpen(event) {
    event.preventDefault(); // Material UI recomendation
    this.setState({
      isOpened: true,
      anchor: event.currentTarget,
    });
  }

  onClose() {
    this.setState({ isOpened: false });
  }

  render() {
    const { displayName, gravatarHash, isLoading, classes } = this.props;
    const { isOpened, anchor } = this.state;
    if (isLoading) {
      return <EmptyButton classes={classes} />;
    }
    return (
      <div
        onClick={this.onOpen}
        onKeyUp={this.onOpen}
        className={classes.box}
        role="button"
        tabIndex={0}
      >
        <Avatar gravatarHash={gravatarHash} />
        <div className={getButtonClass(classes, isOpened)}>
          <span className={classes.displayName}>{displayName}</span>
          <ArrowIcon color={blue} />
        </div>
        <Popover
          onRequestClose={this.onClose}
          open={isOpened}
          anchorEl={anchor}
          anchorOrigin={anchorOrigin}
          targetOrigin={targetOrigin}
        >
          <Menu>
            <SettingsButton onClick={this.onClose} />
            <Divider inset />
            <BillingButton onClick={this.onClose} />
            <Divider inset />
            <LogoutButton />
          </Menu>
        </Popover>
      </div>
    );
  }
}

UserMenu.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
  gravatarHash: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  displayName: getUserNameOrEmail(state),
  gravatarHash: getGravatarHash(state),
  isLoading: isAppLoading(state),
});

export default connect(mapStateToProps)(injectSheet(style)(UserMenu));
