import * as types from './types';
import Model from './models';

/* eslint-disable function-paren-newline */
const removeRolloutFromList = (state, uid) =>
  state.update('rollouts', rollouts =>
    rollouts.filter(rollout => rollout.uid !== uid)
  );

const reducer = (state = Model(), action) => {
  switch (action.type) {
    case types.SET_ROLLOUTS:
      return state.set('rollouts', action.payload).set('failed', false);
    case types.SET_ROLLOUTS_ERROR:
      return state.set('failed', true);
    case types.ARCHIVE_ROLLOUT:
      return removeRolloutFromList(state, action.payload.rolloutUID);
    default:
      return state;
  }
};

export default reducer;

// Selectors
export const getActiveRollouts = state =>
  state.getIn(['overview', 'activeRollouts', 'rollouts']);

export const getContentStatus = state =>
  state.getIn(['overview', 'activeRollouts', 'failed']);
