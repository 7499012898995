import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Page from '../Page';
import { H2, H3 } from '../Dashboard/Typography';
import PackageTable from './PackageTable';
import PackageFilter from './PackageFilter';

const gridStyle = {
  width: '100%',
  paddingTop: 31,
};

const h2Style = { paddingBottom: 16 };
const h3Style = { paddingBottom: 8 };
const rowStyle = { paddingBottom: 8 };

export default () => (
  <Page>
    <Grid style={gridStyle} fluid>
      <Row>
        <Col xs={12}>
          <H2 style={h2Style}>Packages</H2>
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={12}>
          <H3 style={h3Style}>Packages list</H3>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <PackageTable />
        </Col>
        <Col xs={4}>
          <PackageFilter />
        </Col>
      </Row>
    </Grid>
  </Page>
);
