import React from 'react';
import PropTypes from 'prop-types';
import PageButton from './PageButton';

export const fillLength = 5;
export const makePagesArray = (total, actual) => {
  const pages = [];
  const coeficient = Math.floor(fillLength / 2);
  let startPoint = actual - coeficient;
  if (actual - coeficient < 0) {
    startPoint = 0;
  }

  const endPoint = startPoint + fillLength;
  if (endPoint > total) {
    const diff = endPoint - total;

    if (startPoint - diff >= 0) {
      startPoint -= diff;
    }
  }

  for (let i = startPoint; i < startPoint + fillLength; i += 1) {
    if (i < total) {
      pages.push(i);
    }
  }

  if (!pages.length) {
    pages.push(0);
  }

  // FIXME, missing implementation of last and first
  return pages;
};

const pagesBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
};

const Pages = ({ pages, page, onPageChange }) => {
  const pagesArray = makePagesArray(pages, page).map(i => (
    <PageButton
      page={i}
      key={i}
      disabled={page === i}
      onClick={() => onPageChange(i)}
    />
  ));

  return <div style={pagesBoxStyle}>{pagesArray}</div>;
};

Pages.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pages;
