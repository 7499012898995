import * as types from './types';

export const subscribe = uid => ({
  type: types.SUBSCRIBE,
  uid,
});

export const startSubscription = () => ({
  type: types.START_SUBSCRIPTION,
});

export const finalizeSubscription = () => ({
  type: types.FINALIZE_SUBSCRIPTION,
});

export const failSubscription = payload => ({
  type: types.FAIL_SUBSCRIPTION,
  payload,
});

export const failCustomer = () => ({
  type: types.FAIL_CUSTOMER,
});

export const loadedCountries = payload => ({
  type: types.COUNTRIES_LOADED,
  payload,
});

export const setCustomer = payload => ({
  type: types.SET_CUSTOMER,
  payload,
});

export const setNamespace = namespace => ({
  type: types.SET_NAMESPACE,
  namespace,
});
