import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';
import auth from './Auth/reducer';
import app from './App/reducer';
import page from './Page/reducer';
import productList from './ProductListPage/reducer';
import overview from './OverviewPage/reducer';
import dashboard from './Dashboard/reducer';
import devices from './DevicesPage/reducer';
import deviceDetail from './DeviceDetailPage/reducer';
import organizationDetail from './OrganizationDetailPage/reducer';
import organizationInvitation from './OrganizationInvitationPage/reducer';
import packages from './PackagesPage/reducer';
import packageDetail from './PackageDetailPage/reducer';
import refresher from './updatehub/refresher/reducer';
import rollouts from './RolloutsPage/reducer';
import rolloutDetail from './RolloutDetailPage/reducer';
import rolloutCreate from './RolloutCreatePage/reducer';
import settings from './SettingsPage/reducer';
import { reducer as notification } from './Notification';
import startRolloutTaskModal from './StartRolloutTaskModal/reducer';
import billingList from './BillingListPage/reducer';
import billingSubscription from './BillingSubscriptionPage/reducer';
import billingDetails from './BillingDetailsPage/reducer';

export default combineReducers({
  auth,
  app,
  dashboard,
  devices,
  deviceDetail,
  form,
  notification,
  overview,
  organizationDetail,
  organizationInvitation,
  page,
  packages,
  packageDetail,
  productList,
  refresher,
  rollouts,
  rolloutDetail,
  rolloutCreate,
  router: routerReducer,
  startRolloutTaskModal,
  settings,
  billingList,
  billingSubscription,
  billingDetails,
});

// Selectors
export const getRouting = state => state.get('routing');
