import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { white, getProductColor } from './colors';
import { weight } from './Typography';

const style = {
  box: {},
  name: {
    display: 'flex',
    alignSelf: 'center',
  },
};

export const sliceName = name => name.slice(0, 2).toUpperCase();

const productLogoStyle = uid => ({
  background: getProductColor(uid),
  color: white,
  display: 'flex',
  justifyContent: 'center',
  fontSize: 36,
  fontWeight: weight.light,
  height: 64,
  width: 64,
  borderRadius: '50%',
});

class ProductLogo extends React.Component {
  render() {
    const { classes, productName, productUID } = this.props;

    return (
      <div
        className={classes.box}
        onMouseMove={this.mouseMove}
        onMouseLeave={this.mouseLeave}
        style={productLogoStyle(productUID)}
      >
        <span className={classes.name}>{sliceName(productName)}</span>
      </div>
    );
  }
}

ProductLogo.propTypes = {
  productName: PropTypes.string.isRequired,
  productUID: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ProductLogo);
