import { Record } from 'immutable';

export const fetchStatus = {
  fetching: 'fetching',
  error: 'error',
  done: 'done',
};

export const DeviceTransactionModel = Record({
  steps: [],
  log: '',
});

const DeviceModel = Record({
  uid: '',
  version: '',
  hardware: '',
  status: '',
  identity: [],
  attributes: [],
  rollouts: [],
  fetchRolloutsStatus: fetchStatus.fetching,
  rolloutToDetail: null,
  rolloutTransaction: DeviceTransactionModel(),
  fetchTransactionStatus: fetchStatus.fetching,
  fetchProbeInfo: fetchStatus.fetching,
  lastProbe: null,
  probeIntervalMean: 0, // seconds
  isVisibleLogModal: false,
  tags: [],
});

export default DeviceModel;

export const DeviceActionModel = Record({
  disabled: false,
});
