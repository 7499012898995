import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { connect } from 'react-redux';
import LegendContent from '../../OverviewPage/DevicesGraphCard/Legend';
import { getGraphTitle, getGraphData } from '../reducer';

const legendStyle = { width: 215, right: 0 };

export const cellColors = [
  '#fe7699',
  '#dd90ff',
  '#85a1da',
  '#82e6ee',
  '#abadb2',
];

const Graph = ({ title, data }) => (
  <PieChart width={360} height={140}>
    <Pie
      data={data}
      nameKey="name"
      dataKey="value"
      innerRadius="40%"
      outerRadius="65%"
      isAnimationActive={false}
    >
      {data.map((cell, index) => (
        <Cell key={cell} fill={cellColors[index % cellColors.length]} />
      ))}
      ;
    </Pie>
    {data.length && (
      <Legend
        title={title}
        align="right"
        layout="vertical"
        verticalAlign="middle"
        content={LegendContent}
        wrapperStyle={legendStyle}
      />
    )}
  </PieChart>
);

Graph.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  title: getGraphTitle(state),
  data: getGraphData(state),
});

export default connect(mapStateToProps)(Graph);
