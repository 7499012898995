import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkButton, OutlineButton } from '../Buttons';
import { CheckIcon } from '../Icons';
import { Modal, ModalTitle, ModalContent, ModalFooter } from '../Modal';
import { closeStartTaskModal, startTask } from './actions';
import { getTaskInfo, getModalState } from './reducer';

const StartRolloutTaskModal = ({ taskInfo, isOpened, closeModal, onClick }) => (
  <Modal isVisible={isOpened}>
    <ModalContent>
      <ModalTitle title="Do you want to start this task now?" />
      <p>
        The threshold to start this task was triggered and you can start it now.
        Click Yes to start delivering updates to task selected devices.
      </p>
    </ModalContent>
    <ModalFooter>
      <LinkButton label="Cancel" onClick={closeModal} />
      <OutlineButton
        label="Yes, start now"
        onClick={onClick(taskInfo)}
        icon={<CheckIcon />}
        disabled={false}
      />
    </ModalFooter>
  </Modal>
);

StartRolloutTaskModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  taskInfo: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isOpened: getModalState(state),
  taskInfo: getTaskInfo(state),
});

const mapDispatchToProps = dispatch => ({
  onClick(taskInfo) {
    return () => dispatch(startTask(taskInfo));
  },
  closeModal() {
    dispatch(closeStartTaskModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StartRolloutTaskModal);
