import React from 'react';
import PropTypes from 'prop-types';
import SelectedOptions from './SelectedOptions';
import Options from './Options';
import AnimatedInput from './AnimatedInput';

class Select extends React.Component {
  constructor() {
    super();

    this.state = {
      opened: false,
      queryValue: '',
      availableOptions: [],
      page: 1,
      selectedIndex: -1,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.processOptions = this.processOptions.bind(this);
    this.selectedValuesChanged = this.selectedValuesChanged.bind(this);
    this.fetchOptions = this.fetchOptions.bind(this);
    this.queryValueChange = this.queryValueChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleNewPage = this.handleNewPage.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.outsideEvent = this.outsideEvent.bind(this);
    this.toggleOutsideEvent = this.toggleOutsideEvent.bind(this);
    this.triggerOnBlur = this.triggerOnBlur.bind(this);
    this.optionsLength = this.optionsLength.bind(this);
  }

  componentWillUnmount() {
    this.toggleOutsideEvent(false);
  }

  triggerOnBlur() {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
  }

  outsideEvent(e) {
    if (this.wrapper && this.wrapper.contains(e.target)) {
      return;
    }

    this.close();
  }

  toggleOutsideEvent(enabled) {
    if (enabled) {
      document.addEventListener('focus', this.outsideEvent, true);
      document.addEventListener('click', this.outsideEvent);
    } else {
      document.removeEventListener('focus', this.outsideEvent, true);
      document.removeEventListener('click', this.outsideEvent);
    }
  }

  open() {
    this.setState({
      opened: true,
    });

    this.fetchOptions();
    this.toggleOutsideEvent(true);
  }

  close() {
    this.setState({
      opened: false,
      availableOptions: [],
      page: 1,
      selectedIndex: -1,
    });

    this.triggerOnBlur();
    this.toggleOutsideEvent(false);
  }

  focus() {
    this.open();
  }

  // Covered by fetchOptions test #L146
  processOptions(options) {
    const { values } = this.props;
    const selectedValues = values;

    if (!selectedValues.length) {
      return options;
    }

    /* eslint-disable function-paren-newline */
    return options.filter(option =>
      selectedValues.every(
        selectedOption => selectedOption.value !== option.value
      )
    );
  }

  handleNewPage() {
    const { page } = this.state;
    this.setState({
      page: page + 1,
    });

    this.fetchOptions(true);
  }

  selectedValuesChanged(event, value) {
    let valueSelect = value;
    if (!valueSelect) {
      const { queryValue } = this.state;
      valueSelect = {
        label: queryValue,
        value: queryValue,
      };
    }
    const { values, onChange } = this.props;
    const selectedValues = [...values];
    selectedValues.push(valueSelect);
    onChange(selectedValues);

    this.setState({
      availableOptions: [],
      selectedIndex: -1,
      page: 1,
      queryValue: '',
    });

    setTimeout(() => {
      const { selectOnly } = this.props;
      if (selectOnly) {
        return;
      }

      this.fetchOptions();
      if (this.input) {
        this.input.focus();
      }
    });
  }

  removeOption(event, value) {
    const { values, onChange } = this.props;
    const { opened } = this.state;
    const selectedValuesChanged = [...values];
    selectedValuesChanged.splice(values.indexOf(value), 1);

    onChange(selectedValuesChanged);

    if (opened) {
      this.fetchOptions();
    }

    setTimeout(() => {
      if (!opened) {
        this.triggerOnBlur();
      }
    });
  }

  optionsLength() {
    const { createComponent } = this.props;
    const { availableOptions } = this.state;
    let length = availableOptions.length - 1;
    if (createComponent) {
      length += 1;
    }
    return length;
  }

  handleKeyDown({ key, nativeEvent }) {
    const { values, createComponent } = this.props;
    const { availableOptions, selectedIndex, queryValue } = this.state;
    const value = availableOptions[selectedIndex];

    switch (key) {
      case 'Tab': {
        this.close();
        if (!value) {
          break;
        }

        nativeEvent.preventDefault();
        nativeEvent.stopPropagation();
        this.selectedValuesChanged(null, value);
        break;
      }
      case 'Enter': {
        nativeEvent.preventDefault();
        nativeEvent.stopPropagation();
        if (!value && !createComponent) {
          break;
        }

        this.selectedValuesChanged(null, value);
        break;
      }
      case 'ArrowUp': {
        nativeEvent.preventDefault();
        nativeEvent.stopPropagation();
        const newIndex = selectedIndex - 1;
        if (newIndex < 0) {
          break;
        }

        this.setState({
          selectedIndex: newIndex,
        });
        break;
      }
      case 'ArrowDown': {
        nativeEvent.preventDefault();
        nativeEvent.stopPropagation();
        let newIndex = selectedIndex + 1;
        const optionsLength = this.optionsLength();
        if (newIndex > optionsLength) {
          newIndex = optionsLength;
        }

        this.setState({
          selectedIndex: newIndex,
        });
        break;
      }
      case 'Backspace': {
        if (queryValue !== '' || values.length === 0) {
          break;
        }

        nativeEvent.preventDefault();
        nativeEvent.stopPropagation();
        this.removeOption(values[values.length - 1]);
        break;
      }
      default:
        break;
    }
  }

  queryValueChange(_e, value) {
    this.setState(
      {
        queryValue: value,
        page: 1,
      },
      () => this.fetchOptions()
    );
  }

  fetchOptions(append = false) {
    const { values, defaultPageSize, debounce, onFetchDataError } = this.props;

    return new Promise(resolve => {
      if (this.fetchTimeout) {
        clearTimeout(this.fetchTimeout);
      }

      const self = this;

      this.fetchTimeout = setTimeout(() => {
        const { fetchData } = this.props;
        fetchData(self.state.queryValue, self.state.page)
          .then(({ result }) => {
            let options = self.processOptions(result.options);

            const { availableOptions } = this.state;
            if (append === true) {
              options = [...availableOptions, ...options];
            }

            const total = result.total_count;
            const computedTotal = options.length + values.length;

            self.setState(
              {
                ...self.state,
                availableOptions: options,
                paginate: total > defaultPageSize && computedTotal < total,
                showCreateComponent: true,
              },
              () => resolve()
            );
          })
          .catch(error => {
            onFetchDataError(error);
            resolve();
          });
      }, debounce);
    });
  }

  render() {
    const {
      createComponent,
      selectOnly,
      values,
      disableInput,
      inputModifier,
      placeholder,
      inputPlaceholder,
    } = this.props;
    const {
      opened,
      queryValue,
      selectedIndex,
      availableOptions,
      paginate,
    } = this.state;
    return (
      <div>
        <div
          ref={c => {
            this.wrapper = c;
          }}
        >
          {!selectOnly && (
            <SelectedOptions
              values={values}
              onRemoveClick={this.removeOption}
            />
          )}
          {!disableInput && (
            <AnimatedInput
              inputRef={ref => {
                this.input = ref;
              }}
              inputModifier={inputModifier}
              open={opened}
              compact={!selectOnly && values.length > 0}
              selectOnly={selectOnly}
              placeholder={placeholder}
              inputPlaceholder={inputPlaceholder}
              value={queryValue}
              onChange={this.queryValueChange}
              onFocus={this.open}
              onKeyDown={this.handleKeyDown}
              onRequestClose={this.close}
            />
          )}
          <Options
            open={opened}
            selectedIndex={selectedIndex}
            values={availableOptions}
            paginate={paginate}
            onChange={this.selectedValuesChanged}
            onPageEnd={this.handleNewPage}
            createComponent={createComponent}
          />
        </div>
      </div>
    );
  }
}

Select.defaultProps = {
  disableInput: false,
  debounce: 250,
  defaultPageSize: 10,
  placeholder: undefined,
  selectOnly: false,
  onBlur: null,
  onFetchDataError: () => null,
  createComponent: undefined,
  inputModifier: rawValue => rawValue,
};

Select.propTypes = {
  disableInput: PropTypes.bool,
  debounce: PropTypes.number,
  defaultPageSize: PropTypes.number,
  placeholder: PropTypes.string,
  inputPlaceholder: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  selectOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  fetchData: PropTypes.func.isRequired,
  onFetchDataError: PropTypes.func,
  createComponent: PropTypes.func,
  inputModifier: PropTypes.func,
};

export default Select;
