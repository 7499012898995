export const GOT_ROLLOUT = 'ROLLOUT_DETAIL/GOT_ROLLOUT';
export const REQUEST_ROLLOUT = 'ROLLOUT_DETAIL/REQUEST_ROLLOUT';

export const ARCHIVE_ROLLOUT = 'ROLLOUT_DETAIL/ARCHIVE_ROLLOUT';

export const OPEN_ARCHIVE_ROLLOUT_MODAL =
  'ROLLOUT_DETAIL/OPEN_ARCHIVE_ROLLOUT_MODAL';
export const CLOSE_ARCHIVE_ROLLOUT_MODAL =
  'ROLLOUT_DETAIL/CLOSE_ARCHIVE_ROLLOUT_MODAL';

export const UPDATE_ROLLOUT_STATUS = 'ROLLOUT_DETAIL/UPDATE_ROLLOUT_STATUS';

export const SET_CONFLICT_ERROR = 'ROLLOUT_DETAIL/SET_CONFLICT_ERROR';
export const SET_FORCE_SUBMIT = 'ROLLOUT_DETAIL/SET_FORCE_SUBMIT';
export const CLOSE_CONFLICT_MODAL = 'ROLLOUT_DETAIL/CLOSE_CONFLICT_MODAL';
