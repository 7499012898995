import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { fetchPackageVersions } from '../updatehub/api/package';
import { makeRequest } from '../updatehub/http';
import * as actions from './actions';
import { weight } from '../Theme/Typography';
import { getNamespaceUID, getProductUID } from '../Dashboard/reducer';
import { getAuthToken } from '../Auth/reducer';
import { getRawVersion } from './reducer';
import SelectField from '../SingleSelectField';

const style = {
  box: {
    flex: 1,
    marginRight: 25,
  },
  h3: {
    fontSize: 22,
    fontWeight: weight.medium,
    marginBottom: 10,
  },
  p: {
    fontWeight: weight.light,
    fontSize: 14,
    marginBottom: 20,
  },
  selectWrapper: {
    width: '80%',
  },
};

const VersionSelector = ({
  classes,
  fetchData,
  selectedVersionChange,
  selectedVersion,
}) => (
  <div className={classes.box}>
    <h3 className={classes.h3}>Version</h3>
    <p className={classes.p}>
      This is the version you will have when this <br />
      rollout be completed.
    </p>
    <div className={classes.selectWrapper}>
      <SelectField
        id="version-filter"
        placeholder="Choose version"
        inputPlaceholder="Choose version"
        fetchData={fetchData}
        onChange={selectedVersionChange}
        value={selectedVersion}
        single
      />
    </div>
  </div>
);

VersionSelector.defaultProps = {
  selectedVersion: null,
};

VersionSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  selectedVersionChange: PropTypes.func.isRequired,
  selectedVersion: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  selectedVersion: getRawVersion(state),
  fetchData(query, page) {
    const authToken = getAuthToken(state);
    const namespaceUID = getNamespaceUID(state);
    const productUID = getProductUID(state);
    const requestConfig = fetchPackageVersions(
      namespaceUID,
      productUID,
      query,
      page
    );

    return makeRequest(requestConfig, authToken);
  },
});

const mapDispatchToProps = dispatch => ({
  selectedVersionChange(version) {
    dispatch(actions.setVersion(version));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(style)(VersionSelector));
