import Humanize from 'humanize-plus';

const yesOrNo = value => {
  if (value) {
    return 'yes';
  }
  return 'no';
};

const valueOrDash = value => {
  if (value === undefined) {
    return '-';
  }
  return value;
};

const OPTIONS = {
  chip_0_device_path: {
    verboseName: 'Chip-0 device path',
    humanize: valueOrDash,
  },
  chip_1_device_path: {
    verboseName: 'Chip-1 device path',
    humanize: valueOrDash,
  },
  'chunk-size': {
    verboseName: 'Chunk size',
    humanize: Humanize.fileSize,
  },
  count: {
    verboseName: 'Count',
    humanize(value) {
      return value === -1 ? 'all content' : value;
    },
  },
  filesystem: {
    verboseName: 'Filesystem',
    humanize: valueOrDash,
  },
  'format?': {
    verboseName: 'Format device',
    humanize: yesOrNo,
  },
  'format-options': {
    verboseName: 'Format options',
    humanize: valueOrDash,
  },
  mode: {
    verboseName: 'Mode',
    humanize: valueOrDash,
  },
  'mount-options': {
    verboseName: 'Mount options',
    humanize: valueOrDash,
  },
  '1k_padding': {
    verboseName: '1k-padding in the head',
    humanize: yesOrNo,
  },
  search_exponent: {
    verboseName: 'Search exponent',
    humanize: valueOrDash,
  },
  seek: {
    verboseName: 'Seek',
    humanize: valueOrDash,
  },
  skip: {
    verboseName: 'Skip from source',
    humanize: valueOrDash,
  },
  target: {
    verboseName: 'Target',
    humanize: valueOrDash,
  },
  'target-type': {
    verboseName: 'Target type',
    humanize: valueOrDash,
  },
  'target-path': {
    verboseName: 'Target path',
    humanize: valueOrDash,
  },
  truncate: {
    verboseName: 'Truncate',
    humanize: yesOrNo,
  },
  volume: {
    verboseName: 'Volume name',
    humanize: valueOrDash,
  },
  'install-if-different': {
    verboseName: 'Install condition',
    humanize(value) {
      if (value) {
        if (value === 'sha256sum') {
          return 'when content diverges';
        }
        if (value.pattern.length) {
          return 'when version diverges';
        }
      }
      return 'always install';
    },
  },
};

export const MODES = {
  copy: [
    'mode',
    'target',
    'filesystem',
    'format?',
    'format-options',
    'mount-options',
    'target-path',
    'install-if-different',
  ],
  flash: ['mode', 'target', 'install-if-different'],
  imxkobs: [
    'mode',
    '1k_padding',
    'search_exponent',
    'chip_0_device_path',
    'chip_1_device_path',
    'install-if-different',
  ],
  mender: ['mode'],
  zephyr: ['mode'],
  raw: [
    'mode',
    'target',
    'seek',
    'truncate',
    'chunk-size',
    'skip',
    'count',
    'install-if-different',
  ],
  tarball: [
    'mode',
    'target',
    'filesystem',
    'format?',
    'format-options',
    'mount-options',
    'target-path',
    'install-if-different',
  ],
  ubifs: ['mode', 'target', 'install-if-different'],
};

const humanizeMetadataEntry = (rawKey, rawValue) => {
  const key = OPTIONS[rawKey];
  const value = key.humanize ? key.humanize(rawValue) : rawValue;
  return [key.verboseName, value];
};

export const humanizeObjectMetadata = obj => {
  const mode = MODES[obj.mode];
  return mode.map(key => humanizeMetadataEntry(key, obj[key]));
};
