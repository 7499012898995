import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter, Link } from 'react-router-dom';
import url from '../updatehub/urls';
import { Modal, ModalTitle, ModalContent, ModalFooter } from '../Modal';
import { closeConfirmCancelModal } from './actions';
import { getConfirmModal } from './reducer';

const styles = {
  footer: {
    textAlign: 'right',
    width: '100%',
  },
  label: {
    display: 'block',
    marginBottom: 13,
    color: '#3E3E40',
    fontFamily: 'Fira Sans',
    fontSize: 14,
  },
  button: {
    height: 33,
    width: 70,
    border: '2px solid #5CA7DF',
    borderRadius: 20,
    color: '#5CA7DF',
    fontFamily: 'Fira Sans',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.93,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
};

const ConfirmCancelModal = ({ classes, closeModal, isVisible }) => {
  const title = 'Cancellation request sent successfully';
  return (
    <Modal isVisible={isVisible}>
      <ModalContent>
        <ModalTitle title={title} />
        <span className={classes.label}>
          The cancellation process has been started, you will be notified about
          its progress.
        </span>
        <span className={classes.label}>
          We&apos;d like to thank you for all the business. We hope to see you
          again soon!
        </span>
      </ModalContent>
      <ModalFooter>
        <div className={classes.footer}>
          <Link
            className={classes.button}
            onClick={closeModal}
            to={url('billing:list')}
          >
            OK
          </Link>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ConfirmCancelModal.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isVisible: getConfirmModal(state),
});

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(closeConfirmCancelModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectSheet(styles)(ConfirmCancelModal)));
