import React from 'react';
import PropTypes from 'prop-types';
import BaseButton from './BaseButton';

const LinkButton = ({ label, ...props }) => (
  <BaseButton {...props} cls="uh-btn-link">
    {label}
  </BaseButton>
);

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export default LinkButton;
