import { Record } from 'immutable';

export const MemberModel = Record({
  name: '',
  uid: '',
  role: '',
  email: '',
});

export const SelectedMember = Record({
  name: null,
});

export const ModalModel = Record({
  isVisible: false,
});

export const OrganizationModel = Record({
  uid: '',
  name: '',
  createdAt: '',
  myRole: '',
});

export const InviteModel = Record({
  uid: '',
  email: '',
});

export const TransferModel = Record({
  selectTransferIsVisible: false,
  confirmTransferIsVisible: false,
});

export const BillingModel = Record({
  total: 0.0,
});

export const PageModel = Record({
  organization: OrganizationModel(),
  createInviteModal: ModalModel(),
  deleteOrganizationModal: ModalModel(),
  memberToEdit: MemberModel(),
  memberToRemove: MemberModel(),
  inviteToCancel: InviteModel(),
  createTransferModal: TransferModel(),
  selectedMemberToTransfer: SelectedMember(),
  billingToTransfer: BillingModel(),
});
