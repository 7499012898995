import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { Button } from '../Buttons';
import { ArchiveIcon } from '../Icons';
import { rolloutStatus } from './states';
import { openArchiveRolloutModal } from './actions';

const style = {
  box: {
    marginTop: 26,
  },
};

const RolloutArchiveButton = ({ rollout, onClick, classes }) => {
  const disabled = rollout.status === rolloutStatus.archived;
  return (
    <div className={classes.box}>
      <Button
        disabled={disabled}
        onClick={onClick}
        icon={<ArchiveIcon />}
        label="Archive rollout"
        fullWidth
      />
    </div>
  );
};

RolloutArchiveButton.propTypes = {
  rollout: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onClick() {
    dispatch(openArchiveRolloutModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(injectSheet(style)(RolloutArchiveButton));
