import { put, select, takeEvery, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import request from '../updatehub/effects';
import url from '../updatehub/urls';
import tableSaga from '../Table/sagaFactory';
import * as api from '../updatehub/api';
import * as page from '../Page/actions';
import { openNotification } from '../Notification';
import * as actions from './actions';
import { getOrganizationUID } from './reducer';
import {
  DELETE_ORGANIZATION,
  REQUEST_ORGANIZATION,
  REQUEST_MEMBERS,
  REQUEST_INVITES,
  TRANSFER_ORGANIZATION,
} from './types';

export const DELETE_ORGANIZATION_SUCCESS = 'Organization deleted successfully';
export const DELETE_ORGANIZATION_ERROR =
  'Could not delete organization. Try again later';
export const TRANSFER_ORGANIZATION_ERROR =
  'Could not transfer organization. Try again later';

export function* getBilling(organizationUID) {
  const { result } = yield request(api.fetchBillingDetails(organizationUID));
  if (!result) {
    yield put(page.notFound());
  } else {
    yield put(actions.setBillingToTranfer(result));
  }
}

export function* requestOrganizationSaga(action) {
  const { organizationUID } = action.payload;
  const { result } = yield request(api.fetchOrganization(organizationUID));
  if (!result) {
    yield put(page.notFound());
  } else {
    yield call(getBilling, organizationUID);
    yield put(actions.gotOrganization(result));
    yield put(page.loaded());
  }
}

export function* setRequestParams(tableParams) {
  const params = { ...tableParams };
  const organizationUID = yield select(getOrganizationUID);
  return [organizationUID, params];
}

export const requestMembersSaga = tableSaga(
  api.fetchOrganizationMembers,
  actions.gotMembers,
  actions.gotMembersFailed,
  setRequestParams
);

export const requestInvitesSaga = tableSaga(
  api.fetchOrganizationInvites,
  actions.gotInvites,
  actions.gotInvitesFailed,
  setRequestParams
);

export function* deleteOrganizationSaga(action) {
  const { organizationUID } = action.payload;
  const { result } = yield request(api.deleteOrganization(organizationUID));
  yield put(actions.closeDeleteOrganizationModal());
  if (result) {
    yield put(push(url('settings')));
    yield put(openNotification(DELETE_ORGANIZATION_SUCCESS));
  } else {
    yield put(openNotification(DELETE_ORGANIZATION_ERROR));
  }
}

export function* transferOrganizationSaga(action) {
  const organizationUID = yield select(getOrganizationUID);
  const member = action.payload;
  const { result } = yield request(
    api.transferOrganization(organizationUID, member.uid)
  );
  if (!result) {
    yield put(openNotification(TRANSFER_ORGANIZATION_ERROR));
    yield put(actions.cancelTransferOrganizationModal());
  } else {
    yield put(actions.closeTransferOrganizationModal());
  }
}

function* rootSaga() {
  yield takeEvery(TRANSFER_ORGANIZATION, transferOrganizationSaga);
  yield takeEvery(REQUEST_ORGANIZATION, requestOrganizationSaga);
  yield takeEvery(REQUEST_MEMBERS, requestMembersSaga);
  yield takeEvery(REQUEST_INVITES, requestInvitesSaga);
  yield takeEvery(DELETE_ORGANIZATION, deleteOrganizationSaga);
}

export default rootSaga;
