import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';

const legendStyle = { width: 215, right: 0 };
const messageStyle = { fontSize: 12 };

const EmptyLegend = () => (
  <p style={messageStyle}>
    Select a version, and you will see an overview of the rollout you
    {"'"}
    ll create here.
  </p>
);

const Graph = () => (
  <PieChart width={360} height={140}>
    <Pie
      data={[{ value: 100 }]}
      nameKey="name"
      dataKey="value"
      innerRadius="40%"
      outerRadius="65%"
      isAnimationActive={false}
    >
      <Cell key="fill" fill="#F8F8FA" />
    </Pie>
    <Legend
      align="right"
      layout="vertical"
      verticalAlign="middle"
      content={EmptyLegend}
      wrapperStyle={legendStyle}
    />
  </PieChart>
);

export default Graph;
