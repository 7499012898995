import { Record } from 'immutable';

export const PAGE_STATUS = {
  LOADING: 'loading',
  LOADED: 'loaded',
  NOT_FOUND: 'not_found',
  ERROR: 'error',
};

export const PageModel = Record({
  status: PAGE_STATUS.LOADING,
});
