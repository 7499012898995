import { call, select } from 'redux-saga/effects';
import { getAuthToken } from '../Auth/reducer';
import { makeRequest } from './http';

function* request(params) {
  if (!params.decodeSuccess) {
    throw new Error('Request handler: decodeSuccess is required');
  }
  const authToken = yield select(getAuthToken);
  return yield call(makeRequest, params, authToken);
}

export default request;
