import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import StatusFilter from './PackageStatusFilter';
import VersionFilter from './PackageVersionFilter';
import HardwareFilter from './PackageHardwareFilter';

const packageFilterStyle = {
  packageFilter: {
    width: '100%',
    paddingLeft: 56,

    ' & > div': {
      marginBottom: 15,
    },

    '& label': {
      fontSize: 14,
    },
  },
};

const PackageFilter = ({ classes }) => (
  <div className={classes.packageFilter}>
    <StatusFilter />
    <VersionFilter />
    <HardwareFilter />
  </div>
);

PackageFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(packageFilterStyle)(PackageFilter);
