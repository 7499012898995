import identity from 'lodash/fp/identity';
import { checkRequiredKeys, normalizeObjects, tableDecoder } from '../utils';

const recentPackageListDecoder = data => {
  checkRequiredKeys(data, ['packages']);
  return data.packages.map(pkg => {
    checkRequiredKeys(pkg, [
      'uid',
      'version',
      'created_at',
      'supported_hardware',
      'size',
      'status',
      'objects_count',
    ]);
    return {
      uid: pkg.uid,
      version: pkg.version,
      createdAt: pkg.created_at,
      supportedHardware: pkg.supported_hardware,
      packageSize: pkg.size,
      status: pkg.status,
      objectsCount: pkg.objects_count,
    };
  });
};

const packageListDecoder = tableDecoder('packages', data => {
  checkRequiredKeys(data, [
    'uid',
    'version',
    'size',
    'status',
    'supported_hardware',
    'created_at',
  ]);
  return {
    uid: data.uid,
    version: data.version,
    size: data.size,
    status: data.status,
    supportedHardware: data.supported_hardware,
    uploadDate: data.created_at,
  };
});

/* eslint-disable function-paren-newline */
const packageDecoder = data => {
  checkRequiredKeys(data, ['uid', 'metadata', 'size', 'created_at', 'status']);
  checkRequiredKeys(data.metadata, [
    'version',
    'supported-hardware',
    'objects',
  ]);
  data.metadata.objects.forEach(set =>
    set.forEach(obj =>
      checkRequiredKeys(obj, ['filename', 'sha256sum', 'size'])
    )
  );
  return {
    uid: data.uid,
    version: data.metadata.version,
    supportedHardware: data.metadata['supported-hardware'],
    objects: normalizeObjects(data.metadata.objects),
    uploadDate: data.created_at,
    size: data.size,
    status: data.status,
  };
};

const versionsOptionsDecoder = data => {
  checkRequiredKeys(data, ['versions', 'total_count']);

  if (data.versions.length) {
    checkRequiredKeys(data.versions[0], ['version']);
  }

  return {
    options: data.versions.map(({ version }) => ({
      label: version,
      value: version,
    })),
    total_count: data.total_count,
  };
};

const hardwareListOptionsDecoder = data => {
  checkRequiredKeys(data, ['hardware_list', 'total_count']);

  if (data.hardware_list.length) {
    checkRequiredKeys(data.hardware_list[0], ['name']);
  }

  return {
    options: data.hardware_list.map(({ name }) => ({
      label: name,
      value: name,
    })),
    total_count: data.total_count,
  };
};

export const fetchRecentPackages = (namespaceUID, uid) => ({
  path: '/packages',
  params: {
    page: 1,
    per_page: 2,
    order: 'desc',
    sort: 'created_at',
    product_uid: uid,
    status: ['done', 'receiving', 'pending_check', 'error'],
  },
  accepts: [200],
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: recentPackageListDecoder,
});

export const fetchPackages = (
  namespaceUID,
  uid,
  { hardware = null, status = 'done', version = null } = {},
  { page, per_page }
) => ({
  /* eslint camelcase: 0 */
  path: '/packages',
  params: {
    page,
    per_page,
    order: 'desc',
    sort: 'created_at',
    product_uid: uid,
    count: true,
    hardware,
    version,
    status,
  },
  accepts: [200],
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: packageListDecoder,
});

export const fetchPackage = (packageUID, namespaceUID) => ({
  method: 'GET',
  path: `/packages/${packageUID}`,
  headers: { 'UH-Namespace': namespaceUID },
  accepts: [200],
  decodeSuccess: packageDecoder,
});

export const deletePackage = (packageUID, namespaceUID) => ({
  method: 'DELETE',
  path: `/packages/${packageUID}`,
  accepts: [200],
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: identity,
});

export const fetchPackageVersions = (
  namespaceUID,
  productUID,
  version,
  page,
  { packageStatus = 'done' } = {}
) => ({
  method: 'GET',
  path: '/packages/versions',
  params: {
    version,
    page,
    product_uid: productUID,
    count: true,
    package_status: packageStatus,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: versionsOptionsDecoder,
});

export const fetchPackageHardwareList = (
  namespaceUID,
  productUID,
  hardware,
  page,
  { packageStatus = 'done' } = {}
) => ({
  method: 'GET',
  path: '/packages/hardware_list',
  params: {
    hardware,
    page,
    product_uid: productUID,
    count: true,
    package_status: packageStatus,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: hardwareListOptionsDecoder,
});
