import zip from 'lodash/fp/zip';

export const tagToOptions = tag => ({
  label: tag,
  value: tag,
});

export const keyError = key =>
  Error(`"${key}" key must be presented in response data.`);

export const checkRequiredKeys = (data, keys) => {
  keys.forEach(key => {
    if (!(key in data)) {
      throw keyError(key);
    }
  });
  return null;
};

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const tableDecoder = (dataKey, mapFn = null) => body => {
  checkRequiredKeys(body, [dataKey, 'total_count', 'total_pages']);

  let data = body[dataKey];
  if (mapFn) {
    data = body[dataKey].map(mapFn);
  }

  return {
    data,
    totalCount: body.total_count,
    pages: body.total_pages,
  };
};

export const normalizeObjects = objects => {
  if (!objects.length) {
    return null;
  }
  return zip(...objects).map(sets => {
    const obj = sets[0];
    return {
      filename: obj.filename,
      sha256sum: obj.sha256sum,
      size: obj.size,
      sets,
    };
  });
};

export const rolloutConflictTaskParser = (erroredTasks, tasks) =>
  erroredTasks.map(({ queue }, index) => ({
    name: tasks.getIn([index, 'name']) || 'Unknown task',
    conflictsWith: queue.rollouts.map(rollout => ({
      uid: rollout.uid,
      name: rollout.name,
    })),
  }));

/* eslint-disable no-console */
export const logError = (error, description) => {
  if (isDevelopment()) {
    console.error(`[UPDATEHUB] ${error}:`, description);
  }
};

export const logInfo = message => {
  if (isDevelopment()) {
    console.info(`[UPDATEHUB] ${message}`);
  }
};
