import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError } from 'redux-form/immutable';
import { SelectField, TextField } from 'redux-form-material-ui';
import { push } from 'react-router-redux';
import { createProduct } from '../updatehub/api';
import { makeRequest } from '../updatehub/http';
import url from '../updatehub/urls';
import { withAuthToken } from '../Auth';
import { LinkButton, OutlineButton } from '../Buttons';
import { required } from '../Form/validators';
import buildNamespaceOptions from '../FormNamespacesOptions';
import { CheckIcon } from '../Icons';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalLabel,
  ModalFormGroup,
  connectModal,
} from '../Modal';
import { openNotification } from '../Notification';
import {
  createProductKey,
  getModalCreateProduct,
  getNamespaces,
  getDefaultNamespaceUID,
} from './reducer';

export const CREATE_PRODUCT_FAIL = 'Could not create product. Try again later.';

const inputStyle = {
  fontSize: '14px !important',
};

const ModalCreateProduct = ({
  isOpened,
  closeModal,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  namespaces,
}) => (
  <Modal isVisible={isOpened}>
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalFormGroup>
          <ModalLabel htmlFor="name" label="Type your product name" />
          <Field
            id="name"
            name="name"
            validate={[required]}
            component={TextField}
            disabled={submitting}
            style={inputStyle}
            fullWidth
          />
        </ModalFormGroup>
        <ModalFormGroup>
          <ModalLabel htmlFor="namespace" label="Select the product owner" />
          <Field
            id="namespace"
            name="namespace"
            component={SelectField}
            disabled={submitting}
            validate={required}
            style={inputStyle}
            fullWidth
          >
            {buildNamespaceOptions(namespaces)}
          </Field>
        </ModalFormGroup>
      </ModalContent>
      <ModalFooter>
        <LinkButton onClick={closeModal} label="cancel" disabled={submitting} />
        <OutlineButton
          icon={<CheckIcon />}
          label="create"
          type="submit"
          disabled={pristine || invalid || submitting}
        />
      </ModalFooter>
    </form>
  </Modal>
);

ModalCreateProduct.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  namespaces: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

const mapStateToProps = state => ({
  initialValues: {
    namespace: getDefaultNamespaceUID(state),
  },
  namespaces: getNamespaces(state),
  enableReinitialize: true,
});

const reduxConnector = connect(mapStateToProps);

const getRedirectURL = (namespaceUID, productUID) => {
  const base = url('product:dashboard', { namespaceUID, productUID });
  return `${base}?new`;
};

export const submitCreateProductForm = (
  formData,
  dispatch,
  { authToken, closeModal, reset }
) => {
  const namespaceUID = formData.get('namespace');
  const requestConfig = createProduct(namespaceUID, formData.get('name'));
  return makeRequest(requestConfig, authToken).then(({ result, error }) => {
    if (result) {
      closeModal();
      dispatch(push(getRedirectURL(namespaceUID, result.uid)));
      reset();
    } else if (error) {
      throw new SubmissionError(error);
    } else {
      dispatch(openNotification(CREATE_PRODUCT_FAIL));
    }
  });
};

const formConnector = reduxForm({
  form: createProductKey,
  onSubmit: submitCreateProductForm,
});

export const modalConnector = connectModal(
  createProductKey,
  getModalCreateProduct
);

export default withAuthToken(
  reduxConnector(modalConnector(formConnector(ModalCreateProduct)))
);
