import { call, takeEvery, select, put } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './types';
import { getStatus, getVersions, getHardwareList } from './reducer';
import * as uh from '../updatehub/api';
import tableSaga from '../Table/sagaFactory';
import { getContext } from '../Dashboard/sagas';

const extractValue = ({ value }) => value;

export function* setParameters(actualParams) {
  const { namespaceUID, productUID } = yield call(getContext);
  const status = yield select(getStatus);
  let version = yield select(getVersions);
  version = version.map(extractValue);
  let hardware = yield select(getHardwareList);
  hardware = hardware.map(extractValue);

  return [
    namespaceUID,
    productUID,
    { status, version, hardware },
    actualParams,
  ];
}

export const loadPackages = tableSaga(
  uh.fetchPackages,
  actions.receive,
  actions.requestError,
  setParameters
);

export function* triggerLoadPackages() {
  yield put(
    actions.request({
      page: 0,
      pageSize: 10,
    })
  );
}

function* watchForLoadRequest() {
  yield takeEvery(types.REQUEST, loadPackages);
  yield takeEvery(
    [
      types.CHANGE_STATUS_FILTER,
      types.CHANGE_VERSIONS_FILTER,
      types.CHANGE_HARDWARE_LIST_FILTER,
    ],
    triggerLoadPackages
  );
}

export default watchForLoadRequest;
