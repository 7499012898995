import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const style = {
  modalFormGroup: {
    marginBottom: 24,
  },
};

const ModalFormGroup = ({ children, classes }) => (
  <div className={classes.modalFormGroup}>{children}</div>
);

ModalFormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ModalFormGroup);
