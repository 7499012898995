import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '../Buttons';
import { CogIcon } from '../Icons';
import { setMemberToEdit } from './actions';
import { modalConnector } from './UpdateMemberRoleModal';

const OpenUpdateMemberRoleModalButton = ({ onClick }) => (
  <IconButton icon={<CogIcon />} onClick={onClick} />
);

OpenUpdateMemberRoleModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { openModal, member }) => ({
  onClick() {
    dispatch(setMemberToEdit(member));
    openModal();
  },
});

export default modalConnector(
  connect(
    null,
    mapDispatchToProps
  )(OpenUpdateMemberRoleModalButton)
);
