import { Record } from 'immutable';
import * as types from './types';

export const OrganizationInvitationModel = Record({
  token: '',
  organizationName: '',
  invitedBy: '',
  isLoading: false,
});

const organizationInvitationReducer = (
  state = OrganizationInvitationModel(),
  action
) => {
  switch (action.type) {
    case types.REQUEST_INVITATION:
      return OrganizationInvitationModel({
        token: action.payload,
      });
    case types.RECEIVE_INVITATION:
      return state.withMutations(oldState =>
        oldState
          .set('organizationName', action.payload.organization.name)
          .set('invitedBy', action.payload.invitedBy));
    case types.ACCEPT_INVITE:
      return state.set('isLoading', true);
    case types.REJECT_INVITE:
      return state.set('isLoading', true);
    default:
      return state;
  }
};

export default organizationInvitationReducer;

// Selectors

export const getInvitationToken = state =>
  state.getIn(['organizationInvitation', 'token']);

export const getOrganizationName = state =>
  state.getIn(['organizationInvitation', 'organizationName']);

export const getInvitedBy = state =>
  state.getIn(['organizationInvitation', 'invitedBy']);

export const getIsLoading = state =>
  state.getIn(['organizationInvitation', 'isLoading']);
