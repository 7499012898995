import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStatus } from '../reducer';
import * as actions from '../actions';
import StatusFilter from '../../StatusFilter';

export const PACKAGE_STATUS = [
  {
    label: 'Available Packages',
    value: 'done',
  },
  {
    label: 'Uploading in progress',
    value: 'receiving',
  },
  {
    label: 'Removed Packages',
    value: 'removed',
  },
  {
    label: 'Pending process packages',
    value: 'pending_check',
  },
  {
    label: 'Packages with error',
    value: 'error',
  },
];

const PackageStatusFilter = ({ value, onChange }) => (
  <StatusFilter
    label="Status"
    value={value}
    statuses={PACKAGE_STATUS}
    onChange={onChange}
  />
);

PackageStatusFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  value: getStatus(state),
});

const mapDispatchToProps = dispatch => ({
  onChange(value) {
    dispatch(actions.changeStatusFilter(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageStatusFilter);
