import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Page from '../Page';
import { H2, H3 } from '../Dashboard/Typography';
import RolloutTable from './RolloutTable';
import CreateRolloutButton from '../OverviewPage/ActiveRolloutsWidget/CreateRolloutButton';

const gridStyle = {
  width: '100%',
  paddingTop: 31,
};

const h2Style = { paddingBottom: 16 };
const h3Style = { paddingBottom: 8 };
const rowStyle = { paddingBottom: 8 };
const colStyle = { textAlign: 'right' };

export default () => (
  <Page>
    <Grid style={gridStyle} fluid>
      <Row>
        <Col xs={8}>
          <H2 style={h2Style}>Rollouts</H2>
        </Col>
        <Col xs={4} style={colStyle}>
          <CreateRolloutButton />
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={12}>
          <H3 style={h3Style}>Rollouts list</H3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <RolloutTable />
        </Col>
      </Row>
    </Grid>
  </Page>
);
