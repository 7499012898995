import React from 'react';
import PropTypes from 'prop-types';
import {
  PageTitleWithHash,
  HeaderInfo,
  FixedHeaderEntry,
  JustifiedPageHeader,
} from '../PageHeader';
import OpenModalButton from './OpenDeleteDeviceModalButton';

const Header = ({ device }) => (
  <JustifiedPageHeader>
    <FixedHeaderEntry>
      <PageTitleWithHash title="Device" hash={device.uid} />
    </FixedHeaderEntry>
    {device.identity.map(([label, value]) => (
      <FixedHeaderEntry key={label}>
        <HeaderInfo label={label} value={value} />
      </FixedHeaderEntry>
    ))}
    <FixedHeaderEntry>
      <OpenModalButton device={device} />
    </FixedHeaderEntry>
  </JustifiedPageHeader>
);

Header.propTypes = {
  device: PropTypes.object.isRequired,
};

export default Header;
