import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';
import { humanizeObjectMetadata } from '../updatehub/metadata';

const style = {
  box: {
    marginTop: 25,
  },
  entry: {
    lineHeight: '19px',
    marginBottom: 4,
    overflow: 'auto',
  },
  key: {
    float: 'left',
    fontWeight: weight.medium,
    width: 140,
  },
};

const MetadataEntry = injectSheet(style)(({ entry, classes }) => {
  const [key, value] = entry;
  return (
    <div className={classes.entry}>
      <span className={classes.key}>{key}</span>
      {value}
    </div>
  );
});

MetadataEntry.propTypes = {
  entry: PropTypes.array.isRequired,
};

const ObjectMetadata = ({ set, classes }) => {
  const values = humanizeObjectMetadata(set);
  return (
    <div className={classes.box}>
      {values.map(entry => (
        <MetadataEntry key={entry[0]} entry={entry} />
      ))}
    </div>
  );
};

ObjectMetadata.propTypes = {
  set: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ObjectMetadata);
