import { combineReducers } from 'redux-immutable';
import PackageModel, { DeleteModalModel } from './models';
import * as types from './types';

export const packageReducer = (state = PackageModel(), action) => {
  switch (action.type) {
    case types.GOT_PACKAGE:
      return PackageModel(action.payload);
    default:
      return state;
  }
};

/* eslint-disable function-paren-newline */
export const deletePackageReducer = (state = DeleteModalModel(), action) => {
  switch (action.type) {
    case types.OPEN_DELETE_MODAL:
      return state.withMutations(newState =>
        newState.set('isOpened', true).set('disabled', false)
      );
    case types.CLOSE_DELETE_MODAL:
      return state.set('isOpened', false);
    case types.REQUEST_DELETE_PACKAGE:
      return state.set('disabled', true);
    default:
      return state;
  }
};

export default combineReducers({
  pkg: packageReducer,
  deleteModal: deletePackageReducer,
});

// Selectors

export const getPackage = state => state.getIn(['packageDetail', 'pkg']);

export const getPackageStatus = state =>
  state.getIn(['packageDetail', 'pkg', 'status']);

export const getDeleteModalStatus = state =>
  state.getIn(['packageDetail', 'deleteModal', 'isOpened']);

export const getButtonState = state =>
  state.getIn(['packageDetail', 'deleteModal', 'disabled']);
