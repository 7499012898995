import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';

const style = {
  columnEntry: {
    borderBottom: '2px solid #F0F1F4',
    display: 'flex',
    padding: [15, 4],
  },
  entryLabel: {
    fontSize: 14,
    fontWeight: weight.medium,
    marginBottom: 6,
  },
  entryValue: {
    fontWeight: weight.regular,
  },
  entryIcon: {
    display: 'flex',
    alignItems: 'center',
    width: 40,
  },
};

const OverviewColumnEntry = ({ label, value, icon, classes }) => (
  <div className={classes.columnEntry}>
    <div className={classes.entryIcon}>{icon}</div>
    <div>
      <h3 className={classes.entryLabel}>{label}</h3>
      <p className={classes.entryValue}>{value}</p>
    </div>
  </div>
);

OverviewColumnEntry.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(OverviewColumnEntry);
