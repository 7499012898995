import React from 'react';
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import theme from './theme';
import './style.css';

const Theme = ({ children }) => (
  <MuiThemeProvider muiTheme={theme}>
    <div>{children}</div>
  </MuiThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
