import * as types from './types';
import { openStartTaskModal as openModal } from '../../StartRolloutTaskModal/actions';

export const requestRollouts = () => ({
  type: types.REQUEST_ROLLOUTS,
});

export const setRollouts = payload => ({
  type: types.SET_ROLLOUTS,
  payload,
});

export const setRolloutsError = () => ({
  type: types.SET_ROLLOUTS_ERROR,
});

export const archiveRollout = (rolloutUID, namespaceUID) => ({
  type: types.ARCHIVE_ROLLOUT,
  payload: { namespaceUID, rolloutUID },
});

export const openStartTaskModal = (task, namespaceUID) =>
  openModal({
    page: 'overview-page',
    taskUID: task.uid,
    rolloutUID: task.rolloutUID,
    namespaceUID,
  });
