import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import round from 'lodash/fp/round';

export const barColors = {
  device: '#3D49A0',
  bandwidth: '#B68FF8',
  storage: '#6596FF',
  activation: '#45CFEF',
  update: '#90E8C6',
};

const styles = {
  bar: {
    background: 'linear-gradient(0deg, #F4F4F4 53%, #EEEEEE 71%, #ECECEC 87%)',
    borderRadius: 20,
    height: 8,
    overflow: 'hidden',
    marginBottom: 27,
  },
  progress: {
    float: 'left',
    height: '100%',
  },
  deviceBar: {
    extend: 'progress',
    background: barColors.device,
  },
  bandwidthBar: {
    extend: 'progress',
    background: barColors.bandwidth,
  },
  storageBar: {
    extend: 'progress',
    background: barColors.storage,
  },
  activationBar: {
    extend: 'progress',
    background: barColors.activation,
  },
  updateBar: {
    extend: 'progress',
    background: barColors.update,
  },
};

const percent = (value, total) => round((value / total) * 100, 1);

const makeBarStyle = (value, total) => ({
  width:
    `${percent(value, total)}%` !== 'NaN%' ? `${percent(value, total)}%` : 0,
});

const Bar = ({
  classes,
  total,
  devicesPrice,
  transferPrice,
  storagePrice,
  activationsPrice,
  deploymentsPrice,
}) => (
  <div className={classes.bar}>
    <div
      style={{ ...makeBarStyle(devicesPrice, total) }}
      className={classes.deviceBar}
    />
    <div
      style={{ ...makeBarStyle(transferPrice, total) }}
      className={classes.bandwidthBar}
    />
    <div
      style={{ ...makeBarStyle(storagePrice, total) }}
      className={classes.storageBar}
    />
    <div
      style={{ ...makeBarStyle(activationsPrice, total) }}
      className={classes.activationBar}
    />
    <div
      style={{ ...makeBarStyle(deploymentsPrice, total) }}
      className={classes.updateBar}
    />
  </div>
);

Bar.propTypes = {
  classes: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  devicesPrice: PropTypes.number.isRequired,
  transferPrice: PropTypes.number.isRequired,
  storagePrice: PropTypes.number.isRequired,
  activationsPrice: PropTypes.number.isRequired,
  deploymentsPrice: PropTypes.number.isRequired,
};

export default injectSheet(styles)(Bar);
