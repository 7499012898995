import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '../Buttons';
import { TrashIcon } from '../Icons';
import { setInviteToCancel } from './actions';
import { modalConnector } from './CancelInviteModal';

const OpenCancelInviteModalButton = ({ onClick }) => (
  <IconButton onClick={onClick} icon={<TrashIcon />} />
);

OpenCancelInviteModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { openModal, invite }) => ({
  onClick() {
    dispatch(setInviteToCancel(invite));
    openModal();
  },
});

const reduxConnector = connect(
  null,
  mapDispatchToProps
);

export default modalConnector(reduxConnector(OpenCancelInviteModalButton));
