export const SET_DETAILS = 'DETAILS/SET_DETAILS';
export const REQUEST_DETAILS = 'DETAILS/REQUEST_DETAILS';
export const SET_NAMESPACE = 'DETAILS/SET_NAMESPACE';
export const DOWNLOAD_ORDER_REPORT = 'DETAILS/DOWNLOAD_ORDER';
export const DOWNLOADED_ORDER_REPORT = 'DETAILS/DOWNLOADED_ORDER';
export const CANCEL_SUBSCRIPTION = 'DETAILS/CANCEL_SUBSCRIPTION';
export const OPEN_CANCEL_SUBSCRTIPTION_MODAL =
  'DETAILS/OPEN_CANCEL_SUBSCRTIPTION_MODAL';
export const CLOSE_CANCEL_SUBSCRTIPTION_MODAL =
  'DETAILS/CLOSE_CANCEL_SUBSCRTIPTION_MODAL';
export const ENABLE_CANCEL_BUTTON = 'DETAILS/ENABLE_CANCEL_BUTTON';
export const OPEN_CONFIRM_CANCEL_MODAL = 'DETAILS/OPEN_CONFIRM_CANCEL_MODAL';
export const CLOSE_CONFIRM_CANCEL_MODAL = 'DETAILS/CLOSE_CONFIRM_CANCEL_MODAL';
