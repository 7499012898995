import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { WarningIcon } from '../../Icons';
import { weight } from '../../Theme/Typography';

const styles = {
  errorList: {
    listStyle: 'none',
    marginLeft: '24px',
    marginBottom: '24px',
  },
  error: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '12px',

    '& > div': {
      marginRight: '10px',
    },
  },

  errorInfo: {
    '& span': {
      fontSize: '14px',
      fontWeight: weight.light,
      display: 'block',
      letterSpacing: '0px',
    },
  },
};

const warningIconStyle = {
  width: '13px',
  height: '12px',
};

/* eslint-disable react/no-array-index-key */
const ErrorList = ({ classes, errorList }) => (
  <ul className={classes.errorList}>
    {errorList.map(({ name, errors }) => (
      <li className={classes.error} key={name}>
        <div>
          <WarningIcon color="#F1C200" style={warningIconStyle} />
        </div>
        <div className={classes.errorInfo}>
          <span>{name}</span>
          {errors.map(error => (
            <span key={error}>{error}</span>
          ))}
        </div>
      </li>
    ))}
  </ul>
);

ErrorList.propTypes = {
  classes: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};

export default injectSheet(styles)(ErrorList);
