import * as apiKey from './apiKey';
import * as devices from './device';
import * as namespace from './namespace';
import * as organization from './organization';
import * as packageApi from './package';
import * as product from './product';
import * as rollout from './rollout';
import * as billing from './billing';

// to include with
// import { fetchDevices } from 'path to api index'
export * from './apiKey';
export * from './device';
export * from './namespace';
export * from './organization';
export * from './package';
export * from './product';
export * from './rollout';
export * from './billing';

// to include with
// import api from 'path to api index'
export default {
  ...apiKey,
  ...devices,
  ...namespace,
  ...organization,
  ...packageApi,
  ...product,
  ...rollout,
  ...billing,
};
