import * as types from './types';

export const setVersion = payload => ({
  type: types.SET_VERSION,
  payload,
});

export const setForceCreation = payload => ({
  type: types.SET_FORCE_CREATION,
  payload,
});

export const setScope = payload => ({
  type: types.SET_SCOPE,
  payload,
});

export const setDevicesCount = payload => ({
  type: types.SET_DEVICES_COUNT,
  payload,
});

export const submit = () => ({
  type: types.SUBMIT,
});

export const submitStart = () => ({
  type: types.SUBMIT_START,
});

export const submitSuccess = () => ({
  type: types.SUBMIT_SUCCESS,
});

export const toggleAdvancedMode = () => ({
  type: types.TOGGLE_ADVANCED_MODE,
});

export const setFilters = payload => ({
  type: types.SET_FILTERS,
  payload,
});

export const setRemainingDevices = payload => ({
  type: types.SET_REMAINING_DEVICES,
  payload,
});

export const updateGraphTitle = payload => ({
  type: types.UPDATE_GRAPH_TITLE,
  payload,
});

export const updateGraphData = payload => ({
  type: types.UPDATE_GRAPH_DATA,
  payload,
});

// Tasks

export const changeSelectedTask = index => ({
  type: types.CHANGE_SELECTED_TASK,
  payload: index,
});

export const addTask = () => ({
  type: types.ADD_TASK,
});

export const updateTask = (index, key, payload) => ({
  type: types.UPDATE_TASK,
  index,
  key,
  payload,
});

export const updateTaskFilter = (index, payload) => ({
  type: types.UPDATE_TASK_FILTER,
  index,
  payload,
});

export const updateTaskFilters = (index, payload) => ({
  type: types.UPDATE_TASK_FILTERS,
  index,
  payload,
});

export const requestRemoveTask = index => ({
  type: types.REQUEST_REMOVE_TASK,
  index,
});

export const removeTask = index => ({
  type: types.REMOVE_TASK,
  index,
});

export const resetTasks = () => ({
  type: types.RESET_TASKS,
});

// Error Modal

export const setCreateErrorModal = payload => ({
  type: types.SET_CREATE_ERROR_MODAL,
  payload,
});

export const setConflictError = payload => ({
  type: types.SET_CONFLICT_ERROR,
  payload,
});

export const setError = payload => ({
  type: types.SET_ERROR,
  payload,
});

export const closeErrorModal = () => ({
  type: types.CLOSE_ERROR_MODAL,
});
