import * as table from '../Table/actionFactory';
import * as types from './types';

export const requestOrganization = organizationUID => ({
  type: types.REQUEST_ORGANIZATION,
  payload: { organizationUID },
});

export const gotOrganization = organization => ({
  type: types.GOT_ORGANIZATION,
  payload: { organization },
});

export const requestMembers = table.requestAction(types.REQUEST_MEMBERS);

export const refreshMembersTable = () =>
  requestMembers({ page: 0, pageSize: 5 });

export const gotMembers = table.receiveAction(types.GOT_MEMBERS);

export const gotMembersFailed = table.requestErrorAction(
  types.GOT_MEMBERS_FAILED
);

export const resetMembersStore = table.resetAction(types.RESET_MEMBERS_STORE);

export const setMemberToRemove = member => ({
  type: types.SET_MEMBER_TO_REMOVE,
  payload: { member },
});

export const setMemberToEdit = member => ({
  type: types.SET_MEMBER_TO_EDIT,
  payload: { member },
});

export const requestInvites = table.requestAction(types.REQUEST_INVITES);

export const refreshInvitesTable = () =>
  requestInvites({ page: 0, pageSize: 5 });

export const gotInvites = table.receiveAction(types.GOT_INVITES);

export const gotInvitesFailed = table.requestErrorAction(
  types.GOT_INVITES_FAILED
);

export const resetInvitesStore = table.resetAction(types.RESET_INVITES_STORE);

export const closeCreateInviteModal = () => ({
  type: types.CLOSE_CREATE_INVITE_MODAL,
});

export const openCreateInviteModal = () => ({
  type: types.OPEN_CREATE_INVITE_MODAL,
});

export const openDeleteOrganizationModal = () => ({
  type: types.OPEN_DELETE_ORGANIZATION_MODAL,
});

export const closeDeleteOrganizationModal = () => ({
  type: types.CLOSE_DELETE_ORGANIZATION_MODAL,
});

export const deleteOrganization = organizationUID => ({
  type: types.DELETE_ORGANIZATION,
  payload: { organizationUID },
});

export const setInviteToCancel = invite => ({
  type: types.SET_INVITE_TO_CANCEL,
  payload: { invite },
});

export const openTransferOrganizationModal = () => ({
  type: types.OPEN_TRANSFER_ORGANIZATION_MODAL,
});

export const closeTransferOrganizationModal = () => ({
  type: types.CLOSE_TRANSFER_ORGANIZATION_MODAL,
});

export const cancelTransferOrganizationModal = () => ({
  type: types.CANCEL_TRANSFER_ORGANIZATION_MODAL,
});

export const setMemberToTransfer = payload => ({
  type: types.SET_MEMBER_TO_TRANSFER,
  payload,
});

export const transferOrganization = payload => ({
  type: types.TRANSFER_ORGANIZATION,
  payload,
});

export const setBillingToTranfer = payload => ({
  type: types.SET_BILLING_TO_TRANSFER,
  payload,
});

export const closeConfirmTransferOrganizationModal = () => ({
  type: types.CLOSE_CONFIRM_TRANSFER_ORGANIZATION_MODAL,
});
