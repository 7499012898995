import { Record } from 'immutable';
import * as types from './types';

export const ApiKeyModel = Record({
  isOpen: false,
  result: null,
});

/* eslint-disable function-paren-newline */
const createApiKeyModalReducer = (state = ApiKeyModel(), action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return state.set('isOpen', true);
    case types.SUBMIT_SUCCESS:
      return state.withMutations(oldState =>
        oldState.set('result', action.payload)
      );
    case types.CLOSE_MODAL:
      return ApiKeyModel();
    default:
      return state;
  }
};

export default createApiKeyModalReducer;

// Selectors

export const getModalIsOpen = state =>
  state.getIn(['settings', 'createApiKeyModal', 'isOpen']);

export const getResult = state =>
  state.getIn(['settings', 'createApiKeyModal', 'result']);
