import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Transition from 'react-transition-group/Transition';
import ClearIcon from 'material-ui/svg-icons/navigation/close';
import Placeholder from './AnimatedInput/Placeholder';
import PlusPlaceholder from './AnimatedInput/PlusPlaceholder';
import { blue } from '../Theme/colors';

const inputEnterDelay = 150;
const styles = {
  animatedInput: {
    width: '100%',
    minWidth: 261,
    height: 40,
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '3px 3px 0 0',
    outline: 'none',
  },
  inputBox: {
    top: '40px',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    transition: `top ${inputEnterDelay}ms linear`,
    border: 'none',
    outline: 'none',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',

    '& > input': {
      flex: '1 0',
      height: '100%',
      outline: 'none',
      border: 'none',
      paddingLeft: 11,
    },

    '& > input::placeholder': {
      fontStyle: 'italic',
      fontSize: '14px',
      color: '#F0F1F4',
    },
  },
  inputIcon: {
    cursor: 'pointer',
    width: '17px !important',
    height: '17px !important',
    marginRight: 15,
  },
};

class AnimatedInput extends React.Component {
  static getInputStyle(status) {
    if (status === 'entering' || status === 'entered') {
      return {
        top: '0px',
      };
    }

    return {};
  }

  constructor() {
    super();

    this.focus = this.focus.bind(this);
    this.triggerOnFocus = this.triggerOnFocus.bind(this);
    this.triggerOnChange = this.triggerOnChange.bind(this);
  }

  focus() {
    this.input.focus();
  }

  triggerOnFocus(e) {
    const { onFocus } = this.props;
    onFocus(e);
  }

  triggerOnChange(e) {
    const { onChange, inputModifier } = this.props;
    onChange(e, inputModifier(this.input.value));
  }

  render() {
    const {
      classes,
      compact,
      placeholder,
      inputPlaceholder,
      inputRef,
      value,
      open,
      onKeyDown,
      onRequestClose,
      selectOnly,
    } = this.props;

    return (
      <div className={classes.animatedInput}>
        {!compact && (
          <Placeholder
            text={placeholder}
            onClick={this.triggerOnFocus}
            selectOnly={selectOnly}
          />
        )}
        {compact && <PlusPlaceholder onClick={this.triggerOnFocus} />}
        <Transition in={open} timeout={inputEnterDelay} onEntered={this.focus}>
          {status => (
            <div
              className={classes.inputBox}
              style={AnimatedInput.getInputStyle(status)}
            >
              <input
                ref={c => {
                  inputRef(c);
                  this.input = c;
                }}
                tabIndex="-1"
                placeholder={inputPlaceholder}
                value={value}
                onChange={this.triggerOnChange}
                onBlur={this.cancelFocus}
                onKeyDown={onKeyDown}
              />
              <ClearIcon
                className={classes.inputIcon}
                color={blue}
                onClick={onRequestClose}
              />
            </div>
          )}
        </Transition>
      </div>
    );
  }
}

AnimatedInput.defaultProps = {
  placeholder: 'any',
  compact: false,
  selectOnly: false,
  inputRef: () => null,
};

AnimatedInput.propTypes = {
  classes: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  placeholder: PropTypes.string,
  inputPlaceholder: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
  value: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  selectOnly: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  inputModifier: PropTypes.func.isRequired,
};

export default injectSheet(styles)(AnimatedInput);
