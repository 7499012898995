import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Section from './Section';
import { weight } from '../Theme/Typography';

const styles = {
  title: {
    fontWeight: weight.medium,
    fontSize: 24,
    color: '#3E3E40',
  },
};

const Header = ({ classes }) => (
  <Section>
    <h1 className={classes.title}>Settings</h1>
  </Section>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Header);
