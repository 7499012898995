import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const styles = {
  content: {
    paddingTop: 40,
    width: 930,
    margin: '0 auto 44px auto',
  },
};

const Content = ({ classes, children }) => (
  <div className={classes.content}>{children}</div>
);

Content.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default injectSheet(styles)(Content);
