import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { fontFamily, weight } from './Typography';
import { blue, grey400, grey800, transparentPurple, white } from './colors';

const buttonStyle = {
  textColor: blue,
  fontSize: 12,
  fontWeight: weight.semibold,
};

const theme = getMuiTheme({
  appBar: {
    color: white,
    height: 72,
    padding: 40,
    textColor: grey800,
  },
  borderRadius: 2,
  button: {
    height: 40,
    minWidth: 166,
  },
  flatButton: buttonStyle,
  fontFamily,
  inkBar: {
    backgroundColor: blue,
  },
  menuItem: {
    selectedTextColor: blue,
  },
  overlay: {
    backgroundColor: transparentPurple,
  },
  palette: {
    primary1Color: blue,
    textColor: grey800,
  },
  raisedButton: buttonStyle,
  svgIcon: {
    color: grey400,
  },
  tabs: {
    backgroundColor: 'transparent',
    textColor: grey800,
    selectedTextColor: grey800,
  },
});

export default theme;
