import { combineReducers } from 'redux-immutable';
import * as types from './types';
import {
  SubscriptionStateModel,
  FormModel,
  CustomerModel,
  SUBSCRIPTION_STEP,
  FINALIZE_STEP,
  FINALIZE_FAIL_STEP,
  CUSTOMER_FAIL_STEP,
} from './models';

export const formReducer = (state = FormModel(), action) => {
  switch (action.type) {
    case types.COUNTRIES_LOADED:
      return state.mergeIn(['countries'], action.payload);
    default:
      return state;
  }
};

export const managerReducer = (state = SubscriptionStateModel({}), action) => {
  switch (action.type) {
    case types.SET_NAMESPACE:
      return state.set('namespace', action.namespace);
    case types.SUBSCRIBE:
      return SubscriptionStateModel({}).set('namespaceUID', action.uid);
    case types.START_SUBSCRIPTION:
      return state.set('step', SUBSCRIPTION_STEP);
    case types.FINALIZE_SUBSCRIPTION:
      return state.set('step', FINALIZE_STEP);
    case types.FAIL_SUBSCRIPTION:
      return state
        .set('notificationMessage', action.payload)
        .set('step', FINALIZE_FAIL_STEP);
    case types.FAIL_CUSTOMER:
      return state.set('step', CUSTOMER_FAIL_STEP);
    default:
      return state;
  }
};

export const customerReducer = (state = CustomerModel(), action) => {
  switch (action.type) {
    case types.SET_CUSTOMER:
      return state.merge(state, action.payload);
    default:
      return state;
  }
};

export default combineReducers({
  manager: managerReducer,
  form: formReducer,
  customer: customerReducer,
});

const getSlice = state => state.getIn(['billingSubscription', 'manager']);

export const getStep = state => getSlice(state).get('step');

export const getNotificationMessage = state =>
  getSlice(state).get('notificationMessage');

export const getNamespaceUID = state => getSlice(state).get('namespaceUID');

export const getNamespace = state => getSlice(state).get('namespace');

export const getCountries = state =>
  state.getIn(['billingSubscription', 'form', 'countries']);

export const getCustomer = state =>
  state.getIn(['billingSubscription', 'customer']);
