import React from 'react';
import PropTypes from 'prop-types';

const filterGroupStyle = {
  paddingTop: 15,
};

const FilterGroup = ({ children, style }) => (
  <div style={{ ...style, ...filterGroupStyle }}>{children}</div>
);

FilterGroup.defaultProps = {
  style: {},
};

FilterGroup.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default FilterGroup;
