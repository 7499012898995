import { grey800 } from '../Theme/colors';
import { weight } from '../Theme/Typography';
import {
  stepStatus,
  stepName,
} from '../updatehub/api/deviceTransactionSerializer';
import {
  DeviceRunningIcon,
  DeviceErrorIcon,
  DeviceCompletedIcon,
} from '../Icons';

const lightGrey = '#B8BAC0';
const darkGrey = '#F0F1F4';
const solidLine = `2px solid ${darkGrey}`;
const dashedLine = `1px dashed ${darkGrey}`;

const activeStepStyle = (bgIcon, animate) => ({
  '& $stepStatusCircle': {
    animation: animate ? 'spin 3s linear infinite' : 'none',
    background: `url(${bgIcon}) no-repeat center`,
    boxShadow: '0 0 12px 0 #E6E6E9',
    height: 32,
    width: 32,
  },
  '& $stepTime, & $stepLabel': {
    marginTop: 7,
  },

  fontWeight: weight.medium,
});

const idleStepStyle = {
  '& $stepStatusCircle': {
    background: darkGrey,
  },
  '& $stepStatusLine': {
    borderLeft: solidLine,
  },
};

const pendingStepStyle = { color: lightGrey };

const style = {
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(-360deg)',
    },
  },
  loadingBox: {
    textAlign: 'center',
  },
  transaction: {
    alignItems: 'center',
    clear: 'both',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  step: {
    display: 'flex',
    '&:last-child $stepStatusLine': {
      display: 'none',
    },
  },
  stepTime: {
    width: 100,
    marginTop: -1,
    textAlign: 'right',
  },
  stepStatus: {
    width: 100,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  stepLabel: {
    width: 100,
    marginTop: -1,
    textAlign: 'left',
  },
  // Status
  stepStatusLine: {
    borderLeft: dashedLine,
    height: 20,
  },
  stepStatusCircle: {
    border: `1px solid ${darkGrey}`,
    borderRadius: '50%',
    display: 'inline-block',
    height: 15,
    width: 15,
  },
  pending: pendingStepStyle,
  readyToStart: pendingStepStyle,
  running: activeStepStyle(DeviceRunningIcon, true),
  justFinished: activeStepStyle(DeviceCompletedIcon),
  finished: idleStepStyle,
  allFinished: {
    ...activeStepStyle(DeviceCompletedIcon),
    color: grey800,
  },
  error: activeStepStyle(DeviceErrorIcon),
  notReported: {
    ...idleStepStyle,
    ...pendingStepStyle,
  },
  logErrorButtonContainer: {
    textAlign: 'right',
  },
};
const stepToClass = (step, classes) => {
  switch (step.status) {
    case stepStatus.pending:
      return classes.pending;
    case stepStatus.readyToStart:
      return classes.readyToStart;
    case stepStatus.running:
      return classes.running;
    case stepStatus.justFinished:
      return classes.justFinished;
    case stepStatus.finished:
      return step.name === stepName.finish
        ? classes.allFinished
        : classes.finished;
    case stepStatus.error:
      return classes.error;
    case stepStatus.notReported:
    default:
      return classes.notReported;
  }
};

export const getStyleForStep = (step, classes) => {
  const cls = stepToClass(step, classes);
  return [classes.step, cls].join(' ');
};

export default style;
