import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import MUIAutoComplete from 'material-ui/AutoComplete';
import { TextField, AutoComplete } from 'redux-form-material-ui';
import { reduxForm, Field, SubmissionError } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { startSubscription } from './actions';
import { isEmail, required } from '../Form/validators';
import { OutlineButton } from '../Buttons';
import { getCountries, getNamespaceUID } from './reducer';
import { createCustomer } from '../updatehub/api';
import { makeRequest } from '../updatehub/http';
import { openNotification } from '../Notification';
import { getAuthToken } from '../Auth/reducer';

export const SUCCESS_MESSAGE =
  'Customer has been created, going to subscription';
export const UNKNOWN_ERROR = 'Something went wrong. Try again later.';

const onSubmit = (formData, dispatch, { authToken, namespaceUID }) => {
  const requestConfig = createCustomer(namespaceUID, formData.toJS());

  return makeRequest(requestConfig, authToken).then(({ result, error }) => {
    if (result) {
      dispatch(openNotification(SUCCESS_MESSAGE));
      dispatch(startSubscription());
    } else if (error) {
      throw new SubmissionError(error);
    } else {
      dispatch(openNotification(UNKNOWN_ERROR));
    }
  });
};

const formKey = 'billing-customer';

const styles = {
  box: {
    height: 475,
    backgroundColor: '#FFF',
    paddingTop: 51,
    borderRadius: '0 0 2px 2px',
    boxShadow: '0 0 12px 0 #E5E6E8',
  },
  labelForm: {
    display: 'block',
    color: '#3E3E40',
    fontSize: 16,
    fontWeight: 500,
  },
  label: {
    extend: 'labelForm',
    marginLeft: 85,
    marginBottom: 53,
    fontSize: 22,
  },
  formBox: {
    marginBottom: 28,
    transitionDelay: '9999s',
    '& > input': {
      fontSize: '14px  !important',
      color: '#3E3E40 !important',

      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
  },
  table: {
    display: 'inline-table',
    width: 338,
    marginLeft: 85,
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 89,
  },
};

const CustomerForm = ({
  classes,
  countries,
  handleSubmit,
  submitting,
  invalid,
  pristine,
}) => (
  <div className={classes.box}>
    <span className={classes.label}>Billing Information</span>
    <form onSubmit={handleSubmit}>
      <div className={classes.table}>
        <span className={classes.labelForm}>First name</span>
        <Field
          id="firstName"
          name="first_name"
          type="text"
          autoComplete="given-name"
          validate={[required]}
          component={TextField}
          disabled={submitting}
          className={classes.formBox}
          fullWidth
        />
        <span className={classes.labelForm}>Last name</span>
        <Field
          id="lastName"
          name="last_name"
          type="text"
          autoComplete="family-name"
          validate={[required]}
          component={TextField}
          disabled={submitting}
          className={classes.formBox}
          fullWidth
        />
        <span className={classes.labelForm}>Email</span>
        <Field
          id="email"
          name="email"
          type="text"
          autoComplete="email"
          validate={[required, isEmail]}
          component={TextField}
          disabled={submitting}
          className={classes.formBox}
          fullWidth
        />
      </div>
      <div className={classes.table}>
        <span className={classes.labelForm}>Company</span>
        <Field
          id="company"
          name="company"
          type="text"
          autoComplete="organization"
          component={TextField}
          disabled={submitting}
          className={classes.formBox}
          fullWidth
        />
        <span className={classes.labelForm}>Country</span>
        <Field
          id="country"
          name="country"
          component={AutoComplete}
          filter={MUIAutoComplete.fuzzyFilter}
          dataSourceConfig={{ text: 'text', value: 'value' }}
          dataSource={countries.map(country => ({
            value: country.value,
            text: country.name,
          }))}
          maxSearchResults={3}
          openOnFocus
          className={classes.formBox}
          fullWidth
        />
      </div>
      <div className={classes.button}>
        <OutlineButton
          label="Save and continue"
          disabled={pristine || submitting || invalid}
          type="submit"
        />
      </div>
    </form>
  </div>
);

CustomerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

const reduxConnector = connect(state => ({
  countries: getCountries(state).toJS(),
  authToken: getAuthToken(state),
  namespaceUID: getNamespaceUID(state),
}));

const formProps = {
  form: formKey,
  onSubmit,
};

export default injectSheet(styles)(
  reduxConnector(reduxForm(formProps)(CustomerForm))
);
