import React from 'react';
import PropTypes from 'prop-types';
import { fromNow } from '../updatehub/humanizer';
import {
  JustifiedPageHeader,
  PageTitle,
  HeaderInfo,
  FixedHeaderEntry,
} from '../PageHeader';
import ToggleRolloutStatusButton from './ToggleRolloutStatusButton';

const Header = ({ rollout }) => (
  <JustifiedPageHeader>
    <PageTitle title="Rollout">{rollout.name}</PageTitle>
    <HeaderInfo label="Version" value={rollout.version} />
    <HeaderInfo label="Creation date" value={fromNow(rollout.creationDate)} />
    <FixedHeaderEntry>
      <ToggleRolloutStatusButton rollout={rollout} />
    </FixedHeaderEntry>
  </JustifiedPageHeader>
);

Header.propTypes = {
  rollout: PropTypes.object.isRequired,
};

export default Header;
