import React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { weight } from '../../Theme/Typography';
import { getProduct } from '../../Dashboard/reducer';
import Card from '../../Theme/Card';

const style = {
  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 203,
    width: '100%',
  },
  icon: {
    width: 128,
    height: 85,
    marginLeft: 177,
  },
  infoBox: {
    width: 250,
    textAlign: 'center',
    fontSize: 14,
    marginLeft: 50,
  },
  h2: {
    fontWeight: weight.medium,
    fontSize: 16,
    marginBottom: 18,
  },
  textBox: {
    fontWeight: weight.light,
  },
};

const EmptyRolloutList = injectSheet(style)(({ productName, classes }) => (
  <Card className={classes.box}>
    <img
      src="/icons/empty-rollouts.svg"
      className={classes.icon}
      alt="Package"
    />
    <div className={classes.infoBox}>
      <h2 className={classes.h2}>No active rollouts</h2>
      <p className={classes.textBox}>
        An overview of active rollouts of
        <br />
        {productName}
        will be shown here.
      </p>
    </div>
  </Card>
));

const mapStateToProps = state => ({
  productName: getProduct(state).name,
});

export default connect(mapStateToProps)(EmptyRolloutList);
