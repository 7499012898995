import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-flexbox-grid';
import SectionHeader from '../SettingsPage/SectionHeader';
import Section from '../SettingsPage/Section';
import { UserIcon } from '../Icons';
import BackToSettingsButton from './BackToSettingsButton';
import Header from './Header';
import MembersTable from './MembersTable';
import InvitesTable from './InvitesTable';
import InviteMemberModal from './InviteMemberModal';
import DeleteOrganizationModal from './DeleteOrganizationModal';
import OpenCreateInviteModalButton from './OpenCreateInviteModalButton';
import UpdateMemberRoleModal from './UpdateMemberRoleModal';
import RemoveMemberModal from './RemoveMemberModal';
import CancelInviteModal from './CancelInviteModal';
import TransferModal from './TransferModal';
import ConfirmTransferModal from './ConfirmTransferModal';

const Content = ({ model }) => (
  <div>
    <InviteMemberModal organizationUID={model.organization.uid} />
    <DeleteOrganizationModal />
    <UpdateMemberRoleModal />
    <RemoveMemberModal />
    <CancelInviteModal />
    <Header organization={model.organization} />
    <Grid>
      <Section>
        <SectionHeader title="Member list" icon={<UserIcon />}>
          <OpenCreateInviteModalButton />
        </SectionHeader>
        <MembersTable />
        <TransferModal />
        <ConfirmTransferModal />
      </Section>
      <Section>
        <SectionHeader title="Pending invites" icon={<UserIcon />} />
        <InvitesTable />
      </Section>
      <BackToSettingsButton />
    </Grid>
  </div>
);

Content.propTypes = {
  model: PropTypes.object.isRequired,
};

export default Content;
