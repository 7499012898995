import React from 'react';
import { fileSize } from 'humanize-plus';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import url from '../updatehub/urls';
import * as actions from './actions';
import Table, { makeStateToProps } from '../Table';
import { getTable } from './reducer';
import {
  humanizeHash,
  humanizeHardware,
  fromNow,
} from '../updatehub/humanizer';
import PackageStatusIcons from './PackageStatusIcons';

const PackageTable = props => <Table {...props} />;

export const columns = [
  {
    Header: 'Package',
    id: 'uid',
    accessor: ({ uid }) => humanizeHash(uid),
    minWidth: 50,
  },
  {
    Header: 'Version',
    accessor: 'version',
    minWidth: 50,
  },
  {
    Header: 'Supported Hardware',
    id: 'supportedHardware',
    accessor: ({ supportedHardware }) => humanizeHardware(supportedHardware),
    maxWidth: 300,
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: rollout => <PackageStatusIcons status={rollout.status} />,
    minWidth: 70,
  },
  {
    Header: 'Size',
    id: 'size',
    accessor: ({ size }) => fileSize(size),
    minWidth: 50,
  },
  {
    Header: 'Upload Date',
    id: 'uploadDate',
    accessor: ({ uploadDate }) => fromNow(uploadDate),
    minWidth: 50,
  },
];

const mapStateToProps = makeStateToProps(getTable, columns);

const mapDispatchToProps = (dispatch, { match }) => ({
  getTrProps(args, rowInfo) {
    if (!rowInfo) {
      return {};
    }
    const { productUID, namespaceUID } = match.params;
    const { original } = rowInfo;

    return {
      onClick() {
        dispatch(
          push(
            url('product:packages:detail', {
              productUID,
              namespaceUID,
              packageUID: original.uid,
            })
          )
        );
      },
    };
  },

  onChange(payload) {
    dispatch(actions.request(payload));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PackageTable)
);
