import * as types from './types';
import { PageModel } from './models';

const pageReducer = (state = PageModel(), action) => {
  switch (action.type) {
    case types.PAGE_SET_STATUS:
      return state.set('status', action.payload);
    default:
      return state;
  }
};

export default pageReducer;

export const getPageStatus = state => state.getIn(['page', 'status']);
