import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import ValueSlider from './ValueSlider';
import { weight } from '../../Theme/Typography';

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 48,
    fontWeight: weight.light,
    fontSize: 14,
  },
};

const FaultTolerance = ({ classes, value, onChange }) => (
  <div className={classes.box}>
    <span>
      This task has a fault tolerance of{' '}
      <ValueSlider value={value} onChange={onChange} />.
    </span>
  </div>
);

FaultTolerance.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default injectSheet(styles)(FaultTolerance);
