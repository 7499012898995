import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/fp/capitalize';
import { rolloutStatus, taskStatus } from './states';

const getLabelForAbortedRollout = task => {
  switch (task.status) {
    case taskStatus.aborted:
      return 'Aborted';
    default:
      return 'Interrupted';
  }
};

const getLabelForArchivedRollout = task => {
  switch (task.status) {
    case taskStatus.aborted:
      return 'Aborted';
    case taskStatus.running:
      return 'Stopped';
    default:
      return 'Never started';
  }
};

const getStatusLabel = ({ task, rollout }) => {
  switch (rollout.status) {
    case rolloutStatus.aborted:
      return getLabelForAbortedRollout(task);
    case rolloutStatus.stopped:
      return 'Paused';
    case rolloutStatus.archived:
      return getLabelForArchivedRollout(task);
    default:
      return capitalize(task.status);
  }
};

const RolloutTaskStatusLabel = ({ task, rollout }) => (
  <small>{getStatusLabel({ task, rollout })}</small>
);

RolloutTaskStatusLabel.propTypes = {
  task: PropTypes.object.isRequired,
  rollout: PropTypes.object.isRequired,
};

export default RolloutTaskStatusLabel;
