import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { grey800 } from '../Theme/colors';
import { weight } from '../Theme/Typography';

const style = {
  titleBox: {
    color: grey800,
    fontSize: 14,
    fontWeight: weight.regular,
  },
  title: {
    display: 'block',
    fontWeight: weight.medium,
    lineHeight: '23px',
    fontSize: 16,
    marginBottom: 9,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: weight.light,
  },
};

const PageTitle = ({ title, children, classes }) => (
  <div className={classes.titleBox}>
    <span className={classes.title}>{title}</span>
    <span className={classes.subtitle}>{children}</span>
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(PageTitle);
