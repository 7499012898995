import React from 'react';
import PropTypes from 'prop-types';

const headerDiv = {
  width: '100%',
  height: '50%',
  backgroundColor: '#F0F1F4',
  position: 'relative',
  overflow: 'hidden',
};

export const positionStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  marginRight: 'auto',
  marginLeft: 'auto',
  border: 'solid 3px white',
  borderRadius: '50%',
};

const borderOutline = {
  ...positionStyle,
  width: 288,
  height: 288,
  bottom: -144,
  backgroundColor: 'transparent',
};

const contentStyle = {
  ...positionStyle,
  width: 210,
  height: 210,
  bottom: -105,
  backgroundColor: 'white',
};

const bgErrorStyle = {
  ...positionStyle,
  width: 390,
  height: 390,
  backgroundColor: '#EFF0F4',
  bottom: -195,
  border: 'none',
};

const imgErrorStyle = {
  width: '100%',
};

export const HalfWrapper = ({ children, error }) => (
  <div style={headerDiv}>
    {error && <div style={bgErrorStyle} />}
    {error && (
      <img src="/images/error_bg.png" alt="Background" style={imgErrorStyle} />
    )}
    <div style={borderOutline} />
    <div style={contentStyle} />
    {children}
  </div>
);

HalfWrapper.defaultProps = {
  children: null,
  error: false,
};

HalfWrapper.propTypes = {
  error: PropTypes.bool,
  children: PropTypes.node,
};

const stateStyle = {
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '350px',
  height: '100%',
  top: 0,
  right: 0,
  width: '100%',
};

const PageStateWrapper = ({ children }) => (
  <div style={stateStyle}>{children}</div>
);

PageStateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageStateWrapper;
