export const REQUEST_DEVICE = 'DEVICE_DETAIL/REQUEST_DEVICE';
export const SET_DEVICE = 'DEVICE_DETAIL/SET_DEVICE';
export const SET_PROBE_INFO = 'DEVICE_DETAIL/SET_PROBE_INFO';

export const REQUEST_DEVICE_ROLLOUTS = 'DEVICE_DETAIL/REQUEST_DEVICE_ROLLOUTS';
export const SET_DEVICE_ROLLOUTS = 'DEVICE_DETAIL/SET_DEVICE_ROLLOUTS';
export const SET_DEVICE_ROLLOUTS_ERROR =
  'DEVICE_DETAIL/SET_DEVICE_ROLLOUTS_ERROR';

export const REQUEST_ROLLOUT_TRANSACTION =
  'DEVICE_DETAIL/REQUEST_ROLLOUT_TRANSACTION';
export const SET_ROLLOUT_TRANSACTION = 'DEVICE_DETAIL/SET_ROLLOUT_TRANSACTION';
export const SET_ROLLOUT_TRANSACTION_ERROR =
  'DEVICE_DETAIL/SET_ROLLOUT_TRANSACTION_ERROR';

export const OPEN_DEVICE_LOG_MODAL = 'DEVICE_DETAIL/OPEN_DEVICE_LOG_MODAL';
export const CLOSE_DEVICE_LOG_MODAL = 'DEVICE_DETAIL/CLOSE_DEVICE_LOG_MODAL';

export const SET_TAG = 'DEVICE_DETAIL/SET_TAG';

// Disable device
export const REQUEST_DEVICE_DISABLE = 'DEVICE_DETAIL/REQUEST_DEVICE_DISABLE';
