import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Content, { InnerContent } from './Content';
import BackToProducts from './BackToProducts';
import Header from './Header';
import Account from './Account';
import ApiKeys from './ApiKeys';
import Organizations from './Organizations';
import Page from '../Page';
import * as actions from './actions';

const Settings = ({ onLoad }) => (
  <Page onLoad={onLoad}>
    <Content>
      <BackToProducts />
      <InnerContent>
        <Header />
        <Account />
        <ApiKeys />
        <Organizations />
      </InnerContent>
    </Content>
  </Page>
);

Settings.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onLoad() {
    dispatch(actions.requestLoad());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(Settings);
