import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';
import urls from '../../../updatehub/urls';
import StatusIcon from './StatusIcon';
import ObjectsInfo from './ObjectsInfo';
import { fromNow, humanizeHardware } from '../../../updatehub/humanizer';
import { blue, red, transparentGrey200 } from '../../../Theme/colors';
import { weight } from '../../../Theme/Typography';

const style = {
  packageBox: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    height: 84,
    marginBottom: 6,
    padding: 12,
    border: `1px solid ${transparentGrey200}`,
    borderRadius: 4,
    cursor: 'pointer',
    userSelect: 'none',
    outline: 'none',
    textDecoration: 'none',
    color: '#3d3e40',

    '&:hover': {
      borderColor: blue,
    },
  },
  smallBox: {
    width: 70,
    marginRight: 22,
    textAlign: 'right',
  },
  bigBox: {
    flex: '1',
  },
  iconBox: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingRight: 4,
  },
  listStyle: {
    fontSize: 12,
    listStyle: 'none',

    '& > li:first-child': {
      fontWeight: weight.medium,
    },
    '& > li': {
      lineHeight: '19px',
      marginBottom: 1,
    },
  },
  liError: {
    color: red,
  },
  uidLi: {
    color: '#B8BAC0',
  },
  smallIcon: {
    paddingRight: 10,
  },
};

const Package = (props, { namespaceUID, productUID }) => {
  const { uid, version, supportedHardware, createdAt, classes } = props;

  return (
    <Link
      tabIndex="-1"
      role="button"
      to={urls('product:packages:detail', {
        namespaceUID,
        productUID,
        packageUID: uid,
      })}
      className={classes.packageBox}
    >
      <div className={classes.smallBox}>
        <ul className={classes.listStyle}>
          <li>{fromNow(createdAt)}</li>
          <li className={classes.uidLi}>{`${uid.substring(0, 6)}...`}</li>
        </ul>
      </div>
      <div className={classes.bigBox}>
        <ul className={classes.listStyle}>
          <li>
            <img
              src="/icons/package-version.svg"
              className={classes.smallIcon}
              alt="Version Icon"
            />
            {version}
          </li>
          <li>
            <img
              src="/icons/package-hardware.svg"
              className={classes.smallIcon}
              alt="Hardware icon"
            />
            {humanizeHardware(supportedHardware)}
          </li>
          <ObjectsInfo {...props} />
        </ul>
      </div>
      <div className={classes.iconBox}>
        <StatusIcon {...props} />
      </div>
    </Link>
  );
};

Package.propTypes = {
  uid: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  supportedHardware: PropTypes.arrayOf(PropTypes.string).isRequired,
  createdAt: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

Package.contextTypes = {
  namespaceUID: PropTypes.string,
  productUID: PropTypes.string,
};

export default injectSheet(style)(Package);
