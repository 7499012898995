import { call, put, takeEvery, select } from 'redux-saga/effects';
import { List } from 'immutable';
import request from '../updatehub/effects';
import * as api from '../updatehub/api';
import * as page from '../Page/actions';
import { START_ROLLOUT_TASK_DONE } from '../StartRolloutTaskModal/types';
import { openNotification } from '../Notification';
import * as actions from './actions';
import * as types from './types';
import { getTasks } from './reducer';
import { rolloutConflictTaskParser } from '../updatehub/utils';

export const ARCHIVE_ROLLOUT_SUCCESS = 'Rollout archived successfully!';
export const ARCHIVE_ROLLOUT_ERROR =
  'Could not archive rollout. Try again later.';

export const UPDATE_ROLLOUT_SUCCESS = 'Rollout updated successfully!';
export const UPDATE_ROLLOUT_ERROR =
  'Could not update rollout. Try again later.';

export function* requestRolloutSaga(action) {
  const { namespaceUID, rolloutUID } = action.payload;
  const { result } = yield request(api.fetchRollout(namespaceUID, rolloutUID));
  if (!result) {
    yield put(page.notFound());
  } else {
    yield put(actions.gotRollout(result));
    yield put(page.loaded());
  }
}

export function* refreshWithNotificationSaga(
  rolloutUID,
  namespaceUID,
  message
) {
  const fetchAction = actions.requestRolloutDetailPage(
    rolloutUID,
    namespaceUID
  );
  yield put(page.loading());
  yield call(requestRolloutSaga, fetchAction);
  yield put(openNotification(message));
}

export function* refreshAfterStartTask(action) {
  const { rolloutUID, namespaceUID, message } = action.payload;
  if (action.payload.page !== 'rollout-detail') {
    return;
  }
  yield call(refreshWithNotificationSaga, rolloutUID, namespaceUID, message);
}

export function* archiveRolloutSaga(action) {
  const { rolloutUID, namespaceUID } = action.payload;
  const { result } = yield request(
    api.archiveRollout(namespaceUID, rolloutUID)
  );
  const message = result ? ARCHIVE_ROLLOUT_SUCCESS : ARCHIVE_ROLLOUT_ERROR;
  yield call(refreshWithNotificationSaga, rolloutUID, namespaceUID, message);
}

export function* parseConflictError(error) {
  try {
    if (!error.errors || !error.errors.tasks) {
      return [];
    }

    const tasks = List().merge(yield select(getTasks));
    return rolloutConflictTaskParser(error.errors.tasks, tasks);
  } catch (e) {
    return [];
  }
}

export function* updateRolloutStatusSaga(action) {
  const { namespaceUID, rolloutUID, status, forceSubmit } = action.payload;
  const updateRequest = api.updateRolloutStatus(
    namespaceUID,
    rolloutUID,
    status,
    forceSubmit
  );
  const { result, error } = yield request(updateRequest);
  let message = null;

  if (result) {
    message = UPDATE_ROLLOUT_SUCCESS;
  } else if (error && error.isRecoverable) {
    const parsedConflict = yield call(parseConflictError, error);

    if (parsedConflict && parsedConflict.length) {
      yield put(actions.setConflictError(parsedConflict));
    } else {
      message = UPDATE_ROLLOUT_ERROR;
    }
  } else {
    message = UPDATE_ROLLOUT_ERROR;
  }

  if (message !== null) {
    yield call(refreshWithNotificationSaga, rolloutUID, namespaceUID, message);
  }
}

function* rootSaga() {
  yield takeEvery(types.REQUEST_ROLLOUT, requestRolloutSaga);
  yield takeEvery(types.ARCHIVE_ROLLOUT, archiveRolloutSaga);
  yield takeEvery(types.UPDATE_ROLLOUT_STATUS, updateRolloutStatusSaga);
  yield takeEvery(START_ROLLOUT_TASK_DONE, refreshAfterStartTask);
}

export default rootSaga;
