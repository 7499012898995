import identity from 'lodash/fp/identity';
import { checkRequiredKeys } from '../utils';
import { getBillingURL } from '../../config';
import { formErrors } from '../../Form/normalizers';
import { fromNow } from '../humanizer';

export const BILLING_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
};

const NOTIFICATIONS = {
  // possible notifications to active status
  NEAR_FREE_LIMIT: 'near_free_limit',
  PAYMENT_OVERDUE: 'payment_overdue',
  // possible notifications to blocked status
  FREE_LIMIT_REACHED: 'free_limit_reached',
  CANCELED_BY_USER: 'canceled_by_user',
  CANCELED_DUE_PAYMENT_ISSUE: 'canceled_due_payment_issue',
};

export const NOTIFICATION_TYPES = {
  DANGER: 'danger',
  WARNING: 'warn',
};

export const isDanger = type => type === NOTIFICATION_TYPES.DANGER;
export const isBlocked = status => status === BILLING_STATUS.BLOCKED;

const buildAlertType = notification => {
  switch (notification) {
    case NOTIFICATIONS.FREE_LIMIT_REACHED:
    case NOTIFICATIONS.CANCELED_BY_USER:
    case NOTIFICATIONS.CANCELED_DUE_PAYMENT_ISSUE:
      return NOTIFICATION_TYPES.DANGER;
    default:
      return NOTIFICATION_TYPES.WARNING;
  }
};

const buildMessage = ({ notification, lock_date }) => {
  /* eslint camelcase: 0 */
  let dateHumanized = null;

  switch (notification) {
    case NOTIFICATIONS.NEAR_FREE_LIMIT:
      return "You're close to the limit of free tier consumpition.";
    case NOTIFICATIONS.PAYMENT_OVERDUE:
      return 'This invoice payment is overdue.';
    case NOTIFICATIONS.FREE_LIMIT_REACHED:
      return "You've reached the limit of free tier consumpition.";
    case NOTIFICATIONS.CANCELED_BY_USER:
      dateHumanized = fromNow(lock_date);
      return `This namespace was blocked since ${dateHumanized} by the owner.`;
    case NOTIFICATIONS.CANCELED_DUE_PAYMENT_ISSUE:
      dateHumanized = fromNow(lock_date);
      return `This namespace is blocked since ${dateHumanized} due to payment issues.`;
    default:
      return 'Please contact us!';
  }
};

export const buildNotification = data => ({
  type: buildAlertType(data.notification),
  message: buildMessage(data),
});

const informationDecoder = data => {
  checkRequiredKeys(data, ['uid', 'close_in', 'status', 'subscribed', 'price']);
  let notification = null;

  if (data.notification) {
    notification = buildNotification(data);
  }

  return {
    uid: data.uid,
    closeIn: data.close_in,
    status: data.status,
    notification,
    subscribed: data.subscribed,
    price: data.price,
  };
};

export const billingRequest = params => ({
  ...params,
  baseURL: getBillingURL(),
});

export const fetchBillingData = namespaceUID =>
  billingRequest({
    path: '/information',
    headers: { 'UH-Namespace': namespaceUID },
    decodeSuccess: informationDecoder,
  });

const billingCustomerDecoder = data => {
  checkRequiredKeys(data, ['first_name', 'last_name', 'email', 'country']);
  return {
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    country: data.country,
  };
};

const billingCountriesDecoder = data => {
  checkRequiredKeys(data, ['countries']);
  return data.countries.map(country => {
    checkRequiredKeys(country, ['name', 'value']);
    return country;
  });
};

const formErrorsDecoder = data => formErrors(data.errors);

const fsSecurePayloadDecoder = data => {
  checkRequiredKeys(data, ['service', 'service_payload']);
  checkRequiredKeys(data.service_payload, ['session_id']);

  return {
    sessionID: data.service_payload.session_id,
  };
};

export const fetchCustomerData = namespaceUID =>
  billingRequest({
    path: '/customer',
    headers: { 'UH-Namespace': namespaceUID },
    decodeSuccess: billingCustomerDecoder,
  });

export const fetchCountriesList = namespaceUID =>
  billingRequest({
    path: '/countries',
    headers: { 'UH-Namespace': namespaceUID },
    decodeSuccess: billingCountriesDecoder,
  });

export const createCustomer = (namespaceUID, customer) =>
  billingRequest({
    method: 'POST',
    path: '/customer',
    accepts: [201, 422],
    data: customer,
    headers: { 'UH-Namespace': namespaceUID },
    decodeSuccess: identity,
    decodeError: formErrorsDecoder,
  });

export const subscribe = namespaceUID =>
  billingRequest({
    method: 'POST',
    path: '/subscribe',
    accepts: [200],
    headers: { 'UH-Namespace': namespaceUID },
    decodeSuccess: fsSecurePayloadDecoder,
  });

export const finalizeSubscription = (namespaceUID, orderNumber) =>
  billingRequest({
    method: 'POST',
    path: '/subscribe/finalize',
    data: { order_number: orderNumber },
    accepts: [200],
    headers: { 'UH-Namespace': namespaceUID },
    decodeSuccess: identity,
  });

const detailDecoder = data => {
  checkRequiredKeys(data, [
    'uid',
    'close_in',
    'last_synced',
    'devices_price',
    'transfer_price',
    'storage_price',
    'activations_price',
    'deployments_price',
    'orders',
  ]);

  return {
    uid: data.uid,
    closeIn: new Date(data.close_in),
    lastSynced: new Date(data.last_synced),
    devicesPrice: data.devices_price,
    transferPrice: data.transfer_price,
    storagePrice: data.storage_price,
    activationsPrice: data.activations_price,
    deploymentsPrice: data.deployments_price,
    orders: data.orders.map(order => {
      checkRequiredKeys(order, ['uid', 'status', 'total_price', 'created_at']);
      return {
        uid: order.uid,
        status: order.status,
        totalPrice: order.total_price,
        createdAt: new Date(order.created_at),
      };
    }),
  };
};

export const fetchBillingDetails = namespaceUID =>
  billingRequest({
    path: '/details',
    headers: { 'UH-Namespace': namespaceUID },
    decodeSuccess: detailDecoder,
  });

export const fetchOrderReport = (namespaceUID, uid) =>
  billingRequest({
    path: `/orders/${uid}/pdf`,
    headers: { 'UH-Namespace': namespaceUID },
    transformResponse: [],
    responseType: 'blob',
    decodeSuccess: identity,
  });

export const cancelSubscription = namespaceUID =>
  billingRequest({
    method: 'POST',
    path: '/cancel',
    accepts: [200],
    headers: { 'UH-Namespace': namespaceUID },
    decodeSuccess: identity,
  });
