import { Record, Map } from 'immutable';
import * as types from './types';

export const RefresherModel = Record({
  internalTasks: Map({}),
  registeredTasks: Map({}),
});

export default (state = RefresherModel(), action) => {
  switch (action.type) {
    case types.REGISTER_RUNNER:
      return state.setIn(['internalTasks', action.identifier], action.payload);
    case types.REGISTER_TASK:
      return state.setIn(
        ['registeredTasks', action.identifier],
        action.payload
      );
    case types.CLEAN_RUNNERS:
      return RefresherModel();
    default:
      return state;
  }
};

// Selectors

export const getTaskByIdentifier = (state, identifier) =>
  state.getIn(['refresher', 'internalTasks', identifier]);

export const getInternalTasks = state =>
  state.getIn(['refresher', 'internalTasks']);
