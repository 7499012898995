import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import buildUrl, {
  findByPath,
  findByName,
  extractParams,
} from '../../updatehub/urls';
import BreadcrumbItem from './Item';
import { ArrowRight } from '../../Icons';

const styles = {
  breadcrumb: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  item: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 10,
      marginLeft: 10,
    },
  },
};

export const buildLinks = (lastURL, params = {}) => {
  let linksArray = [];
  if (!lastURL) {
    return linksArray;
  }

  let name = '';
  if (typeof lastURL.displayName === 'function') {
    name = lastURL.displayName(params);
  } else {
    name = lastURL.displayName;
  }

  linksArray.push({
    name,
    link: buildUrl(lastURL.name, params),
  });

  if (lastURL.parent) {
    linksArray = linksArray.concat(buildLinks(findByName(lastURL.parent), params));
  }

  return linksArray;
};

const Breadcrumb = ({ classes, location }) => {
  const url = findByPath(location.pathname);

  if (!url) {
    return <div />;
  }

  const params = extractParams(location.pathname, url);
  const links = buildLinks(url, params);
  links.push({ name: 'Products', link: '/' });

  return (
    <div className={classes.breadcrumb}>
      {links.reverse().map(({ name, link }, index) => (
        <div key={link} className={classes.item}>
          {index !== 0 && <ArrowRight color="#304B60" />}
          <BreadcrumbItem name={name} link={link} active={(links.length - 1) === index} />
        </div>
      ))}
    </div>
  );
};

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(injectSheet(styles)(Breadcrumb));
