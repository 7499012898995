import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OutlineButton, LinkButton } from '../Buttons';
import { CheckIcon } from '../Icons';
import { Modal, ModalContent, ModalTitle, ModalFooter } from '../Modal';
import { closeArchiveRolloutModal, archiveRollout } from './actions';

const RolloutArchiveModal = (
  { rollout, closeModal, onClick },
  { namespaceUID }
) => (
  <Modal isVisible={rollout.showArchiveModal}>
    <ModalContent>
      <ModalTitle title="Are you sure you want to archive this rollout?" />
      <p>
        When archiving a rollout it will stop all tasks and you will not be able
        to start it again.
      </p>
    </ModalContent>
    <ModalFooter>
      <LinkButton label="Cancel" onClick={closeModal} />
      <OutlineButton
        label="Yes, archive it"
        onClick={onClick(namespaceUID)}
        icon={<CheckIcon />}
        disabled={false}
      />
    </ModalFooter>
  </Modal>
);

RolloutArchiveModal.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
};

RolloutArchiveModal.propTypes = {
  rollout: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { rollout }) => ({
  onClick(namespaceUID) {
    return () => dispatch(archiveRollout(rollout.uid, namespaceUID));
  },
  closeModal() {
    dispatch(closeArchiveRolloutModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(RolloutArchiveModal);
