import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Card from '../Theme/Card';
import { weight } from '../Theme/Typography';
import RolloutListEntry from './DeviceRolloutListEntry';
import RolloutDetail from './DeviceRolloutDetail';

const style = {
  box: {
    display: 'flex',
    width: '100%',
  },
  boxList: {
    flexGrow: 1,
  },
  boxDetail: {
    borderRadius: 0,
    width: 375,
  },
  boxHeader: {
    borderBottom: '2px solid #F9F9F9',
    display: 'block',
    fontWeight: weight.medium,
    padding: [33, 24, 20],
  },
  list: {
    listStyle: 'none',
  },
};

const RolloutList = (
  { deviceUID, rollouts, classes },
  { namespaceUID, productUID }
) => (
  <div className={classes.box}>
    <div className={classes.boxList}>
      <span className={classes.boxHeader}>Rollout List</span>
      <ul className={classes.list}>
        {rollouts.map(rollout => (
          <RolloutListEntry
            key={rollout.uid}
            rollout={rollout}
            deviceUID={deviceUID}
            namespaceUID={namespaceUID}
            productUID={productUID}
          />
        ))}
      </ul>
    </div>
    <Card className={classes.boxDetail}>
      <span className={classes.boxHeader}>Details</span>
      <RolloutDetail />
    </Card>
  </div>
);

RolloutList.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
  productUID: PropTypes.string.isRequired,
};

RolloutList.propTypes = {
  deviceUID: PropTypes.string.isRequired,
  rollouts: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutList);
