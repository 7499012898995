import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { LinkButton } from '../../Buttons';
import { Modal, ModalFooter, ModalContent, ModalTitle } from '../../Modal';
import { getModalState } from '../reducer';
import * as actions from '../actions';
import ErrorList from './ErrorList';

const styles = {
  errorMessage: {
    marginBottom: '24px',
  },
};

const CreateErrorModal = ({ classes, onCancel, errorList }) => (
  <Modal isVisible>
    <ModalTitle title="Fail to create this rollout" />
    <ModalContent large>
      <p className={classes.errorMessage}>
        Please verify the errors found below:
      </p>
      <ErrorList errorList={errorList} />
    </ModalContent>
    <ModalFooter>
      <LinkButton label="Cancel" onClick={onCancel} />
    </ModalFooter>
  </Modal>
);

CreateErrorModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  errorList: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  errorList: getModalState(state).createError,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(actions.closeErrorModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(CreateErrorModal));
