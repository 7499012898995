import * as types from './types';
import * as tableFactory from '../Table/actionFactory';

export const reset = tableFactory.resetAction(types.RESET);
export const request = tableFactory.requestAction(types.REQUEST);
export const receive = tableFactory.receiveAction(types.RECEIVE);
export const requestError = tableFactory.requestErrorAction(
  types.REQUEST_ERROR
);

export const changeStatusFilter = payload => ({
  type: types.CHANGE_STATUS_FILTER,
  payload,
});

export const changeVersionsFilter = payload => ({
  type: types.CHANGE_VERSIONS_FILTER,
  payload,
});

export const changeHardwareListFilter = payload => ({
  type: types.CHANGE_HARDWARE_LIST_FILTER,
  payload,
});
