import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import url from '../../updatehub/urls';
import Card from '../Card';
import { requestDevices } from '../actions';
import { getDevicesGraphCardStatus } from '../reducer';
import DevicesGraph from './DevicesGraph';

const DevicesGraphCard = ({ onLoad, status }, { namespaceUID, productUID }) => (
  <Card
    title="Devices"
    to={url('product:devices', { namespaceUID, productUID })}
    onLoad={onLoad}
    status={status}
  >
    <DevicesGraph />
  </Card>
);

DevicesGraphCard.propTypes = {
  onLoad: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

DevicesGraphCard.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
  productUID: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
  status: getDevicesGraphCardStatus(state),
});

export const mapDispatchToProps = dispatch => ({
  onLoad() {
    dispatch(requestDevices());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesGraphCard);
