import { Record } from 'immutable';
import * as types from './types';

export const CreateOrganizatonModel = Record({
  isOpen: false,
});

const createOrganizationReducer = (
  state = CreateOrganizatonModel(),
  action
) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return state.set('isOpen', true);
    case types.CLOSE_MODAL:
      return CreateOrganizatonModel();
    default:
      return state;
  }
};

export default createOrganizationReducer;

export const getModalIsOpen = state =>
  state.getIn(['settings', 'createOrganizationModal', 'isOpen']);
