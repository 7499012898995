import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { grey800 } from '../Theme/colors';
import { weight } from '../Theme/Typography';

const style = {
  infoBox: {
    color: grey800,
    fontSize: 14,
    fontWeight: weight.regular,
  },
  infoLabel: {
    display: 'block',
    fontWeight: weight.medium,
    lineHeight: '23px',
  },
};

const HeaderInfo = ({ label, value, classes }) => (
  <div className={classes.infoBox}>
    <span className={classes.infoLabel}>{label}</span>
    {value}
  </div>
);

HeaderInfo.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(HeaderInfo);
