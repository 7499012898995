import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import CircularProgress from 'material-ui/CircularProgress';
import { fromNow } from '../updatehub/humanizer';
import {
  stepStatus,
  stepName,
} from '../updatehub/api/deviceTransactionSerializer';
import { fetchStatus } from './models';
import { getRolloutTransaction, getRolloutTransactionStatus } from './reducer';
import style, { getStyleForStep } from './DeviceRolloutTransactionStyle';
import DeviceLogModalOpenButton from './DeviceLogModalOpenButton';

const allStepLabels = new Map([
  // [stepName, [idle, running, completed]]
  [stepName.start, ['Start', null, 'Started']],
  [stepName.download, ['Download', 'Downloading', 'Downloaded']],
  [stepName.installation, ['Installation', 'Installing', 'Installed']],
  [stepName.reboot, ['Reboot', 'Rebooting', 'Rebooted']],
  [stepName.finish, ['Finish', null, 'Finished']],
]);

const getLabelForStep = step => {
  const currentStepLabels = allStepLabels.get(step.name);
  switch (step.status) {
    case stepStatus.readyToStart:
    case stepStatus.pending:
    case stepStatus.notReported:
    case stepStatus.error:
      return currentStepLabels[0];
    case stepStatus.running:
      return currentStepLabels[1];
    case stepStatus.justFinished:
    case stepStatus.finished:
      return currentStepLabels[2];
    default:
      return 'Unknown';
  }
};

const parseTimeForStep = step => {
  if (step.elapsedTime != null) {
    return `took ${step.elapsedTime}`;
  }

  return fromNow(step.date);
};

const getTimeForStep = step => {
  switch (step.status) {
    case stepStatus.readyToStart:
      return 'Pending';
    case stepStatus.pending:
      return '--';
    case stepStatus.notReported:
      return 'Not reported';
    default:
      return parseTimeForStep(step);
  }
};

const RolloutTransaction = ({ transaction, status, classes }) => {
  if (status === fetchStatus.error) {
    return <p>Could not get rollout transaction. Try again later.</p>;
  }
  if (status === fetchStatus.fetching) {
    return (
      <div className={classes.loadingBox}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div className={classes.transaction}>
        {transaction.steps.map(step => (
          <div key={step.name} className={getStyleForStep(step, classes)}>
            <div className={classes.stepTime}>{getTimeForStep(step)}</div>
            <div className={classes.stepStatus}>
              <div className={classes.stepStatusCircle} />
              <div className={classes.stepStatusLine} />
            </div>
            <div className={classes.stepLabel}>{getLabelForStep(step)}</div>
          </div>
        ))}
      </div>
      <div className={classes.logErrorButtonContainer}>
        <DeviceLogModalOpenButton />
      </div>
    </div>
  );
};

RolloutTransaction.propTypes = {
  transaction: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  transaction: getRolloutTransaction(state),
  status: getRolloutTransactionStatus(state),
});

export default connect(mapStateToProps)(injectSheet(style)(RolloutTransaction));
