import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Row, Col } from 'react-flexbox-grid';
import { green, red, grey } from './graph-utils';
import { rolloutStatus } from './states';
import CardContainer from './CardContainer';
import ProgressBar from './RolloutTaskProgressBar';
import LegendEntry from './RolloutTaskProgressLegendEntry';

const style = {
  abortedProgress: {
    opacity: 0.5,
  },
};

const isRolloutStopped = rollout =>
  [rolloutStatus.aborted, rolloutStatus.archived].includes(rollout.status);

const getOpacityClass = (rollout, classes) => {
  if (isRolloutStopped(rollout)) {
    return classes.abortedProgress;
  }
  return null;
};

const RolloutTaskProgress = ({ task, rollout, classes }) => (
  <div className={getOpacityClass(rollout, classes)}>
    <CardContainer>
      <ProgressBar
        failed={task.failedDevices}
        total={task.totalDevices}
        updated={task.updatedDevices}
      />
      <Row>
        <Col md={4}>
          <LegendEntry
            label="Updated"
            value={task.updatedDevices}
            color={green}
            total={task.totalDevices}
          />
        </Col>
        <Col md={4}>
          <LegendEntry
            label="Failed"
            value={task.failedDevices}
            color={red}
            total={task.totalDevices}
          />
        </Col>
        <Col md={4}>
          <LegendEntry
            label={isRolloutStopped(rollout) ? 'Not Updated' : 'Remaining'}
            value={task.remainingDevices}
            color={grey}
            total={task.totalDevices}
          />
        </Col>
      </Row>
    </CardContainer>
  </div>
);

RolloutTaskProgress.propTypes = {
  task: PropTypes.object.isRequired,
  rollout: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutTaskProgress);
