import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { Grid } from 'react-flexbox-grid';
import Card from '../Theme/Card';
import SectionTitle from '../Theme/SectionTitle';
import { getPackageStatus, getPackage } from './reducer';
import ObjectsTable from './ObjectsTable';
import ObjectDetailPane from './ObjectDetailPane';

const style = {
  box: {
    display: 'flex',
    minHeight: 490,
    overflow: 'hidden',
  },
  boxOpacity: {
    extend: 'box',
    opacity: 0.5,
  },
};

class ObjectsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(index) {
    this.setState({
      index,
    });
  }

  render() {
    const { index } = this.state;
    const { status, objects, classes } = this.props;
    if (!objects.length) {
      return null;
    }
    const current = objects[index];
    const activeBox =
      status !== 'error' && status !== 'removed'
        ? classes.box
        : classes.boxOpacity;
    return (
      <Grid>
        <SectionTitle text="Objects" />
        <Card className={activeBox}>
          <ObjectsTable
            objects={objects}
            onClick={this.handleClick}
            currentIndex={index}
          />
          <ObjectDetailPane obj={current} />
        </Card>
      </Grid>
    );
  }
}

ObjectsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  objects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  status: getPackageStatus(state),
  objects: getPackage(state).objects,
});

export default injectSheet(style)(connect(mapStateToProps)(ObjectsWidget));
