import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { List } from 'immutable';
import TasksManager from './TasksManager';
import TaskBody from './TaskBody';
import { getTasks } from '../reducer';

const styles = {
  box: {
    width: '100%',
  },
  title: {
    fontSize: 14,
    marginBottom: 16,
  },
};

const AdvancedMode = ({ classes, tasks }) => (
  <div className={classes.box}>
    <h4 className={classes.title}>Tasks</h4>

    <TasksManager />
    {tasks.size !== 0 && <TaskBody />}
  </div>
);

AdvancedMode.propTypes = {
  classes: PropTypes.object.isRequired,
  tasks: PropTypes.instanceOf(List).isRequired,
};

const mapStateToProps = state => ({
  tasks: getTasks(state),
});

export default connect(mapStateToProps)(injectSheet(styles)(AdvancedMode));
