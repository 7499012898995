import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { LinkButton, OutlineButton } from '../../Buttons';
import { Modal, ModalFooter, ModalContent, ModalTitle } from '../../Modal';
import { getForceCreation, getModalState } from '../reducer';
import TaskErrorList from './TaskErrorList';
import * as actions from '../actions';

const styles = {
  errorMessage: {
    marginBottom: '24px',
  },
  buttonsGroup: {
    width: '280px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

const checkboxLabel =
  'I’m aware that force start will instantaneously cancel all conflicting rollouts and start this one.';
const ConflictModal = ({
  classes,
  errorList,
  forceCreation,
  onForceSubmitCheck,
  onForceStart,
  onSave,
  onCancel,
}) => (
  <Modal isVisible>
    <ModalTitle title="Conflict found: devices already in use in other rollout" />
    <ModalContent large>
      <p className={classes.errorMessage}>
        You can whether cancel and get back editing, save all the information
        set and start this rollout later, or force start this rollout now, which
        will cancel other rollouts involved.
      </p>
      <TaskErrorList errorList={errorList} />
      <Checkbox
        label={checkboxLabel}
        checked={forceCreation}
        onCheck={onForceSubmitCheck}
      />
    </ModalContent>
    <ModalFooter>
      <div>
        <LinkButton label="Cancel" onClick={onCancel} />
      </div>
      <div className={classes.buttonsGroup}>
        <OutlineButton label="Save For Later" onClick={onSave} />
        <OutlineButton
          label="Force Start"
          disabled={!forceCreation}
          onClick={onForceStart}
        />
      </div>
    </ModalFooter>
  </Modal>
);

ConflictModal.propTypes = {
  classes: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  forceCreation: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onForceSubmitCheck: PropTypes.func.isRequired,
  onForceStart: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  forceCreation: getForceCreation(state),
  errorList: getModalState(state).conflictError,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(actions.closeErrorModal());
  },
  onForceSubmitCheck(e, value) {
    dispatch(actions.setForceCreation(value));
  },
  onForceStart() {
    dispatch(actions.submitStart());
  },
  onSave() {
    dispatch(actions.submit());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(ConflictModal));
