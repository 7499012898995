import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import ProgressBar from '../RolloutDetailPage/RolloutTaskProgressBar';
import RolloutProgressBarLegend from './RolloutProgressBarLegend';

const style = {
  baseBox: {
    alignItems: 'center',
    display: 'flex',
  },
  box: {
    extend: 'baseBox',
    cursor: 'pointer',
    outline: 'none',
    width: 230,
    height: 10,
  },
};

class RolloutProgressBarColumn extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpened: false,
      anchor: null,
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onOpen(event) {
    event.preventDefault();
    this.setState({
      isOpened: true,
      anchor: event.currentTarget,
    });
  }

  onClose(event) {
    event.preventDefault();
    this.setState({
      isOpened: false,
      anchor: null,
    });
  }

  render() {
    const { classes, small, rollout } = this.props;
    const { isOpened, anchor } = this.state;

    return (
      <div
        onMouseMove={this.onOpen}
        onMouseLeave={this.onClose}
        className={classes.box}
        role="button"
        tabIndex={0}
      >
        <ProgressBar
          updated={rollout.updatedDevices}
          failed={rollout.failedDevices}
          total={rollout.totalDevices}
          small={small}
        />
        <RolloutProgressBarLegend
          open={isOpened}
          anchor={anchor}
          rollout={rollout}
        />
      </div>
    );
  }
}

RolloutProgressBarColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  small: PropTypes.bool.isRequired,
  rollout: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutProgressBarColumn);
