export const normalizeError = field =>
  Error(`Could not convert "${field}" to string.`);

export const formErrors = data => {
  const result = {};
  Object.entries(data).forEach(([field, errors]) => {
    try {
      result[field] = `${errors.join('. ')}.`;
    } catch (err) {
      throw normalizeError(field);
    }
  });
  return result;
};
