import { put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import url from '../updatehub/urls';
import request from '../updatehub/effects';
import * as page from '../Page/actions';
import * as actions from './actions';
import { openNotification } from '../Notification';
import { getInvitationToken } from './reducer';
import {
  fetchOrganizationInvite,
  acceptOrganizationInvite,
  rejectOrganizationInvite,
} from '../updatehub/api/organization';
import { REQUEST_INVITATION, ACCEPT_INVITE, REJECT_INVITE } from './types';

export const ACCEPT_SUCCEFULLY = 'You accepted this invite, redirecting...';
export const REJECT_SUCCEFULLY = 'You declined this invite, redirecting...';
export const UNKNOWN_ERROR = 'Something went wrong. Try again later.';

export function* getOrganizationInvitation(action) {
  const token = action.payload;
  const { result } = yield request(fetchOrganizationInvite(token));
  if (!result) {
    yield put(page.notFound());
  } else {
    yield put(actions.receiveInvitation(result));
    yield put(page.loaded());
  }
}

export function* acceptInvite() {
  const token = yield select(getInvitationToken);
  const { result } = yield request(acceptOrganizationInvite(token));

  if (!result) {
    yield put(openNotification(UNKNOWN_ERROR));
  } else {
    yield put(openNotification(ACCEPT_SUCCEFULLY));
  }

  yield put(push(url('main:page')));
}

export function* rejectInvite() {
  const token = yield select(getInvitationToken);
  const { result } = yield request(rejectOrganizationInvite(token));

  if (!result) {
    yield put(openNotification(UNKNOWN_ERROR));
  } else {
    yield put(openNotification(REJECT_SUCCEFULLY));
  }

  yield put(push(url('main:page')));
}

function* rootSaga() {
  yield takeEvery(REQUEST_INVITATION, getOrganizationInvitation);
  yield takeEvery(ACCEPT_INVITE, acceptInvite);
  yield takeEvery(REJECT_INVITE, rejectInvite);
}

export default rootSaga;
