import * as types from './types';
import * as tableFactory from '../Table/actionFactory';

export const request = tableFactory.requestAction(types.REQUEST);
export const receive = tableFactory.receiveAction(types.RECEIVE);
export const requestError = tableFactory.requestErrorAction(
  types.REQUEST_ERROR
);
export const reset = tableFactory.resetAction(types.RESET);

export const updateRawFilter = (key, payload) => ({
  type: types.UPDATE_RAW_FILTER,
  key,
  payload,
});

export const updateFilter = payload => ({
  type: types.UPDATE_FILTER,
  payload,
});

export const setColumns = payload => ({
  type: types.SET_COLUMNS,
  payload,
});

export const setLastProbe = (index, payload) => ({
  type: types.SET_LAST_PROBE,
  index,
  payload,
});
