import humanizePlus from 'humanize-plus';
import moment from 'moment';

export const humanizeHash = uid => `${uid.substring(0, 6)}...`;

const DEFAULT_MESSAGES = {
  anyText: 'Any',
  manyText: 'Many hardware boards supported',
};

/* eslint-disable max-len */
export const humanizeHardware = (supportedHardware, options) => {
  const { anyText, manyText } = { ...DEFAULT_MESSAGES, ...options };

  if (!supportedHardware || !supportedHardware.length) {
    return anyText;
  }

  if (supportedHardware.length > 1) {
    return manyText;
  }

  return supportedHardware[0];
};

export const fromNow = date => {
  const parsedDate = moment(date);
  const now = moment();

  if (moment.duration(now.diff(parsedDate)).asDays() <= 7) {
    return parsedDate.fromNow();
  }

  return parsedDate.format('MM/DD/YYYY');
};

export const humanizeDate = date => {
  const parsedDate = moment(date);
  return parsedDate.format('MMMM DD, YYYY');
};

export const humanizeHour = date => {
  const parsedDate = moment(date);
  return parsedDate.format('YYYY-MM-DD, HH:mm:ss');
};

export const humanizePrice = price =>
  `$ ${humanizePlus.formatNumber(price, 2)}`;
