import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import url from '../updatehub/urls';
import { isAppLoading } from '../App/reducer';
import { blue900 } from '../Theme/colors';
import { weight } from '../Theme/Typography';
import logo from '../Header/img/logo.png';
import groteskLight from '../Header/fonts/HKGrotesk-Light.woff2';
import groteskSemiBold from '../Header/fonts/HKGrotesk-SemiBold.woff2';

const menuLogoStyle = {
  background: 'linear-gradient(0deg, #FCFCFC, #FFFFFF)',
  letterSpacing: 1,
  lineHeight: '56px',
  minHeight: 56,
};

const style = {
  '@font-face': [
    {
      fontFamily: 'Grotesk',
      fontWeight: weight.semibold,
      src: `url(${groteskSemiBold})`,
    },
    {
      fontFamily: 'Grotesk',
      fontWeight: weight.light,
      src: `url(${groteskLight})`,
    },
  ],

  logo: {
    color: blue900,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Grotesk',
    fontSize: 24,
    textDecoration: 'none',
    height: 72,
  },
  logoText: {
    fontWeight: weight.light,
    '& strong': {
      fontWeight: weight.semibold,
    },
  },
  logoImage: {
    marginRight: 10,
    width: 18,
  },
  logoLoading: {
    background: 'rgba(239,240,244,0.50)',
    borderRadius: 18,
    height: 30,
    width: 150,
  },
};

const Logo = ({ isLoading, classes }) => {
  if (isLoading) {
    return <div className={classes.logoLoading} />;
  }
  return (
    <Link style={menuLogoStyle} className={classes.logo} to={url('main:page')}>
      <img src={logo} className={classes.logoImage} alt="UpdateHub" />
      <span className={classes.logoText}>
        <strong>update</strong>
        hub
      </span>
    </Link>
  );
};

Logo.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isLoading: isAppLoading(state),
});

export default connect(mapStateToProps)(injectSheet(style)(Logo));
