import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { renderRoutes } from 'react-router-config';
import { requireAuth } from '../Auth';
import { AppHeader } from '../Header';
import Footer from '../Footer';
import AppStatus from './Status';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: 0,
  },
  content: {
    flex: 1,
  },
};

const App = ({ classes, route }) => (
  <div className={classes.wrapper}>
    <AppHeader />
    <AppStatus>
      <div className={classes.content}>{renderRoutes(route.routes)}</div>
    </AppStatus>
    <Footer />
  </div>
);

App.propTypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.shape({
    routes: PropTypes.array,
  }).isRequired,
};

export default requireAuth(injectSheet(styles)(App));
