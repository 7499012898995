import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPackageHardwareList } from '../../updatehub/api/package';
import { makeRequest } from '../../updatehub/http';
import SelectField from '../../SelectField';
import * as actions from '../actions';
import { getStatus, getHardwareList } from '../reducer';
import { getProductUID, getNamespaceUID } from '../../Dashboard/reducer';
import { getAuthToken } from '../../Auth/reducer';

/* FIXME, make test for this, and review request mode */
/* eslint-disable jsx-a11y/label-has-for */
const HardwareFilter = ({ values, fetchData, onChange }) => (
  <div>
    <label htmlFor="supported-hardware-filter">
      Supported Hardware
      <SelectField
        id="supported-hardware-filter"
        inputPlaceholder="Choose hardware"
        onChange={onChange}
        fetchData={fetchData}
        values={values}
      />
    </label>
  </div>
);

HardwareFilter.propTypes = {
  values: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  values: getHardwareList(state),
  fetchData(query, page) {
    const authToken = getAuthToken(state);
    const namespaceUID = getNamespaceUID(state);
    const productUID = getProductUID(state);
    const packageStatus = getStatus(state);
    const requestConfig = fetchPackageHardwareList(
      namespaceUID,
      productUID,
      query,
      page,
      { packageStatus }
    );

    return makeRequest(requestConfig, authToken);
  },
});

export const mapDispatchToProps = dispatch => ({
  onChange(payload) {
    dispatch(actions.changeHardwareListFilter(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HardwareFilter);
