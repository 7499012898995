import * as types from './types';

export const requestLogout = () => ({
  type: types.REQUEST_LOGOUT,
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const configureOAuth = config => ({
  type: types.CONFIGURE_OAUTH,
  payload: config,
});

export const requestToken = () => ({
  type: types.REQUEST_TOKEN,
});

export const receiveToken = () => ({
  type: types.RECEIVE_TOKEN,
});
