import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '../Buttons';
import { TrashIcon } from '../Icons';
import { openDeleteOrganizationModal } from './actions';

const OpenDeleteOrganizationModalButton = ({ onClick }) => (
  <IconButton onClick={onClick} icon={<TrashIcon />} />
);

OpenDeleteOrganizationModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onClick() {
    dispatch(openDeleteOrganizationModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(OpenDeleteOrganizationModalButton);
