import { Record } from 'immutable';

export const APP_STATUS = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
  REDIRECT: 'redirect',
};

export const UserModel = Record({
  name: '',
  email: '',
  gravatarHash: '',
});

export const AppModel = Record({
  status: APP_STATUS.LOADING,
  errorMessage: '',
  user: UserModel(),
});

export const isLoading = status => status === APP_STATUS.LOADING;
