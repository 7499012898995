import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parseQuery, validateToken } from './utils';
import * as actions from '../App/actions';
import { receiveToken } from './actions';
import { logError } from '../updatehub/utils';
import url from '../updatehub/urls';

export class CallbackComponent extends React.Component {
  componentDidMount() {
    const {
      location,
      history,
      loadError,
      receiveToken: receiveTokenFunction,
    } = this.props;
    const parameters = parseQuery(location.hash);

    if (validateToken(parameters)) {
      receiveTokenFunction();
    } else {
      logError('Received wrong callback parameters.', parameters);
      loadError('Something went wrong on authentication process');
    }

    history.push(url('main:page'));
  }

  render() {
    return null;
  }
}

CallbackComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
  receiveToken: PropTypes.func.isRequired,
  loadError: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  isLoggedIn: state.get('auth').isLoggedIn,
  token: state.get('auth').token,
});

export default connect(
  mapStateToProps,
  { receiveToken, loadError: actions.loadError }
)(CallbackComponent);
