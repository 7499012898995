import pathToRegexp from 'path-to-regexp';
import { renderRoutes as originalRenderRoutes } from 'react-router-config';
import { matchPath } from 'react-router';

export const URLS = {};

export const register = (name, path, args = {}) => {
  const keys = [];
  pathToRegexp(path, keys);

  URLS[name] = {
    name,
    displayName: args.displayName,
    path,
    toPath: pathToRegexp.compile(path),
    keys: keys.map(key => key.name),
    parent: args.parent,
  };
};

export const find = (name, args) => {
  const url = URLS[name];
  if (!url) {
    throw new Error(`"${name}" URL is not registered`);
  }
  const keys = {};
  url.keys.forEach(key => {
    const value = args[key];
    if (value === '') {
      // We don't want an exception when user pass all args with empty string.
      keys[key] = ' ';
    } else if (value === undefined) {
      throw new Error(`"${name}" URL requires "${key}" param`);
    } else {
      keys[key] = value;
    }
  });
  return url.toPath(keys);
};

export const extractParams = (path, urlDef) => {
  const keys = [];
  const re = pathToRegexp(urlDef.path, keys);

  const result = re.exec(path);
  if (!result) {
    return {};
  }
  result.shift();

  const newObject = {};
  keys.forEach(({ name }, index) => {
    newObject[name] = result[index];
  });

  return newObject;
};

export const findByName = name => URLS[name];

/* eslint-disable function-paren-newline */
export const findByPath = path => {
  const name = Object.keys(URLS).find(key =>
    matchPath(path, {
      path: URLS[key].path,
      exact: true,
      strict: true,
    })
  );

  if (!name) {
    return null;
  }

  return { ...URLS[name], name };
};

export default find;

const parseRoutes = routes =>
  routes.map(route => {
    const parsedRoute = { ...route };
    if (parsedRoute.name && parsedRoute.path) {
      register(parsedRoute.name, parsedRoute.path, {
        parent: parsedRoute.parent,
        displayName: parsedRoute.displayName,
      });
    }

    if (parsedRoute.routes) {
      parsedRoute.routes = parseRoutes(parsedRoute.routes);
    }

    delete parsedRoute.name;
    delete parsedRoute.parent;
    return parsedRoute;
  });

export const renderRoutes = (routes, extraProps = {}) => {
  const parsedRoutes = parseRoutes(routes);

  return originalRenderRoutes(parsedRoutes, extraProps);
};
