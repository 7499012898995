import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';
import { grey800 } from '../Theme/colors';
import { CopiableHash } from '../Copiable';
import { FileIconPackage } from '../Icons';
import ObjectSets from './ObjectSets';
import Card from '../Theme/Card';

const style = {
  box: {
    borderRadius: 0,
    color: grey800,
    fontSize: 14,
    fontWeight: weight.regular,
    minHeight: '100%',
    width: 388,
  },
  boxHeader: {
    borderBottom: '2px solid #F9F9F9',
    fontWeight: weight.medium,
    padding: '33px 24px 20px',
  },
  boxContent: {
    padding: 24,
  },
  contentTitle: {
    alignItems: 'flex-start',
    display: 'flex',
    minHeight: 80,
  },
  filename: {
    fontSize: 16,
    fontWeight: weight.medium,
    lineHeight: '23px',
    marginBottom: 9,
  },
  icon: {
    paddingTop: 5,
    paddingRight: 16,
  },
};

const ObjectDetailPane = ({ obj, classes }) => (
  <Card className={classes.box}>
    <div className={classes.boxHeader}>
      <h4>Details</h4>
    </div>
    <div className={classes.boxContent}>
      <div className={classes.contentTitle}>
        <FileIconPackage className={classes.icon} />
        <div>
          <h5 className={classes.filename}>{obj.filename}</h5>
          <CopiableHash text={obj.sha256sum} />
        </div>
      </div>
      <ObjectSets sets={obj.sets} />
    </div>
  </Card>
);

ObjectDetailPane.propTypes = {
  obj: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ObjectDetailPane);
