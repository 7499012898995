import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from '../Modal';
import { weight } from '../Theme/Typography';
import { bgStripe, grey800 } from '../Theme/colors';
import { ProductIcon } from '../Icons';
import { createProductKey } from './reducer';
import { setDefaultNamespaceUID } from './actions';

const cardStyle = {
  background: bgStripe,
  borderRadius: 2,
  cursor: 'pointer',
  height: 230,
  marginBottom: 36,
  outline: 'none',
  padding: '40px 34px',
  textAlign: 'center',
  border: 'none',
};

const imageStyle = {
  marginBottom: 24,
  width: 80,
};

const leadStyle = {
  color: grey800,
  fontSize: 14,
  fontWeight: weight.regular,
  letterSpacing: 0,
  lineHeight: '19px',
};

const EmptyProductCard = ({ onClick }) => (
  <button style={cardStyle} onClick={onClick} tabIndex={0}>
    <img src={ProductIcon} style={imageStyle} alt="Add product" />
    <p style={leadStyle}>You have no products in this section.</p>
  </button>
);

EmptyProductCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { namespace }) => ({
  onClick() {
    dispatch(setDefaultNamespaceUID(namespace.uid));
    dispatch(openModal(createProductKey));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(EmptyProductCard);
