import { Record } from 'immutable';
import * as types from './types';
import { getToken, getExpirationDate, getIssuedAt, hasToken } from './utils';

export const Auth = Record({
  config: null,
  token: null,
  expiresAt: null,
  issuedAt: null,
  isLoggedIn: false,
  refreshUrl: null,
});

export const initialState = () =>
  Auth({
    token: getToken(),
    expiresAt: getExpirationDate(),
    issuedAt: getIssuedAt(),
    isLoggedIn: hasToken(),
  });

const authReducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.CONFIGURE_OAUTH:
      return state.set('config', action.payload);
    case types.RECEIVE_TOKEN:
      return state.merge({
        token: getToken(),
        expiresAt: getExpirationDate(),
        issuedAt: getIssuedAt(),
        isLoggedIn: hasToken(),
        refreshUrl: null,
      });
    case types.LOGOUT:
      return initialState();
    default:
      return state;
  }
};

export default authReducer;

// Selectors
export const getAuth = state => state.get('auth');

export const getOAuthConfiguration = state => state.getIn(['auth', 'config']);

export const getAuthToken = state => state.getIn(['auth', 'token']);

export const getSilentRefreshUrl = state => state.getIn(['auth', 'refreshUrl']);

export const getAuthTokenDetails = state => ({
  token: getAuth(state).get('token'),
  expiresAt: getAuth(state).get('expiresAt'),
  issuedAt: getAuth(state).get('issuedAt'),
});
