import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { rolloutStatus } from './states';
import CardCorner from './RolloutTaskCardCorner';
import Card from '../Theme/Card';

const style = {
  taskCard: {
    position: 'relative',
  },
  taskCardStopped: {
    extend: 'taskCard',
    opacity: 0.5,
  },
  taskCardInner: {
    borderRadius: 2,
    marginBottom: 10,
    padding: [30, 0, 35],
  },
};

const getCardClass = (rollout, classes) => {
  if (rollout.status === rolloutStatus.stopped) {
    return classes.taskCardStopped;
  }
  return classes.taskCard;
};

const RolloutTaskCard = ({ task, rollout, children, classes }) => (
  <div className={getCardClass(rollout, classes)}>
    <CardCorner task={task} rollout={rollout} />
    <Card className={classes.taskCardInner}>{children}</Card>
  </div>
);

RolloutTaskCard.propTypes = {
  task: PropTypes.object.isRequired,
  rollout: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutTaskCard);
