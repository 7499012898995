import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import ArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import { blue } from '../../Theme/colors';

const styles = {
  placeholder: {
    top: '0px',
    position: 'absolute',
    border: 'none',
    borderBottom: '1px solid #B8BAC0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    background: 'none',

    '& > span': {
      color: blue,
      fontWeight: 500,
      fontSize: 12,
      textTransform: 'uppercase',
      letterSpacing: 1,
      marginLeft: 8,
    },
  },
  iconStyle: {
    marginRight: 11,
    textAlign: 'center',
  },
};

const Placeholder = ({ classes, text, selectOnly, onClick }) => (
  <button tabIndex="-1" className={classes.placeholder} onClick={onClick}>
    <span>{text}</span>
    <div className={classes.iconStyle}>
      {!selectOnly && <ArrowDown />}
      {selectOnly && <img src="/icons/device-filter-select.svg" alt="Filter" />}
    </div>
  </button>
);

Placeholder.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  selectOnly: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(Placeholder);
