import React from 'react';
import PropTypes from 'prop-types';
import TaskCard from './RolloutTaskCard';
import CardHeader from './RolloutTaskCardHeader';
import CardFooter from './RolloutTaskCardFooter';
import CardContent from './RolloutTaskCardContent';

const RolloutTask = ({ task, rollout }) => (
  <TaskCard rollout={rollout} task={task}>
    <CardHeader task={task} rollout={rollout} />
    <CardContent task={task} rollout={rollout} />
    <CardFooter task={task} />
  </TaskCard>
);

RolloutTask.propTypes = {
  rollout: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
};

export default RolloutTask;
