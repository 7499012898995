import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Wrapper, { HalfWrapper, positionStyle } from '../../Page/Wrappers';
import { getErrorMessage } from '../reducer';
import { weight } from '../../Theme/Typography';

const shortTextStyle = {
  textAlign: 'center',
  marginTop: 45,
  fontSize: 50,
  fontWeight: weight.light,
};

const contentStyle = {
  ...positionStyle,
  width: 210,
  height: 210,
  backgroundColor: 'white',
  bottom: -105,
};

const longTextStyle = {
  paddingTop: 21,
  textAlign: 'center',
};

const ErrorPage = ({ errorMessage }) => (
  <Wrapper>
    <HalfWrapper error>
      <div style={contentStyle}>
        <h1 style={shortTextStyle}>Error</h1>
      </div>
    </HalfWrapper>
    <div style={longTextStyle}>
      <h3>{errorMessage}</h3>
    </div>
  </Wrapper>
);

ErrorPage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
  errorMessage: getErrorMessage(state),
});

export default connect(mapStateToProps)(ErrorPage);
