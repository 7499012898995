import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table, { makeStateToProps, ActionsCell } from '../Table';
import * as actions from './actions';
import { getMembersTable } from './reducer';
import OpenRemoveMemberModalButton from './OpenRemoveMemberModalButton';
import OpenUpdateMemberRoleModalButton from './OpenUpdateMemberRoleModal';
import TransferButton from './TransferButton';

const renderCell = row => (
  <ActionsCell>
    <OpenUpdateMemberRoleModalButton member={row.original} />
    <OpenRemoveMemberModalButton member={row.original} />
  </ActionsCell>
);

export const columns = [
  {
    Header: 'Name',
    id: 'name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    id: 'email',
    accessor: 'email',
  },
  {
    Header: 'Role',
    id: 'role',
    accessor: 'role',
  },
  {
    Header: null,
    id: 'uid',
    accessor: 'uid',
    Cell: renderCell,
  },
];

const mapStateToProps = makeStateToProps(getMembersTable, columns, {
  pageSize: 5,
  paginationIfNecessary: true,
  paginationLink: TransferButton,
});

const mapDispatchToProps = dispatch => ({
  onChange(payload) {
    dispatch(actions.requestMembers(payload));
  },
  getTrProps() {
    return { className: 'no-cursor' };
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Table)
);
