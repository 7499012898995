import PropTypes from 'prop-types';
import { Component } from 'react';
import { getFSAccessKey, getFSStoreFront, getBillingURL } from '../config';
import { isDevelopment } from '../updatehub/utils';

class FastSpringLoader extends Component {
  static buildPromise() {
    window.FSRegisterCallback = () =>
      new Promise(resolve => {
        window.addEventListener('FSPopupClosed', e => {
          resolve(e.detail ? e.detail.id : null);
        });
      });

    window.onFSPopupClosed = order => {
      // eslint-disable-next-line no-console
      console.log('Calling custom event ', order);
      dispatchEvent(new CustomEvent('FSPopupClosed', { detail: order }));
    };
  }

  componentWillMount() {
    FastSpringLoader.buildPromise();

    const { forceProduction } = this.props;
    const development = isDevelopment();
    const scriptNode = document.createElement('script');

    scriptNode.id = 'fsc-api';
    scriptNode.type = 'text/javascript';
    scriptNode.setAttribute('data-storefront', getFSStoreFront());
    scriptNode.setAttribute('data-access-key', getFSAccessKey());
    scriptNode.setAttribute('data-popup-closed', 'onFSPopupClosed');
    scriptNode.setAttribute('data-debug', development);

    if (development && !forceProduction) {
      scriptNode.src = `${getBillingURL()}/script`;
    } else {
      scriptNode.src =
        'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.7.5/fastspring-builder.min.js';
    }

    document.body.appendChild(scriptNode);
  }

  render() {
    return null;
  }
}

FastSpringLoader.defaultProps = {
  forceProduction: false,
};

FastSpringLoader.propTypes = {
  forceProduction: PropTypes.bool,
};

export default FastSpringLoader;
