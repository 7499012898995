import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import SectionHeader from '../SectionHeader';
import Section from '../Section';
import { AccountIcon } from '../../Icons';
import Avatar from './Avatar';
import UserInfo from './UserInfo';
import { getUser } from '../../App/reducer';

const styles = {
  avatarCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const accountIconStyle = {
  width: 14,
  height: 14,
};

const Account = ({ classes, user }) => (
  <Section>
    <SectionHeader
      title="Account"
      icon={<AccountIcon style={accountIconStyle} />}
    />
    <Row>
      <Col sm={3} className={classes.avatarCol}>
        <Avatar gravatarHash={user.gravatarHash} />
      </Col>
      <Col sm={9}>
        <UserInfo user={user} />
      </Col>
    </Row>
  </Section>
);

Account.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    gravatarHash: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(injectSheet(styles)(Account));
