import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkButton } from '../Buttons';
import { Modal, ModalFooter, ModalContent, ModalTitle } from '../Modal';
import { getModalState } from './reducer';
import { closeErrorModal } from './actions';

const GenericErrorModal = ({ errorMessage, onCancel }) => (
  <Modal isVisible>
    <ModalTitle title="Problem when creating this rollout" />
    <ModalContent>{errorMessage}</ModalContent>
    <ModalFooter>
      <LinkButton label="Cancel" onClick={onCancel} />
    </ModalFooter>
  </Modal>
);

GenericErrorModal.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  errorMessage: getModalState(state).errorMessage,
});

const mapDispatchToProps = dispatch => ({
  onCancel() {
    dispatch(closeErrorModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericErrorModal);
