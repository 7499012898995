import { combineReducers } from 'redux-immutable';
import { Record, Map } from 'immutable';
import * as types from './types';

export const NamespaceBillingModel = Record({
  uid: '',
  closeIn: '',
  status: '',
  notification: null,
  subscribed: false,
  price: '$ 0.0',
});

export const NamespaceModel = Record({
  uid: '',
  name: '',
  isOrganization: false,
  billing: NamespaceBillingModel(),
});

const buildNamespaceMap = payload => {
  const namespaceMap = {};
  payload.forEach(namespace => {
    namespaceMap[namespace.uid] = NamespaceModel(namespace);
  });

  return Map(namespaceMap);
};

export const listReducer = (state = Map(), { type, payload, uid = null }) => {
  switch (type) {
    case types.GOT_NAMESPACES:
      return buildNamespaceMap(payload);
    case types.GOT_BILLING_INFORMATION:
      return state.setIn([uid, 'billing'], NamespaceBillingModel(payload));
    case types.REMOVE_NAMESPACE:
      return state.remove(uid);
    default:
      return state;
  }
};

export default combineReducers({
  list: listReducer,
});

const listPath = 'list';
const getSlice = state => state.getIn(['billingList', listPath]);

export const getNamespaces = getSlice;
