import { Record } from 'immutable';

const ModalModel = Record({
  isOpened: false,
  page: '',
  taskUID: '',
  rolloutUID: '',
  namespaceUID: '',
});

export default ModalModel;
