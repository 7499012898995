import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import BaseCopiable from './BaseCopiable';
import { CopyIcon } from '../Icons';

const style = {
  box: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  icon: {
    width: 9,
  },
};

const CopiableBlock = ({ text, classes, children }) => (
  <BaseCopiable text={text}>
    <div className={classes.box}>
      <div>{children}</div>
      <CopyIcon className={classes.icon} />
    </div>
  </BaseCopiable>
);

CopiableBlock.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(CopiableBlock);
