import { put, takeEvery } from 'redux-saga/effects';
import request from '../updatehub/effects';
import * as api from '../updatehub/api';
import * as actions from './actions';
import * as types from './types';

export const START_TASK_SUCCESS = 'Task started successfully!';
export const START_TASK_ERROR = 'Could not start task. Try again later.';

export function* startRolloutTask(action) {
  const { page, taskUID, rolloutUID, namespaceUID } = action.payload;

  const requestEffect = api.startRolloutTask(namespaceUID, rolloutUID, taskUID);
  const { result } = yield request(requestEffect);

  const message = result ? START_TASK_SUCCESS : START_TASK_ERROR;
  const payload = { message, page, rolloutUID, namespaceUID };

  yield put(actions.closeStartTaskModal());
  yield put(actions.startTaskDone(payload));
}

export default function* saga() {
  yield takeEvery(types.START_ROLLOUT_TASK, startRolloutTask);
}
