import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { weight } from '../../Theme/Typography';
import { green200, red } from '../../Theme/colors';
import { isPending, isWaiting, percent } from './utils';
import {
  addSpacerBetweenTaskComponents,
  EmptySpacer,
} from './RolloutTasksSpacer';
import StartTaskButton from './RolloutStartTaskButton';

const style = {
  flexContainer: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  progressBox: {
    fontSize: 12,
    fontWeight: weight.regular,
    marginTop: 20,
    textAlign: 'center',
    width: 192,
  },
  progressBoxPending: {
    extend: 'progressBox',
    color: '#F8F8FA',
  },
};

const getProgressPieClass = (status, classes) => {
  if (isPending(status)) {
    return classes.progressBoxPending;
  }
  return classes.progressBox;
};

const getProgressDataName = (name, value, total) => {
  const p = percent(value, total);
  return `${p}% ${name}`;
};

const getProgressData = task => {
  const entries = [
    ['Success', task.updatedDevices, green200],
    ['Failed', task.failedDevices, red],
    ['Remaining', task.remainingDevices, '#DEDEDE'],
  ];
  const total =
    task.updatedDevices + task.failedDevices + task.remainingDevices;
  const data = entries.map(([name, value, color]) => ({
    color: isPending(task.status) ? '#F8F8FA' : color,
    name: getProgressDataName(name, value, total),
    value,
  }));
  return data;
};

const TaskProgressPie = injectSheet(style)(({ task, classes }) => {
  const data = getProgressData(task);
  const progressPieClass = getProgressPieClass(task.status, classes);
  const cells = data.map(entry => <Cell key={entry.name} fill={entry.color} />);
  return (
    <div className={progressPieClass}>
      <PieChart width={190} height={50}>
        <Pie
          data={data}
          dataKey="value"
          innerRadius={14}
          outerRadius={24}
          isAnimationActive={false}
          cx={24}
        >
          {cells}
        </Pie>
        <Legend
          align="right"
          layout="vertical"
          verticalAlign="middle"
          iconType="circle"
          iconSize={8}
        />
      </PieChart>
    </div>
  );
});

const TaskProgress = ({ task }) => {
  if (isWaiting(task.status)) {
    return <StartTaskButton task={task} />;
  }
  return <TaskProgressPie task={task} />;
};

TaskProgress.propTypes = {
  task: PropTypes.object.isRequired,
};

const TasksProgressRow = ({ tasks, classes }) => {
  const progress = addSpacerBetweenTaskComponents(
    tasks,
    TaskProgress,
    EmptySpacer
  );
  return <div className={classes.flexContainer}>{progress}</div>;
};

TasksProgressRow.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(TasksProgressRow);
