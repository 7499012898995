import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const style = {
  modalContent: {
    marginBottom: 34,
    width: 336, // 400 - (2 * modalPadding)
  },
  largeModalContent: {
    extend: 'modalContent',
    width: 704, // 768 - (2 * modalPadding)
  },
};

const ModalContent = ({ children, large, classes }) => {
  const cls = large ? classes.largeModalContent : classes.modalContent;
  return <div className={cls}>{children}</div>;
};

ModalContent.defaultProps = {
  large: false,
};

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  large: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ModalContent);
