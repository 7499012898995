import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MenuItem from 'material-ui/MenuItem';
import { SelectField } from 'redux-form-material-ui';
import { reduxForm, Field } from 'redux-form/immutable';
import { withAuthToken } from '../Auth';
import { LinkButton, OutlineButton } from '../Buttons';
import { CheckIcon } from '../Icons';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalLabel,
  ModalFormGroup,
  connectModal,
} from '../Modal';
import {
  getMemberToEdit,
  getUpdateMemberRoleModal,
  updateMemberRoleModalKey,
} from './reducer';
import submitUpdateMemberForm from './submitUpdateMemberForm';

const roles = [
  ['administrator', 'Administrator'],
  ['release_manager', 'Release Manager'],
  ['developer', 'Developer'],
];

const ModalDialog = ({
  memberName,
  isOpened,
  closeModal,
  handleSubmit,
  submitting,
  invalid,
  pristine,
}) => (
  <Modal isVisible={isOpened}>
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalFormGroup>
          <ModalLabel
            htmlFor="role"
            label={`Select a new role for ${memberName}:`}
          />
          <Field id="role" name="role" component={SelectField} fullWidth>
            {roles.map(([value, label]) => (
              <MenuItem key={value} value={value} primaryText={label} />
            ))}
          </Field>
        </ModalFormGroup>
      </ModalContent>
      <ModalFooter>
        <LinkButton onClick={closeModal} label="cancel" disabled={submitting} />
        <OutlineButton
          label="Set role"
          icon={<CheckIcon />}
          disabled={pristine || submitting || invalid}
          type="submit"
        />
      </ModalFooter>
    </form>
  </Modal>
);

ModalDialog.propTypes = {
  memberName: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const member = getMemberToEdit(state);
  return {
    memberName: member.name,
    memberUID: member.uid,
    organizationUID: match.params.organizationUID,
    initialValues: {
      role: member.role,
    },
  };
};

const reduxConnector = connect(mapStateToProps);

export const modalConnector = connectModal(
  updateMemberRoleModalKey,
  getUpdateMemberRoleModal
);

const formConnector = reduxForm({
  form: updateMemberRoleModalKey,
  enableReinitialize: true,
  onSubmit: submitUpdateMemberForm,
});

export default compose(
  withRouter,
  withAuthToken,
  reduxConnector,
  modalConnector,
  formConnector
)(ModalDialog);
