import * as types from './types';

export const openStartTaskModal = ({
  namespaceUID,
  page,
  rolloutUID,
  taskUID,
}) => ({
  type: types.OPEN_START_TASK_MODAL,
  payload: { namespaceUID, page, rolloutUID, taskUID },
});

export const closeStartTaskModal = () => ({
  type: types.CLOSE_START_TASK_MODAL,
});

export const startTask = ({ namespaceUID, page, rolloutUID, taskUID }) => ({
  type: types.START_ROLLOUT_TASK,
  payload: { namespaceUID, page, rolloutUID, taskUID },
});

export const startTaskDone = ({ message, namespaceUID, page, rolloutUID }) => ({
  type: types.START_ROLLOUT_TASK_DONE,
  payload: { message, namespaceUID, page, rolloutUID },
});
