import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { LinkButton } from '../../Buttons';
import { weight } from '../../Theme/Typography';
import { openStartTaskModal } from './actions';

const style = {
  progressBox: {
    fontSize: 12,
    fontWeight: weight.regular,
    marginTop: 20,
    textAlign: 'center',
    width: 192,
  },
};

const RolloutStartTaskButton = ({ onClick, classes }, { namespaceUID }) => (
  <div className={classes.progressBox}>
    <p>This task is ready to start.</p>
    <LinkButton label="Start now?" onClick={onClick(namespaceUID)} />
  </div>
);

RolloutStartTaskButton.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
};

RolloutStartTaskButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch, { task }) => ({
  onClick(namespaceUID) {
    return () => dispatch(openStartTaskModal(task, namespaceUID));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(injectSheet(style)(RolloutStartTaskButton));
