import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';
import url from '../updatehub/urls';
import ProductLogo from '../Theme/ProductLogo';

const style = {
  box: {
    outline: 'none',
    margin: '25px 70px 0px 71px',
  },
  link: {
    outline: 'none',
    textDecoration: 'none',
  },
};

const ProductLink = ({ classes, productUID, productName, namespaceUID }) => {
  const to = url('product:index', { productUID, namespaceUID });

  return (
    <Link to={to} className={classes.link}>
      <div className={classes.box}>
        <ProductLogo
          productUID={productUID}
          productName={productName}
          namespaceUID={namespaceUID}
        />
      </div>
    </Link>
  );
};
ProductLink.propTypes = {
  classes: PropTypes.object.isRequired,
  productUID: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  namespaceUID: PropTypes.string.isRequired,
};

export default injectSheet(style)(ProductLink);
