import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { capitalize } from 'humanize-plus';
import url from '../../updatehub/urls';
import * as actions from './actions';
import Table, { makeStateToProps } from '../../Table';
import { getTable } from './reducer';
import { fromNow } from '../../updatehub/humanizer';
import ActionsCell from './ActionsCell';
import { canAccessDetail } from './rules';

const OrganizationTable = props => <Table {...props} />;

export const ActionsCellWrapper = ({ original }) => (
  <ActionsCell data={original} />
);

ActionsCellWrapper.propTypes = {
  original: PropTypes.object.isRequired,
};

export const columns = [
  {
    Header: 'UID',
    id: 'uid',
    accessor: 'uid',
    show: false,
  },
  {
    Header: 'Name',
    id: 'name',
    accessor: 'name',
  },
  {
    Header: 'Created',
    id: 'created',
    accessor: ({ createdAt }) => fromNow(createdAt),
  },
  {
    Header: 'My role',
    id: 'role',
    accessor: ({ myRole }) => capitalize(myRole),
  },
  {
    Header: null,
    Cell: ActionsCellWrapper,
  },
];

const mapStateToProps = makeStateToProps(getTable, columns, {
  pageSize: 5,
  paginationIfNecessary: true,
});

export const mapDispatchToProps = dispatch => ({
  getTrProps(args, row) {
    return {
      className: row && canAccessDetail(row.original.myRole) ? '' : 'no-cursor',
      onClick() {
        if (row && !canAccessDetail(row.original.myRole)) {
          return null;
        }

        return dispatch(
          push(
            url('settings:organization:detail', {
              organizationUID: row.original.uid,
            })
          )
        );
      },
    };
  },

  onChange(payload) {
    dispatch(actions.request(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationTable);
