import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../../Theme/Typography';
import { red } from '../../Theme/colors';
import { RunningStatusIcon } from '../../Icons';
import { isActive } from './utils';

const style = {
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  status: {
    animation: 'spin 4s linear infinite',
    marginRight: 14,
    verticalAlign: -1,
  },
  progressLabel: {
    fontSize: 18,
    fontWeight: weight.light,
    marginRight: 5,
  },
  progressValue: {
    fontSize: 22,
    fontWeight: weight.light,
  },
  failedIcon: {
    background: red,
    borderRadius: 12,
    display: 'inline-block',
    height: 12,
    marginRight: 14,
    width: 12,
  },
};

const ProgressLabel = ({ status, progress, classes }) => {
  if (isActive(status)) {
    return (
      <span>
        <img className={classes.status} src={RunningStatusIcon} alt="status" />
        <span className={classes.progressLabel}>Overall progress:</span>
        <span className={classes.progressValue}>{progress}%</span>
      </span>
    );
  }
  return (
    <span>
      <div className={classes.failedIcon} />
      <span className={classes.progressLabel}>Rollout has failed</span>
    </span>
  );
};

ProgressLabel.propTypes = {
  status: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ProgressLabel);
