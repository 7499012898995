import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import capitalize from 'lodash/fp/capitalize';
import {
  PendingStatusIcon,
  RunningStatusIcon,
  WaitingStatusIcon,
} from '../../Icons';
import { weight } from '../../Theme/Typography';
import {
  addSpacerBetweenTaskComponents,
  LineSpacer,
} from './RolloutTasksSpacer';
import { isWaiting, isRunning } from './utils';

const style = {
  flexContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  statusBox: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '20px 3px',
    border: '1px solid #B8BAC0',
    borderRadius: 22,
    fontSize: 14,
    height: 22,
    lineHeight: '22px',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: 192,
  },
  statusBoxPending: {
    extend: 'statusBox',
    backgroundImage: `url(${PendingStatusIcon})`,
  },
  statusBoxRunning: {
    extend: 'statusBox',
    backgroundImage: `url(${RunningStatusIcon})`,
  },
  statusBoxWaiting: {
    extend: 'statusBox',
    backgroundColor: '#F1C200',
    backgroundImage: `url(${WaitingStatusIcon})`,
    border: '1px solid #F1C200',
    color: 'white',
    fontWeight: weight.medium,
  },
};

const getStatusBoxClass = (status, classes) => {
  if (isRunning(status)) {
    return classes.statusBoxRunning;
  }
  if (isWaiting(status)) {
    return classes.statusBoxWaiting;
  }
  return classes.statusBoxPending;
};

const TaskStatus = injectSheet(style)(({ task, classes }) => {
  const statusClass = getStatusBoxClass(task.status, classes);
  return (
    <div className={classes.task}>
      <div className={statusClass}>{capitalize(task.status)}</div>
    </div>
  );
});

const TasksStatusRow = ({ tasks, classes }) => {
  const tasksStatusRow = addSpacerBetweenTaskComponents(
    tasks,
    TaskStatus,
    LineSpacer
  );
  return <div className={classes.flexContainer}>{tasksStatusRow}</div>;
};

TasksStatusRow.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(TasksStatusRow);
