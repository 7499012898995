import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { WarningIcon } from '../../Icons';
import { weight } from '../../Theme/Typography';
import url from '../../updatehub/urls';

const styles = {
  taskList: {
    listStyle: 'none',
    marginBottom: '24px',
  },
  task: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '12px',

    '& > div': {
      marginRight: '10px',
    },
  },

  taskInfo: {
    '& span': {
      fontSize: '14px',
      fontWeight: weight.light,
      display: 'block',
      letterSpacing: '0px',
    },
  },

  rolloutLink: {
    display: 'inline-block',

    '& span': {
      display: 'inline-block',
    },

    '& a': {
      paddingLeft: '10px',
    },
  },
};

const warningIconStyle = {
  width: '13px',
  height: '12px',
};

/* eslint-disable react/no-array-index-key */
const TaskErrorList = (
  { classes, errorList },
  { namespaceUID, productUID }
) => (
  <ul className={classes.taskList}>
    {errorList.map(({ name, conflictsWith }, index) => (
      <li className={classes.task} key={`${name}-${index}`}>
        <div>
          <WarningIcon color="#F1C200" style={warningIconStyle} />
        </div>
        <div className={classes.taskInfo}>
          <span>{name}</span>
          <span>
            Queue from this task conflicts with rollouts:
            {conflictsWith.map((rollout, conflictIndex) => (
              <div className={classes.rolloutLink} key={conflictIndex}>
                {conflictIndex !== 0 ? ',' : null}
                <Link
                  to={url('product:rollouts:detail', {
                    namespaceUID,
                    productUID,
                    rolloutUID: rollout.uid,
                  })}
                  target="_blank"
                >
                  {rollout.name}
                </Link>
              </div>
            ))}
          </span>
        </div>
      </li>
    ))}
  </ul>
);

TaskErrorList.contextTypes = {
  namespaceUID: PropTypes.string,
  productUID: PropTypes.string,
};

TaskErrorList.propTypes = {
  classes: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
};

export default injectSheet(styles)(TaskErrorList);
