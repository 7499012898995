import identity from 'lodash/fp/identity';
import { checkRequiredKeys, tableDecoder } from '../utils';

const keyDecoder = data => {
  checkRequiredKeys(data, ['access_key', 'secret_key']);
  return {
    accessKey: data.access_key,
    secretKey: data.secret_key,
  };
};

const keyListDecoder = tableDecoder('api_keys', data => {
  checkRequiredKeys(data, [
    'uid',
    'name',
    'namespace',
    'access_key',
    'last_timestamp',
    'created_at',
  ]);
  checkRequiredKeys(data.namespace, ['name']);

  return {
    uid: data.uid,
    name: data.name,
    namespace: data.namespace.name,
    accessKey: data.access_key,
    lastActivity: data.last_timestamp,
    createdAt: data.created_at,
  };
});

export const createApiKey = (namespaceUID, name) => ({
  accepts: [201],
  path: '/api_keys',
  method: 'POST',
  headers: { 'UH-Namespace': namespaceUID },
  data: { name },
  decodeSuccess: keyDecoder,
});

export const fetchApiKeys = ({ page, per_page }) => ({
  /* eslint camelcase: 0 */
  path: '/api_keys',
  params: {
    page,
    per_page,
    count: true,
  },
  accepts: [200],
  decodeSuccess: keyListDecoder,
});

export const deleteApiKey = uid => ({
  accepts: [200],
  path: `/api_keys/${uid}`,
  method: 'DELETE',
  decodeSuccess: identity,
});

export default { fetchApiKeys };
