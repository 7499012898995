import React from 'react';
import PropTypes from 'prop-types';
import BaseButton from './BaseButton';
import Icon from './Icon';
import Wrapper from './Wrapper';

const IconButton = ({ icon, ...props }) => (
  <BaseButton {...props} cls="uh-btn-round">
    <Wrapper>
      <Icon icon={icon} />
    </Wrapper>
  </BaseButton>
);

IconButton.defaultProps = {
  icon: null,
};

IconButton.propTypes = {
  icon: PropTypes.node,
};

export default IconButton;
