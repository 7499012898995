import { List } from 'immutable';
import { combineReducers } from 'redux-immutable';
import deleteApiKeyModalReducer from './DeleteApiKeyModal/reducer';
import createApiKeyModalReducer from './CreateApiKeyModal/reducer';
import createOrganizationModalReducer from './CreateOrganizationModal/reducer';
import leaveOrganizationModalReducer from './LeaveOrganizationModal/reducer';
import organizationReducer from './Organizations/reducer';
import apiKeyReducer from './ApiKeys/reducer';
import * as types from './types';

export const namespaceReducer = (state = List([]), action) => {
  switch (action.type) {
    case types.REQUEST_LOAD:
      return List([]);
    case types.RECEIVE_NAMESPACES:
      return state.merge(action.payload);
    default:
      return state;
  }
};

export default combineReducers({
  deleteApiKeyModal: deleteApiKeyModalReducer,
  createApiKeyModal: createApiKeyModalReducer,
  apiKey: apiKeyReducer,
  organization: organizationReducer,
  createOrganizationModal: createOrganizationModalReducer,
  leaveOrganizationModal: leaveOrganizationModalReducer,
  namespaces: namespaceReducer,
});

export const getNamespaces = state => state.getIn(['settings', 'namespaces']);
