import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fetchStatus } from './models';
import { fromNow } from '../updatehub/humanizer';

const formatDuration = seconds =>
  moment.duration(seconds, 'seconds').humanize();

const DeviceProbeInfo = ({ device }) => {
  if (device.fetchProbeInfo === fetchStatus.fetching) {
    return <p>Fetching probe information</p>;
  }

  if (device.fetchProbeInfo === fetchStatus.error) {
    return <p>Not enough data</p>;
  }

  return (
    <div>
      <p>Last device report {fromNow(device.lastProbe)}.</p>
      <p>
        Expected next report to be in {formatDuration(device.probeIntervalMean)}
        .
      </p>
    </div>
  );
};

DeviceProbeInfo.propTypes = {
  device: PropTypes.object.isRequired,
};

export default DeviceProbeInfo;
