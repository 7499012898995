import {
  all,
  call,
  cancel,
  take,
  takeEvery,
  select,
  fork,
  put,
} from 'redux-saga/effects';
import sagaFactory from '../Table/sagaFactory';
import request from '../updatehub/effects';
import * as actions from './actions';
import * as uh from '../updatehub/api';
import * as types from './types';
import { getContext } from '../Dashboard/sagas';
import { getProductUID } from '../Dashboard/reducer';
import { getTable, getRawFilter, defaultTableColumns } from './reducer';

export const processAttributes = attributes => {
  const filter = [];

  attributes.get('keys').forEach(({ value }) => {
    const values = attributes.get('values')[value];

    if (!values) {
      return;
    }

    values.forEach(attrValue => {
      const element = {};
      element[value] = attrValue.value;

      filter.push(element);
    });
  });

  if (!filter.length) {
    return null;
  }

  return filter;
};

export const processFields = fields => {
  const processedFields = fields.map(value => value.value);

  if (!processedFields.length) {
    return null;
  }

  return processedFields;
};

export const makeColumn = (type, key) => ({
  Header: key,
  id: key,
  accessor: data => {
    if (!data || !data[type] || !data[type][key]) {
      return null;
    }

    return data[type][key];
  },
  minWidth: 200,
});

export function* buildFilter() {
  let tableColumns = [...defaultTableColumns];
  const rawFilter = yield select(getRawFilter);
  const rawIdentityFilter = rawFilter.get('identities');
  const rawAttributesFilter = rawFilter.get('attributes');
  const productUID = yield select(getProductUID);
  const body = {
    status: rawFilter.get('status'),
    filter: {},
  };

  const otherColumns = tableColumns.slice(1, tableColumns.length);
  tableColumns = [tableColumns[0]];
  rawIdentityFilter.get('keys').forEach(({ value }) => {
    tableColumns.push(makeColumn('identity', value));
  });
  tableColumns = [...tableColumns, ...otherColumns];

  const identityFilter = yield call(processAttributes, rawIdentityFilter);
  if (identityFilter) {
    body.filter.identities = identityFilter;
  }

  const hardwareFilter = yield call(processFields, rawFilter.get('hardware'));
  if (hardwareFilter) {
    body.filter.hardware = hardwareFilter;
  }

  const tagFilter = yield call(processFields, rawFilter.get('tags'));
  if (tagFilter) {
    body.filter.tags = tagFilter;
  }

  body.filter.products = {};
  body.filter.products[productUID] = 'all';
  const versionFilter = yield call(processFields, rawFilter.get('versions'));
  if (versionFilter) {
    body.filter.products[productUID] = versionFilter;
  }

  rawAttributesFilter.get('keys').forEach(({ value }) => {
    tableColumns.push(makeColumn('attrs', value));
  });

  const attributesFilter = yield call(processAttributes, rawAttributesFilter);
  if (attributesFilter) {
    body.filter.attrs = attributesFilter;
  }

  yield put(actions.setColumns(tableColumns));
  yield put(actions.updateFilter(body));
  return body;
}

export function* setParameters(params) {
  const newParams = { ...params };
  const { namespaceUID, productUID } = yield call(getContext);

  newParams.body = yield call(buildFilter);
  return [namespaceUID, productUID, newParams];
}

export function* updateRawFilter() {
  yield put(
    actions.request({
      page: 0,
      pageSize: 10,
    })
  );
}

export const loadDevices = sagaFactory(
  uh.fetchDevices,
  actions.receive,
  actions.requestError,
  setParameters
);

export function* loadProbeInfo(index, device) {
  const { namespaceUID } = yield call(getContext);
  const { result } = yield request(
    uh.fetchDeviceProbeInfo(namespaceUID, device.uid)
  );

  if (result) {
    yield put(actions.setLastProbe(index, result.lastProbe));
  } else {
    yield put(actions.setLastProbe(index, 'Not enough data'));
  }
}

/* eslint-disable function-paren-newline */
export function* loadDeviceProbeFromList() {
  const tableData = yield select(getTable);
  const entries = tableData.get('data').toJS();
  const calls = entries.map((device, index) =>
    call(loadProbeInfo, index, device)
  );

  yield all(calls);
}

export function* waitForLoadDeviceProbe() {
  while (yield take(types.RECEIVE)) {
    const task = yield fork(loadDeviceProbeFromList);
    yield take(types.REQUEST);
    yield cancel(task);
  }
}

export function* rootSaga() {
  yield takeEvery(types.REQUEST, loadDevices);
  yield fork(waitForLoadDeviceProbe);
  yield takeEvery(types.UPDATE_RAW_FILTER, updateRawFilter);
}

export default rootSaga;
