import React from 'react';
import PropTypes from 'prop-types';
import { fileSize } from 'humanize-plus';

// Test coverage by index
const ObjectsInfo = ({ status, packageSize, objectsCount, classes }) => {
  let text = `${fileSize(packageSize)} - ${objectsCount} artifacts`;
  if (status === 'error') {
    text = 'Upload failed';
  }

  return (
    <li className={status === 'error' ? classes.liError : null}>
      <img
        src="/icons/package-info.svg"
        className={classes.smallIcon}
        alt="Package icon"
      />
      {text}
    </li>
  );
};

ObjectsInfo.propTypes = {
  status: PropTypes.string.isRequired,
  packageSize: PropTypes.number.isRequired,
  objectsCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ObjectsInfo;
