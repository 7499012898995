import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormModal from './FormModal';
import SuccessModal from './SuccessModal';
import { getModalIsOpen, getResult } from './reducer';
import { Modal } from '../../Modal';

const NewApiKeyModal = ({ isOpen, result }) => (
  <Modal isVisible={isOpen}>
    {result ? <SuccessModal /> : <FormModal />}
  </Modal>
);

NewApiKeyModal.defaultProps = {
  result: null,
};

NewApiKeyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  result: PropTypes.object,
};

const mapStateToProps = state => ({
  isOpen: getModalIsOpen(state),
  result: getResult(state),
});

export default connect(mapStateToProps)(NewApiKeyModal);
