import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import BaseHeader from './BaseHeader';

const style = {
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
};

const PageHeader = ({ children, classes }) => (
  <BaseHeader>
    <div className={classes.container}>{children}</div>
  </BaseHeader>
);

PageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(PageHeader);
