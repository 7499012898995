import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { OutlineButton } from '../Buttons';
import { StartIcon } from '../Icons';
import { openStartTaskModal } from './actions';
import { rolloutStatus } from './states';

const style = {
  startTaskContainer: {
    background: 'rgba(241, 194, 0, 0.04)',
    marginBottom: 30,
    padding: [33, 0],
    textAlign: 'center',
  },
};

const RolloutTaskStartButton = (
  { rollout, onClick, classes },
  { namespaceUID }
) => {
  const disabled = rollout.status === rolloutStatus.stopped;
  return (
    <div className={classes.startTaskContainer}>
      <OutlineButton
        onClick={onClick(namespaceUID)}
        disabled={disabled}
        icon={<StartIcon />}
        label="Start now"
      />
    </div>
  );
};

RolloutTaskStartButton.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
};

RolloutTaskStartButton.propTypes = {
  rollout: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch, { task }) => ({
  onClick(namespaceUID) {
    return () => dispatch(openStartTaskModal(task, namespaceUID));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(injectSheet(style)(RolloutTaskStartButton));
