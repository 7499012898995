import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { LoadingIcon } from '../Icons';

const styles = {
  box: {
    height: 475,
    paddingTop: 51,
    backgroundColor: '#FFF',
    borderRadius: '0 0 2px 2px',
    boxShadow: '0 0 12px 0 #E5E6E8',
  },
  label: {
    display: 'block',
    color: '#3E3E40',
    marginLeft: 85,
    marginBottom: 75,
    fontSize: 22,
    fontWeight: 500,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 28,
  },
  text: {
    width: 238,
    color: '#3E3E40',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
  },
};

const SubmitPage = ({ classes }) => (
  <div className={classes.box}>
    <span className={classes.label}>Payment Details</span>
    <div className={classes.body}>
      <LoadingIcon />
    </div>
    <div className={classes.body}>
      <span className={classes.text}>
        Wait a second, we are redirecting you to a secure checkout.
      </span>
    </div>
  </div>
);

SubmitPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(SubmitPage);
