import qs from 'qs';
import cuid from 'cuid';
import * as actions from './actions';
import { getOAuthConfig } from '../config';
import { logInfo } from '../updatehub/utils';

export const clearStorage = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('state');
  localStorage.removeItem('access_token_expires_at');
  localStorage.removeItem('redir_url');
};

export const clearState = () => {
  localStorage.removeItem('state');
};

export const getNewToken = () =>
  new Promise((resolve, reject) => {
    const handler = ({ data }) => {
      if (typeof data === 'object' && data.newToken) {
        resolve(data);
      }
    };

    setTimeout(() => {
      logInfo('[token] removing listener');
      window.removeEventListener('message', handler);
      reject();
    }, 5000);

    logInfo('[token] add listener to wait message from frame');
    window.addEventListener('message', handler);
  });
export const getIssuedAt = () => localStorage.getItem('issued_at');
export const setIssuedAt = issuedAt =>
  localStorage.setItem('issued_at', issuedAt);
export const getToken = () => localStorage.getItem('access_token');
export const hasToken = () => localStorage.getItem('access_token') !== null;
export const getExpirationDate = () =>
  localStorage.getItem('access_token_expires_at');
export const setExpirationDate = expiresIn => {
  localStorage.setItem(
    'access_token_expires_at',
    Date.now() + expiresIn * 1000
  );
};
export const expiredToken = () => {
  const expirationDate = parseInt(getExpirationDate(), 10);
  if (Number.isNaN(expirationDate)) {
    return true;
  }

  return Date.now() > expirationDate;
};
export const setToken = token => localStorage.setItem('access_token', token);
export const parseQuery = query => qs.parse(query.substr(1));

export const generateURL = (config, silent = false) => {
  const keys = Object.keys(config);
  const requiredKeys = ['clientId', 'url'];

  if (!requiredKeys.every(value => keys.indexOf(value) !== -1)) {
    return null;
  }

  const state = cuid();
  const query = qs.stringify({
    state,
    response_type: 'token',
    client_id: config.clientId,
    scope: config.scope,
    redirect_uri: silent ? config.silentUri : config.redirectUri,
  });

  const url = `${config.url}?${query}`;
  if (!silent) {
    localStorage.setItem('state', state);
    return url;
  }

  return {
    url,
    state,
  };
};

export const validateToken = parameters => {
  const expectedState = localStorage.getItem('state');

  clearStorage();
  if (!parameters.state || parameters.state !== expectedState) {
    return false;
  }
  if (!parameters.access_token) {
    return false;
  }
  if (!parameters.expires_in) {
    return false;
  }

  setIssuedAt(Date.now());
  setExpirationDate(parameters.expires_in);
  setToken(parameters.access_token);

  return true;
};

// FIXME, missing rework here!
export const createOnEnter = store => {
  const state = store.getState();
  const app = state.get('app');
  const auth = state.get('auth');

  store.dispatch(actions.configureOAuth(getOAuthConfig()));
  if ((!auth || !auth.get('isLoggedIn')) && !app.get('errorMessage')) {
    store.dispatch(actions.requestToken());
  }
};

export const redirectTo = url => {
  window.location.href = url;
};
