import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import Page from '../Page';
import * as actions from './actions';
import { CheckIcon } from '../Icons';
import { blue } from '../Theme/colors';
import { getOrganizationName, getInvitedBy, getIsLoading } from './reducer';
import { OutlineButton, Button } from '../Buttons';

const styles = {
  container: {
    paddingTop: 149,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  heading: {
    width: 520,
    fontSize: 24,
    marginBottom: 35,
  },
  description: {
    width: 460,
    marginBottom: 54,
  },
  joinButtonBox: {
    width: 234,
    marginBottom: 25,
  },
  rejectButtonBox: {
    width: 166,
  },
};

const iconStyle = {
  width: 15,
  height: 15,
};

const OrganizationInvitationPage = ({
  onLoad,
  classes,
  organizationName,
  invitedBy,
  isLoading,
  onAccept,
  onReject,
}) => (
  <Page onLoad={onLoad}>
    <div className={classes.container}>
      <h1 className={classes.heading}>
        You’ve been invited to be a member of the organization
        {organizationName}
      </h1>
      <p className={classes.description}>
        You‘ve been invited and granted access to the organization&nbsp;
        {organizationName}
        by
        {invitedBy}
        <br />
        <span>
          <b>To confirm your entry, please click the Join button.</b>
        </span>
      </p>
      <div className={classes.joinButtonBox}>
        <OutlineButton
          label="Join Organization"
          icon={<CheckIcon />}
          onClick={onAccept}
          disabled={isLoading}
          fullWidth
        />
      </div>
      <div className={classes.rejectButtonBox}>
        <Button
          label="Reject Invite"
          icon={<CloseIcon color={blue} style={iconStyle} />}
          onClick={onReject}
          disabled={isLoading}
          fullWidth
        />
      </div>
    </div>
  </Page>
);

OrganizationInvitationPage.propTypes = {
  organizationName: PropTypes.string.isRequired,
  invitedBy: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  organizationName: getOrganizationName(state),
  invitedBy: getInvitedBy(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onAccept() {
    dispatch(actions.acceptInvite());
  },
  onReject() {
    dispatch(actions.rejectInvite());
  },
  onLoad() {
    const { token } = match.params;
    dispatch(actions.requestInvitation(token));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(OrganizationInvitationPage));
