import { SubmissionError } from 'redux-form/immutable';
import { createOrganization } from '../../updatehub/api';
import { makeRequest } from '../../updatehub/http';
import { openNotification } from '../../Notification';
import { closeModal } from './actions';
import { request as requestOrganizations } from '../Organizations/actions';

export const CREATE_ORGANIZATION_SUCCESS = 'Organization created successfully';
export const UNKNOWN_ERROR = 'Something went wrong. Try again later.';

const onSubmit = (formData, dispatch, { authToken, reset }) => {
  const requestConfig = createOrganization(formData.get('name'));

  return makeRequest(requestConfig, authToken).then(({ result, error }) => {
    if (result) {
      dispatch(closeModal());
      dispatch(openNotification(CREATE_ORGANIZATION_SUCCESS));
      dispatch(reset());
      dispatch(requestOrganizations({ page: 0, pageSize: 5 }));
    } else if (error) {
      throw new SubmissionError(error);
    } else {
      dispatch(openNotification(UNKNOWN_ERROR));
    }
  });
};

export default onSubmit;
