import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';

const style = {
  modalLabel: {
    display: 'block',
    fontSize: 16,
    fontWeight: weight.medium,
  },
};

/* eslint-disable jsx-a11y/label-has-for */
const ModalLabel = ({ label, htmlFor, classes }) => (
  <label htmlFor={htmlFor} className={classes.modalLabel}>
    {label}
  </label>
);

ModalLabel.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ModalLabel);
