import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Paper from 'material-ui/Paper';
import { connect } from 'react-redux';
import { grey800 } from '../Theme/colors';
import AdvancedModeButton from './AdvancedModeButton';
import ActionButtons from './ActionButtons';
import Header from './Header';
import VersionSelector from './VersionSelector';
import Statistics from './Statistics';
import ErrorModal from './ErrorModal';
import AdvancedMode from './AdvancedMode';
import { getAdvancedMode } from './reducer';

const style = {
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  fixedBox: {
    width: 852,
    paddingTop: 83,
  },
  content: {
    padding: '45px 70px',
    color: grey800,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 50,
  },
};

const RolloutCreatePage = ({ classes, advancedMode }) => (
  <div className={classes.wrapper}>
    <div className={classes.fixedBox}>
      <Header />

      <Paper className={classes.content}>
        <VersionSelector />
        <Statistics />
        {!advancedMode && <AdvancedModeButton />}
        {advancedMode && <AdvancedMode />}
      </Paper>

      <ActionButtons />
      <ErrorModal />
    </div>
  </div>
);

RolloutCreatePage.propTypes = {
  classes: PropTypes.object.isRequired,
  advancedMode: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  advancedMode: getAdvancedMode(state),
});

export default connect(mapStateToProps)(injectSheet(style)(RolloutCreatePage));
