import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { grey800 } from '../Theme/colors';
import { weight } from '../Theme/Typography';

const style = {
  '@keyframes fade-in': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  '@keyframes dialog': {
    from: {
      top: -50,
    },
    to: {
      top: 0,
    },
  },
  modal: {
    alignItems: 'center',
    animation: 'fade-in 0.3s',
    background: 'rgba(59, 68, 108, 0.36)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000,
  },
  modalDialog: {
    animation: 'dialog 0.4s',
    background: 'white',
    boxShadow: '0 2px 0 0 rgba(0,0,0,0.20)',
    borderRadius: 2,
    color: grey800,
    fontSize: 14,
    fontWeight: weight.regular,
    margin: 'auto',
    padding: [38, 32],
    position: 'relative',
  },
};

const Modal = ({ children, isVisible, classes }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <div className={classes.modal}>
      <div>
        <div className={classes.modalDialog}>{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(Modal);
