import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import humanize from 'humanize-plus';
import { weight } from '../Theme/Typography';
import { percent } from './graph-utils';

const style = {
  legendEntry: {
    color: '#3D3E40',
    display: 'inline-block',
    marginBottom: 30,
  },
  legendWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  legendIcon: {
    borderRadius: '50%',
    height: 8,
    marginRight: 12,
    width: 8,
  },
  relativeValue: {
    display: 'block',
    fontSize: 16,
    fontWeight: weight.medium,
    marginBottom: 7,
  },
  absoluteValue: {
    display: 'block',
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: weight.light,
    paddingLeft: 20,
  },
};

const RolloutTaskProgressLegendEntry = ({
  label,
  value,
  total,
  color,
  classes,
}) => {
  const relativeValue = `${percent(value, total)}% ${label}`;
  const absoluteValue = `(${value} ${humanize.pluralize(value, 'device')})`;
  const iconStyle = { background: color };
  return (
    <div className={classes.legendEntry}>
      <div className={classes.legendWrapper}>
        <div className={classes.legendIcon} style={iconStyle} />
        <span className={classes.relativeValue}>{relativeValue}</span>
      </div>
      <span className={classes.absoluteValue}>{absoluteValue}</span>
    </div>
  );
};

RolloutTaskProgressLegendEntry.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutTaskProgressLegendEntry);
