import React from 'react';
import injectSheet from 'react-jss';
import Logo from './Logo';
import UserMenu from './UserMenu';
import { blue900 } from '../Theme/colors';
import Breadcrumb from './Breadcrumb';

const style = {
  header: {
    width: '100%',
    height: 72,
    background: blue900,
    boxShadow: '0 0 32px 0 rgba(50,51,56,0.10) !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    padding: [0, 40],
    zIndex: 1,
  },
};

export const AppHeader = injectSheet(style)(({ classes }) => (
  <div className={classes.header}>
    <Logo />
    <UserMenu />
  </div>
));

export const DashboardHeader = injectSheet(style)(({ classes }) => (
  <div className={classes.header}>
    <Breadcrumb />
    <UserMenu />
  </div>
));
