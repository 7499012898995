import * as types from './types';

export const requestPage = () => ({
  type: types.PAGE_REQUEST,
});

export const gotNamespaces = payload => ({
  type: types.GOT_NAMESPACES,
  payload,
});

export const gotBillingInformation = (uid, payload) => ({
  type: types.GOT_BILLING_INFORMATION,
  uid,
  payload,
});

export const removeNamespace = uid => ({
  type: types.REMOVE_NAMESPACE,
  uid,
});
