import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import CloseButton from './CloseButton';
import { CopiableText, CopiableBlock } from '../../Copiable';
import { weight } from '../../Theme/Typography';
import { grey800 } from '../../Theme/colors';
import { getResult } from './reducer';
import { ModalTitle, ModalContent, ModalFooter } from '../../Modal';

const styles = {
  apiKey: {
    color: grey800,
    fontSize: 14,
    lineHeight: '20px',
    margin: '20px 0px 10px 0px',
    textAlign: 'justify',
  },
  block: {
    extend: 'apiKey',
    display: 'block',
    fontFamily: 'Courier, monospace, Courier New',
    fontWeight: weight.regular,
    fontSize: 12,
    margin: '7px 0px 20px 0px',
    padding: '0.85em 1em 0.85em 1em',
    backgroundColor: '#f7f7f7',
    width: '100%',
  },
  blockYocto: {
    extend: 'block',
    margin: '7px 0px 15px 0px',
    width: 'auto',
    textAlign: 'left',
    borderLeft: '5px solid #D5D7D8',
  },
  blockInLine: {
    extend: 'block',
    display: 'inline-block',
    width: 'auto',
    margin: '0px 2px 0px 2px',
    padding: '2px 5px 2px 5px',
  },
  subtitle: {
    extend: 'apiKey',
    lineHeight: '19px',
    fontWeight: 300,
    margin: '25px 0px 9px 0px',
    display: 'block',
  },
  footer: {
    textAlign: 'right',
    width: '100%',
  },
};

const SuccessModal = ({ classes, result }) => {
  const keyYocto =
    `UPDATEHUB_ACCESS_ID = "${result.accessKey}"` +
    '\n' +
    `UPDATEHUB_ACCESS_SECRET = "${result.secretKey}"`;
  return (
    <div>
      <ModalContent large>
        <ModalTitle title="Access key was created successfully" />

        <div className={classes.apiKey}>
          <p>
            The access key is required to access your account externally. Your
            access key id and its corresponding secret key are:
          </p>
          <span className={classes.subtitle}>ACCESS KEY ID </span>
          <span className={classes.block}>
            <CopiableText text={result.accessKey} />
          </span>
          <span className={classes.subtitle}>SECRET KEY </span>
          <span className={classes.block}>
            <CopiableText text={result.secretKey} />
          </span>
          <p>
            After you close this dialog, the secret key will not be accessible
            anymore so in case you lose it, please generate a new one.
          </p>
        </div>
        <div className={classes.apiKey}>
          <p>
            If you are creating a product based on the Yocto Project, you should
            use the
            <span className={classes.blockInLine}>UPDATEHUB_ACCESS_ID</span>
            and the
            <span className={classes.blockInLine}>UPDATEHUB_ACCESS_SECRET</span>
            variable inside your{' '}
            <span className={classes.blockInLine}>conf/site.conf</span>, or the{' '}
            <span className={classes.blockInLine}>conf/local.conf</span>,
            configuration file to set the credentials to be used, as follows:
          </p>
          <span className={classes.subtitle}>CONFIGURATION </span>
          <span className={classes.blockYocto}>
            <CopiableBlock text={keyYocto}>
              <span>
                UPDATEHUB_ACCESS_ID = &quot;{result.accessKey}&quot;
                <br />
                UPDATEHUB_ACCESS_SECRET = &quot;{result.secretKey}&quot;
              </span>
            </CopiableBlock>
          </span>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className={classes.footer}>
          <CloseButton />
        </div>
      </ModalFooter>
    </div>
  );
};

SuccessModal.propTypes = {
  classes: PropTypes.object.isRequired,
  result: PropTypes.shape({
    accessKey: PropTypes.string,
    secretKey: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  result: getResult(state),
});

export default connect(mapStateToProps)(injectSheet(styles)(SuccessModal));
