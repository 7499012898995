import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';

const style = {
  h2: {
    fontWeight: weight.medium,
    fontSize: 16,
    marginBottom: 29,
  },
};

const Header = ({ classes }) => (
  <h2 className={classes.h2}>Create new rollout</h2>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(Header);
