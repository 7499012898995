import React from 'react';
import PropTypes from 'prop-types';
import { grey200, blue } from '../../Theme/colors';

const pageControlBaseButtonStyle = {
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.70)',
  border: '2px solid white',
  outline: 'none',
  color: blue,
  cursor: 'pointer',
};

const controlIconStyle = {
  paddingTop: 2,
  height: 18,
};

const getControlButtonStyle = disabled => {
  if (disabled) {
    return {
      ...pageControlBaseButtonStyle,
      border: `2px solid ${grey200}`,
      backgroundColor: 'transparent',
      color: grey200,
      cursor: 'default',
    };
  }

  return pageControlBaseButtonStyle;
};

const ControlButton = ({ icon, disabled, onClick }) => {
  const Icon = icon;
  const style = getControlButtonStyle(disabled);

  return (
    <button style={style} disabled={disabled} onClick={onClick}>
      <Icon style={controlIconStyle} color={style.color} />
    </button>
  );
};

ControlButton.propTypes = {
  icon: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ControlButton;
