import { select, put, takeEvery } from 'redux-saga/effects';
import { openNotification } from '../../Notification';
import { request as requestTableLoad } from '../Organizations/actions';
import * as actions from './actions';
import * as types from './types';
import * as uh from '../../updatehub/api';
import request from '../../updatehub/effects';
import { getUID } from './reducer';

export const SUCCESS_MESSAGE = 'You left the organization.';
export const ERROR_MESSAGE =
  'Error on leaving from this organization. Try again later.';

export function* leaveOrganizationSaga() {
  const uid = yield select(getUID);

  const { result } = yield request(uh.leaveOrganization(uid));
  if (result) {
    yield put(openNotification(SUCCESS_MESSAGE));
    yield put(requestTableLoad({ page: 0, pageSize: 5 }));
  } else {
    yield put(openNotification(ERROR_MESSAGE));
  }
  yield put(actions.closeModal());
}

function* rootSaga() {
  yield takeEvery(types.LEAVE_ORGANIZATION, leaveOrganizationSaga);
}

export default rootSaga;
