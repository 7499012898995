import { duration } from 'moment';

export const stepName = {
  start: 'start',
  download: 'download',
  installation: 'installation',
  reboot: 'reboot',
  finish: 'finish',
};

export const stepStatus = {
  readyToStart: 'ready-to-start',
  pending: 'pending',
  running: 'running',
  justFinished: 'just-finished',
  finished: 'finished',
  error: 'error',
  notReported: 'not-reported',
};

const deviceReport = {
  startFinished: 'probed',
  downloadRunning: 'downloading',
  downloadFinished: 'downloaded',
  downloadError: 'downloading_error',
  installationRunning: 'installing',
  installationFinished: 'installed',
  installationError: 'installing_error',
  rebootRunning: 'rebooting',
  rebootFinished: 'completed',
  rebootError: 'rolled_back',
  finishFinished: 'completed',
};

const getStepStatus = report => {
  const status = report ? report.status : report;
  switch (status) {
    case deviceReport.downloadRunning:
    case deviceReport.installationRunning:
    case deviceReport.rebootRunning:
      return stepStatus.running;
    case deviceReport.startFinished:
    case deviceReport.downloadFinished:
    case deviceReport.installationFinished:
    case deviceReport.finishFinished:
      return stepStatus.finished;
    case deviceReport.downloadError:
    case deviceReport.installationError:
    case deviceReport.rebootError:
      return stepStatus.error;
    default:
      return stepStatus.pending;
  }
};

const getReport = (reports, reportStatus) =>
  reports.filter(report => report.status === reportStatus)[0] || null;

const Step = (name, report, elapsedTime = null) => ({
  name,
  status: getStepStatus(report),
  date: report ? report.date : null,
  log: report ? report.log : undefined,
  elapsedTime: elapsedTime || null,
});

const measureElapsedTime = (startStatus = null, finishedStatus = null) => {
  if (!startStatus || !finishedStatus) {
    return null;
  }

  return duration(
    new Date(finishedStatus.date) - new Date(startStatus.date)
  ).humanize();
};

const getStartStepReports = data => {
  const finished = getReport(data, deviceReport.startFinished);
  return Step(stepName.start, finished);
};

const getDownloadStepReports = data => {
  const error = getReport(data, deviceReport.downloadError);
  const finished = getReport(data, deviceReport.downloadFinished);
  const running = getReport(data, deviceReport.downloadRunning);
  const current = error || finished || running;
  const elapsedTime = measureElapsedTime(running, finished);
  return Step(stepName.download, current, elapsedTime);
};

const getInstallationStepReports = data => {
  const error = getReport(data, deviceReport.installationError);
  const finished = getReport(data, deviceReport.installationFinished);
  const running = getReport(data, deviceReport.installationRunning);
  const current = error || finished || running;
  const elapsedTime = measureElapsedTime(running, finished);
  return Step(stepName.installation, current, elapsedTime);
};

const getRebootStepReports = data => {
  const error = getReport(data, deviceReport.rebootError);
  const finished = getReport(data, deviceReport.rebootFinished);
  const running = getReport(data, deviceReport.rebootRunning);
  const current = running && finished ? finished : error || running;
  const elapsedTime = measureElapsedTime(running, finished);
  return Step(stepName.reboot, current, elapsedTime);
};

const getFinishStepReports = data => {
  const start = getReport(data, deviceReport.startFinished);
  const finished = getReport(data, deviceReport.finishFinished);
  const elapsedTime = measureElapsedTime(start, finished);
  return Step(stepName.finish, finished, elapsedTime);
};

const extractStepsFromReports = reports =>
  [
    getStartStepReports,
    getDownloadStepReports,
    getInstallationStepReports,
    getRebootStepReports,
    getFinishStepReports,
  ].map(f => f(reports));

const setNotReportedStatus = data =>
  data
    .reduceRight(
      /* eslint-disable no-param-reassign */
      (transaction, currentStep) => {
        let hasFutureActions;
        if (!transaction.length) {
          hasFutureActions = currentStep.status !== stepStatus.pending;
          return [[currentStep, hasFutureActions]];
        }
        const nextStepIsActive = transaction[0][1];
        const currentStepIsNotCompleted =
          currentStep.status !== stepStatus.finished;
        const currentStepIsActive = currentStep.status !== stepStatus.pending;
        if (nextStepIsActive && currentStepIsNotCompleted) {
          currentStep.status = stepStatus.notReported;
          currentStep.date = null;
        }
        hasFutureActions = nextStepIsActive || currentStepIsActive;
        return [[currentStep, hasFutureActions], ...transaction];
      },
      []
    )
    .map(step => step[0]);

const setReadyToStartAndJustFinishedStatus = data =>
  data.reduceRight((transaction, currentStep) => {
    if (!transaction.length) {
      return [currentStep];
    }
    const nextStep = transaction[0];
    const currentStepIsFinished = currentStep.status === stepStatus.finished;
    const nextStepIsPending = nextStep.status === stepStatus.pending;
    if (currentStepIsFinished && nextStepIsPending) {
      currentStep.status = stepStatus.justFinished;
      nextStep.status = stepStatus.readyToStart;
    }
    return [currentStep, ...transaction];
  }, []);

const extractLogFromSteps = steps => {
  const data = steps.map(step => [
    {
      name: step.name,
      status: step.status,
      date: step.date,
      elapsedTime: step.elapsedTime,
    },
    step.log,
  ]);
  const finalSteps = data.map(entry => entry[0]);
  const log = data
    .map(entry => entry[1])
    .filter(entry => entry)
    .join('\n');
  return [finalSteps, log];
};

const serializeTransaction = data => {
  const [steps, log] = [
    extractStepsFromReports,
    setNotReportedStatus,
    setReadyToStartAndJustFinishedStatus,
    extractLogFromSteps,
  ].reduce((curr, f) => f(curr), data);
  return { steps, log };
};

export default serializeTransaction;
