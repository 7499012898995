import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import CardContainer from './CardContainer';
import FooterEntry from './RolloutTaskCardFooterEntry';

const RolloutTaskCardFooter = ({ task }) => (
  <CardContainer>
    <Row>
      <Col md={4}>
        <FooterEntry
          label="Devices involved"
          value={task.totalDevices.toString()}
        />
      </Col>
      <Col md={4}>
        <FooterEntry
          label="Fault tolerance"
          value={`${task.faultTolerance}%`}
        />
      </Col>
    </Row>
  </CardContainer>
);

RolloutTaskCardFooter.propTypes = {
  task: PropTypes.object.isRequired,
};

export default RolloutTaskCardFooter;
