import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../Theme/SectionTitle';
import Task from './RolloutTask';

const TasksColumn = ({ rollout }) => (
  <div>
    <SectionTitle text="Tasks" />
    {rollout.tasks.map((task, index) => (
      <Task key={task.uid} task={task} rollout={rollout} index={index} />
    ))}
  </div>
);

TasksColumn.propTypes = {
  rollout: PropTypes.object.isRequired,
};

export default TasksColumn;
