import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { isAborted } from './utils';
import TasksNamesRow from './RolloutTasksNames';
import TasksStatusRow from './RolloutTasksStatus';
import TasksProgressRow from './RolloutTasksProgress';

const style = {
  box: {
    marginTop: 30,
  },
  smallBox: {
    extend: 'box',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
  },
};

const getBoxClass = (tasks, classes) => {
  if (tasks.length <= 1) {
    return null;
  }
  if (tasks.length === 2) {
    return classes.smallBox;
  }
  return classes.box;
};

const sliceTasksByStatus = (tasks, status) => {
  const statusList = tasks.map(task => task.status);
  const index = statusList.lastIndexOf(status);
  const lastIndex = tasks.length - 1;
  if (index === lastIndex) {
    return tasks.slice(-3);
  }
  if (index > 0) {
    return tasks.slice(index - 1, index + 2);
  }
  return null;
};

const selectTasksToShow = tasks => {
  if (tasks.length <= 3) {
    return tasks;
  }
  const waitingSlice = sliceTasksByStatus(tasks, 'waiting');
  const runningSlice = sliceTasksByStatus(tasks, 'running');
  return waitingSlice || runningSlice || tasks.slice(0, 3);
};

const Tasks = ({ status, tasks, classes }) => {
  // We don't display tasks for aborted nor simple rollouts
  if (isAborted(status) || tasks.length === 1) {
    return null;
  }
  const selectedTasks = selectTasksToShow(tasks);
  const boxClass = getBoxClass(selectedTasks, classes);
  return (
    <div className={boxClass}>
      <TasksNamesRow tasks={selectedTasks} />
      <TasksStatusRow tasks={selectedTasks} />
      <TasksProgressRow tasks={selectedTasks} />
    </div>
  );
};

Tasks.propTypes = {
  status: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(Tasks);
