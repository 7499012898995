import { combineReducers } from 'redux-immutable';
import { modalReducer } from '../Modal';
import ListModel from './models';
import * as types from './types';

export const createProductKey = 'create-product';

export const isEmpty = products =>
  products.reduce((empty, set) => empty && set.products.length <= 0, true);

/* eslint-disable function-paren-newline */
export const listReducer = (state = ListModel(), action) => {
  switch (action.type) {
    case types.PRODUCT_LIST_GOT_NAMESPACES:
      return state.set('namespaces', action.payload);
    case types.PRODUCT_LIST_GOT_PRODUCTS:
      return state.withMutations(oldState =>
        oldState
          .set('products', action.payload)
          .set('isEmpty', isEmpty(action.payload))
      );
    default:
      return state;
  }
};

export const defaultNamespaceUID = (state = '', action) => {
  switch (action.type) {
    case types.SET_DEFAULT_NAMESPACE_UID:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  list: listReducer,
  modalCreateProduct: modalReducer(createProductKey),
  selectedNamespaceUID: defaultNamespaceUID,
});

const getSlice = state => state.get('productList');

export const getNamespaces = state =>
  getSlice(state).getIn(['list', 'namespaces']);

export const getModalCreateProduct = state =>
  getSlice(state).get('modalCreateProduct');

export const getIsEmpty = state => getSlice(state).getIn(['list', 'isEmpty']);

export const getProductsList = state =>
  getSlice(state).getIn(['list', 'products']);

export const getDefaultNamespaceUID = state =>
  getSlice(state).get('selectedNamespaceUID');
