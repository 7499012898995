import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const style = {
  cell: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: [0, 10],

    '& > *:not(:first-child)': {
      marginLeft: 10,
    },
  },
};

const ActionsCell = ({ children, classes }) => (
  <div className={classes.cell}>{children}</div>
);

ActionsCell.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ActionsCell);
