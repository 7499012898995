import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { fromNow, humanizeDate, humanizePrice } from '../updatehub/humanizer';

const styles = {
  overviewTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  overview: {
    extend: 'overviewTop',
    marginBottom: 26,
  },
  close: {
    color: '#0E283D',
    fontSize: 12,
  },
  total: {
    color: '#3E3E40',
    fontSize: 20,
    fontWeight: 600,
  },
  label: {
    color: '#3E3E40',
    fontSize: 16,
  },
};

const AmountPrice = ({ classes, total, closeIn }) => (
  <div>
    <div className={classes.overviewTop}>
      <span className={classes.total}>{humanizePrice(total)}</span>
      <span className={classes.close}>
        Billing cycle closes
        {fromNow(closeIn)}
      </span>
    </div>
    <div className={classes.overview}>
      <span className={classes.label}>You owe</span>
      <span className={classes.close}>({humanizeDate(closeIn)})</span>
    </div>
  </div>
);

AmountPrice.propTypes = {
  classes: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  closeIn: PropTypes.instanceOf(Date).isRequired,
};

export default injectSheet(styles)(AmountPrice);
