import { Record } from 'immutable';
import * as types from './types';

export const DeleteApiKeyModel = Record({
  isOpen: false,
  uid: '',
  isLoading: false,
});

/* eslint-disable function-paren-newline */
const deleteApiKeyModalReducer = (state = DeleteApiKeyModel(), action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return state.withMutations(oldState =>
        oldState.set('isOpen', true).set('uid', action.payload)
      );
    case types.DELETE_API_KEY:
      return state.set('isLoading', true);
    case types.CLOSE_MODAL:
      return DeleteApiKeyModel();
    default:
      return state;
  }
};

export default deleteApiKeyModalReducer;

// Selectors

const getModalState = state => state.getIn(['settings', 'deleteApiKeyModal']);

export const getUID = state => getModalState(state).get('uid');

export const getModalIsOpen = state => getModalState(state).get('isOpen');

export const getModalIsLoading = state => getModalState(state).get('isLoading');
