import { Record, Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as types from './types';
import reducerFactory, {
  selectorFactory,
  TableModel,
} from '../Table/reducerFactory';
import { humanizeHash, fromNow } from '../updatehub/humanizer';

const lastProbe = device => {
  if (!device.lastProbe) {
    return 'Fetching...';
  }

  if (Number.isNaN(Date.parse(device.lastProbe))) {
    return device.lastProbe;
  }

  return fromNow(device.lastProbe);
};

export const defaultTableColumns = [
  {
    Header: 'UID',
    id: 'uid',
    accessor: ({ uid }) => humanizeHash(uid),
    minWidth: 100,
  },
  {
    Header: 'Hardware',
    accessor: 'hardware',
    minWidth: 200,
  },
  {
    Header: 'Version',
    accessor: 'version',
    minWidth: 200,
  },
  {
    Header: 'Last Activity',
    id: 'last-probe',
    accessor: lastProbe,
  },
];

const deviceTable = TableModel({
  columns: defaultTableColumns,
});

const rawTableReducer = reducerFactory(
  types.REQUEST,
  types.RECEIVE,
  types.RESET
);
export const table = (state = deviceTable, action) => {
  const processedState = rawTableReducer(state, action);

  switch (action.type) {
    case types.SET_LAST_PROBE:
      return processedState.setIn(
        ['data', action.index, 'lastProbe'],
        action.payload
      );
    case types.SET_COLUMNS:
      return processedState.set('columns', action.payload);
    default:
      return processedState;
  }
};

export const RawFilter = Record({
  status: 'active',
  identities: Map({
    keys: [],
    values: {},
  }),
  versions: [],
  hardware: [],
  tags: [],
  attributes: Map({
    keys: [],
    values: {},
  }),
});

export const DeviceFilter = Record({
  rawFilter: RawFilter(),
  computedFilter: {},
});

export const filter = (state = DeviceFilter(), action) => {
  switch (action.type) {
    case types.UPDATE_FILTER:
      return state.setIn(['computedFilter'], action.payload);
    case types.UPDATE_RAW_FILTER: {
      let { key } = action;

      if (typeof action.key === 'string') {
        key = [action.key];
      }

      return state.setIn(['rawFilter', ...key], action.payload);
    }
    default:
      return state;
  }
};

export default combineReducers({
  table,
  filter,
});

export const getTable = selectorFactory(['devices', 'table']);
export const getTableColumns = state =>
  state.getIn(['devices', 'table', 'columns']);
export const getRawFilter = state =>
  state.getIn(['devices', 'filter', 'rawFilter']);
export const getFilter = state =>
  state.getIn(['devices', 'filter', 'computedFilter']);
