import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: weight.light,
    fontSize: 18,
    color: '#3E3E40',
  },
  icon: {
    display: 'flex',
    width: 24,
    height: 24,
    marginRight: 6,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
};

const SectionHeader = ({ classes, icon, title, children }) => (
  <div className={classes.header}>
    <h2 className={classes.title}>
      <div className={classes.icon}>{icon}</div>
      {title}
    </h2>
    {children}
  </div>
);

SectionHeader.defaultProps = {
  children: null,
};

SectionHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default injectSheet(styles)(SectionHeader);
