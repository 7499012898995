import { Record } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as types from './types';
import activeRollouts from './ActiveRolloutsWidget/reducer';
import { CARD_STATUS } from './Card';

export const DevicesGraphModel = Record({
  data: [],
  status: CARD_STATUS.LOADING,
});

export const RecentPackagesModel = Record({
  packages: [],
  status: CARD_STATUS.LOADING,
});

/* eslint-disable function-paren-newline */
export const devicesGraph = (state = DevicesGraphModel(), action) => {
  switch (action.type) {
    case types.REQUEST_DEVICES:
      return state.set('status', CARD_STATUS.LOADING);
    case types.REQUEST_DEVICES_ERROR:
      return state.set('status', CARD_STATUS.LOAD_ERROR);
    case types.SET_DEVICES:
      return state.withMutations(oldState =>
        oldState.set('data', action.payload).set('status', CARD_STATUS.LOADED)
      );
    default:
      return state;
  }
};

export const recentPackages = (state = RecentPackagesModel(), action) => {
  switch (action.type) {
    case types.REQUEST_PACKAGES:
      return state.set('status', CARD_STATUS.LOADING);
    case types.REQUEST_PACKAGES_ERROR:
      return state.set('status', CARD_STATUS.LOAD_ERROR);
    case types.SET_PACKAGES:
      return state.withMutations(oldState =>
        oldState
          .set('packages', action.payload)
          .set('status', CARD_STATUS.LOADED)
      );
    default:
      return state;
  }
};

export default combineReducers({
  activeRollouts,
  devicesGraph,
  recentPackages,
});

// Selectors
export const getDevicesGraphCardStatus = state =>
  state.getIn(['overview', 'devicesGraph', 'status']);

export const getDevicesData = state =>
  state.getIn(['overview', 'devicesGraph', 'data']);

export const getRecentPackagesCardStatus = state =>
  state.getIn(['overview', 'recentPackages', 'status']);

export const getRecentPackages = state =>
  state.getIn(['overview', 'recentPackages', 'packages']);
