import { combineReducers } from 'redux-immutable';
import { REQUEST, RECEIVE, RESET } from './types';
import reducerFactory, { selectorFactory } from '../../Table/reducerFactory';

export const table = reducerFactory(REQUEST, RECEIVE, RESET);

export default combineReducers({
  table,
});

export const getTable = selectorFactory(['settings', 'organization', 'table']);
