import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { LinkButton } from '../Buttons';
import { Modal, ModalContent, ModalFooter } from '../Modal';
import { closeDeviceLogModal } from './actions';
import { getRolloutTransaction, deviceLogModalIsVisible } from './reducer';

const style = {
  deviceLog: {
    background: 'black',
    color: 'white',
    fontSize: 12,
    height: 400,
    overflow: 'auto',
  },
};

const DeviceLogModal = ({ log, isVisible, onRequestClose, classes }) => (
  <Modal isVisible={isVisible}>
    <ModalContent large>
      <div className={classes.deviceLog}>
        <p>{log}</p>
      </div>
    </ModalContent>
    <ModalFooter>
      <LinkButton label="close" onClick={onRequestClose} />
    </ModalFooter>
  </Modal>
);

DeviceLogModal.propTypes = {
  log: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  log: getRolloutTransaction(state).log,
  isVisible: deviceLogModalIsVisible(state),
});

const mapDispatchToProps = dispatch => ({
  onRequestClose() {
    dispatch(closeDeviceLogModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(style)(DeviceLogModal));
