import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import * as actions from './actions';
import { getRolloutToDetail } from './reducer';

const style = {
  rolloutListItem: {
    background: 'white',
    '&:nth-child(odd)': {
      background: '#FBFCFC',
    },
    '&:hover, & .active': {
      background: '#F3F4F7',
    },
  },
  link: {
    width: '100%',
    cursor: 'pointer',
    display: 'block',
    outline: 'none',
    border: 'none',
    background: 'none',
    textAlign: 'start',
    padding: [14, 24],
  },
};

const RolloutEntry = ({ activeRollout, rollout, onClick, classes }) => {
  let linkClass = classes.link;
  if (activeRollout && activeRollout.uid === rollout.uid) {
    linkClass += ' active';
  }

  return (
    <li className={classes.rolloutListItem}>
      <button className={linkClass} tabIndex={0} onClick={onClick}>
        {rollout.name}
      </button>
    </li>
  );
};

RolloutEntry.defaultProps = {
  activeRollout: {},
};

RolloutEntry.propTypes = {
  activeRollout: PropTypes.object,
  rollout: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRollout: getRolloutToDetail(state),
});

const mapDispatchToProps = (
  dispatch,
  { rollout, deviceUID, namespaceUID, productUID }
) => ({
  onClick() {
    const action = actions.requestRolloutTransaction(
      rollout.uid,
      deviceUID,
      namespaceUID,
      productUID
    );
    dispatch(action);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(style)(RolloutEntry));
