import React from 'react';
import PropTypes from 'prop-types';
import { Sector } from 'recharts';

const gStyle = {
  cursor: 'pointer',
};

const SelectedShape = props => {
  const {
    percent,
    cx,
    cy,
    innerRadius,
    outerRadius,
    fill,
    startAngle,
    endAngle,
  } = props;
  const startAngleValue = percent !== 1 ? startAngle + 4 : startAngle;
  const endAngleValue = percent !== 1 ? endAngle - 4 : endAngle;

  return (
    <g style={gStyle}>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 5}
        startAngle={startAngleValue}
        endAngle={endAngleValue}
        fill={fill}
      />
    </g>
  );
};

SelectedShape.propTypes = {
  percent: PropTypes.number.isRequired,
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  innerRadius: PropTypes.number.isRequired,
  outerRadius: PropTypes.number.isRequired,
  startAngle: PropTypes.number.isRequired,
  endAngle: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
};

export default SelectedShape;
