import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { withRouter } from 'react-router-dom';
import { withAuthToken } from '../Auth';
import { LinkButton, OutlineButton } from '../Buttons';
import { CheckIcon } from '../Icons';
import { Modal, ModalContent, ModalFooter, connectModal } from '../Modal';
import { removeOrganizationMember } from '../updatehub/api';
import { makeRequest } from '../updatehub/http';
import { openNotification } from '../Notification';
import { refreshMembersTable } from './actions';
import {
  removeMemberKey,
  getRemoveMemberModal,
  getMemberToRemove,
} from './reducer';

export const REMOVE_MEMBER_SUCCESS = 'Member removed successfully';
export const REMOVE_MEMBER_ERROR = 'Could not remove member. Try again later.';

const RemoveMemberModal = ({
  isOpened,
  closeModal,
  handleSubmit,
  submitting,
  member,
}) => (
  <Modal isVisible={isOpened}>
    <form onSubmit={handleSubmit}>
      <ModalContent>
        {`Are you sure you want to remove ${
          member.name
        } from your organization?`}
      </ModalContent>
      <ModalFooter>
        <LinkButton label="cancel" onClick={closeModal} disabled={submitting} />
        <OutlineButton
          type="submit"
          label="remove member"
          icon={<CheckIcon />}
          disabled={submitting}
        />
      </ModalFooter>
    </form>
  </Modal>
);

RemoveMemberModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  member: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  member: getMemberToRemove(state),
});

const reduxConnector = connect(mapStateToProps);

export const modalConnector = connectModal(
  removeMemberKey,
  getRemoveMemberModal
);

const submitRemoveMemberForm = (
  formData,
  dispatch,
  { member, match, closeModal, authToken }
) => {
  const { params } = match;
  const requestConfig = removeOrganizationMember(
    params.organizationUID,
    member.uid
  );
  return makeRequest(requestConfig, authToken).then(({ result }) => {
    const msg = result ? REMOVE_MEMBER_SUCCESS : REMOVE_MEMBER_ERROR;
    closeModal();
    dispatch(openNotification(msg));
    dispatch(refreshMembersTable());
  });
};

const formConnector = reduxForm({
  form: removeMemberKey,
  onSubmit: submitRemoveMemberForm,
});

export default compose(
  withRouter,
  withAuthToken,
  reduxConnector,
  modalConnector,
  formConnector
)(RemoveMemberModal);
