import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';
import { fromNow } from '../updatehub/humanizer';
import { taskStatus, rolloutStatus } from './states';

const style = {
  rolloutStatus: {
    color: '#3D3E40',
    fontSize: 12,
    fontWeight: weight.regular,
    marginBottom: 15,
  },
};

const getTextForAbortedTask = task =>
  `This task has reached its fault tolerance of ${task.faultTolerance}%.`;

const getTextForAbortedRollout = task => {
  if (task.status === taskStatus.aborted) {
    return getTextForAbortedTask(task);
  }
  return 'This task was interrupted since another task reached its fault tolerance.';
};

const getTextForArchivedRollout = task => {
  switch (task.status) {
    case taskStatus.aborted:
      return getTextForAbortedTask(task);
    case taskStatus.waiting:
    case taskStatus.pending:
      return 'This task has never started.';
    case taskStatus.running:
    default:
      return 'This task will never start again since rollout was archived.';
  }
};

const getTextForRunningRollout = task => {
  switch (task.status) {
    case taskStatus.running:
      return `Started ${fromNow(task.startDate)}.`;
    case taskStatus.aborted:
      return getTextForAbortedTask(task);
    case taskStatus.waiting:
      return 'This task is ready to start.';
    case taskStatus.pending:
      return `Waiting previous task to reach ${
        task.startThreshold
      }% of progress.`;
    default:
      return 'Unknown task status.';
  }
};

const getStatusText = ({ task, rollout }) => {
  switch (rollout.status) {
    case rolloutStatus.aborted:
      return getTextForAbortedRollout(task);
    case rolloutStatus.stopped:
      return 'Rollout is currently not running.';
    case rolloutStatus.archived:
      return getTextForArchivedRollout(task);
    default:
      return getTextForRunningRollout(task);
  }
};

const RolloutTaskStatus = ({ task, rollout, classes }) => (
  <p className={classes.rolloutStatus}>{getStatusText({ task, rollout })}</p>
);

RolloutTaskStatus.propTypes = {
  task: PropTypes.object.isRequired,
  rollout: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutTaskStatus);
