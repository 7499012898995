import React from 'react';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import Table from './Table';
import OrganizationsIcon from './OrganizationsIcon';
import LeaveOrganizationModal from '../LeaveOrganizationModal';
import CreateOrganizationModal from '../CreateOrganizationModal';
import CreateOrganizationButton from '../CreateOrganizationModal/OpenModalButton';

const Organizations = () => (
  <Section>
    <SectionHeader title="Organizations" icon={<OrganizationsIcon />}>
      <CreateOrganizationButton />
    </SectionHeader>
    <CreateOrganizationModal />
    <LeaveOrganizationModal />
    <Table />
  </Section>
);

export default Organizations;
