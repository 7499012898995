import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRawFilter } from '../reducer';
import * as actions from '../actions';
import StatusFilter from '../../StatusFilter';

const statuses = [
  {
    label: 'Active Devices',
    value: 'active',
  },
  {
    label: 'Inactive Devices',
    value: 'inactive',
  },
];

const ShowingFilter = ({ value, onChange }) => (
  <StatusFilter
    label="Showing"
    value={value}
    statuses={statuses}
    onChange={onChange}
  />
);

ShowingFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  value: getRawFilter(state).get('status'),
});

export const mapDispatchToProps = dispatch => ({
  onChange(value) {
    dispatch(actions.updateRawFilter('status', value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowingFilter);
