import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../Buttons';
import { PauseIcon, StartIcon } from '../Icons';
import { rolloutStatus } from './states';
import { updateRolloutStatus } from './actions';

const getButtonProps = rollout => {
  switch (rollout.status) {
    case rolloutStatus.running:
    case rolloutStatus.waiting:
      return [<PauseIcon />, 'Pause', false, rolloutStatus.stopped];
    case rolloutStatus.stopped:
      return [<StartIcon />, 'Play', false, rolloutStatus.running];
    case rolloutStatus.aborted:
    case rolloutStatus.archived:
    default:
      return [<StartIcon />, 'Play', true, null];
  }
};

const ToggleRolloutStatusButton = (
  { rollout, updateStatus },
  { namespaceUID }
) => {
  const [icon, label, disabled, status] = getButtonProps(rollout);
  return (
    <Button
      onClick={updateStatus(status, namespaceUID)}
      disabled={disabled}
      icon={icon}
      label={label}
      fullWidth
    />
  );
};

ToggleRolloutStatusButton.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
};

ToggleRolloutStatusButton.propTypes = {
  rollout: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { rollout }) => ({
  updateStatus(status, namespaceUID) {
    return () =>
      dispatch(updateRolloutStatus(rollout.uid, status, namespaceUID));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(ToggleRolloutStatusButton);
