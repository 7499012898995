import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import url from '../../updatehub/urls';
import { Button } from '../../Buttons';
import { AddIcon } from '../../Icons';

const CreateRolloutButton = ({ dispatch }, { productUID, namespaceUID }) => {
  const toCreateRollout = url('product:rollouts:create', {
    namespaceUID,
    productUID,
  });
  return (
    <Button
      label="create rollout"
      onClick={() => dispatch(push(toCreateRollout))}
      icon={<AddIcon />}
    />
  );
};

CreateRolloutButton.contextTypes = {
  productUID: PropTypes.string.isRequired,
  namespaceUID: PropTypes.string.isRequired,
};

CreateRolloutButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(CreateRolloutButton);
