import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { weight } from '../../Theme/Typography';
import Graph from './Graph';
import EmptyGraph from './EmptyGraph';
import TotalDevices from './TotalDevices';
import { getGraphData } from '../reducer';

const style = {
  box: {
    flex: 1,
  },
  h4: {
    paddingTop: 10,
    fontWeight: weight.medium,
    fontSize: 14,
  },
  graphBox: {
    marginBottom: 15,
  },
};

const Statistics = ({ classes, graphData }) => (
  <div className={classes.box}>
    <div className={classes.graphBox}>
      <h4 className={classes.h4}>Rollout Overview</h4>
      <TotalDevices />
    </div>
    {graphData.length ? <Graph /> : <EmptyGraph />}
  </div>
);

Statistics.propTypes = {
  classes: PropTypes.object.isRequired,
  graphData: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  graphData: getGraphData(state),
});

export default connect(mapStateToProps)(injectSheet(style)(Statistics));
