import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { grey800 } from '../Theme/colors';
import { weight } from '../Theme/Typography';

const style = {
  modalTitle: {
    color: grey800,
    fontSize: 16,
    fontWeight: weight.medium,
    marginBottom: 14,
  },
};

const ModalTitle = ({ title, classes }) => (
  <h3 className={classes.modalTitle}>{title}</h3>
);

ModalTitle.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ModalTitle);
