import { call, take, put, select, delay } from 'redux-saga/effects';
import { APP_STATUS } from './models';
import { getStatus } from './reducer';
import * as app from './actions';
import * as auth from '../Auth/actions';
import * as oauth2 from '../Auth/utils';
import * as types from './types';
import * as uh from '../updatehub/api';
import request from '../updatehub/effects';

export const COMMUNICATION_ERROR_MESSAGE =
  'Problem when communicating with server';

export function* verifyAcessToken() {
  const expiredToken = yield call(oauth2.expiredToken);

  if (expiredToken) {
    yield put(app.redirectLogin());
    yield delay(200);
    yield put(auth.requestToken());
  }
}

export function* loadMe() {
  const { result } = yield request(uh.fetchMe());

  if (result) {
    yield put(app.setUser(result));
  } else {
    yield put(app.loadError(COMMUNICATION_ERROR_MESSAGE));
  }
}

export function* callIfNoError(fn, ...args) {
  const status = yield select(getStatus);

  if (status === APP_STATUS.LOADING) {
    yield call(fn, ...args);
  }
}

function* watchForLoadRequest() {
  yield take(types.REQUEST_LOAD);
  yield call(callIfNoError, verifyAcessToken);
  yield call(callIfNoError, loadMe);
  const status = yield select(getStatus);

  if (status === APP_STATUS.LOADING) {
    yield put(app.loaded());
  }
}

export default watchForLoadRequest;
