import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from './PageTitle';
import { CopiableHash } from '../Copiable';

const PageTitleWithHash = ({ hash, ...props }) => (
  <PageTitle {...props}>
    <CopiableHash text={hash} />
  </PageTitle>
);

PageTitleWithHash.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default PageTitleWithHash;
