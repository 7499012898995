import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Entry from './Entry';
import EditAccountLink from './EditAccountLink';

const styles = {
  userInfo: {
    marginTop: 34,
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const UserInfo = ({ classes, user }) => (
  <div className={classes.userInfo}>
    {user.name && <Entry label="Full Name" value={user.name} />}
    <Entry label="Email address" value={user.email} />
    <EditAccountLink />
  </div>
);

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default injectSheet(styles)(UserInfo);
