import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { YellowCorner, RedCorner } from '../Icons';
import { taskStatus, rolloutStatus } from './states';

const style = {
  corner: {
    position: 'absolute',
    zIndex: 100,
    top: -2,
    left: -2,
  },
};

const isTaskAborted = task => task.status === taskStatus.aborted;

const isTaskWaiting = task => task.status === taskStatus.waiting;

const isRolloutAborted = rollout => rollout.status === rolloutStatus.aborted;

const isRolloutArchived = rollout => rollout.status === rolloutStatus.archived;

const getCornerForAbortedRollout = (task, classes) => {
  if (isTaskAborted(task)) {
    return <img alt="error" src={RedCorner} className={classes.corner} />;
  }
  return null;
};

const CardCorner = ({ task, rollout, classes }) => {
  if (isRolloutAborted(rollout) || isTaskAborted(task)) {
    return getCornerForAbortedRollout(task, classes);
  }
  if (isTaskWaiting(task) && !isRolloutArchived(rollout)) {
    return <img alt="warning" src={YellowCorner} className={classes.corner} />;
  }
  return null;
};

CardCorner.propTypes = {
  task: PropTypes.object.isRequired,
  rollout: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(CardCorner);
