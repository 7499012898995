import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { openNotification } from '../Notification';

const BaseCopiable = ({ text, children, onCopy }) => (
  <CopyToClipboard text={text} onCopy={onCopy}>
    {children}
  </CopyToClipboard>
);

BaseCopiable.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onCopy: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onCopy() {
    dispatch(openNotification('Copied to clipboard!'));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(BaseCopiable);
