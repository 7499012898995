import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../Card';
import url from '../../updatehub/urls';
import { requestPackages } from '../actions';
import { getRecentPackagesCardStatus } from '../reducer';
import PackageList from './PackageList';

const RecentPackagesCard = (
  { onLoad, status },
  { namespaceUID, productUID }
) => (
  <Card
    title="Packages"
    to={url('product:packages', { namespaceUID, productUID })}
    status={status}
    onLoad={onLoad}
  >
    <PackageList />
  </Card>
);

RecentPackagesCard.propTypes = {
  onLoad: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

RecentPackagesCard.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
  productUID: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
  status: getRecentPackagesCardStatus(state),
});

export const mapDispatchToProps = dispatch => ({
  onLoad() {
    dispatch(requestPackages());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentPackagesCard);
