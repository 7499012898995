import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { APP_STATUS } from '../models';
import { getStatus } from '../reducer';
import Loading from './Loading';
import Error from './Error';
import Redirect from './Redirect';

const AppStatus = ({ status, children }) => {
  switch (status) {
    case APP_STATUS.LOADED:
      return children;
    case APP_STATUS.LOADING:
      return <Loading />;
    case APP_STATUS.REDIRECT:
      return <Redirect />;
    default:
      return <Error />;
  }
};

AppStatus.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const mapStateToProps = state => ({
  status: getStatus(state),
});

export default connect(mapStateToProps)(AppStatus);
