import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import request from '../updatehub/effects';
import url from '../updatehub/urls';
import buildRefresher from '../updatehub/refresher';
import * as api from '../updatehub/api';
import * as page from '../Page/actions';
import { getNamespaceUID, getProductUID } from '../Dashboard/reducer';
import {
  REQUEST_DEVICE,
  REQUEST_DEVICE_ROLLOUTS,
  SET_DEVICE_ROLLOUTS,
  REQUEST_ROLLOUT_TRANSACTION,
  REQUEST_DEVICE_DISABLE,
} from './types';
import * as actions from './actions';
import { getDevice as getLoadedDevice, getRolloutToDetail } from './reducer';
import { openNotification } from '../Notification';
import { fetchStatus } from './models';

export function* getRollouts({ payload }) {
  const { namespaceUID, deviceUID, productUID } = payload;
  const { result } = yield request(
    api.fetchDeviceRollouts(namespaceUID, deviceUID, productUID)
  );

  if (!result) {
    yield put(actions.setRolloutsError());
  } else {
    yield put(actions.setRollouts(result));
  }
}

export function* setDefaultRolloutTransaction({ payload }) {
  const { rollouts } = payload;
  const selectedRollout = yield select(getRolloutToDetail);

  if (selectedRollout) {
    return;
  }

  if (rollouts.length) {
    const rollout = rollouts[0];
    const namespaceUID = yield select(getNamespaceUID);
    const device = yield select(getLoadedDevice);
    const productUID = yield select(getProductUID);

    yield put(
      actions.requestRolloutTransaction(
        rollout.uid,
        device.uid,
        namespaceUID,
        productUID
      )
    );
  }
}

export function* getDeviceProbeInfo(action) {
  const { namespaceUID, deviceUID } = action.payload;
  const { result } = yield request(
    api.fetchDeviceProbeInfo(namespaceUID, deviceUID)
  );

  if (result) {
    yield put(
      actions.setProbeInfo({
        ...result,
        fetchStatus: fetchStatus.done,
      })
    );
  } else {
    yield put(
      actions.setProbeInfo({
        fetchStatus: fetchStatus.error,
      })
    );
  }
}

export function* getDevice(action) {
  const { namespaceUID, productUID, deviceUID } = action.payload;
  const { result } = yield request(
    api.fetchDevice(namespaceUID, productUID, deviceUID)
  );
  if (!result) {
    yield put(page.notFound());
  } else {
    yield put(actions.setDevice(result));
    yield put(page.loaded());

    yield put(actions.requestRollouts(namespaceUID, deviceUID, productUID));
    yield call(getDeviceProbeInfo, action);
  }
}

export function* getRolloutTransaction(action) {
  const { namespaceUID, deviceUID, rolloutUID, productUID } = action.payload;
  const { result } = yield request(
    api.fetchDeviceRollout(namespaceUID, deviceUID, rolloutUID, productUID)
  );
  if (!result) {
    yield put(actions.setRolloutTransactionError());
  } else {
    yield put(actions.setRolloutTransaction(result));
  }
}

export const SUCCESS_MESSAGE = 'Device disabled successfully!';
export const DISABLE_ERROR = 'Device could not be disabled. Try again later.';

export function* disableDevice(action) {
  const { deviceUID, namespaceUID, productUID } = action.payload;
  const { result } = yield request(
    api.deleteDevice(deviceUID, namespaceUID, productUID)
  );
  if (result) {
    yield put(openNotification(SUCCESS_MESSAGE));
    yield put(push(url('product:devices', { namespaceUID, productUID })));
  } else {
    yield put(openNotification(DISABLE_ERROR));
  }
}

function* rootSaga() {
  yield takeEvery(REQUEST_DEVICE, getDevice);
  yield takeEvery(REQUEST_DEVICE_ROLLOUTS, getRollouts);
  yield takeEvery(SET_DEVICE_ROLLOUTS, setDefaultRolloutTransaction);
  yield takeLatest(REQUEST_ROLLOUT_TRANSACTION, getRolloutTransaction);
  yield buildRefresher(
    REQUEST_ROLLOUT_TRANSACTION,
    getRolloutTransaction,
    'device-transaction',
    5
  );
  yield buildRefresher(
    REQUEST_DEVICE_ROLLOUTS,
    getRollouts,
    'device-rollouts',
    20
  );
  yield takeEvery(REQUEST_DEVICE_DISABLE, disableDevice);
}

export default rootSaga;
