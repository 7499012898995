import React from 'react';
import LoadingIcon from '../../Page/LoadingIcon';
import Wrapper, { HalfWrapper } from '../../Page/Wrappers';
import { weight } from '../../Theme/Typography';
import { grey800 } from '../../Theme/colors';

const loadingText = {
  textAlign: 'center',
  marginTop: 77,
  fontSize: 20,
  fontWeight: weight.light,
  color: grey800,
};

export default () => (
  <Wrapper>
    <HalfWrapper />
    <LoadingIcon />
    <h1 style={loadingText}>
      Loading
      <br />
      Application
    </h1>
  </Wrapper>
);
