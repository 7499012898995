import React from 'react';
import PropTypes from 'prop-types';
import {
  PausedIcon,
  AbortedIcon,
  RunningIconSmall,
  WaitingIcon,
  PendingIcon,
} from '../Icons';
import * as states from './states';

const getIconForRunninRollout = taskStatus => {
  switch (taskStatus) {
    case states.taskStatus.running:
      return <RunningIconSmall />;
    case states.taskStatus.waiting:
      return <WaitingIcon />;
    case states.taskStatus.pending:
      return <PendingIcon />;
    case states.taskStatus.aborted:
    default:
      return <AbortedIcon />;
  }
};

const getIconForArchivedRollout = taskStatus => {
  if (taskStatus === states.taskStatus.aborted) {
    return <AbortedIcon />;
  }
  return null;
};

const RolloutTaskStatusIcon = ({ taskStatus, rolloutStatus }) => {
  switch (rolloutStatus) {
    case states.rolloutStatus.stopped:
      return <PausedIcon />;
    case states.rolloutStatus.aborted:
      return <AbortedIcon />;
    case states.rolloutStatus.archived:
      return getIconForArchivedRollout(taskStatus);
    default:
      return getIconForRunninRollout(taskStatus);
  }
};

RolloutTaskStatusIcon.propTypes = {
  taskStatus: PropTypes.string.isRequired,
  rolloutStatus: PropTypes.string.isRequired,
};

export default RolloutTaskStatusIcon;
