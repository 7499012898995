import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Page from '../Page';
import { H2, H3 } from '../Dashboard/Typography';
import DeviceTable from './DeviceTable';
import DeviceFilter from './DeviceFilter';

const gridStyle = {
  width: '100%',
  paddingTop: 31,
};

const h2Style = {
  paddingBottom: 16,
};

const h3Style = {
  paddingBottom: 8,
};

export default () => (
  <Page>
    <Grid style={gridStyle} fluid>
      <Row>
        <Col xs={12}>
          <H2 style={h2Style}>Devices</H2>
        </Col>
      </Row>
      <Row style={{ paddingBottom: 8 }}>
        <Col xs={12}>
          <H3 style={h3Style}>Devices list</H3>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <DeviceTable />
        </Col>
        <Col xs={4}>
          <DeviceFilter />
        </Col>
      </Row>
    </Grid>
  </Page>
);
