import * as types from './types';
import { PAGE_STATUS } from './models';

export const loading = () => ({
  type: types.PAGE_SET_STATUS,
  payload: PAGE_STATUS.LOADING,
});

export const loaded = () => ({
  type: types.PAGE_SET_STATUS,
  payload: PAGE_STATUS.LOADED,
});

export const notFound = () => ({
  type: types.PAGE_SET_STATUS,
  payload: PAGE_STATUS.NOT_FOUND,
});

export const error = () => ({
  type: types.PAGE_SET_STATUS,
  payload: PAGE_STATUS.ERROR,
});
