import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './actions';
import { OutlineButton } from '../../Buttons';

const CloseButton = ({ closeModal }) => (
  <OutlineButton label="Close" onClick={closeModal} />
);

CloseButton.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(actions.closeModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(CloseButton);
