import React from 'react';
import Wrapper, { HalfWrapper } from '../../Page/Wrappers';
import LoadingIcon from '../../Page/LoadingIcon';

const longTextStyle = {
  paddingTop: 21,
  textAlign: 'center',
};

const Redirect = () => (
  <Wrapper>
    <HalfWrapper />
    <LoadingIcon />
    <div style={longTextStyle}>
      <h3>Your authentication has been expired, redirecting to login...</h3>
    </div>
  </Wrapper>
);

export default Redirect;
