import React from 'react';
import PropTypes from 'prop-types';
import Section from './Section';
import SectionHeader from './SectionHeader';
import userIcon from '../Icons/svg/user-namespace-icon.svg';
import organizationIcon from '../Icons/svg/organization-namespace-icon.svg';

const BillingHeader = ({ isOrganization }) => {
  const iconOrganization = (
    <img src={organizationIcon} alt="Organization icon" />
  );
  const iconMyProduct = <img src={userIcon} alt="Usage icon" />;
  const text = isOrganization ? 'My organizations' : 'My usage';
  const Icon = isOrganization ? iconOrganization : iconMyProduct;
  return (
    <Section>
      <SectionHeader title={text} icon={Icon} />
    </Section>
  );
};

BillingHeader.propTypes = {
  isOrganization: PropTypes.bool.isRequired,
};

export default BillingHeader;
