import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getActiveRollouts, getContentStatus } from './reducer';
import RolloutWidget from './RolloutWidget';
import ContentEmpty from './ContentEmpty';
import ContentError from './ContentError';

const Content = ({ rollouts, failed }) => {
  if (failed) {
    return <ContentError />;
  }
  if (!rollouts.length) {
    return <ContentEmpty />;
  }
  return (
    <div>
      {rollouts.map(rollout => (
        <RolloutWidget key={rollout.uid} rollout={rollout} />
      ))}
    </div>
  );
};

Content.propTypes = {
  rollouts: PropTypes.arrayOf(PropTypes.object).isRequired,
  failed: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  rollouts: getActiveRollouts(state),
  failed: getContentStatus(state),
});

export default connect(mapStateToProps)(Content);
