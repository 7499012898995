import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Grid } from 'react-flexbox-grid';
import { transparentGrey200 } from '../Theme/colors';

const style = {
  header: {
    background: transparentGrey200,
    height: 112,
    marginBottom: 55,
    paddingTop: 24,
    width: '100%',
  },
};

const BaseHeader = ({ children, classes }) => (
  <div className={classes.header}>
    <Grid>{children}</Grid>
  </div>
);

BaseHeader.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(BaseHeader);
