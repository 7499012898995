import validator from 'validator';

export const EMAIL_ERROR = 'You must provide a valid email';
export const REQUIRED_ERROR = 'This field is required';

export function required(value) {
  return value ? undefined : REQUIRED_ERROR;
}

export function isEmail(value) {
  return validator.isEmail(value) ? undefined : EMAIL_ERROR;
}
