import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'redux-form-material-ui';
import { reduxForm, Field, reset } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { getModalIsOpen } from './reducer';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalLabel,
  ModalFormGroup,
} from '../../Modal';
import { LinkButton, OutlineButton } from '../../Buttons';
import { required } from '../../Form/validators';
import { CheckIcon } from '../../Icons';
import { getAuthToken } from '../../Auth/reducer';
import * as actions from './actions';
import onSubmit from './submitHandler';

export const formKey = 'create-organization';

const inputStyle = {
  fontSize: '14px !important',
};

const CreateOrganizationModal = ({
  isOpen,
  closeModal,
  handleSubmit,
  submitting,
  invalid,
  pristine,
}) => (
  <Modal isVisible={isOpen}>
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalFormGroup>
          <ModalLabel htmlFor="name" label="Organization name" />
          <Field
            id="name"
            name="name"
            type="text"
            validate={[required]}
            component={TextField}
            disabled={submitting}
            style={inputStyle}
            fullWidth
          />
        </ModalFormGroup>
      </ModalContent>
      <ModalFooter>
        <LinkButton
          type="button"
          label="cancel"
          onClick={closeModal}
          disabled={submitting}
        />
        <OutlineButton
          label="Save"
          icon={<CheckIcon />}
          disabled={pristine || submitting || invalid}
          type="submit"
        />
      </ModalFooter>
    </form>
  </Modal>
);

CreateOrganizationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isOpen: getModalIsOpen(state),
  authToken: getAuthToken(state),
});

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(actions.closeModal());
    dispatch(reset(formKey));
  },
});

const formProps = {
  form: formKey,
  onSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm(formProps)(CreateOrganizationModal));
