import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';
import injectSheet from 'react-jss';
import CardComponent from '../Theme/Card';
import ContentError from './ActiveRolloutsWidget/ContentError';
import CardHeader from './CardHeader';

export const CARD_STATUS = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  LOAD_ERROR: 'LOAD_ERROR',
};

const style = {
  loading: {
    display: 'flex',
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    paddingTop: 17,
  },
};

export const CardError = ContentError;
export const CardLoading = injectSheet(style)(({ classes }) => (
  <CardComponent className={classes.loading}>
    <CircularProgress size={60} thickness={5} />
  </CardComponent>
));

class Card extends React.Component {
  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  render() {
    const { status, to, title, header, children, classes } = this.props;
    let content = null;

    switch (status) {
      case CARD_STATUS.LOADING:
        content = <CardLoading />;
        break;
      case CARD_STATUS.LOADED:
        content = <CardComponent>{children}</CardComponent>;
        break;
      default:
        content = <CardError />;
    }

    return (
      <div className={classes.card}>
        <CardHeader title={title} to={to}>
          {header}
        </CardHeader>
        {content}
      </div>
    );
  }
}

Card.defaultProps = {
  header: null,
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  onLoad: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(Card);
