import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '../SelectField';

class SingleSelectField extends React.Component {
  constructor() {
    super();
    this.adaptProps = this.adaptProps.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(values) {
    const { onChange } = this.props;
    if (values.length) {
      onChange(values[0]);
    } else {
      onChange(null);
    }
  }

  adaptProps() {
    const { value: valueProps } = this.props;
    const newProps = { ...this.props };
    newProps.values = [];
    if (valueProps) {
      newProps.values = [valueProps];
    }
    delete newProps.value;

    if (newProps.values.length === 1) {
      newProps.disableInput = true;
    }

    newProps.onChange = this.onChange;
    return newProps;
  }

  render() {
    const props = this.adaptProps();

    return <SelectField {...props} />;
  }
}

SingleSelectField.defaultProps = {
  value: null,
};

SingleSelectField.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default SingleSelectField;
