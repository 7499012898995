import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Download } from '../Icons';
import * as actions from './actions';

const styles = {
  link: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    color: '#5CA7DF',
    fill: '#5CA7DF',
    '&:hover': {
      color: '#36a6f9',
      fill: '#36a6f9',
    },
    '&:disabled': {
      color: 'rgba(184, 186, 191, 0.5)',
      fill: 'rgba(184, 186, 191, 0.5)',
    },
  },
  textLink: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    marginRight: 8,
  },
};

const ViewReportButton = ({ classes, onClick, disabled }) => (
  <button className={classes.link} onClick={onClick} disabled={disabled}>
    <span className={classes.textLink}>VIEW REPORT</span>
    <Download />
  </button>
);

ViewReportButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default connect(
  null,
  (dispatch, { uid }) => ({
    onClick() {
      dispatch(actions.downloadOrderReport(uid));
    },
  })
)(injectSheet(styles)(ViewReportButton));
