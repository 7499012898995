import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from './actions';
import Table, { makeStateToProps, ActionsCell } from '../Table';
import { getInvitesTable } from './reducer';
import OpenCancelInviteModalButton from './OpenCancelInviteModalButton';

const renderCell = row => (
  <ActionsCell>
    <OpenCancelInviteModalButton invite={row.original} />
  </ActionsCell>
);

export const columns = [
  {
    Header: 'Email',
    id: 'email',
    accessor: 'email',
  },
  {
    Header: 'Role',
    id: 'role',
    accessor: 'role',
  },
  {
    Header: null,
    id: 'uid',
    accessor: 'uid',
    Cell: renderCell,
  },
];

const mapStateToProps = makeStateToProps(getInvitesTable, columns, {
  pageSize: 5,
  paginationIfNecessary: true,
});

const mapDispatchToProps = dispatch => ({
  onChange(payload) {
    dispatch(actions.requestInvites(payload));
  },
  getTrProps() {
    return { className: 'no-cursor' };
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Table)
);
