import { SubmissionError } from 'redux-form/immutable';
import { updateOrganizationMember } from '../updatehub/api';
import { makeRequest } from '../updatehub/http';
import { openNotification } from '../Notification';
import { refreshMembersTable } from './actions';

export const UNKNOWN_ERROR = 'Something went wrong. Try again later.';

export const UPDATE_MEMBER_SUCCESS = 'Member updated successfully';

const submitUpdateMemberForm = (
  formData,
  dispatch,
  { closeModal, reset, authToken, organizationUID, memberUID }
) => {
  const role = formData.get('role');
  const request = updateOrganizationMember(organizationUID, memberUID, role);

  return makeRequest(request, authToken).then(({ result, error }) => {
    if (result) {
      reset();
      closeModal();
      dispatch(openNotification(UPDATE_MEMBER_SUCCESS));
      dispatch(refreshMembersTable());
    } else if (error) {
      throw new SubmissionError(error);
    } else {
      dispatch(openNotification(UNKNOWN_ERROR));
      dispatch(refreshMembersTable());
    }
  });
};

export default submitUpdateMemberForm;
