import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const style = {
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const ModalFooter = ({ children, classes }) => (
  <div className={classes.modalFooter}>{children}</div>
);

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ModalFooter);
