import { call, put, takeEvery, fork } from 'redux-saga/effects';
import request from '../updatehub/effects';
import {
  fetchDeviceVersionsGraphData,
  fetchRecentPackages,
} from '../updatehub/api';
import { getContext } from '../Dashboard/sagas';
import * as actions from './actions';
import * as types from './types';
import activeRolloutsSaga from './ActiveRolloutsWidget/sagas';
import buildRefresher from '../updatehub/refresher';

export function* getDevicesDataSaga() {
  const { namespaceUID, productUID } = yield call(getContext);
  const { result } = yield request(
    fetchDeviceVersionsGraphData(namespaceUID, productUID)
  );

  if (!result) {
    yield put(actions.requestDevicesError());
    return;
  }

  yield put(actions.setDevices(result));
}

export function* getPackagesSaga() {
  const { namespaceUID, productUID } = yield call(getContext);
  const { result } = yield request(
    fetchRecentPackages(namespaceUID, productUID)
  );

  if (!result) {
    yield put(actions.requestPackagesError());
    return;
  }

  yield put(actions.setPackages(result));
}

function* watchForLoadRequests() {
  yield fork(activeRolloutsSaga);
  yield takeEvery(types.REQUEST_PACKAGES, getPackagesSaga);
  yield takeEvery(types.REQUEST_DEVICES, getDevicesDataSaga);
  yield buildRefresher(
    types.REQUEST_PACKAGES,
    getPackagesSaga,
    'overview-packages',
    5
  );
  yield buildRefresher(
    types.REQUEST_DEVICES,
    getDevicesDataSaga,
    'overview-devices',
    15
  );
}

export default watchForLoadRequests;
