import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkButton } from '../Buttons';
import { closeDeleteOrganizationModal } from './actions';

const CloseDeleteOrganizationModalButton = ({ onClick }) => (
  <LinkButton onClick={onClick} label="cancel" />
);

CloseDeleteOrganizationModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onClick() {
    dispatch(closeDeleteOrganizationModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(CloseDeleteOrganizationModalButton);
