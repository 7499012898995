import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { isAborted, percent } from './utils';
import { grey900, red } from '../../Theme/colors';

const style = {
  faultStyle: {
    fontSize: 14,
    lineHeight: '19px',
    textAlign: 'center',
    color: grey900,
  },
  faultStyleRed: {
    extend: 'faultStyle',
    color: red,
  },
};

const findAbortedTask = tasks => {
  const abortedTasks = tasks.filter(task => {
    if (task.status === 'aborted') {
      return true;
    }
    return false;
  });
  return abortedTasks[0] || null;
};

const TaskAborted = ({ status, tasks, classes }) => {
  const abortedTask = findAbortedTask(tasks);
  if (!isAborted(status) || !abortedTask) {
    return null;
  }
  const failed = percent(abortedTask.failedDevices, abortedTask.totalDevices);
  return (
    <div>
      <span className={classes.faultStyleRed}>Fault summary: </span>
      <span className={classes.faultStyle}>
        {abortedTask.name} has reached {failed}% failure, which aborted this
        rollout.
      </span>
    </div>
  );
};

TaskAborted.propTypes = {
  status: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(TaskAborted);
