import { push } from 'react-router-redux';
import {
  take,
  cancel,
  fork,
  call,
  put,
  takeEvery,
  select,
  delay,
} from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import { Map } from 'immutable';
import request from '../updatehub/effects';
import * as actions from './actions';
import * as types from './types';
import urls from '../updatehub/urls';
import { getNamespaceUID, getProductUID } from '../Dashboard/reducer';
import {
  createRollout,
  fetchDevices,
  fetchScopedDeviceVersionsGraphData,
} from '../updatehub/api';
import {
  getAdvancedMode,
  getVersion,
  getStatus,
  getForceCreation,
  getScope,
  getFilters,
  getTask,
  getTasks,
} from './reducer';
import { processAttributes, processFields } from '../DevicesPage/sagas';
import { rolloutConflictTaskParser } from '../updatehub/utils';

export function* buildScope() {
  const scope = {};

  scope.product_uid = yield select(getProductUID);
  scope.version = yield select(getVersion);

  yield put(actions.setScope(scope));
}

export function* processVersion() {
  const version = yield select(getVersion);
  if (!version) {
    yield put(actions.resetTasks());

    return;
  }

  yield call(buildScope);
  yield put(actions.addTask());
}

export function* processTask(taskIndex) {
  const task = yield select(getTask, taskIndex);
  const productUID = yield select(getProductUID);
  const newFilter = {};

  // FIXME, need to move this method(processAttributes, processFields) to generic file
  const identityFilter = yield call(
    processAttributes,
    task.rawFilter.identities
  );
  if (identityFilter) {
    newFilter.identities = identityFilter;
  }

  const hardwareFilter = yield call(processFields, task.rawFilter.hardware);
  if (hardwareFilter) {
    newFilter.hardware = hardwareFilter;
  }

  const tagFilter = yield call(processFields, task.rawFilter.tags);
  if (tagFilter) {
    newFilter.tags = tagFilter;
  }

  const versionFilter = yield call(processFields, task.rawFilter.versions);
  if (versionFilter) {
    newFilter.products = {};
    newFilter.products[productUID] = versionFilter;
  }

  const attributesFilter = yield call(
    processAttributes,
    task.rawFilter.attributes
  );
  if (attributesFilter) {
    newFilter.attrs = attributesFilter;
  }

  if (!isEqual(newFilter, task.filter)) {
    yield put(actions.updateTaskFilter(taskIndex, newFilter));
  }
}

export function* processTasks() {
  const tasks = yield select(getTasks);

  for (let i = 0; i < tasks.size; i += 1) {
    yield call(processTask, i);
  }
}

export function* computeTotal() {
  let total = 0;
  const tasks = yield select(getTasks);

  for (let i = 0; i < tasks.size; i += 1) {
    total += tasks.getIn([i, 'devicesCount']);
  }

  yield put(actions.setDevicesCount(total));
}

export function* buildBody() {
  const productUID = yield select(getProductUID);
  const version = yield select(getVersion);
  const status = yield select(getStatus);
  const forceCreation = yield select(getForceCreation);
  const tasks = yield select(getTasks);

  return {
    product_uid: productUID,
    version,
    status,
    force_creation: forceCreation,
    tasks: tasks
      .map(task => ({
        name: task.get('name'),
        automatic_finish: task.get('automaticFinish'),
        required_success_rate: task.get('requiredSuccessRate'),
        fault_tolerance: task.get('faultTolerance'),
        filter: task.get('filter'),
      }))
      .toJS(),
  };
}

export function* parseConflictError(error) {
  try {
    if (!error.errors || !error.errors.tasks) {
      return [];
    }

    const tasks = yield select(getTasks);
    return rolloutConflictTaskParser(error.errors.tasks, tasks);
  } catch (e) {
    return [];
  }
}

const capitalizeKey = str =>
  `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`;

function parseTaskError(tasksErrors) {
  const errors = [];
  tasksErrors.forEach((taskError, taskIndex) => {
    const taskErrors = Object.keys(taskError).map(key => taskError[key]);
    if (taskErrors.length > 0) {
      errors.push({
        name: `Task ${taskIndex + 1}`,
        errors: taskErrors,
      });
    }
  });

  return errors;
}

export function parseError(error) {
  const parsed = [];
  Object.keys(error).forEach(key => {
    if (key === 'tasks' && Array.isArray(error[key])) {
      parsed.push(...parseTaskError(error[key]));
      return;
    }

    parsed.push({ name: capitalizeKey(key), errors: [error[key]] });
  });

  return parsed;
}

export function* submit() {
  const namespaceUID = yield select(getNamespaceUID);
  const body = yield call(buildBody);

  const { result, error } = yield request(createRollout(namespaceUID, body));
  if (result) {
    const productUID = yield select(getProductUID);

    yield put(
      push(
        urls('product:rollouts', {
          namespaceUID,
          productUID,
        })
      )
    );
    yield put(actions.submitSuccess());
  } else if (error && error.isRecoverable) {
    // conflict
    const parsedConflict = yield call(parseConflictError, error);

    if (parsedConflict && parsedConflict.length) {
      yield put(actions.setConflictError(parsedConflict));
    } else {
      yield put(actions.setError('Error when parsing, please try again later'));
    }
  } else if (error) {
    const parsed = yield call(parseError, error.errors);
    if (parsed && parsed.length > 0) {
      yield put(actions.setCreateErrorModal(parsed));
    } else {
      yield put(actions.setError('Error when parsing, please try again later'));
    }
  } else {
    // 500, or unknown/not parsed error
    yield put(
      actions.setError('Probably internal error, please try again later')
    );
  }
}

export function* requestTaskDevices({ index }) {
  const task = yield select(getTask, index);
  const namespaceUID = yield select(getNamespaceUID);
  const parameters = {
    page: 1,
    body: {},
  };
  parameters.body.scope = yield select(getScope);
  parameters.body.filters = task.filters;
  parameters.body.filter = task.filter;

  // NOTE, this delay exists to control update cascade to avoid request overload
  yield delay(250);

  // FIXME, review second argument
  const { result } = yield request(
    fetchDevices(namespaceUID, null, parameters)
  );

  // FIXME, else case not covered
  if (result) {
    const { totalCount } = result;

    yield put(actions.updateTask(index, 'devicesCount', totalCount));
    yield call(computeTotal);
  } else {
    /* eslint-disable no-console */
    console.error('request task devices error');
  }
}

export function* buildFilters() {
  const filters = [];
  const tasks = yield select(getTasks);

  for (let i = 0; i < tasks.size; i += 1) {
    if (!isEqual(filters, tasks.getIn([i, 'filters']))) {
      yield put(actions.updateTaskFilters(i, [...filters]));
    }

    filters.push(tasks.getIn([i, 'filter']));
  }

  yield put(actions.setFilters(filters));
}

export function* requestRemainingDevices() {
  const namespaceUID = yield select(getNamespaceUID);
  const parameters = {
    page: 1,
    body: {},
  };
  parameters.body.scope = yield select(getScope);
  parameters.body.filters = yield select(getFilters);
  parameters.body.filter = {};
  // FIXME, review second argument
  const { result } = yield request(
    fetchDevices(namespaceUID, null, parameters)
  );

  // FIXME, else case not covered
  if (result) {
    const { totalCount } = result;

    yield put(actions.setRemainingDevices(totalCount));
  } else {
    /* eslint-disable no-console */
    console.error('request task devices error');
  }
}

export function* removeTask({ index }) {
  yield put(actions.changeSelectedTask(0));
  yield put(actions.removeTask(index));
  yield call(computeTotal);
}

export function* markSelected() {
  const tasks = yield select(getTasks);
  yield put(actions.changeSelectedTask(tasks.size - 1));
}

export function* requestPool(pattern, saga) {
  let requests = Map({});

  /* eslint-disable no-constant-condition */
  while (true) {
    const action = yield take(pattern);
    const { index } = action;
    const latestTask = requests.get(index);

    if (latestTask) {
      yield cancel(latestTask);
    }

    const task = yield fork(saga, action);
    requests = requests.set(index, task);
  }
}

export function* buildAdvancedGraphData() {
  const tasks = yield select(getTasks);
  const graphData = [];

  tasks.forEach(task => {
    graphData.push({
      name: task.name,
      value: task.devicesCount,
    });
  });

  if (graphData.length > 3) {
    const othersCount = graphData
      .splice(3)
      .reduce((sum, { value }) => sum + value, 0);
    graphData.push({
      name: 'Others',
      value: othersCount,
    });
  }

  return graphData;
}

export function* updateGraphData() {
  const isAdvancedMode = yield select(getAdvancedMode);

  if (isAdvancedMode) {
    const result = yield call(buildAdvancedGraphData);
    yield put(actions.updateGraphTitle('Tasks'));
    yield put(actions.updateGraphData(result));
  } else {
    const namespaceUID = yield select(getNamespaceUID);
    const scope = yield select(getScope);
    const { result } = yield request(
      fetchScopedDeviceVersionsGraphData(namespaceUID, scope)
    );

    yield put(actions.updateGraphData(result));
  }
}

function* rolloutCreateSaga() {
  yield takeEvery(types.SET_VERSION, processVersion);
  yield takeEvery(types.ADD_TASK, markSelected);
  yield takeEvery(
    [types.ADD_TASK, types.UPDATE_TASK, types.REMOVE_TASK],
    processTasks
  );
  yield takeEvery(
    [
      types.ADD_TASK,
      types.UPDATE_TASK,
      types.REMOVE_TASK,
      types.TOGGLE_ADVANCED_MODE,
    ],
    updateGraphData
  );
  yield takeEvery([types.UPDATE_TASK_FILTER, types.REMOVE_TASK], buildFilters);
  yield fork(
    requestPool,
    [types.UPDATE_TASK_FILTER, types.UPDATE_TASK_FILTERS],
    requestTaskDevices
  );
  yield takeEvery(types.REQUEST_REMOVE_TASK, removeTask);
  yield takeEvery(types.SET_FILTERS, requestRemainingDevices);
  yield takeEvery([types.SUBMIT, types.SUBMIT_START], submit);
}

export default rolloutCreateSaga;
