import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { fromNow } from '../updatehub/humanizer';
import ButtonSubscribe from './ButtonSubscribe';
import ButtonDetails from './ButtonDetails';

const styles = {
  box: {
    display: 'flex',
    height: 110,
    justifyContent: 'center',
  },
  textName: {
    width: 120,
    color: '#3E3E40',
    fontFamily: 'Fira Sans',
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'left',
    marginRight: 86,
    alignSelf: 'center',
  },
  bar: {
    boxSizing: 'border-box',
    height: 45,
    border: '1px solid rgba(216,216,216,0.3)',
    marginRight: 18.5,
    alignSelf: 'center',
  },
  closeCycle: {
    width: 147,
    marginRight: 16.5,
    alignSelf: 'center',
  },
  closeText: {
    display: 'block',
    fontSize: 12,
    color: '#3E3E40',
  },
  total: {
    width: 100,
    marginRight: 50,
    alignSelf: 'center',
  },
  totalValue: {
    display: 'block',
    color: '#3E3E40',
    fontFamily: 'Fira Sans',
    fontSize: 20,
    fontWeight: 600,
  },
  totalText: {
    color: '#3E3E40',
    fontFamily: 'Fira Sans',
    fontSize: 12,
  },
};

const BillingValue = ({
  classes,
  namespaceName,
  price,
  closeIn,
  subscribed,
  uid,
}) => {
  const priceText = subscribed ? price : 'FREE';
  return (
    <div className={classes.box}>
      <span className={classes.textName}>{namespaceName}</span>
      <div className={classes.bar} />
      <div className={classes.closeCycle}>
        <span className={classes.closeText}>Billing cycle</span>
        <span className={classes.closeText}>closes {fromNow(closeIn)}</span>
      </div>
      <div className={classes.bar} />
      <div className={classes.total}>
        <span className={classes.totalText}>You owe</span>
        <span className={classes.totalValue}>{priceText}</span>
      </div>
      {subscribed ? <ButtonDetails uid={uid} /> : <ButtonSubscribe uid={uid} />}
    </div>
  );
};

BillingValue.propTypes = {
  classes: PropTypes.object.isRequired,
  namespaceName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  closeIn: PropTypes.string.isRequired,
  subscribed: PropTypes.bool.isRequired,
  uid: PropTypes.string.isRequired,
};

export default injectSheet(styles)(BillingValue);
