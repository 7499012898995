import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchDeviceVersions } from '../../updatehub/api/device';
import { makeRequest } from '../../updatehub/http';
import FilterGroup from './FilterGroup';
import SelectField from '../../SelectField';
import * as actions from '../actions';
import { getRawFilter } from '../reducer';
import { getProductUID, getNamespaceUID } from '../../Dashboard/reducer';
import { getAuthToken } from '../../Auth/reducer';

/* FIXME, make test for this, and review request mode */
/* eslint-disable jsx-a11y/label-has-for */
const VersionFilter = ({ values, fetchData, rawFilterUpdate }) => (
  <FilterGroup>
    <label htmlFor="version-filter" style={{ fontSize: 14 }}>
      Version
      <SelectField
        id="version-filter"
        inputPlaceholder="Choose version"
        onChange={rawFilterUpdate('versions')}
        fetchData={fetchData}
        values={values}
      />
    </label>
  </FilterGroup>
);

VersionFilter.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchData: PropTypes.func.isRequired,
  rawFilterUpdate: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  values: getRawFilter(state).get('versions'),
  fetchData(q, page) {
    const authToken = getAuthToken(state);
    const namespaceUID = getNamespaceUID(state);
    const productUID = getProductUID(state);
    const status = getRawFilter(state).get('status');
    const requestConfig = fetchDeviceVersions(
      namespaceUID,
      productUID,
      q,
      page,
      { status }
    );

    return makeRequest(requestConfig, authToken);
  },
});

export const mapDispatchToProps = dispatch => ({
  rawFilterUpdate(key) {
    return payload => {
      dispatch(actions.updateRawFilter(key, payload));
    };
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionFilter);
