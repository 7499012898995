import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import MenuItem from 'material-ui/MenuItem';
import { blue, blue900 } from '../Theme/colors';
import { weight } from '../Theme/Typography';

const itemStyle = {
  alignItems: 'center',
  color: 'inherit',
  display: 'flex',
  height: 56,
};

const linkStyle = {
  textDecoration: 'none',
};

const baseMenuItemStyle = {
  borderLeft: '4px solid transparent',
  color: blue,
  fontSize: 12,
  fontWeight: weight.semiBold,
  letterSpacing: 1,
  lineHeight: '56px',
  minHeight: 56,
  textTransform: 'uppercase',
};

const activeMenuItemStyle = baseColor => ({
  ...baseMenuItemStyle,
  backgroundColor: 'rgba(14,40,61,0.04)',
  borderLeft: `4px solid ${baseColor}`,
  color: blue900,
});

const Item = ({ title, icon, iconStyle, link, location }) => {
  const isLinkActive = location.pathname === link;

  const baseColor = blue900;
  const iconColor = isLinkActive ? baseColor : blue;
  const menuItemStyle = isLinkActive
    ? activeMenuItemStyle(baseColor)
    : baseMenuItemStyle;
  const Icon = icon;

  return (
    <Link to={link} style={linkStyle}>
      <MenuItem style={menuItemStyle}>
        <div style={itemStyle}>
          <Icon style={iconStyle} color={iconColor} />
          {title}
        </div>
      </MenuItem>
    </Link>
  );
};

Item.contextTypes = {
  productUID: PropTypes.string.isRequired,
};

Item.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.func.isRequired,
  iconStyle: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(Item);
