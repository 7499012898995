import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const style = {
  entry: {
    width: 180,
  },
};

const FixedHeaderEntry = ({ children, classes }) => (
  <div className={classes.entry}>{children}</div>
);

FixedHeaderEntry.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(FixedHeaderEntry);
