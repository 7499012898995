import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openNotification } from '../Notification';
import { makeRequest } from '../updatehub/http';
import { getNamespaceUID, getProductUID } from '../Dashboard/reducer';
import { getAuthToken } from '../Auth/reducer';
import { getTags, getDeviceUID } from './reducer';
import SelectField from '../SelectField';
import TagNewAssign from './TagNewAssign';
import { assignTagToDevice, fetchDeviceTags } from '../updatehub/api';
import { requestDevice } from './actions';

const TagSelector = ({ fetchData, selectedTagChange, selectedTag }) => (
  <SelectField
    id="tag-filter"
    inputPlaceholder="Choose Tag"
    fetchData={fetchData}
    onChange={selectedTagChange}
    values={selectedTag}
    createComponent={TagNewAssign}
    inputModifier={rawValue => rawValue.toLowerCase()}
  />
);

TagSelector.defaultProps = {
  selectedTag: [],
};

TagSelector.propTypes = {
  fetchData: PropTypes.func.isRequired,
  selectedTagChange: PropTypes.func.isRequired,
  selectedTag: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
  selectedTag: getTags(state),
  namespaceUID: getNamespaceUID(state),
  deviceUID: getDeviceUID(state),
  authToken: getAuthToken(state),
  productUID: getProductUID(state),
  fetchData(query, page) {
    const namespaceUID = getNamespaceUID(state);
    const productUID = getProductUID(state);
    const authToken = getAuthToken(state);
    const requestConfig = fetchDeviceTags(
      namespaceUID,
      productUID,
      query,
      page
    );

    return makeRequest(requestConfig, authToken);
  },
});

const mapDispatchToProps = {
  openNotification,
  requestDevice,
};

const mergeProps = (state, actions) => ({
  ...state,
  selectedTagChange(values) {
    const tags = values.map(tag => tag.value);
    const requestAssign = assignTagToDevice(
      state.namespaceUID,
      state.deviceUID,
      state.productUID,
      tags
    );
    return makeRequest(requestAssign, state.authToken).then(
      ({ result, error }) => {
        if (result) {
          actions.requestDevice(
            state.deviceUID,
            state.namespaceUID,
            state.productUID
          );
        } else if (error) {
          actions.openNotification(error);
        }
      }
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TagSelector);
