import * as types from './types';
import Model from './models';

/* eslint-disable function-paren-newline */
const reducer = (state = Model(), action) => {
  switch (action.type) {
    case types.OPEN_START_TASK_MODAL:
      return state.withMutations(oldState =>
        oldState
          .set('taskUID', action.payload.taskUID)
          .set('namespaceUID', action.payload.namespaceUID)
          .set('rolloutUID', action.payload.rolloutUID)
          .set('page', action.payload.page)
          .set('isOpened', true)
      );
    case types.CLOSE_START_TASK_MODAL:
      return state.withMutations(oldState =>
        oldState
          .set('taskUID', '')
          .set('namespaceUID', '')
          .set('rolloutUID', '')
          .set('page', '')
          .set('isOpened', false)
      );
    default:
      return state;
  }
};

export default reducer;

// Selectors
const reducerPath = 'startRolloutTaskModal';

export const getTaskInfo = state => {
  const namespaceUID = state.getIn([reducerPath, 'namespaceUID']);
  const rolloutUID = state.getIn([reducerPath, 'rolloutUID']);
  const taskUID = state.getIn([reducerPath, 'taskUID']);
  const page = state.getIn([reducerPath, 'page']);
  return { taskUID, rolloutUID, namespaceUID, page };
};

export const getModalState = state => state.getIn([reducerPath, 'isOpened']);
