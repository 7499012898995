import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { blue } from '../Theme/colors';
import { weight } from '../Theme/Typography';

const styles = {
  menuItem: {
    width: '100%',
    height: 40,
    cursor: 'pointer',
    color: blue,
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    fontSize: 14,
    fontWeight: weight.regular,
    backgroundColor: '#FCFBFD',
    border: 'none',
    borderBottom: '1px solid #F0F1F4',
    '&:hover': {
      backgroundColor: '#F4F1F7 !important',
    },
    '& > span': {
      marginLeft: 16,
    },
  },
};

const MenuItem = ({ classes, children, value, style, onClick }) => (
  <button
    className={classes.menuItem}
    style={style}
    onClick={event => onClick(event, value)}
    tabIndex="-1"
  >
    <span>{children}</span>
  </button>
);

MenuItem.defaultProps = {
  style: {},
};

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object.isRequired,
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(MenuItem);
