import React from 'react';
import PropTypes from 'prop-types';
import { grey200, blue } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';

const pageButtonStyle = {
  minWidth: 24,
  height: 24,
  marginLeft: 8,
  marginRight: 8,
  borderRadius: 14,
  outline: 'none',
  border: `1px solid ${grey200}`,
  backgroundColor: 'transparent',
  paddingLeft: 5,
  paddingRight: 5,
  color: blue,
  fontSize: 12,
  fontWeight: weight.semiBold,
  cursor: 'pointer',
};

const getPageButtonStyle = disabled => {
  if (disabled) {
    return {
      ...pageButtonStyle,
      color: '#B8BAC0',
      cursor: 'default',
    };
  }

  return pageButtonStyle;
};

const PageButton = ({ page, disabled, onClick }) => (
  <button
    style={getPageButtonStyle(disabled)}
    disabled={disabled}
    onClick={onClick}
  >
    {page + 1}
  </button>
);

PageButton.propTypes = {
  page: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PageButton;
