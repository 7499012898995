import React from 'react';
import PropTypes from 'prop-types';
import tinycolor2 from 'tinycolor2';
import { connect } from 'react-redux';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import EmptyGraph from './EmptyGraph';
import SelectedShape from './SelectedShape';
import ShapeInfoOverlay from './ShapeInfoOverlay';
import LegendContent from './Legend';
import { getDevicesData } from '../reducer';

export const cellColors = [
  '#fe7699',
  '#dc90ff',
  '#85a1da',
  '#82e6ee',
  '#b8babf',
];
const legendStyle = { width: 245, right: 0, paddingRight: 20 };
const wrapperStyle = {
  width: 410,
  height: 203,
  position: 'relative',
  overflow: 'hidden',
  outline: 'none',
};

const getCellStyle = entry => {
  if (entry.details) {
    return { cursor: 'pointer' };
  }

  return null;
};

class DevicesGraph extends React.Component {
  constructor() {
    super();
    this.state = {
      hoverIndex: -1,
      selectedVersion: null,
    };

    this.closeOverlay = this.closeOverlay.bind(this);
    this.getCellColor = this.getCellColor.bind(this);
    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseOutHandler = this.mouseOutHandler.bind(this);
    this.selectedVersionChanged = this.selectedVersionChanged.bind(this);
  }

  getCellColor(index) {
    const { hoverIndex } = this.state;
    const baseColor = tinycolor2(cellColors[index % cellColors.length]);

    if (hoverIndex !== -1 && hoverIndex !== index) {
      return baseColor.setAlpha(0.5).toString();
    }

    return baseColor.toString();
  }

  mouseOverHandler(data, hoverIndex) {
    this.setState({
      hoverIndex,
    });
  }

  mouseOutHandler() {
    const { selectedVersion } = this.state;
    if (selectedVersion) {
      return;
    }

    this.setState({
      hoverIndex: -1,
    });
  }

  closeOverlay(e) {
    e.stopPropagation();

    this.setState({
      hoverIndex: -1,
      selectedVersion: null,
    });
  }

  selectedVersionChanged({ name }, index, e) {
    const { data } = this.props;
    e.preventDefault();
    e.stopPropagation();

    const { details } = data[index];
    if (!details) {
      return;
    }

    const color = cellColors[index % cellColors.length];
    this.setState({
      hoverIndex: index,
      selectedVersion: {
        name,
        color,
        details,
      },
    });
  }

  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  render() {
    const { data } = this.props;
    const { hoverIndex, selectedVersion } = this.state;

    if (!data.length) {
      return <EmptyGraph />;
    }

    return (
      <div
        role="button"
        tabIndex="-1"
        style={wrapperStyle}
        onKeyUp={this.closeOverlay}
        onClick={this.closeOverlay}
      >
        <PieChart width={410} height={203}>
          <Pie
            dataKey="count"
            nameKey="name"
            valueKey="count"
            innerRadius="40%"
            outerRadius="65%"
            data={data}
            onClick={this.selectedVersionChanged}
            activeIndex={hoverIndex}
            activeShape={SelectedShape}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell
                key={entry}
                fill={this.getCellColor(index)}
                stroke={this.getCellColor(index)}
                style={getCellStyle(entry)}
              />
            ))}
          </Pie>
          {data.length && (
            <Legend
              title="Versions"
              align="right"
              layout="vertical"
              verticalAlign="middle"
              content={LegendContent}
              wrapperStyle={legendStyle}
              onClick={this.selectedVersionChanged}
              onMouseOver={this.mouseOverHandler}
              onMouseOut={this.mouseOutHandler}
            />
          )}
        </PieChart>
        {selectedVersion && <ShapeInfoOverlay version={selectedVersion} />}
      </div>
    );
  }
}

DevicesGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.number,
      details: PropTypes.array,
    })
  ).isRequired,
};

export const mapStateToProps = state => ({
  data: getDevicesData(state),
});

export default connect(mapStateToProps)(DevicesGraph);
