import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { grey800 } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';

const styles = {
  label: {
    fontSize: 14,
    color: grey800,

    '&.bold': {
      fontWeight: weight.medium,
    },
  },
};

/* disabled because the form received by children */
/* eslint-disable jsx-a11y/label-has-for */
const FormGroup = ({ classes, children, label, bold, htmlFor }) => (
  <div>
    <label
      htmlFor={htmlFor}
      className={bold ? `${classes.label} bold` : classes.label}
    >
      {label}
      {children}
    </label>
  </div>
);

FormGroup.defaultProps = {
  bold: false,
};

FormGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  htmlFor: PropTypes.string.isRequired,
};

export default injectSheet(styles)(FormGroup);
