import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import OrderTable from './OrderTable';

const styles = {
  label: {
    display: 'block',
    color: '#3E3E40',
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 17,
  },
  content: {
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 12px 0 #E5E6E8',
    marginBottom: 13,
  },
};

const History = ({ classes }) => (
  <div>
    <span className={classes.label}>Billing history</span>
    <div className={classes.content}>
      <OrderTable />
    </div>
  </div>
);

History.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(History);
