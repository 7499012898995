import * as types from './types';

export const openModal = payload => ({
  type: types.OPEN_MODAL,
  payload,
});

export const leaveOrganization = () => ({
  type: types.LEAVE_ORGANIZATION,
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});
