import React from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import SelectField from '../SelectField';

const labelStyle = {
  fontSize: 14,
};

const valueStyle = {
  paddingTop: 10,
};

/* FIXME, make test for this */
class MultipleSelectField extends React.Component {
  constructor() {
    super();

    this.selectedKeysChange = this.selectedKeysChange.bind(this);
    this.selectedValuesChange = this.selectedValuesChange.bind(this);
    this.keys = {};
  }

  selectedKeysChange(selectedKeys) {
    const { keys, values, onValuesChange, onKeysChange } = this.props;
    const diff = difference(selectedKeys, keys);
    const selectedValues = { ...values };
    diff.forEach(({ value }) => {
      selectedValues[value] = [];
    });

    onValuesChange(selectedValues);
    onKeysChange(selectedKeys);

    setTimeout(() => {
      const key = selectedKeys[selectedKeys.length - 1];
      this.selectField.close();
      if (key) {
        this.keys[key.value].focus();
      }
    });
  }

  selectedValuesChange(key) {
    return values => {
      const { values: objectValues, onValuesChange } = this.props;
      const selectedValues = { ...objectValues };
      selectedValues[key] = values;

      onValuesChange(selectedValues);
    };
  }

  validateInput(key) {
    return () => {
      const { keys, values, onKeysChange } = this.props;
      const selectedKeys = [...keys];
      const selected = values[key.value];

      if (!selected || selected.length === 0) {
        const position = selectedKeys.indexOf(key);

        selectedKeys.splice(position, 1);
        onKeysChange(selectedKeys);
      }
    };
  }

  render() {
    /* eslint-disable jsx-a11y/label-has-for */
    const {
      placeholder,
      inputPlaceholder,
      keys,
      fetchKeys,
      values,
      fetchValues,
    } = this.props;
    return (
      <div>
        <SelectField
          ref={c => {
            this.selectField = c;
          }}
          placeholder={placeholder}
          inputPlaceholder={inputPlaceholder}
          values={keys}
          onChange={this.selectedKeysChange}
          fetchData={fetchKeys}
          selectOnly
        />
        {[...keys].reverse().map(key => (
          <div style={valueStyle} key={key.value}>
            <label style={labelStyle} htmlFor={key.value}>
              {key.value}
              <SelectField
                id={key.value}
                ref={c => {
                  this.keys[key.value] = c;
                }}
                inputPlaceholder={`Choose value for ${key.value}`}
                values={values[key.value]}
                onChange={this.selectedValuesChange(key.value)}
                onBlur={this.validateInput(key)}
                fetchData={fetchValues(key.value)}
              />
            </label>
          </div>
        ))}
      </div>
    );
  }
}

MultipleSelectField.defaultProps = {
  placeholder: undefined,
};

MultipleSelectField.propTypes = {
  placeholder: PropTypes.string,
  inputPlaceholder: PropTypes.string.isRequired,
  fetchKeys: PropTypes.func.isRequired,
  fetchValues: PropTypes.func.isRequired,
  keys: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  onKeysChange: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func.isRequired,
};

export default MultipleSelectField;
