import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';
import CardContainer from './CardContainer';
import TaskStatusIcon from './RolloutTaskStatusIcon';
import TaskStatus from './RolloutTaskStatus';
import TaskStatusLabel from './RolloutTaskStatusLabel';

const style = {
  taskHeader: {
    fontSize: 20,
    fontWeight: weight.medium,
    marginBottom: 5,
    '& small': {
      fontSize: 14,
      fontWeight: weight.regular,
    },
  },
  statusIcon: {
    marginLeft: 16,
    marginRight: 10,
  },
};

const RolloutTaskCardHeader = ({ rollout, task, classes }) => (
  <CardContainer>
    <h3 className={classes.taskHeader}>
      {task.name}
      <span className={classes.statusIcon}>
        <TaskStatusIcon
          rolloutStatus={rollout.status}
          taskStatus={task.status}
        />
      </span>
      <TaskStatusLabel task={task} rollout={rollout} />
    </h3>
    <TaskStatus task={task} rollout={rollout} />
  </CardContainer>
);

RolloutTaskCardHeader.propTypes = {
  rollout: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutTaskCardHeader);
