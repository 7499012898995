import * as types from './types';

export const openModal = () => ({
  type: types.OPEN_MODAL,
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});

export const submitSuccess = payload => ({
  type: types.SUBMIT_SUCCESS,
  payload,
});
