import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PAGE_STATUS } from './models';
import { getPageStatus } from './reducer';
import * as page from './actions';
import PageLoading from './Loading';
import PageNotFound from './NotFound';
import PageError from './Error';
import urls from '../updatehub/urls';

export class PageComponent extends React.Component {
  componentWillMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  componentWillUnmount() {
    const { onExit } = this.props;
    onExit();
  }

  render() {
    const { status, goBackURL, children } = this.props;
    const backUrl = goBackURL || urls('main:page');

    switch (status) {
      case PAGE_STATUS.LOADING:
        return <PageLoading />;
      case PAGE_STATUS.LOADED:
        return children;
      case PAGE_STATUS.NOT_FOUND:
        return <PageNotFound goBackURL={backUrl} />;
      default:
        return <PageError />;
    }
  }
}

export const pagePropTypes = {
  status: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
};

PageComponent.defaultProps = {
  onExit: () => null,
  goBackURL: null,
};

PageComponent.propTypes = {
  ...pagePropTypes,
  onExit: PropTypes.func,
  children: PropTypes.node.isRequired,
  goBackURL: PropTypes.string,
};

export const mapStateToProps = state => ({
  status: getPageStatus(state),
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad() {
    if (!ownProps.onLoad) {
      return dispatch(page.loaded());
    }

    dispatch(page.loading());
    return ownProps.onLoad();
  },
  onExit: ownProps.onExit || undefined,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
