import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';

const styles = {
  breadcrumbItem: {
    color: '#DBDCDF',
    fontSize: 16,
    textDecoration: 'none',
    userSelect: 'none',
    '&:hover': {
      color: '#B7D5EA',
    },
    '&.active': {
      color: '#5CA7DF',
      fontWeight: 600,
    },
  },
};

const BreadcrumbItem = ({ classes, name, link, active }) => {
  if (active) {
    return (
      <span className={`${classes.breadcrumbItem} active`}>{name}</span>
    );
  }

  return (
    <Link to={link} className={classes.breadcrumbItem}>
      {name}
    </Link>
  );
};

BreadcrumbItem.defaultProps = {
  active: false,
};

BreadcrumbItem.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default injectSheet(styles)(BreadcrumbItem);
