import { call, put, takeEvery } from 'redux-saga/effects';
import request from '../updatehub/effects';
import * as api from '../updatehub/api';
import * as app from '../App/actions';
import * as page from '../Page/actions';
import * as actions from './actions';
import * as types from './types';

export const NAMESPACE_ERROR = 'namespace fail';
export const PRODUCTS_ERROR = 'products fail';

export function* getNamespaces() {
  const { result } = yield request(api.fetchNamespaces());
  return result || null;
}

export function* fetchProducts(nm, requestedPage = 1) {
  const { result } = yield request(api.fetchProducts(nm.uid, requestedPage));

  if (!result) {
    return null;
  }

  if (requestedPage < result.pages) {
    const recursiveResult = yield call(fetchProducts, nm, requestedPage + 1);
    result.products = result.products.concat(recursiveResult || []);
  }

  return result.products;
}

export function* getProducts(namespaces) {
  const products = [];
  /* eslint-disable no-restricted-syntax */
  for (const nm of namespaces) {
    const result = yield call(fetchProducts, nm);
    products.push({
      namespace: nm,
      products: result || [],
    });
  }

  return products;
}

export function* loadPage() {
  const namespaces = yield call(getNamespaces);
  if (!namespaces) {
    yield put(app.loadError(NAMESPACE_ERROR));
    return;
  }
  yield put(actions.gotNamespaces(namespaces));

  const products = yield call(getProducts, namespaces);
  if (!products) {
    yield put(app.loadError(PRODUCTS_ERROR));
    return;
  }
  yield put(actions.gotProducts(products));
  yield put(page.loaded());
}

export default function* rootSaga() {
  yield takeEvery(types.PRODUCT_LIST_PAGE_REQUESTED, loadPage);
}
