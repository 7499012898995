import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import request from '../updatehub/effects';
import url from '../updatehub/urls';
import * as api from '../updatehub/api';
import * as page from '../Page/actions';
import { openNotification } from '../Notification';
import * as actions from './actions';
import * as types from './types';

export const DELETE_SUCCESS = 'Package deleted successfully!';
export const DELETE_ERROR = 'Package could not be deleted. Try again later.';

export function* getPackage(action) {
  const { packageUID, namespaceUID } = action.payload;
  const { result } = yield request(api.fetchPackage(packageUID, namespaceUID));
  if (!result) {
    yield put(page.notFound());
  } else {
    yield put(actions.gotPackage(result));
    yield put(page.loaded());
  }
}

export function* deletePackage(action) {
  const { packageUID, namespaceUID, productUID } = action.payload;
  const { result } = yield request(api.deletePackage(packageUID, namespaceUID));
  if (result) {
    yield put(openNotification(DELETE_SUCCESS));
    yield put(push(url('product:packages', { namespaceUID, productUID })));
  } else {
    yield put(openNotification(DELETE_ERROR));
  }
  yield put(actions.closeModal());
}

export default function* rootSaga() {
  yield takeEvery(types.REQUEST_PAGE, getPackage);
  yield takeEvery(types.REQUEST_DELETE_PACKAGE, deletePackage);
}
