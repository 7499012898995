import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchDeviceAttributeKeys,
  fetchDeviceAttributeValues,
} from '../../updatehub/api/device';
import { makeRequest } from '../../updatehub/http';
import FilterGroup from './FilterGroup';
import MultipleSelectField from '../../MultipleSelectField';
import * as actions from '../actions';
import { getRawFilter } from '../reducer';
import { getNamespaceUID, getProductUID } from '../../Dashboard/reducer';
import { getAuthToken } from '../../Auth/reducer';

/* FIXME, make test for this, and review request mode */
const AttributesFilter = props => {
  const {
    keys,
    values,
    fetchKeys,
    fetchValues,
    onKeysChange,
    onValuesChange,
  } = props;

  /* eslint-disable jsx-a11y/label-has-for */
  return (
    <FilterGroup>
      <label htmlFor="attributes" style={{ fontSize: 14 }}>
        Device Attributes
        <MultipleSelectField
          id="attributes"
          placeholder="Add attribute filter"
          inputPlaceholder="Choose device attribute"
          keys={keys}
          values={values}
          onKeysChange={onKeysChange}
          onValuesChange={onValuesChange}
          fetchKeys={fetchKeys}
          fetchValues={fetchValues}
        />
      </label>
    </FilterGroup>
  );
};

AttributesFilter.propTypes = {
  keys: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  fetchKeys: PropTypes.func.isRequired,
  fetchValues: PropTypes.func.isRequired,
  onKeysChange: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  keys: getRawFilter(state).getIn(['attributes', 'keys']),
  values: getRawFilter(state).getIn(['attributes', 'values']),
  fetchKeys(q, page) {
    const authToken = getAuthToken(state);
    const namespaceUID = getNamespaceUID(state);
    const productUID = getProductUID(state);
    const requestConfig = fetchDeviceAttributeKeys(
      namespaceUID,
      productUID,
      q,
      page,
      {
        status: getRawFilter(state).get('status'),
      }
    );

    return makeRequest(requestConfig, authToken);
  },
  fetchValues(key) {
    const authToken = getAuthToken(state);
    const namespaceUID = getNamespaceUID(state);
    const productUID = getProductUID(state);

    return (q, page) => {
      const requestConfig = fetchDeviceAttributeValues(
        namespaceUID,
        productUID,
        key,
        q,
        page,
        {
          status: getRawFilter(state).get('status'),
        }
      );

      return makeRequest(requestConfig, authToken);
    };
  },
});

export const mapDispatchToProps = dispatch => ({
  onKeysChange(values) {
    dispatch(actions.updateRawFilter(['attributes', 'keys'], values));
  },
  onValuesChange(values) {
    dispatch(actions.updateRawFilter(['attributes', 'values'], values));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributesFilter);
