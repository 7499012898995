import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { grey200, blue } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';
import { styles as taskStyle } from './Task';
import { getRemainingDevicesCount } from '../reducer';

const styles = {
  taskBox: {
    width: 140,
    padding: 10,
    userSelect: 'none',
    cursor: 'pointer',
    position: 'relative',
    outline: 'none',
    color: grey200,
    background: 'none',
    border: 'none',
    textAlign: 'start',

    '&.active': {
      color: blue,
    },
  },
  taskName: {
    fontSize: 16,
    fontWeight: weight.medium,
  },
  span: taskStyle.span,
};

const AddTaskButton = ({ classes, remainingDevicesCount, onClick }) => {
  const active = remainingDevicesCount !== 0;
  let remainingDevices = (
    <span className={classes.span}>{remainingDevicesCount} remaining</span>
  );

  if (!active) {
    remainingDevices = (
      <span className={classes.span}>No devices remaining</span>
    );
  }

  return (
    <button
      tabIndex="-1"
      className={active ? `${classes.taskBox} active` : classes.taskBox}
      onClick={active ? onClick : null}
    >
      <h2 className={classes.taskName}>+ Add task</h2>
      {remainingDevices}
    </button>
  );
};

AddTaskButton.propTypes = {
  classes: PropTypes.object.isRequired,
  remainingDevicesCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  remainingDevicesCount: getRemainingDevicesCount(state),
});

const mapDispatchToProps = dispatch => ({
  onClick() {
    dispatch(actions.addTask());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(AddTaskButton));
