import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { fileSize } from 'humanize-plus';
import { weight } from '../Theme/Typography';
import { white, grey200, grey800 } from '../Theme/colors';

const style = {
  box: {
    flex: 1,
  },
  table: {
    borderCollapse: 'collapse',
    color: grey800,
    fontSize: 14,
    lineHeight: '19px',
    width: '100%',
  },
  th: {
    fontWeight: weight.medium,
    padding: '33px 24px 20px',
    textAlign: 'left',
  },
  tr: {
    background: white,
    cursor: 'pointer',
    '&:nth-child(odd)': {
      background: '#F9F9F9',
    },
    '&:hover': {
      background: grey200,
    },
  },
  trActive: {
    background: grey200,
  },
  td: {
    padding: '14px 24px 15px',
  },
};

const getTrClass = (cls, current, child) => {
  if (current === child) {
    return cls.trActive;
  }
  return cls.tr;
};

const ObjectRow = ({ obj, rowIndex, currentIndex, onClick, classes }) => (
  <tr
    key={obj.sha256sum}
    className={getTrClass(classes, currentIndex, rowIndex)}
    onClick={() => onClick(rowIndex)}
  >
    <td className={classes.td}>{obj.filename}</td>
    <td className={classes.td}>{fileSize(obj.size)}</td>
  </tr>
);

ObjectRow.propTypes = {
  obj: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

/* eslint-disable react/no-array-index-key */
const ObjectsTable = ({ objects, onClick, classes, currentIndex }) => (
  <div className={classes.box}>
    <table className={classes.table}>
      <thead>
        <tr>
          <th className={classes.th}>Name</th>
          <th className={classes.th}>Size</th>
        </tr>
      </thead>
      <tbody>
        {objects.map((obj, rowIndex) => (
          <ObjectRow
            obj={obj}
            rowIndex={rowIndex}
            currentIndex={currentIndex}
            classes={classes}
            onClick={onClick}
            key={rowIndex}
          />
        ))}
      </tbody>
    </table>
  </div>
);

ObjectsTable.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ObjectsTable);
