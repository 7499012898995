import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { BackIcon } from '../Icons';
import { blue } from '../Theme/colors';
import { weight } from '../Theme/Typography';
import url from '../updatehub/urls';
import Section from './Section';

const styles = {
  link: {
    display: 'flex',
    textTransform: 'uppercase',
    fontSize: 12,
    alignItems: 'center',
    letterSpacing: 1,
    color: blue,
    fontWeight: weight.semiBold,
    textDecoration: 'none',
  },
};

const backButtonStyle = {
  margin: '0px 19px 0px 0px',
  width: 13,
};

const BackToProducts = ({ classes }) => (
  <Section>
    <Link className={classes.link} to={url('main:page')}>
      <BackIcon style={backButtonStyle} />
      Back to products
    </Link>
  </Section>
);

BackToProducts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(BackToProducts);
