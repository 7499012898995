import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import capitalize from 'lodash/fp/capitalize';
import SectionTitle from '../Theme/SectionTitle';
import ColumnEntry from './OverviewColumnEntry';
import { DeviceIcon } from '../Icons';
import RolloutStatusIcon from './RolloutStatusIcon';
import RolloutPieGraph from './RolloutPieGraph';
import OpenArchiveModalButton from './OpenArchiveRolloutModalButton';

const style = {
  overviewColumn: {
    fontSize: 14,
  },
};

const OverviewColumn = ({ rollout, classes }) => (
  <div className={classes.overviewColumn}>
    <SectionTitle text="Overview" />
    <RolloutPieGraph rollout={rollout} />
    <ColumnEntry
      label="Status"
      icon={<RolloutStatusIcon status={rollout.status} />}
      value={capitalize(rollout.status)}
    />
    <ColumnEntry
      label="Total devices in this rollout"
      icon={<DeviceIcon />}
      value={rollout.totalDevices.toString()}
    />
    <OpenArchiveModalButton rollout={rollout} />
  </div>
);

OverviewColumn.propTypes = {
  rollout: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(OverviewColumn);
