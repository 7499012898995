import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { getNamespace } from './reducer';

const styles = {
  box: {
    marginBottom: 73,
  },
  title: {
    display: 'block',
    color: '#3E3E40',
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 6,
  },
  subTitle: {
    display: 'block',
    color: '#3E3E40',
    fontWeight: 500,
    fontSize: 22,
  },
};

const Header = ({ classes, namespace }) => (
  <div className={classes.box}>
    <span className={classes.title}>Billing</span>
    <span className={classes.subTitle}>{namespace}</span>
  </div>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  namespace: PropTypes.string.isRequired,
};

const reduxConnector = connect(state => ({
  namespace: getNamespace(state),
}));

export default reduxConnector(injectSheet(styles)(Header));
