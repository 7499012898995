import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ text }) => <span className="uh-btn-label">{text}</span>;

Label.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Label;
