import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import request from '../updatehub/effects';
import * as api from '../updatehub/api';
import * as page from '../Page/actions';
import * as actions from './actions';
import * as types from './types';
import { getNamespaces } from './reducer';

export function* loadPage() {
  const { result } = yield request(api.fetchNamespaces());
  if (!result) {
    yield put(page.error());
    return;
  }

  yield put(actions.gotNamespaces(result));
}

export function* fetchBillingInformation(namespaceUID) {
  const { result } = yield request(api.fetchBillingData(namespaceUID));

  if (!result) {
    // FIXME
    // Currently billing-service not return for no owners, it's is a ugly patch
    // to avoid this situation
    yield put(actions.removeNamespace(namespaceUID));
  } else {
    yield put(actions.gotBillingInformation(namespaceUID, result));
  }
}

/* eslint-disable function-paren-newline */
export function* fetchBillingInformations() {
  const namespaces = yield select(getNamespaces);
  const sagas = [];
  namespaces.forEach((_, uid) =>
    sagas.push(call(fetchBillingInformation, uid))
  );

  try {
    yield all(sagas);
    yield put(page.loaded());
  } catch (err) {
    yield put(page.error());
  }
}

export default function* rootSaga() {
  yield takeEvery(types.PAGE_REQUEST, loadPage);
  yield takeEvery(types.GOT_NAMESPACES, fetchBillingInformations);
}
