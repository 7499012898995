import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTotalDevicesCount } from '../reducer';

const TotalDevices = ({ count }) => {
  if (count) {
    return <small>{count} total devices</small>;
  }

  return <small>No devices</small>;
};

TotalDevices.propTypes = {
  count: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  count: getTotalDevicesCount(state),
});

export default connect(mapStateToProps)(TotalDevices);
