import React from 'react';
import { Link } from 'react-router-dom';
import { OutlineButton } from '../Buttons';
import Wrapper, { HalfWrapper, positionStyle } from './Wrappers';
import { LessIcon } from '../Icons';
import { weight } from '../Theme/Typography';
import urls from '../updatehub/urls';

const shortTextStyle = {
  textAlign: 'center',
  marginTop: 45,
  fontSize: 50,
  fontWeight: weight.light,
};

const contentStyle = {
  ...positionStyle,
  width: 210,
  height: 210,
  backgroundColor: 'white',
  bottom: -105,
};

const longTextStyle = {
  paddingTop: 21,
  textAlign: 'center',
};

const buttonStyle = {
  marginTop: 41,
};

export default () => (
  <Wrapper>
    <HalfWrapper error>
      <div style={contentStyle}>
        <h1 style={shortTextStyle}>500</h1>
      </div>
    </HalfWrapper>
    <div style={longTextStyle}>
      <h3>Something went wrong</h3>
      <Link to={urls('main:page')}>
        <OutlineButton
          label="Go Home"
          style={buttonStyle}
          icon={<LessIcon />}
        />
      </Link>
    </div>
  </Wrapper>
);
