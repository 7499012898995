import React from 'react';
import PropTypes from 'prop-types';
import BackIcon from 'material-ui/svg-icons/image/navigate-before';
import NextIcon from 'material-ui/svg-icons/image/navigate-next';
import Pages from './Pages';
import ControlButton from './ControlButton';
import Info from './Info';

const paginationComponentStyle = {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 19,
};

const paginationLinkStyle = {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 19,
  justifyContent: 'flex-end',
};

const paginatorStyle = {
  display: 'flex',
  alignSelf: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
};

class PaginationComponent extends React.Component {
  constructor() {
    super();

    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  previousPage() {
    const { onPageChange, page } = this.props;
    onPageChange(page - 1);
  }

  nextPage() {
    const { onPageChange, page } = this.props;
    onPageChange(page + 1);
  }

  render() {
    const {
      pages,
      page,
      pageSize,
      canNext,
      canPrevious,
      onPageChange,
      customProps,
      paginationLink: PaginationLink,
    } = this.props;
    const infoProps = { page, pageSize, totalCount: customProps.totalCount };

    if (pages <= 1)
      return (
        <div style={paginationLinkStyle}>
          <PaginationLink />
        </div>
      );

    return (
      <div style={paginationComponentStyle}>
        <Info {...infoProps} />
        <div style={paginatorStyle}>
          <ControlButton
            icon={BackIcon}
            disabled={!canPrevious}
            onClick={this.previousPage}
          />
          <Pages pages={pages} page={page} onPageChange={onPageChange} />
          <ControlButton
            icon={NextIcon}
            disabled={!canNext}
            onClick={this.nextPage}
          />
        </div>
        <PaginationLink />
      </div>
    );
  }
}

PaginationComponent.defaultProps = {
  paginationLink: () => null,
};

PaginationComponent.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  canNext: PropTypes.bool.isRequired,
  canPrevious: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  paginationLink: PropTypes.func,

  customProps: PropTypes.shape({
    totalCount: PropTypes.number,
  }).isRequired,
};

export default PaginationComponent;
