/* eslint-disable */
export const getApiURL = () => UPDATEHUB_API_URL;
export const getBillingURL = () => UPDATEHUB_BILLING_API_URL;
export const getOAuthConfig = () => ({
  url: UPDATEHUB_OAUTH2_URL,
  clientId: UPDATEHUB_OAUTH2_ID,
  redirectUri: UPDATEHUB_OAUTH2_REDIR_URL,
  silentUri: UPDATEHUB_OAUTH2_SILENT_REDIR_URL,
  scope: '',
});
export const getClientID = () => UPDATEHUB_OAUTH2_ID;
export const getAppURL = () => UPDATEHUB_APP_URL;
export const getIdpLogoutURL = () => UPDATEHUB_IDP_LOGOUT_URL;
export const getEditProfileURL = () => UPDATEHUB_EDIT_PROFILE_URL;
export const getFSAccessKey = () => UPDATEHUB_FS_KEY;
export const getFSStoreFront = () => UPDATEHUB_FS_STORE_FRONT;

export default {
  getOAuthConfig,
};
