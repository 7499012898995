import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { getSilentRefreshUrl } from './reducer';

const styles = {
  iframeStyle: {
    display: 'none',
  },
};

const RefreshTokenFrame = ({ classes, refreshUrl }) => {
  if (!refreshUrl) {
    return null;
  }

  return (
    <iframe
      className={classes.iframeStyle}
      src={refreshUrl}
      title="Refresh token hidden iframe"
    />
  );
};

RefreshTokenFrame.defaultProps = {
  refreshUrl: null,
};

RefreshTokenFrame.propTypes = {
  classes: PropTypes.object.isRequired,
  refreshUrl: PropTypes.string,
};

const mapStateToProps = state => ({
  refreshUrl: getSilentRefreshUrl(state),
});

export default connect(mapStateToProps)(injectSheet(styles)(RefreshTokenFrame));
