import React from 'react';
import PropTypes from 'prop-types';
import { weight } from '../../Theme/Typography';

const infoStyle = {
  position: 'absolute',
  alignSelf: 'center',
  fontSize: 12,
  fontWeight: weight.light,
};

export const TotalInfo = ({ total }) => <span> of {total}</span>;

TotalInfo.propTypes = {
  total: PropTypes.number.isRequired,
};

const Info = ({ page, pageSize, totalCount }) => {
  if (totalCount === 0) {
    return null;
  }

  const startPoint = page * pageSize + 1;
  let endPoint = pageSize * (page + 1);

  if (endPoint > totalCount) {
    endPoint = totalCount;
  }

  return (
    <div style={infoStyle}>
      <span>
        {startPoint}-{endPoint}
      </span>
      {totalCount > pageSize && <TotalInfo total={totalCount} />}
    </div>
  );
};

Info.defaultProps = {
  totalCount: null,
};

Info.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
};

export default Info;
