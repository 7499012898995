import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Transition from 'react-transition-group/Transition';

export const menuEnterDelay = 150;
const styles = {
  menu: {
    width: 261,
    maxHeight: 0,
    overflow: 'hidden',
    transition: `max-height ${menuEnterDelay}ms linear`,
    position: 'absolute',
    zIndex: 2,

    '& > div': {
      maxHeight: 138,
      boxShadow: '0 1px 2px 1px #F0F1F4',
      overflow: 'auto',
    },
  },
};

export const getMenuStyle = (status, anchorEl = null) => {
  let { width } = styles.menu;
  if (anchorEl) {
    width = anchorEl.offsetWidth;
  }

  if (status === 'entering' || status === 'entered') {
    return {
      width,
      maxHeight: 140,
    };
  }

  return { width };
};

const Menu = ({ classes, open, children, anchorEl }) => (
  <Transition in={open} timeout={menuEnterDelay}>
    {status => (
      <div className={classes.menu} style={getMenuStyle(status, anchorEl)}>
        <div>{children}</div>
      </div>
    )}
  </Transition>
);

Menu.defaultProps = {
  anchorEl: null,
};

Menu.propTypes = {
  anchorEl: PropTypes.any,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default injectSheet(styles)(Menu);
