import { Record } from 'immutable';
import * as types from './types';

export const LeaveOrganizationModel = Record({
  isOpen: false,
  uid: '',
  isLoading: false,
});

/* eslint-disable function-paren-newline */
const leaveOrganizationModalReducer = (
  state = LeaveOrganizationModel(),
  action
) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return state.withMutations(oldState =>
        oldState.set('isOpen', true).set('uid', action.payload)
      );
    case types.LEAVE_ORGANIZATION:
      return state.set('isLoading', true);
    case types.CLOSE_MODAL:
      return LeaveOrganizationModel();
    default:
      return state;
  }
};

export default leaveOrganizationModalReducer;

// Selectors

const getModalState = state =>
  state.getIn(['settings', 'leaveOrganizationModal']);

export const getUID = state => getModalState(state).get('uid');

export const getModalIsOpen = state => getModalState(state).get('isOpen');

export const getModalIsLoading = state => getModalState(state).get('isLoading');
