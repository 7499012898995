import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { grey200 } from '../Theme/colors';
import { TrashIconPackage } from '../Icons';
import { openModal } from './actions';

const style = {
  removed: {
    background: 'none',
    borderRadius: '100%',
    height: 40,
    outline: 'none',
    width: 40,
  },
  button: {
    background: 'none',
    border: '1px solid #B8BAC0',
    borderRadius: '100%',
    cursor: 'pointer',
    height: 40,
    outline: 'none',
    width: 40,
    '&:hover': {
      background: grey200,
    },
  },
  buttonInner: {
    border: '2px solid #FFFFFF',
    borderRadius: '100%',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
};

const OpenModalButton = ({ onClick, classes, status }) => {
  if (status === 'removed') {
    return <div className={classes.removed} />;
  }
  return (
    <button className={classes.button} onClick={onClick}>
      <div className={classes.buttonInner}>
        <TrashIconPackage />
      </div>
    </button>
  );
};

OpenModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onClick() {
    dispatch(openModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(injectSheet(style)(OpenModalButton));
