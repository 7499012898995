import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { archiveRollout } from './actions';
import { isAborted } from './utils';

const style = {
  icon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 7,
    top: 5,
    width: '16px !important',
    fill: '#B8BAC0',
  },
};

const ArchiveButton = ({ status, onClick, classes }, { namespaceUID }) => {
  if (!isAborted(status)) {
    return null;
  }
  return (
    <CloseIcon className={classes.icon} onClick={() => onClick(namespaceUID)} />
  );
};

ArchiveButton.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
};

ArchiveButton.propTypes = {
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch, { rolloutUID }) => ({
  onClick(namespaceUID) {
    dispatch(archiveRollout(rolloutUID, namespaceUID));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(injectSheet(style)(ArchiveButton));
