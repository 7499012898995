import * as types from './types';

export const requestPage = () => ({
  type: types.PRODUCT_LIST_PAGE_REQUESTED,
});

export const gotNamespaces = namespaces => ({
  type: types.PRODUCT_LIST_GOT_NAMESPACES,
  payload: namespaces,
});

export const gotProducts = products => ({
  type: types.PRODUCT_LIST_GOT_PRODUCTS,
  payload: products,
});

export const setDefaultNamespaceUID = payload => ({
  type: types.SET_DEFAULT_NAMESPACE_UID,
  payload,
});
