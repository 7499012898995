import * as types from './types';

export const requestDetails = payload => ({
  type: types.REQUEST_DETAILS,
  payload,
});

export const setDetails = payload => ({
  type: types.SET_DETAILS,
  payload,
});

export const setNamespace = payload => ({
  type: types.SET_NAMESPACE,
  payload,
});

export const downloadOrderReport = uid => ({
  type: types.DOWNLOAD_ORDER_REPORT,
  payload: { uid },
});

export const downloadedOrderReport = () => ({
  type: types.DOWNLOADED_ORDER_REPORT,
});

export const cancelSubscription = () => ({
  type: types.CANCEL_SUBSCRIPTION,
});

export const openCancelSubscriptionModal = () => ({
  type: types.OPEN_CANCEL_SUBSCRTIPTION_MODAL,
});

export const closeCancelSubscriptionModal = () => ({
  type: types.CLOSE_CANCEL_SUBSCRTIPTION_MODAL,
});

export const enableCancelButton = () => ({
  type: types.ENABLE_CANCEL_BUTTON,
});

export const openConfirmCancelModal = () => ({
  type: types.OPEN_CONFIRM_CANCEL_MODAL,
});

export const closeConfirmCancelModal = () => ({
  type: types.CLOSE_CONFIRM_CANCEL_MODAL,
});
