import { Record } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as types from './types';
import reducerFactory, { selectorFactory } from '../Table/reducerFactory';

export const PackageFilter = Record({
  status: 'done',
  versions: [],
  hardwareList: [],
});

const table = reducerFactory(types.REQUEST, types.RECEIVE, types.RESET);

/* eslint-disable function-paren-newline */
export const filter = (state = PackageFilter(), action) => {
  switch (action.type) {
    case types.CHANGE_STATUS_FILTER:
      return state.withMutations(oldState =>
        oldState
          .set('status', action.payload)
          .set('versions', [])
          .set('hardwareList', [])
      );
    case types.CHANGE_VERSIONS_FILTER:
      return state.set('versions', action.payload);
    case types.CHANGE_HARDWARE_LIST_FILTER:
      return state.set('hardwareList', action.payload);
    default:
      return state;
  }
};

export default combineReducers({
  table,
  filter,
});

// Selectors

export const getTable = selectorFactory(['packages', 'table']);

const getFilter = state => state.getIn(['packages', 'filter']);

export const getStatus = state => getFilter(state).get('status');

export const getVersions = state => getFilter(state).get('versions');

export const getHardwareList = state => getFilter(state).get('hardwareList');
