import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { isActive } from './utils';

const style = {
  bar: {
    background: 'linear-gradient(0deg, #F4F4F4 53%, #EEEEEE 71%, #ECECEC 87%)',
    borderRadius: 20,
    height: 20,
    marginBottom: 12,
    overflow: 'hidden',
    width: '100%',
  },
  inactiveBar: {
    extend: 'bar',
    opacity: 0.5,
  },
  progress: {
    float: 'left',
    height: '100%',
  },
  failedProgress: {
    extend: 'progress',
    background: 'linear-gradient(0deg, #F8533D, #FF7664)',
  },
  updatedProgress: {
    extend: 'progress',
    background: 'linear-gradient(0deg, #51E475, #93F09A)',
  },
};

const ProgressBar = ({ status, failed, updated, classes }) => {
  const barClass = isActive(status) ? classes.bar : classes.inactiveBar;
  const barFailedClass = classes.failedProgress;
  const barUpdatedClass = classes.updatedProgress;
  return (
    <div className={barClass}>
      <div style={{ width: `${updated}%` }} className={barUpdatedClass} />
      <div style={{ width: `${failed}%` }} className={barFailedClass} />
    </div>
  );
};

ProgressBar.propTypes = {
  status: PropTypes.string.isRequired,
  failed: PropTypes.number.isRequired,
  updated: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ProgressBar);
