import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OutlineButton, LinkButton } from '../../Buttons';
import { CheckIcon } from '../../Icons';
import { Modal, ModalContent, ModalTitle, ModalFooter } from '../../Modal';
import * as actions from './actions';
import { getModalIsOpen, getModalIsLoading } from './reducer';

const DeleteApiKeyModal = ({ isOpened, onClose, onDelete, disabled }) => (
  <Modal isVisible={isOpened}>
    <ModalContent>
      <ModalTitle title="Are you sure you want to delete this api key?" />
      <p>This operation is irreversible</p>
    </ModalContent>
    <ModalFooter>
      <LinkButton label="Cancel" onClick={onClose} disabled={disabled} />
      <OutlineButton
        label="Yes, delete it!"
        icon={<CheckIcon />}
        onClick={onDelete}
        disabled={disabled}
      />
    </ModalFooter>
  </Modal>
);

DeleteApiKeyModal.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isOpened: getModalIsOpen(state),
  disabled: getModalIsLoading(state),
});

const mapDispatchToProps = dispatch => ({
  onClose() {
    dispatch(actions.closeModal());
  },
  onDelete() {
    dispatch(actions.deleteApiKey());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeleteApiKeyModal)
);
