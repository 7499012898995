import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { blue } from '../Theme/colors';
import { weight } from '../Theme/Typography';

const styles = {
  option: {
    display: 'flex',
    alignItems: 'center',
    background: '#F0F1F4',
    border: '1px solid #F0F1F4',
    borderRadius: 3,
    height: 40,
    marginTop: 2,

    '& > span': {
      fontSize: 14,
      fontWeight: weight.medium,
      paddingLeft: 16,
    },
  },
  iconButton: {
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: 14,
    outline: 'none',
    background: 'none',
    border: 'none',

    '& > svg': {
      width: '17px !important',
      height: '17px !important',
    },
  },
};

const SelectedOption = ({ classes, value, onClick }) => (
  <div className={classes.option}>
    <span>{value.label}</span>
    <button
      tabIndex="-1"
      className={classes.iconButton}
      onClick={e => onClick(e, value)}
    >
      <CloseIcon color={blue} />
    </button>
  </div>
);

SelectedOption.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(SelectedOption);
