import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { Button } from '../Buttons';
import { CogIcon } from '../Icons';
import * as actions from './actions';
import { getDisabled } from './reducer';
import { transparentGrey200 } from '../Theme/colors';

const style = {
  box: {
    width: '100%',
  },
  buttonBox: {
    textAlign: 'right',
  },
  divider: {
    width: '100%',
    borderStyle: 'solid',
    borderColor: transparentGrey200,
    marginTop: 40,
    marginBottom: 20,
  },
};

const AdvancedModeButton = ({ classes, disabled, toggleAdvancedMode }) => (
  <div className={classes.box}>
    <hr className={classes.divider} />
    <div className={classes.buttonBox}>
      <Button
        label="advanced mode"
        icon={<CogIcon />}
        onClick={toggleAdvancedMode}
        disabled={disabled}
      />
    </div>
  </div>
);

AdvancedModeButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleAdvancedMode: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  disabled: getDisabled(state),
});

const mapDispatchToProps = dispatch => ({
  toggleAdvancedMode() {
    dispatch(actions.toggleAdvancedMode());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(style)(AdvancedModeButton));
