import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestLogout } from '../Auth/actions';
import { blue } from '../Theme/colors';
import MenuItem from './MenuItem';
import { ExitIcon } from '../Icons';

const LogoutButton = ({ logout }) => (
  <MenuItem
    leftIcon={<ExitIcon color={blue} />}
    primaryText="log out"
    onClick={logout}
  />
);

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  logout() {
    dispatch(requestLogout());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(LogoutButton);
