import { combineReducers } from 'redux-immutable';
import { modalReducer } from '../Modal';
import tableReducer from '../Table/reducerFactory';
import {
  PageModel,
  OrganizationModel,
  MemberModel,
  InviteModel,
} from './models';
import * as types from './types';

// Modal keys
export const updateMemberRoleModalKey = 'update-member-modal';
export const removeMemberKey = 'remove-organization-member';
export const cancelInviteKey = 'cancel-member-invite';

const storeOrganization = (state, organization) =>
  state.set('organization', OrganizationModel(organization));

const membersReducer = tableReducer(
  types.REQUEST_MEMBERS,
  types.GOT_MEMBERS,
  types.RESET_MEMBERS_STORE
);

const invitesReducer = tableReducer(
  types.REQUEST_INVITES,
  types.GOT_INVITES,
  types.RESET_INVITES_STORE
);

export const total = payload => {
  const totalPrice =
    payload.devicesPrice +
    payload.transferPrice +
    payload.storagePrice +
    payload.activationsPrice +
    payload.deploymentsPrice;
  return totalPrice;
};

export const pageReducer = (state = PageModel(), action) => {
  switch (action.type) {
    case types.GOT_ORGANIZATION:
      return storeOrganization(state, action.payload.organization);
    case types.OPEN_CREATE_INVITE_MODAL:
      return state.setIn(['createInviteModal', 'isVisible'], true);
    case types.CLOSE_CREATE_INVITE_MODAL:
      return state.setIn(['createInviteModal', 'isVisible'], false);
    case types.OPEN_DELETE_ORGANIZATION_MODAL:
      return state.setIn(['deleteOrganizationModal', 'isVisible'], true);
    case types.CLOSE_DELETE_ORGANIZATION_MODAL:
      return state.setIn(['deleteOrganizationModal', 'isVisible'], false);
    case types.SET_MEMBER_TO_EDIT:
      return state.set('memberToEdit', MemberModel(action.payload.member));
    case types.SET_MEMBER_TO_REMOVE:
      return state.set('memberToRemove', MemberModel(action.payload.member));
    case types.SET_INVITE_TO_CANCEL:
      return state.set('inviteToCancel', InviteModel(action.payload.invite));
    case types.OPEN_TRANSFER_ORGANIZATION_MODAL:
      return state.setIn(
        ['createTransferModal', 'selectTransferIsVisible'],
        true
      );
    case types.CLOSE_TRANSFER_ORGANIZATION_MODAL:
      return state
        .setIn(['createTransferModal', 'selectTransferIsVisible'], false)
        .setIn(['createTransferModal', 'confirmTransferIsVisible'], true)
        .setIn(['selectedMemberToTransfer', 'name'], null);
    case types.CANCEL_TRANSFER_ORGANIZATION_MODAL:
      return state
        .setIn(['createTransferModal', 'selectTransferIsVisible'], false)
        .setIn(['selectedMemberToTransfer', 'name'], null);
    case types.SET_MEMBER_TO_TRANSFER:
      return state.setIn(['selectedMemberToTransfer', 'name'], action.payload);
    case types.SET_BILLING_TO_TRANSFER: {
      const totalPrice = total(action.payload);
      return state.setIn(['billingToTransfer', 'total'], totalPrice);
    }
    case types.CLOSE_CONFIRM_TRANSFER_ORGANIZATION_MODAL:
      return state.setIn(
        ['createTransferModal', 'confirmTransferIsVisible'],
        false
      );
    default:
      return state;
  }
};

export default combineReducers({
  page: pageReducer,
  membersTable: membersReducer,
  invitesTable: invitesReducer,
  updateMemberRoleModal: modalReducer(updateMemberRoleModalKey),
  removeMemberModal: modalReducer(removeMemberKey),
  cancelInviteModal: modalReducer(cancelInviteKey),
});

// Selectors

const getSlice = state => state.get('organizationDetail');

export const getPageModel = state => getSlice(state).get('page');

export const getMembersTable = state => getSlice(state).get('membersTable');

export const getInvitesTable = state => getSlice(state).get('invitesTable');

export const getUpdateMemberRoleModal = state =>
  getSlice(state).get('updateMemberRoleModal');

export const getRemoveMemberModal = state =>
  getSlice(state).get('removeMemberModal');

export const getCancelInviteModal = state =>
  getSlice(state).get('cancelInviteModal');

export const getOrganizationUID = state =>
  getPageModel(state).getIn(['organization', 'uid']);

export const getOrganizationRole = state =>
  getPageModel(state).getIn(['organization', 'myRole']);

export const getCreateInviteModalState = state =>
  getPageModel(state).getIn(['createInviteModal', 'isVisible']);

export const getDeleteOrganizationModalState = state =>
  getPageModel(state).getIn(['deleteOrganizationModal', 'isVisible']);

export const getMemberToEdit = state => getPageModel(state).get('memberToEdit');

export const getMemberToRemove = state =>
  getPageModel(state).get('memberToRemove');

export const getInviteToCancel = state =>
  getPageModel(state).get('inviteToCancel');

export const getTransferModal = state =>
  getPageModel(state).getIn(['createTransferModal', 'selectTransferIsVisible']);

export const getConfirTransferModal = state =>
  getPageModel(state).getIn([
    'createTransferModal',
    'confirmTransferIsVisible',
  ]);

export const getMemberSelectedToTransfer = state =>
  getPageModel(state).getIn(['selectedMemberToTransfer', 'name']);

export const getBillingToTransfer = state =>
  getPageModel(state).getIn(['billingToTransfer', 'total']);
