import { combineReducers } from 'redux-immutable';
import * as types from './types';
import DetailsModel from './models';

export const total = payload => {
  const totalPrice =
    payload.devicesPrice +
    payload.transferPrice +
    payload.storagePrice +
    payload.activationsPrice +
    payload.deploymentsPrice;
  return totalPrice;
};

export const detailsReducer = (state = DetailsModel(), action) => {
  switch (action.type) {
    case types.SET_NAMESPACE:
      return state.set('namespace', action.payload);
    case types.REQUEST_DETAILS:
      return DetailsModel({}).set('namespaceUID', action.payload);
    case types.SET_DETAILS: {
      const totalPrice = total(action.payload);
      return state.merge(state, action.payload).set('total', totalPrice);
    }
    case types.DOWNLOAD_ORDER_REPORT:
      return state.set('isDownloading', true);
    case types.DOWNLOADED_ORDER_REPORT:
      return state.set('isDownloading', false);
    case types.OPEN_CANCEL_SUBSCRTIPTION_MODAL:
      return state.set('modalCancelSubscription', true);
    case types.CLOSE_CANCEL_SUBSCRTIPTION_MODAL:
      return state
        .set('modalCancelSubscription', false)
        .set('disabledCancelButton', true);
    case types.ENABLE_CANCEL_BUTTON:
      return state.set('disabledCancelButton', false);
    case types.OPEN_CONFIRM_CANCEL_MODAL:
      return state
        .set('modalCancelSubscription', false)
        .set('modalConfirm', true);
    case types.CLOSE_CONFIRM_CANCEL_MODAL:
      return state.set('modalConfirm', false);
    default:
      return state;
  }
};

export default combineReducers({
  details: detailsReducer,
});

export const getDetails = state => state.getIn(['billingDetails', 'details']);
export const getOrders = state =>
  state.getIn(['billingDetails', 'details', 'orders']);
export const getNamespaceUID = state =>
  state.getIn(['billingDetails', 'details', 'namespaceUID']);
export const getNamespace = state =>
  state.getIn(['billingDetails', 'details', 'namespace']);
export const getIsDownloading = state => getDetails(state).get('isDownloading');
export const getCancelSubscriptionModal = state =>
  state.getIn(['billingDetails', 'details', 'modalCancelSubscription']);
export const getCancelButton = state =>
  state.getIn(['billingDetails', 'details', 'disabledCancelButton']);
export const getConfirmModal = state =>
  state.getIn(['billingDetails', 'details', 'modalConfirm']);
