import React from 'react';
import Table from './Table';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import { ApiKeysIcon } from '../../Icons';
import CreateModalButton from '../CreateApiKeyModal/OpenButton';
import CreateApiKeyModal from '../CreateApiKeyModal';
import DeleteApiKeyModal from '../DeleteApiKeyModal';

const apiKeyStyle = {
  width: 22,
  height: 20,
};

const ApiKeys = () => (
  <Section>
    <SectionHeader
      title="Application access"
      icon={<ApiKeysIcon style={apiKeyStyle} />}
    >
      <CreateModalButton />
      <CreateApiKeyModal />
    </SectionHeader>
    <DeleteApiKeyModal />
    <Table />
  </Section>
);

export default ApiKeys;
