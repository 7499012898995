import React from 'react';
import injectSheet from 'react-jss';
import { weight } from '../../Theme/Typography';

const style = {
  box: {
    display: 'flex',
    width: '100%',
    height: 203,
    flexDirection: 'row',
  },
  icon: {
    width: 128,
    height: 69,
    marginLeft: 24,
    alignSelf: 'center',
  },
  infoBox: {
    width: 175,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 14,
    marginLeft: 17,
  },
  h2: {
    fontWeight: weight.medium,
    fontSize: 16,
    marginBottom: 18,
  },
  textBox: {
    fontWeight: weight.light,
  },
};

const EmptyPackageList = injectSheet(style)(({ classes }) => (
  <div className={classes.box}>
    <img
      src="/icons/empty-packages.svg"
      className={classes.icon}
      alt="Package"
    />
    <div className={classes.infoBox}>
      <h2 className={classes.h2}>No packages yet</h2>
      <p className={classes.textBox}>
        You’ll find recently
        <br />
        uploaded packages here.
      </p>
    </div>
  </div>
));

export default EmptyPackageList;
