import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import url from '../../updatehub/urls';
import CardHeader from '../CardHeader';
import Content from './Content';
import CreateRolloutButton from './CreateRolloutButton';
import StartRolloutTaskModal from '../../StartRolloutTaskModal';

const style = {
  box: {
    marginBottom: 28,
  },
};

const ActiveRolloutsWidget = ({ classes }, { productUID, namespaceUID }) => (
  <div className={classes.box}>
    <CardHeader
      title="Rollouts"
      to={url('product:rollouts', { namespaceUID, productUID })}
    >
      <CreateRolloutButton />
    </CardHeader>
    <StartRolloutTaskModal />
    <Content />
  </div>
);

ActiveRolloutsWidget.contextTypes = {
  productUID: PropTypes.string.isRequired,
  namespaceUID: PropTypes.string.isRequired,
};

ActiveRolloutsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ActiveRolloutsWidget);
