import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../../Theme/Typography';
import { blue } from '../../Theme/colors';
import { ExternalLinkIcon } from '../../Icons';
import { getEditProfileURL } from '../../config';

const styles = {
  span: {
    fontSize: 12,
    color: '#3E3E40',
    letterSpacing: 0.2,
  },
  link: {
    display: 'flex',
    fontSize: 12,
    fontWeight: weight.semiBold,
    color: blue,
    cursor: 'pointer',
    letterSpacing: 1,
    marginBottom: 5,

    '& > img': {
      marginLeft: 11,
      width: 11,
      height: 11,
    },
  },
};

/* eslint-disable jsx-a11y/label-has-for */
const EditAccountLink = ({ classes }) => (
  <div>
    <a
      href={getEditProfileURL()}
      className={classes.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      EDIT ACCOUNT
      <ExternalLinkIcon />
    </a>
    <span className={classes.span}>
      To edit this you will be redirected to identity provider.
    </span>
  </div>
);

EditAccountLink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(EditAccountLink);
