import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchDeviceIdentityKeys,
  fetchDeviceIdentityValues,
} from '../../updatehub/api/device';
import { makeRequest } from '../../updatehub/http';
import FilterGroup from './FilterGroup';
import MultipleSelectField from '../../MultipleSelectField';
import * as actions from '../actions';
import { getRawFilter } from '../reducer';
import { getNamespaceUID, getProductUID } from '../../Dashboard/reducer';
import { getAuthToken } from '../../Auth/reducer';

/* FIXME, make test for this, and review request mode */
/* eslint-disable jsx-a11y/label-has-for */
const IdentityFilter = ({
  keys,
  values,
  fetchKeys,
  fetchValues,
  onKeysChange,
  onValuesChange,
}) => (
  <FilterGroup>
    <label htmlFor="device-identity" style={{ fontSize: 14 }}>
      Device Identity
      <MultipleSelectField
        id="device-identity"
        placeholder="Add identifier filter"
        inputPlaceholder="Choose device identity"
        onKeysChange={onKeysChange}
        onValuesChange={onValuesChange}
        fetchKeys={fetchKeys}
        fetchValues={fetchValues}
        keys={keys}
        values={values}
      />
    </label>
  </FilterGroup>
);

IdentityFilter.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  fetchKeys: PropTypes.func.isRequired,
  fetchValues: PropTypes.func.isRequired,
  onKeysChange: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  keys: getRawFilter(state).getIn(['identities', 'keys']),
  values: getRawFilter(state).getIn(['identities', 'values']),
  fetchKeys(q, page) {
    const authToken = getAuthToken(state);
    const productUID = getProductUID(state);
    const namespaceUID = getNamespaceUID(state);
    const requestConfig = fetchDeviceIdentityKeys(
      namespaceUID,
      productUID,
      q,
      page,
      {
        status: getRawFilter(state).get('status'),
      }
    );

    return makeRequest(requestConfig, authToken);
  },
  fetchValues(key) {
    return (q, page) => {
      const authToken = getAuthToken(state);
      const productUID = getProductUID(state);
      const namespaceUID = getNamespaceUID(state);
      const requestConfig = fetchDeviceIdentityValues(
        namespaceUID,
        productUID,
        key,
        q,
        page,
        {
          status: getRawFilter(state).get('status'),
        }
      );

      return makeRequest(requestConfig, authToken);
    };
  },
});

export const mapDispatchToProps = dispatch => ({
  onKeysChange(values) {
    dispatch(actions.updateRawFilter(['identities', 'keys'], values));
  },
  onValuesChange(values) {
    dispatch(actions.updateRawFilter(['identities', 'values'], values));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentityFilter);
