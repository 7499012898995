import { Record } from 'immutable';

/* eslint-disable import/prefer-default-export */
export const ProductModel = Record({
  name: '',
  uid: '',
  status: '',
  activeRollouts: 0,
});

export const ProductModalModel = Record({
  isVisible: false,
});
