import * as types from './types';
import { openStartTaskModal as openModal } from '../StartRolloutTaskModal/actions';

export const requestRolloutDetailPage = (rolloutUID, namespaceUID) => ({
  type: types.REQUEST_ROLLOUT,
  payload: { rolloutUID, namespaceUID },
});

export const gotRollout = rollout => ({
  type: types.GOT_ROLLOUT,
  payload: { rollout },
});

export const openStartTaskModal = (task, namespaceUID) =>
  openModal({
    taskUID: task.uid,
    rolloutUID: task.rolloutUID,
    page: 'rollout-detail',
    namespaceUID,
  });

export const archiveRollout = (rolloutUID, namespaceUID) => ({
  type: types.ARCHIVE_ROLLOUT,
  payload: { rolloutUID, namespaceUID },
});

export const openArchiveRolloutModal = () => ({
  type: types.OPEN_ARCHIVE_ROLLOUT_MODAL,
});

export const closeArchiveRolloutModal = () => ({
  type: types.CLOSE_ARCHIVE_ROLLOUT_MODAL,
});

export const updateRolloutStatus = (
  rolloutUID,
  status,
  namespaceUID,
  forceSubmit = false
) => ({
  type: types.UPDATE_ROLLOUT_STATUS,
  payload: { rolloutUID, forceSubmit, status, namespaceUID },
});

export const closeConflictModal = () => ({
  type: types.CLOSE_CONFLICT_MODAL,
});

export const setForceSubmit = payload => ({
  type: types.SET_FORCE_SUBMIT,
  payload,
});

export const setConflictError = payload => ({
  type: types.SET_CONFLICT_ERROR,
  payload,
});
