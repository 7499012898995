import { Record, List } from 'immutable';

export const CUSTOMER_STEP = 'CUSTOMER_STEP';
export const SUBSCRIPTION_STEP = 'SUBSCRIPTION_STEP';
export const FINALIZE_STEP = 'FINALIZE_STEP';
export const FINALIZE_FAIL_STEP = 'FAIL_STEP';
export const CUSTOMER_FAIL_STEP = 'CUSTOMER_FAIL_STEP';

export const SubscriptionStateModel = Record({
  step: CUSTOMER_STEP,
  notificationMessage: '',
  namespaceUID: '',
  service: 'FS',
  namespace: '',
});

export const FormModel = Record({
  countries: List(),
});

export const CustomerModel = Record({
  firstName: '',
  lastName: '',
  email: '',
  country: '',
});
