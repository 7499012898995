import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import organizationIcon from '../Icons/svg/organization-namespace-icon.svg';
import personalIcon from '../Icons/svg/user-namespace-icon.svg';

const iconStyle = { height: 18 };
const labelStyle = { marginLeft: 6 };

const buildNamespaceOptions = namespaces =>
  namespaces.map(({ name, uid, isOrganization }) => {
    const text = isOrganization ? name : 'Me';
    const icon = isOrganization ? (
      <img style={iconStyle} src={organizationIcon} alt="org" />
    ) : (
      <img style={iconStyle} src={personalIcon} alt="me" />
    );
    const label = (
      <div>
        {icon}
        <span style={labelStyle}>{text}</span>
      </div>
    );
    return (
      <MenuItem
        leftIcon={icon}
        key={uid}
        value={uid}
        primaryText={text}
        label={label}
      />
    );
  });

export default buildNamespaceOptions;
