import React from 'react';
import PropTypes from 'prop-types';
import humanizePlus from 'humanize-plus';
import { weight } from '../../Theme/Typography';

const liStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: 10,
  fontSize: 14,
  outline: 'none',
  cursor: 'pointer',
};
const valueStyle = {
  width: 35,
  textAlign: 'right',
};
const titleStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
};
const iconStyle = {
  width: 8,
  height: 8,
  marginRight: 10,
  marginLeft: 10,
  borderRadius: '50%',
  alignSelf: 'center',
};

export const LegendItem = ({
  name,
  value,
  fill,
  onClick,
  onFocus,
  onMouseOver,
}) => {
  const processedStyle = {
    ...liStyle,
    cursor: onClick ? 'pointer' : 'default',
  };

  return (
    <li>
      <div
        role="button"
        tabIndex="-1"
        style={processedStyle}
        onClick={onClick}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        onKeyUp={onMouseOver}
      >
        <span style={valueStyle}>{value}%</span>
        <div style={{ ...iconStyle, backgroundColor: fill }} />
        <span style={titleStyle}>{name}</span>
      </div>
    </li>
  );
};

LegendItem.defaultProps = {
  onClick: null,
  onFocus: null,
  onMouseOver: null,
};

LegendItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseOver: PropTypes.func,
};

const ulStyle = {
  listStyleType: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  fontSize: 12,
  fontWeight: weight.regular,
};

const h3Style = {
  fontSize: 14,
  marginBottom: 10,
};

/* eslint-disable react/no-array-index-key */
const Legend = ({ title, payload, onClick, onMouseOver, onMouseOut }) => {
  const total = payload.reduce((last, entry) => last + entry.payload.value, 0);
  const unitValue = 100 / total;

  return (
    <div>
      <h3 style={h3Style}>{title}</h3>
      <ul style={ulStyle} onMouseOut={onMouseOut} onBlur={onMouseOut}>
        {payload.map((entry, index) => (
          <LegendItem
            key={index}
            name={entry.payload.name}
            fill={entry.payload.fill}
            value={humanizePlus.formatNumber(
              entry.payload.value * unitValue,
              0
            )}
            onClick={onClick ? e => onClick(entry.payload, index, e) : null}
            onFocus={
              onMouseOver ? e => onMouseOver(entry.payload, index, e) : null
            }
            onMouseOver={
              onMouseOver ? e => onMouseOver(entry.payload, index, e) : null
            }
          />
        ))}
      </ul>
    </div>
  );
};

Legend.defaultProps = {
  onClick: null,
  onMouseOver: null,
  onMouseOut: null,
};

Legend.propTypes = {
  title: PropTypes.string.isRequired,
  payload: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

export default Legend;
