import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { openTransferOrganizationModal } from './actions';
import { getOrganizationRole } from './reducer';

const styles = {
  label: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    color: '#A8A7A7',
    fontSize: 11,
  },
};

const TransferButton = ({ classes, openCancelModal, myRole }) => {
  if (myRole !== 'owner') return null;

  return (
    <span className={classes.label}>
      To transfer your organization,
      <button className={classes.label} onClick={openCancelModal}>
        click here.
      </button>
    </span>
  );
};

TransferButton.propTypes = {
  classes: PropTypes.object.isRequired,
  openCancelModal: PropTypes.func.isRequired,
  myRole: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  myRole: getOrganizationRole(state),
});

const mapDispatchToProps = dispatch => ({
  openCancelModal() {
    dispatch(openTransferOrganizationModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(TransferButton));
