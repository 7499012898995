import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from './actions';
import Table, { makeStateToProps } from '../../Table';
import { getTable } from './reducer';
import { fromNow } from '../../updatehub/humanizer';
import ActionsCell from './ActionsCell';

const ApiKeyTable = props => <Table {...props} />;

export const ActionsCellWrapper = ({ value }) => <ActionsCell value={value} />;

ActionsCellWrapper.propTypes = {
  value: PropTypes.string.isRequired,
};

const lastActivity = apiKey => {
  if (apiKey.lastActivity === null) {
    return 'Not yet used';
  }
  return fromNow(apiKey.lastActivity);
};

export const columns = [
  {
    Header: 'Name',
    id: 'name',
    accessor: 'name',
  },
  {
    Header: 'Available For',
    id: 'namespace',
    accessor: 'namespace',
  },
  {
    Header: 'Access Key',
    id: 'access-key',
    accessor: 'accessKey',
  },
  {
    Header: 'Last Activity',
    id: 'last-activity',
    accessor: lastActivity,
  },
  {
    Header: 'Created',
    id: 'created',
    accessor: ({ createdAt }) => fromNow(createdAt),
  },
  {
    Header: null,
    accessor: 'uid',
    Cell: ActionsCellWrapper,
  },
];

const mapStateToProps = makeStateToProps(getTable, columns, {
  pageSize: 5,
  paginationIfNecessary: true,
});

const mapDispatchToProps = dispatch => ({
  getTrProps() {
    return {
      className: 'no-cursor',
    };
  },
  onChange(payload) {
    dispatch(actions.request(payload));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApiKeyTable)
);
