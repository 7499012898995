import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { blue } from '../Theme/colors';
import url from '../updatehub/urls';
import FinalizeHeader from './FinalizeHeader';
import { getCustomer, getNamespace } from './reducer';

const styles = {
  box: {
    height: 360,
    backgroundColor: '#FFF',
    borderRadius: '0 0 2px 2px',
    boxShadow: '0 0 12px 0 #E5E6E8',
    borderTop: '2px solid #51E475',
  },
  text: {
    display: 'block',
    color: '#3E3E40',
    textAlign: 'center',
    fontSize: 14,
  },
  textBottom: {
    extend: 'text',
    fontWeight: 300,
  },
  textEmail: {
    extend: 'text',
    marginBottom: 30,
  },
  label: {
    extend: 'textEmail',
    marginBottom: 30,
    fontWeight: 500,
  },
  textBoxFooter: {
    extend: 'label',
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 44,
  },
  link: {
    display: 'block',
    textTransform: 'uppercase',
    textDecoration: 'none',
    textAlign: 'center',
    color: blue,
    letterSpacing: 1.17,
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 103,
  },
};

const FinalizePage = ({ classes, email, namespace }) => (
  <div className={classes.box}>
    <FinalizeHeader />
    <span className={classes.label}>
      You have successfully unlocked
      {namespace}.
    </span>
    <span className={classes.textEmail}>
      We will send a confirmation email to
      {email.email}.
    </span>
    <span className={classes.text}>
      Thank you for trusting us with your devices.
    </span>
    <span className={classes.textBoxFooter}>Start updating today!</span>
    <Link className={classes.link} to={url('billing:list')}>
      Back to billing page
    </Link>
    <span className={classes.text}>Any doubt or request about your order?</span>
    <span className={classes.textBottom}>
      Let us know at contact@ossystems.com and we will be glad to help.
    </span>
  </div>
);

FinalizePage.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  namespace: PropTypes.string.isRequired,
};

const reduxConnector = connect(state => ({
  email: getCustomer(state),
  namespace: getNamespace(state),
}));

export default reduxConnector(injectSheet(styles)(FinalizePage));
