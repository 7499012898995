import * as types from './types';

export const requestPage = (namespaceUID, packageUID) => ({
  type: types.REQUEST_PAGE,
  payload: { namespaceUID, packageUID },
});

export const gotPackage = pkg => ({
  type: types.GOT_PACKAGE,
  payload: pkg,
});

// Delete modal

export const openModal = () => ({
  type: types.OPEN_DELETE_MODAL,
});

export const closeModal = () => ({
  type: types.CLOSE_DELETE_MODAL,
});

export const deletePackage = (packageUID, namespaceUID, productUID) => ({
  type: types.REQUEST_DELETE_PACKAGE,
  payload: {
    packageUID,
    namespaceUID,
    productUID,
  },
});
