import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import injectSheet from 'react-jss';
import Page from '../Page';
import { requestPage } from './actions';
import Header from './Header';
import ObjectsWidget from './ObjectsWidget';
import DeletePackageModal from './DeletePackageModal';
import urls from '../updatehub/urls';
import PackageAlert from './PackageAlert';

const style = {
  wrapper: {
    width: '100%',
  },
};

const PackageDetailPage = injectSheet(style)(({ classes, onLoad, match }) => (
  <Page
    onLoad={onLoad}
    goBackURL={urls('product:packages', {
      namespaceUID: match.params.namespaceUID,
      productUID: match.params.productUID,
    })}
  >
    <div className={classes.wrapper}>
      <Header />
      <PackageAlert />
      <ObjectsWidget />
      <DeletePackageModal />
    </div>
  </Page>
));

PackageDetailPage.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { match }) => ({
  onLoad() {
    const { params } = match;
    const { namespaceUID, packageUID } = params;
    dispatch(requestPage(namespaceUID, packageUID));
  },
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(PackageDetailPage)
);
