import { routerMiddleware } from 'react-router-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reduxReset from 'redux-reset';
import reducers from './reducers';

export default history => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return {
    ...createStore(
      reducers,
      composeEnhancers(
        reduxReset(),
        applyMiddleware(sagaMiddleware, routerMiddleware(history))
      )
    ),
    runSaga: sagaMiddleware.run,
  };
};
