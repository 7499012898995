import round from 'lodash/fp/round';

export const isRunning = rolloutStatus => rolloutStatus === 'running';

export const isAborted = rolloutStatus => rolloutStatus === 'aborted';

export const isWaiting = rolloutStatus => rolloutStatus === 'waiting';

export const isPending = rolloutStatus => rolloutStatus === 'pending';

export const isActive = rolloutStatus =>
  isRunning(rolloutStatus) || isWaiting(rolloutStatus);

export const percent = (value, total) => round((value / total) * 100, 1);
