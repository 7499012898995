import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { loading } from '../Page/actions';
import { subscribe } from './actions';
import { getNamespaceUID, getNotificationMessage } from './reducer';
import { blue } from '../Theme/colors';
import { DeviceErrorIcon } from '../Icons';

const styles = {
  box: {
    height: 285,
    paddingTop: 51,
    backgroundColor: '#FFF',
    borderRadius: '0 0 2px 2px',
    boxShadow: '0 0 12px 0 #E5E6E8',
    borderTop: '2px solid #F8533D',
  },
  label: {
    display: 'block',
    color: '#3E3E40',
    fontSize: 22,
    fontWeight: 500,
    marginLeft: 85,
    marginBottom: 75,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 28,
  },
  textAlert: {
    color: '#3E3E40',
    fontSize: 14,
  },
  icon: {
    height: 16,
    marginRight: 7,
  },
  link: {
    display: 'block',
    border: 'none',
    color: blue,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.17,
    textTransform: 'uppercase',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
};

const FinalizeFail = ({ classes, onSubscribe, notificationMesssage }) => (
  <div className={classes.box}>
    <span className={classes.label}>Payment Details</span>
    <div className={classes.body}>
      <img src={DeviceErrorIcon} alt="error" className={classes.icon} />
      <span className={classes.textAlert}>{notificationMesssage}</span>
    </div>
    <div className={classes.body}>
      <button className={classes.link} onClick={onSubscribe}>
        TRY AGAIN
      </button>
    </div>
  </div>
);

FinalizeFail.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  notificationMesssage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  namespace: getNamespaceUID(state),
  notificationMesssage: getNotificationMessage(state),
});

const mapDispatchToProps = {
  subscribe,
  loading,
};

const mergeProps = (state, actions) => ({
  ...state,
  onSubscribe() {
    actions.loading();
    actions.subscribe(state.namespace);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(injectSheet(styles)(FinalizeFail));
