import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { EmptyTable } from '../Icons';

const styles = {
  box: {
    paddingBottom: 41,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: 113,
    width: 113,
    border: '1px solid #EFF0F4',
    borderRadius: '50%',
    margin: '39px 0 18px 0',
  },
  boxIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    display: 'block',
    color: '#B8BABF',
    fontSize: 20,
    fontWeight: 300,
    textAlign: 'center',
  },
};

const OrderEmpty = ({ classes }) => (
  <div className={classes.box}>
    <div className={classes.boxIcon}>
      <div className={classes.icon}>
        <EmptyTable />
      </div>
    </div>
    <span className={classes.text}>The closed billing will appear here.</span>
  </div>
);

OrderEmpty.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(OrderEmpty);
