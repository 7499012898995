import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';
import { DeviceRolloutIcon } from '../Icons';
import { getRolloutToDetail } from './reducer';
import RolloutTransaction from './DeviceRolloutTransaction';

const style = {
  box: {
    padding: 24,
  },
  rolloutName: {
    display: 'inline-block',
    fontSize: 16,
    fontWeight: weight.medium,
    padding: [0, 0, 30],
  },
  rolloutIcon: {
    marginRight: 16,
    verticalAlign: -4,
  },
  versionHeader: {
    fontWeight: weight.medium,
    marginBottom: 10,
  },
  versionContent: {
    float: 'left',
    marginBottom: 40,
    width: '50%',
    '& h5': {
      fontSize: 14,
      fontWeight: weight.light,
      marginBottom: 5,
    },
  },
  empty: {
    display: 'block',
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 13,
    fontWeight: 500,
  },
  tip: {
    fontWeight: 600,
  },
  tipText: {
    display: 'block',
    textAlign: 'justify',
  },
};

const RolloutDetail = ({ rollout, classes }) => {
  let content;
  if (!rollout) {
    content = (
      <div>
        <span className={classes.empty}>
          This device has not yet been part of any rollouts.
        </span>
        <span className={classes.tipText}>
          <span className={classes.tip}>Tip: </span>
          If you started a rollout to update this device recently, the rollout
          will only appear in the list after the device performs a probe request
          and thus starts the update process.
        </span>
      </div>
    );
  } else {
    content = (
      <div>
        <h3 className={classes.rolloutName}>
          <DeviceRolloutIcon className={classes.rolloutIcon} />
          {rollout.name}
        </h3>
        <h4 className={classes.versionHeader}>Version</h4>
        <div className={classes.versionContent}>
          <h5>From</h5>
          <p>{rollout.fromVersion}</p>
        </div>
        <div className={classes.versionContent}>
          <h5>To</h5>
          <p>{rollout.toVersion}</p>
        </div>
        <RolloutTransaction />
      </div>
    );
  }
  return <div className={classes.box}>{content}</div>;
};

RolloutDetail.propTypes = {
  rollout: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

RolloutDetail.defaultProps = {
  rollout: null,
};

const mapStateToProps = state => ({
  rollout: getRolloutToDetail(state),
});

export default connect(mapStateToProps)(injectSheet(style)(RolloutDetail));
