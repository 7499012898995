import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OutlineButton, LinkButton } from '../../Buttons';
import { CheckIcon } from '../../Icons';
import { Modal, ModalContent, ModalTitle, ModalFooter } from '../../Modal';
import * as actions from './actions';
import { getModalIsOpen, getModalIsLoading } from './reducer';

const LeaveOrganizationModal = ({ isOpened, onClose, onLeave, disabled }) => (
  <Modal isVisible={isOpened}>
    <ModalContent>
      <ModalTitle title="Are you sure you want to leave from this organization?" />
    </ModalContent>
    <ModalFooter>
      <LinkButton label="Cancel" onClick={onClose} disabled={disabled} />
      <OutlineButton
        label="Yes"
        icon={<CheckIcon />}
        onClick={onLeave}
        disabled={disabled}
      />
    </ModalFooter>
  </Modal>
);

LeaveOrganizationModal.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isOpened: getModalIsOpen(state),
  disabled: getModalIsLoading(state),
});

const mapDispatchToProps = dispatch => ({
  onClose() {
    dispatch(actions.closeModal());
  },
  onLeave() {
    dispatch(actions.leaveOrganization());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeaveOrganizationModal)
);
