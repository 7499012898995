import { call, put, takeEvery } from 'redux-saga/effects';
import request from '../../updatehub/effects';
import * as api from '../../updatehub/api';
import { getContext } from '../../Dashboard/sagas';
import * as page from '../../Page/actions';
import { openNotification } from '../../Notification';
import { START_ROLLOUT_TASK_DONE } from '../../StartRolloutTaskModal/types';
import * as actions from './actions';
import * as types from './types';
import buildRefresher from '../../updatehub/refresher';

export function* getRollouts() {
  const { namespaceUID, productUID } = yield call(getContext);
  const { result } = yield request(
    api.fetchActiveRollouts(namespaceUID, productUID)
  );
  if (!result) {
    yield put(actions.setRolloutsError());
  } else {
    yield put(actions.setRollouts(result));
  }
  yield put(page.loaded());
}

export function* archiveRollout(action) {
  const { namespaceUID, rolloutUID } = action.payload;
  yield request(api.archiveRollout(namespaceUID, rolloutUID));
}

export function* refreshAfterStartTask(action) {
  if (action.payload.page !== 'overview-page') {
    return;
  }
  yield put(page.loading());
  yield call(getRollouts);
  yield put(openNotification(action.payload.message));
}

export default function* saga() {
  yield takeEvery(types.REQUEST_ROLLOUTS, getRollouts);
  yield takeEvery(types.ARCHIVE_ROLLOUT, archiveRollout);
  yield takeEvery(START_ROLLOUT_TASK_DONE, refreshAfterStartTask);
  yield buildRefresher(
    types.REQUEST_ROLLOUTS,
    getRollouts,
    'overview-rollouts',
    20
  );
}
