import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import { OutlineButton } from '../../Buttons';
import urls from '../../updatehub/urls';

const CancelButton = ({ onClick }, { namespaceUID, productUID }) => (
  <OutlineButton
    label="Cancel"
    onClick={() => onClick(namespaceUID, productUID)}
  />
);

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

CancelButton.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
  productUID: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onClick(namespaceUID, productUID) {
    dispatch(
      push(
        urls('product:rollouts', {
          namespaceUID,
          productUID,
        })
      )
    );
  },
});

export default connect(
  null,
  mapDispatchToProps
)(CancelButton);
