import { all, takeEvery, put } from 'redux-saga/effects';
import organization from './Organizations/sagas';
import apiKey from './ApiKeys/sagas';
import deleteApiKey from './DeleteApiKeyModal/sagas';
import leaveOrganization from './LeaveOrganizationModal/sagas';
import * as types from './types';
import * as uh from '../updatehub/api';
import request from '../updatehub/effects';
import * as page from '../Page/actions';
import * as actions from './actions';

export function* loadNamespaces() {
  const { result } = yield request(uh.fetchNamespaces());

  if (result) {
    yield put(actions.receiveNamespaces(result));
    yield put(page.loaded());
  } else {
    yield put(page.error());
  }
}

function* settingsSaga() {
  yield all([
    apiKey(),
    deleteApiKey(),
    organization(),
    leaveOrganization(),
    takeEvery(types.REQUEST_LOAD, loadNamespaces),
  ]);
}

export default settingsSaga;
