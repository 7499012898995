export const blue = '#5CA7DF';
export const blue900 = '#0E283D';

export const green = '#50E37D';
export const green200 = '#4DDF65';
export const green400 = '#9DC754';

export const grey200 = '#F0F1F4';
export const transparentGrey200 = 'rgba(240, 241, 244, 0.4)';

export const grey300 = '#D4D4D6';
export const grey400 = '#ABADB2';
export const grey600 = '#ACACAC';
export const grey800 = '#3D3E40';
export const grey900 = '#3E3E40';

export const purple = '#9399C1';
export const transparentPurple = 'rgba(59, 68, 108, 0.36)';

export const red = '#F8533D';

export const white = '#FFFFFF';

export const yellow = '#F0CA00';
export const yellow200 = '#F1C200';

const bgs1 = '#EEEEF1';
const bgs2 = '#F4F4F7';
export const bgStripe = `repeating-linear-gradient(45deg, ${bgs1}, ${bgs1} 2px, ${bgs2} 2px, ${bgs2} 8px)`;

// Allowed background colors
export const colors = [
  '#E86830',
  '#E83A30',
  '#E8308C',
  '#8930E8',

  '#2516C7',
  '#1976E8',
  '#30C9E8',
  '#30E8BD',

  '#30E849',
  '#A5E830',
  '#EEE966',
  '#E8B730',

  '#E88C30',
  '#A66037',
  '#848484',
  '#545454',
];

export const getProductColor = uid => {
  const value = Array.from(uid).reduce(
    (total, chr) => total + chr.charCodeAt(),
    0
  );
  return colors[value % colors.length];
};
