import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, OutlineButton } from '../Buttons';
import { AddIcon } from '../Icons';
import { setDefaultNamespaceUID } from './actions';
import { createProductKey } from './reducer';
import * as actions from '../Modal/actions';

const ButtonOpenCreateProductModal = ({ openModal, outline, ...other }) => {
  const props = {
    icon: <AddIcon />,
    label: 'Add Product',
    onClick: openModal,
    ...other,
  };
  const Comp = outline ? OutlineButton : Button;
  return <Comp {...props} />;
};

ButtonOpenCreateProductModal.defaultProps = {
  outline: false,
};

ButtonOpenCreateProductModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  outline: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, { namespace }) => ({
  openModal() {
    if (namespace) {
      dispatch(setDefaultNamespaceUID(namespace.uid));
    }
    dispatch(actions.openModal(createProductKey));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(ButtonOpenCreateProductModal);
