import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getDisplayName from 'react-display-name';
import { getAuthToken } from './reducer';
import { createOnEnter } from './utils';
import * as actions from '../App/actions';

/* eslint-disable import/prefer-default-export */
export const withAuthToken = InnerComponent => {
  const mapStateToProps = state => ({
    authToken: getAuthToken(state),
  });
  const comp = connect(mapStateToProps)(InnerComponent);
  comp.displayName = `withAuthToken(${getDisplayName(InnerComponent)})`;
  return comp;
};

// FIXME, missing test for this HOC
class AuthBootstrap extends Component {
  componentDidMount() {
    const { store } = this.context;
    const { requestLoad } = this.props;
    createOnEnter(store);

    requestLoad();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

AuthBootstrap.contextTypes = {
  store: PropTypes.object.isRequired,
};

AuthBootstrap.propTypes = {
  children: PropTypes.node.isRequired,
  requestLoad: PropTypes.func.isRequired,
};

const ConnectedAuthBootstrap = connect(
  null,
  { requestLoad: actions.requestLoad }
)(AuthBootstrap);

export const requireAuth = InnerComponent => {
  const Wrapper = props => (
    <ConnectedAuthBootstrap>
      <InnerComponent {...props} />
    </ConnectedAuthBootstrap>
  );
  const comp = Wrapper;
  comp.displayName = `requireAuth(${getDisplayName(InnerComponent)})`;
  comp.InnerComponent = InnerComponent;

  return comp;
};
