import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import BaseCopiable from './BaseCopiable';
import { CopyIcon } from '../Icons';

const style = {
  box: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: '100%',
    marginRight: 6,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  icon: {
    width: 9,
  },
};

const CopiableText = ({ text, classes }) => (
  <BaseCopiable text={text}>
    <div className={classes.box}>
      <span className={classes.label}>{text}</span>
      <CopyIcon className={classes.icon} />
    </div>
  </BaseCopiable>
);

CopiableText.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(CopiableText);
