import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OutlineButton } from '../Buttons';
import { CheckIcon } from '../Icons';
import { deleteOrganization } from './actions';

const DeleteOrganizationButton = ({ onClick }) => (
  <OutlineButton
    icon={<CheckIcon />}
    onClick={onClick}
    label="Yes, delete it!"
  />
);

DeleteOrganizationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchtoProps = (dispatch, { organizationUID }) => ({
  onClick() {
    dispatch(deleteOrganization(organizationUID));
  },
});

export default connect(
  null,
  mapDispatchtoProps
)(DeleteOrganizationButton);
