import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Tabs, Tab } from 'material-ui/Tabs';
import { grey200 } from '../Theme/colors';
import ObjectMetadata from './ObjectMetadata';

const style = {
  tabBox: {
    display: 'flex',
    alignItems: 'center',
    width: '33.3333%',
  },
  tabLabel: {
    lineHeight: '19px',
  },
};

const tabStyle = {
  borderBottom: `1px solid ${grey200}`,
};

const TabsLabel = ({ classes }) => (
  <div className={classes.tabBox}>
    <span className={classes.tabLabel}>Installation Set</span>
  </div>
);
// To get rid of mui warnings we must set a muiName for this component;
TabsLabel.muiName = 'Tab';

TabsLabel.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ObjectSets = ({ sets, classes }) => (
  <Tabs initialSelectedIndex={1} tabItemContainerStyle={tabStyle}>
    <TabsLabel classes={classes} />
    {sets.map((set, index) => (
      /* eslint-disable react/no-array-index-key */
      <Tab key={index} label={`#${index}`}>
        <ObjectMetadata set={set} />
      </Tab>
    ))}
  </Tabs>
);

ObjectSets.propTypes = {
  sets: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(ObjectSets);
