import { all } from 'redux-saga/effects';
import auth from './Auth/sagas';
import app from './App/sagas';
import productList from './ProductListPage/sagas';
import overviewPage from './OverviewPage/sagas';
import dashboard from './Dashboard/sagas';
import devices from './DevicesPage/sagas';
import deviceDetailSaga from './DeviceDetailPage/sagas';
import organizationDetailSaga from './OrganizationDetailPage/sagas';
import organizationInvitationSaga from './OrganizationInvitationPage/sagas';
import packages from './PackagesPage/sagas';
import packageDetailSaga from './PackageDetailPage/sagas';
import refresher from './updatehub/refresher/saga';
import rollouts from './RolloutsPage/sagas';
import rolloutCreate from './RolloutCreatePage/sagas';
import rolloutDetail from './RolloutDetailPage/sagas';
import settings from './SettingsPage/sagas';
import startRolloutTaskModalSaga from './StartRolloutTaskModal/sagas';
import billingList from './BillingListPage/sagas';
import billingSubscription from './BillingSubscriptionPage/sagas';
import billingDetails from './BillingDetailsPage/sagas';

export default function* rootSaga() {
  yield all([
    auth(),
    app(),
    dashboard(),
    devices(),
    deviceDetailSaga(),
    productList(),
    organizationDetailSaga(),
    organizationInvitationSaga(),
    overviewPage(),
    packages(),
    packageDetailSaga(),
    refresher(),
    rollouts(),
    rolloutCreate(),
    rolloutDetail(),
    startRolloutTaskModalSaga(),
    settings(),
    billingList(),
    billingSubscription(),
    billingDetails(),
  ]);
}
