import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import * as actions from '../actions';
import { transparentGrey200 } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';
import FormGroup from './FormGroup';
import StartPolicy from './StartPolicy';
import FaultTolerance from './FaultTolerance';
import VersionFilter from './Filters/VersionFilter';
import HardwareFilter from './Filters/HardwareFilter';
import TagFilter from './Filters/TagFilter';
import IdentityFilter from './Filters/IdentityFilter';
import AttributesFilter from './Filters/AttributesFilter';
import {
  TaskModel,
  getSelectedTaskIndex,
  getCurrentTask,
  getScope,
} from '../reducer';

const styles = {
  box: {
    backgroundColor: transparentGrey200,
    padding: 46,
  },
  taskInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 36,

    '& > div': {
      width: 283,
    },
  },
  deviceFilterTitle: {
    width: '100%',
    fontWeight: weight.light,
    fontSize: 14,
    marginBottom: 22,
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      width: 283,
      marginBottom: 30,
    },
  },
};

const TaskBody = props => {
  const {
    classes,
    selectedTaskIndex,
    task,
    scope,
    onNameChange,
    onVersionsChange,
    onFaultToleranceChange,
    onHardwareChange,
    onTagChange,
    onIdentityKeysChange,
    onIdentityValuesChange,
    onAttributesKeysChange,
    onAttributesValuesChange,
  } = props;

  return (
    <div className={classes.box}>
      {selectedTaskIndex !== 0 && <StartPolicy taskIndex={selectedTaskIndex} />}
      <div className={classes.taskInfo}>
        <FormGroup htmlFor="name" label="Name">
          <TextField
            id="name"
            value={task.name}
            onChange={onNameChange(selectedTaskIndex)}
            fullWidth
          />
        </FormGroup>
        <FormGroup htmlFor="fault-tolerance" label="Fault Tolerance" bold>
          <FaultTolerance
            value={task.faultTolerance}
            onChange={onFaultToleranceChange(selectedTaskIndex)}
          />
        </FormGroup>
      </div>
      <div className={classes.filterBox}>
        <h4 className={classes.deviceFilterTitle}>Device Filters</h4>
        <VersionFilter
          values={task.rawFilter.versions}
          onChange={onVersionsChange(selectedTaskIndex)}
          filters={task.filters}
          scope={scope}
        />
        <TagFilter
          values={task.rawFilter.tags}
          onChange={onTagChange(selectedTaskIndex)}
          filters={task.filters}
          scope={scope}
        />
        <HardwareFilter
          values={task.rawFilter.hardware}
          onChange={onHardwareChange(selectedTaskIndex)}
          filters={task.filters}
          scope={scope}
        />
        <IdentityFilter
          keys={task.rawFilter.identities.get('keys')}
          onKeysChange={onIdentityKeysChange(selectedTaskIndex)}
          values={task.rawFilter.identities.get('values')}
          onValuesChange={onIdentityValuesChange(selectedTaskIndex)}
          filters={task.filters}
          scope={scope}
        />
        <AttributesFilter
          keys={task.rawFilter.attributes.get('keys')}
          onKeysChange={onAttributesKeysChange(selectedTaskIndex)}
          values={task.rawFilter.attributes.get('values')}
          onValuesChange={onAttributesValuesChange(selectedTaskIndex)}
          filters={task.filters}
          scope={scope}
        />
      </div>
    </div>
  );
};

TaskBody.defaultProps = {
  scope: null,
};

TaskBody.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedTaskIndex: PropTypes.number.isRequired,
  scope: PropTypes.object,
  task: PropTypes.instanceOf(TaskModel).isRequired,
  onNameChange: PropTypes.func.isRequired,
  onFaultToleranceChange: PropTypes.func.isRequired,
  onVersionsChange: PropTypes.func.isRequired,
  onHardwareChange: PropTypes.func.isRequired,
  onTagChange: PropTypes.func.isRequired,
  onIdentityKeysChange: PropTypes.func.isRequired,
  onIdentityValuesChange: PropTypes.func.isRequired,
  onAttributesKeysChange: PropTypes.func.isRequired,
  onAttributesValuesChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedTaskIndex: getSelectedTaskIndex(state),
  task: getCurrentTask(state),
  scope: getScope(state),
});

const mapDispatchToProps = dispatch => ({
  onNameChange(taskIndex) {
    return (e, value) => {
      dispatch(actions.updateTask(taskIndex, 'name', value));
    };
  },
  onFaultToleranceChange(taskIndex) {
    return (e, value) => {
      dispatch(actions.updateTask(taskIndex, 'faultTolerance', value));
    };
  },
  onVersionsChange(taskIndex) {
    return values => {
      dispatch(
        actions.updateTask(taskIndex, ['rawFilter', 'versions'], values)
      );
    };
  },
  onTagChange(taskIndex) {
    return values => {
      dispatch(actions.updateTask(taskIndex, ['rawFilter', 'tags'], values));
    };
  },
  onHardwareChange(taskIndex) {
    return values => {
      dispatch(
        actions.updateTask(taskIndex, ['rawFilter', 'hardware'], values)
      );
    };
  },
  onIdentityKeysChange(taskIndex) {
    return values => {
      dispatch(
        actions.updateTask(
          taskIndex,
          ['rawFilter', 'identities', 'keys'],
          values
        )
      );
    };
  },
  onIdentityValuesChange(taskIndex) {
    return values => {
      dispatch(
        actions.updateTask(
          taskIndex,
          ['rawFilter', 'identities', 'values'],
          values
        )
      );
    };
  },
  onAttributesKeysChange(taskIndex) {
    return values => {
      dispatch(
        actions.updateTask(
          taskIndex,
          ['rawFilter', 'attributes', 'keys'],
          values
        )
      );
    };
  },
  onAttributesValuesChange(taskIndex) {
    return values => {
      dispatch(
        actions.updateTask(
          taskIndex,
          ['rawFilter', 'attributes', 'values'],
          values
        )
      );
    };
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(TaskBody));
