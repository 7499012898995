import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

const styles = {
  button: {
    backgroundColor: '#FCFBFD',
    cursor: 'pointer',
    border: 'none',
    width: '100%',
    display: 'flex',
    height: 40,
    alignItems: 'center',
    paddingLeft: 12,
    color: '#5CA7DF',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#F4F1F7 !important',
    },
  },
};

const TagNewAssign = ({ classes, style, onClick }) => (
  <button onClick={onClick} className={classes.button} style={style}>
    + Add new tag
  </button>
);

TagNewAssign.defaultProps = {
  style: {},
};

TagNewAssign.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default injectSheet(styles)(TagNewAssign);
