import * as types from './types';

export const requestDevice = (deviceUID, namespaceUID, productUID) => ({
  type: types.REQUEST_DEVICE,
  payload: { deviceUID, namespaceUID, productUID },
});

export const setDevice = device => ({
  type: types.SET_DEVICE,
  payload: { device },
});

export const setProbeInfo = payload => ({
  type: types.SET_PROBE_INFO,
  payload,
});

export const requestRollouts = (namespaceUID, deviceUID, productUID) => ({
  type: types.REQUEST_DEVICE_ROLLOUTS,
  payload: { namespaceUID, deviceUID, productUID },
});

export const setRollouts = rollouts => ({
  type: types.SET_DEVICE_ROLLOUTS,
  payload: { rollouts },
});

export const setRolloutsError = () => ({
  type: types.SET_DEVICE_ROLLOUTS_ERROR,
});

export const requestRolloutTransaction = (
  rolloutUID,
  deviceUID,
  namespaceUID,
  productUID
) => ({
  type: types.REQUEST_ROLLOUT_TRANSACTION,
  payload: { rolloutUID, deviceUID, namespaceUID, productUID },
});

export const setRolloutTransaction = transaction => ({
  type: types.SET_ROLLOUT_TRANSACTION,
  payload: { transaction },
});

export const setRolloutTransactionError = () => ({
  type: types.SET_ROLLOUT_TRANSACTION_ERROR,
});

export const openDeviceLogModal = () => ({
  type: types.OPEN_DEVICE_LOG_MODAL,
});

export const closeDeviceLogModal = () => ({
  type: types.CLOSE_DEVICE_LOG_MODAL,
});

export const setTag = payload => ({
  type: types.SET_TAG,
  payload,
});

// Status actions

export const disableDevice = (deviceUID, namespaceUID, productUID) => ({
  type: types.REQUEST_DEVICE_DISABLE,
  payload: {
    deviceUID,
    namespaceUID,
    productUID,
  },
});
