import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import StepInformation from './StepInformation';

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 26,
  },
};

const Steps = ({ classes }) => (
  <div className={classes.box}>
    <StepInformation value={1} />
    <StepInformation value={2} />
  </div>
);

Steps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Steps);
