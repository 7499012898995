import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import url from '../updatehub/urls';
import * as actions from './actions';
import Table, { makeStateToProps } from '../Table';
import { getTable } from './reducer';
import RolloutProgressBarColumn from './RolloutProgressBarColumn';
import RolloutStatusIcons from './RolloutStatusIcons';
import { fromNow } from '../updatehub/humanizer';

const RolloutTable = props => <Table {...props} />;

const rolloutProgressBar = rollout => {
  const props = {
    small: true,
    rollout,
  };
  return <RolloutProgressBarColumn {...props} />;
};

export const columns = [
  {
    Header: 'uid',
    accessor: 'uid',
    show: false,
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Version',
    accessor: 'version',
  },
  {
    Header: 'Created',
    id: 'creationDate',
    accessor: ({ creationDate }) => fromNow(creationDate),
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: rollout => <RolloutStatusIcons status={rollout.status} />,
  },
  {
    Header: 'Progress',
    id: 'Progress',
    accessor: rolloutProgressBar,
  },
];

const mapStateToProps = makeStateToProps(getTable, columns);

const getStatusColor = status => {
  switch (status) {
    case 'aborted':
      return 'td-aborted';
    case 'waiting':
      return 'td-waiting';
    case 'archived':
      return 'td-archived';
    default:
      return '';
  }
};

const mapDispatchToProps = (dispatch, { match }) => ({
  getTrProps(props, rowInfo) {
    if (!rowInfo) {
      return {};
    }
    const { namespaceUID, productUID } = match.params;
    const { original } = rowInfo;

    return {
      className: getStatusColor(original.status),
      onClick() {
        dispatch(
          push(
            url('product:rollouts:detail', {
              namespaceUID,
              productUID,
              rolloutUID: original.uid,
            })
          )
        );
      },
    };
  },

  onChange(payload) {
    dispatch(actions.request(payload));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RolloutTable)
);
