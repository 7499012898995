import {
  call,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects';
import uri from 'urijs';
import qs from 'qs';
import * as actions from './actions';
import * as types from './types';
import { getOAuthConfiguration } from './reducer';
import {
  clearStorage,
  redirectTo,
  generateURL,
} from './utils';
import { getIdpLogoutURL, getClientID } from '../config';

export function* login() {
  const config = yield select(getOAuthConfiguration);
  const redirectUrl = yield call(generateURL, config);
  yield call(redirectTo, redirectUrl);
}

export function* logout() {
  const client = yield call(getClientID);
  yield call(clearStorage);
  yield put(actions.logout());
  const baseUrl = yield call(getIdpLogoutURL);
  const url = uri(baseUrl);
  url.query(qs.stringify({ client }));
  window.location.href = url.toString();
}

export function* authRootSaga() {
  yield takeLatest(types.REQUEST_TOKEN, login);
  yield takeLatest(types.REQUEST_LOGOUT, logout);
}

export default authRootSaga;
