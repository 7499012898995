import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Page from '../Page';
import { requestOrganization } from './actions';
import { getPageModel } from './reducer';
import Content from './Content';
import urls from '../updatehub/urls';

const OrganizationDetailPage = ({ onLoad, model }) => (
  <Page onLoad={onLoad} goBackURL={urls('settings')}>
    <Content model={model} />
  </Page>
);

OrganizationDetailPage.propTypes = {
  onLoad: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  model: getPageModel(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onLoad() {
    dispatch(requestOrganization(match.params.organizationUID));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganizationDetailPage)
);
