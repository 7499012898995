import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { getPackageStatus } from './reducer';
import { DeviceErrorIcon, DisableIcon } from '../Icons';

const style = {
  alert: {
    width: 890,
    height: 64,
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
    margin: '-20px auto 41px auto',
    boxShadow: '0px 0px 12px 0px #E6E6E9',
    borderTop: '2px solid #F8533D',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textAlert: {
    color: '#3E3E40',
    textAlign: 'center',
    fontFamily: 'Fira Sans',
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 5,
  },
  iconStyle: {
    width: 14,
  },
};

const PackageAlert = ({ status, classes }) => {
  switch (status) {
    case 'error':
      return (
        <div className={classes.alert}>
          <img
            className={classes.iconStyle}
            src={DeviceErrorIcon}
            alt="Error status icon"
          />
          <span className={classes.textAlert}>
            This package wasn&rsquo;t processed correctly
          </span>
        </div>
      );
    case 'removed':
      return (
        <div className={classes.alert}>
          <DisableIcon className={classes.iconStyle} />
          <span className={classes.textAlert}>This package was removed</span>
        </div>
      );
    default:
      return null;
  }
};

PackageAlert.propTypes = {
  status: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  status: getPackageStatus(state),
});

export default connect(mapStateToProps)(injectSheet(style)(PackageAlert));
