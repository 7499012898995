import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './actions';
import { Button } from '../../Buttons';
import { AddIcon } from '../../Icons';

const OpenModalButton = ({ openModal }) => (
  <Button label="Create organization" onClick={openModal} icon={<AddIcon />} />
);

OpenModalButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  openModal() {
    dispatch(actions.openModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(OpenModalButton);
