import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';
import { LinkIcon } from '../Icons';
import { grey800 } from '../Theme/colors';

const style = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 17,
    alignItems: 'flex-end',
  },
  h2: {
    display: 'inline-block',
    fontSize: 16,
  },
  link: {
    color: grey800,
    textDecoration: 'none !important',
  },
  linkIcon: {
    marginLeft: 8,
    verticalAlign: -2,
  },
};

const CardHeader = ({ classes, title, to, children }) => (
  <div className={classes.header}>
    <h2 className={classes.h2}>
      <Link to={to} className={classes.link}>
        {title}
        <LinkIcon className={classes.linkIcon} alt={title} />
      </Link>
    </h2>
    {children}
  </div>
);

CardHeader.defaultProps = {
  children: null,
};

CardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default injectSheet(style)(CardHeader);
