import * as types from './types';
import { AppModel, UserModel, APP_STATUS, isLoading } from './models';

const appReducer = (state = AppModel(), action) => {
  switch (action.type) {
    case types.LOADED:
      return state.set('status', APP_STATUS.LOADED);
    case types.LOAD_ERROR:
      /* eslint-disable function-paren-newline */
      return state.withMutations(oldState =>
        oldState
          .set('status', APP_STATUS.ERROR)
          .set('errorMessage', action.payload)
      );
    case types.REDIRECT_LOGIN:
      /* eslint-disable function-paren-newline */
      return state.withMutations(oldState =>
        oldState
          .set('status', APP_STATUS.REDIRECT)
      );
    case types.SET_USER:
      return state.set('user', UserModel(action.payload));
    default:
      return state;
  }
};

export default appReducer;

// Selectors
export const getUser = state => state.getIn(['app', 'user']);

export const getUserNameOrEmail = state =>
  getUser(state).get('name') || getUser(state).get('email');

export const getGravatarHash = state =>
  state.getIn(['app', 'user', 'gravatarHash']);

export const getStatus = state => state.getIn(['app', 'status']);

export const getErrorMessage = state => state.getIn(['app', 'errorMessage']);

export const isAppLoading = state => isLoading(state.getIn(['app', 'status']));
