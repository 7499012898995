import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Content from './Content';
import Header from './Header';
import * as actions from './actions';
import { getNamespaces } from './reducer';
import BillingCard from './BillingCard';
import BillingHeader from './BillingHeader';
import Page from '../Page';

const BillingListPage = ({ namespaces, onLoad }) => {
  const usageList = namespaces.filter(billing => !billing.isOrganization);
  const organizationList = namespaces.filter(billing => billing.isOrganization);
  return (
    <Page onLoad={onLoad}>
      <Content>
        <Header />
        {usageList.length >= 1 && <BillingHeader isOrganization={false} />}
        {usageList.map(namespace => (
          <div key={namespace.uid}>
            <BillingCard
              uid={namespace.uid}
              namespaceName={namespace.name}
              billing={namespace.billing}
            />
          </div>
        ))}
        {organizationList.length >= 1 && <BillingHeader isOrganization />}
        {organizationList.map(namespace => (
          <div key={namespace.uid}>
            <BillingCard
              uid={namespace.uid}
              namespaceName={namespace.name}
              billing={namespace.billing}
            />
          </div>
        ))}
      </Content>
    </Page>
  );
};

BillingListPage.propTypes = {
  namespaces: PropTypes.array.isRequired,
  onLoad: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  state => ({
    namespaces: Object.values(getNamespaces(state).toJS()),
  }),
  dispatch => ({
    onLoad() {
      dispatch(actions.requestPage());
    },
  })
);

export default reduxConnector(BillingListPage);
