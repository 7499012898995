import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Popover from 'material-ui/Popover';
import Slider from 'material-ui/Slider';
import { blue } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';

const styles = {
  box: {
    display: 'inline-block',
  },
  value: {
    color: blue,
    fontWeight: weight.semiBold,
    outline: 'none',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
  },
  sliderBox: {
    width: 120,
    padding: 15,
  },
};

const sliderStyle = {
  marginBottom: 0,
  marginTop: 0,
};

class ValueSlider extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };
    this.toggleSlider = this.toggleSlider.bind(this);
  }

  toggleSlider() {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  }

  render() {
    const { open } = this.state;
    const { classes, min, max, value, onChange } = this.props;

    return (
      <div className={classes.box}>
        <button
          ref={c => {
            this.anchor = c;
          }}
          tabIndex="-1"
          className={classes.value}
          onClick={this.toggleSlider}
        >
          {value}%
        </button>
        <Popover
          anchorEl={this.anchor}
          open={open}
          onRequestClose={this.toggleSlider}
        >
          <div className={classes.sliderBox}>
            <Slider
              min={min}
              max={max}
              step={1}
              value={value}
              onChange={onChange}
              sliderStyle={sliderStyle}
            />
          </div>
        </Popover>
      </div>
    );
  }
}

ValueSlider.defaultProps = {
  min: 0,
  max: 100,
};

ValueSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default injectSheet(styles)(ValueSlider);
