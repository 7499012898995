import { SubmissionError } from 'redux-form/immutable';
import { createOrganizationInvite } from '../updatehub/api';
import { makeRequest } from '../updatehub/http';
import { openNotification } from '../Notification';
import { closeCreateInviteModal, refreshInvitesTable } from './actions';

export const CREATE_INVITE_SUCCESS = 'Member invited successfully';

export const UNKNOWN_ERROR = 'Something went wrong. Try again later.';

const onSubmit = (
  formData,
  dispatch,
  { authToken, organizationUID, reset }
) => {
  const requestConfig = createOrganizationInvite(
    organizationUID,
    formData.get('email'),
    formData.get('role')
  );

  return makeRequest(requestConfig, authToken).then(({ result, error }) => {
    if (result) {
      dispatch(closeCreateInviteModal());
      dispatch(openNotification(CREATE_INVITE_SUCCESS));
      dispatch(reset());
      dispatch(refreshInvitesTable());
    } else if (error) {
      throw new SubmissionError(error);
    } else {
      dispatch(openNotification(UNKNOWN_ERROR));
    }
  });
};

export default onSubmit;
