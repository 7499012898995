import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { LinkButton, OutlineButton } from '../Buttons';
import { Modal, ModalFooter, ModalContent, ModalTitle } from '../Modal';
import {
  getShowConflictModal,
  getForceSubmit,
  getConflictError,
} from './reducer';
import TaskErrorList from '../RolloutCreatePage/ConflictModal/TaskErrorList';
import * as actions from './actions';

const styles = {
  errorMessage: {
    marginBottom: '24px',
  },
};

const checkboxLabel =
  'I’m aware that force start will instantaneously cancel all conflicting rollouts and start this one.';
const ConflictModal = (
  {
    classes,
    showConflictModal,
    errorList,
    forceCreation,
    onForceSubmitCheck,
    onForceStart,
    onCancel,
  },
  { namespaceUID }
) => (
  <Modal isVisible={showConflictModal}>
    <ModalTitle title="Conflict found: devices already in use in other rollout" />
    <ModalContent large>
      <p className={classes.errorMessage}>
        You can cancel this operation, or force start this rollout now, which
        will cancel other rollouts involved.
      </p>
      <TaskErrorList errorList={errorList} />
      <Checkbox
        label={checkboxLabel}
        checked={forceCreation}
        onCheck={onForceSubmitCheck}
      />
    </ModalContent>
    <ModalFooter>
      <LinkButton label="Cancel" onClick={onCancel} />
      <OutlineButton
        label="Force Start"
        disabled={!forceCreation}
        onClick={onForceStart(namespaceUID)}
      />
    </ModalFooter>
  </Modal>
);

ConflictModal.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
};

ConflictModal.defaultProps = {
  errorList: [],
};

ConflictModal.propTypes = {
  classes: PropTypes.object.isRequired,
  showConflictModal: PropTypes.bool.isRequired,
  forceCreation: PropTypes.bool.isRequired,
  errorList: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onForceSubmitCheck: PropTypes.func.isRequired,
  onForceStart: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showConflictModal: getShowConflictModal(state),
  forceCreation: getForceSubmit(state),
  errorList: getConflictError(state),
});

const mapDispatchToProps = (dispatch, { rollout }) => ({
  onCancel() {
    dispatch(actions.closeConflictModal());
  },
  onForceSubmitCheck(e, value) {
    dispatch(actions.setForceSubmit(value));
  },
  onForceStart(namespaceUID) {
    return () =>
      dispatch(
        actions.updateRolloutStatus(rollout.uid, 'running', namespaceUID, true)
      );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(ConflictModal));
