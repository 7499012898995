import { all, call, takeEvery, select, put } from 'redux-saga/effects';
import request from '../updatehub/effects';
import * as api from '../updatehub/api';
import * as page from '../Page/actions';
import * as actions from './actions';
import * as types from './types';
import { getNamespaceUID } from './reducer';

export const PAYMENT_GATEWAY_NOT_AVAILABLE =
  'There was a problem with payment gateway';
export const FAILURE_TO_CREATE_SESSION =
  'There was a problem receiving your subscribe details.';
export const FAILURE_TO_CREATE_PAYMENT =
  'There was a problem receiving your payment details.';

export function* getNamespaces() {
  const uid = yield select(getNamespaceUID);
  const { result } = yield request(api.fetchNamespaces());
  const name = result.find(namespace => {
    if (namespace.uid === uid) {
      return namespace;
    }
    return false;
  });

  return name.name;
}

export function* checkoutProcess(sessionID) {
  const namespaceUID = yield select(getNamespaceUID);
  const [orderNumber] = yield all([
    call(window.FSRegisterCallback),
    call(window.fastspring.builder.checkout, sessionID),
  ]);
  // ORDER NOT COMPLETED
  if (!orderNumber) {
    return false;
  }
  const { result } = yield request(
    api.finalizeSubscription(namespaceUID, orderNumber)
  );
  if (!result) {
    return false;
  }
  return true;
}

export function* getCustomer() {
  const namespaceUID = yield select(getNamespaceUID);
  const { result } = yield request(api.fetchCustomerData(namespaceUID));
  if (result) {
    yield put(actions.setCustomer(result));
  }
}

export function* startSubscription() {
  if (!window.fastspring) {
    yield put(actions.failSubscription(PAYMENT_GATEWAY_NOT_AVAILABLE));
    return;
  }
  if (!window.FSRegisterCallback) {
    yield put(actions.failSubscription(PAYMENT_GATEWAY_NOT_AVAILABLE));
    return;
  }
  const namespaceUID = yield select(getNamespaceUID);
  const { result } = yield request(api.subscribe(namespaceUID));
  if (!result) {
    yield put(actions.failSubscription(FAILURE_TO_CREATE_SESSION));
    return;
  }
  const success = yield call(checkoutProcess, result.sessionID);
  if (!success) {
    yield put(actions.failSubscription(FAILURE_TO_CREATE_PAYMENT));
    return;
  }
  yield call(getCustomer);
  yield put(actions.finalizeSubscription());
}

export function* loadCountriesList() {
  const namespaceUID = yield select(getNamespaceUID);
  const { result } = yield request(api.fetchCountriesList(namespaceUID));
  if (!result) {
    yield put(actions.failCustomer());
    return;
  }
  yield put(actions.loadedCountries(result));
}

export function* startCustomerStep() {
  const namespaceUID = yield select(getNamespaceUID);
  const namespace = yield call(getNamespaces);
  yield put(actions.setNamespace(namespace));
  const { result } = yield request(api.fetchCustomerData(namespaceUID));
  if (result) {
    yield put(actions.startSubscription());
    yield put(page.loaded());
    return;
  }
  yield call(loadCountriesList);
  yield put(page.loaded());
}

export default function* rootSaga() {
  yield takeEvery(types.SUBSCRIBE, startCustomerStep);
  yield takeEvery(types.START_SUBSCRIPTION, startSubscription);
}
