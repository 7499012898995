import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PaginationComponent from './PaginationComponent';
import LoadingComponent from './LoadingComponent';
import './custom-table.css';

export { default as ActionsCell } from './ActionsCell';
export { TableModel } from './reducerFactory';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.onPageChangeWrapper = this.onPageChangeWrapper.bind(this);
  }

  componentWillMount() {
    const { onChange, pageSize } = this.props;

    onChange({
      page: 0,
      pageSize,
    });
  }

  onPageChangeWrapper(newPage) {
    const { onChange, pageSize } = this.props;

    onChange({ page: newPage, pageSize });
  }

  getShowPagination() {
    const { paginationIfNecessary, pages, paginationLink } = this.props;

    if (!paginationIfNecessary || paginationLink) return true;

    return pages > 1;
  }

  render() {
    const {
      data,
      totalCount,
      columns,
      pageSize,
      page,
      pages,
      loading,
      ...other
    } = this.props;

    return (
      <ReactTable
        manual
        data={data}
        columns={columns}
        page={page}
        pages={pages}
        loading={loading}
        onPageChange={this.onPageChangeWrapper}
        sortable={false}
        showPagination={this.getShowPagination()}
        PaginationComponent={PaginationComponent}
        LoadingComponent={LoadingComponent}
        defaultPageSize={pageSize}
        className="-striped -highlight"
        customProps={{ totalCount }}
        noDataText=""
        {...other}
      />
    );
  }
}

Table.defaultProps = {
  pageSize: 10,
  paginationIfNecessary: false,
  paginationLink: undefined,
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  paginationIfNecessary: PropTypes.bool,
  pageSize: PropTypes.number,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  paginationLink: PropTypes.func,
};

export const makeStateToProps = (selector, columns, toMerge = {}) => state => {
  const tableData = selector(state).set('columns', columns);

  return {
    ...toMerge,

    // required because the state merge on reducer
    data: tableData.data.toJS(),
    totalCount: tableData.totalCount,
    columns: tableData.columns,
    page: tableData.page,
    pages: tableData.pages,
    loading: tableData.loading,
  };
};

export const makeDispatchToProps = requestAction => dispatch => ({
  onChange(payload) {
    dispatch(requestAction(payload));
  },
});

export default Table;
