import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkButton } from '../Buttons';
import { openDeviceLogModal } from './actions';
import { getRolloutTransaction } from './reducer';

const OpenDeviceLogModalOpenButton = ({ active, onClick }) => {
  if (!active) {
    return null;
  }

  return <LinkButton onClick={onClick} label="see device log" />;
};

OpenDeviceLogModalOpenButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  active: Boolean(getRolloutTransaction(state).log.length),
});

const mapDispatchToProps = dispatch => ({
  onClick() {
    dispatch(openDeviceLogModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenDeviceLogModalOpenButton);
