import * as types from './types';

export const requestLoad = () => ({
  type: types.REQUEST_LOAD,
});

export const receiveNamespaces = payload => ({
  type: types.RECEIVE_NAMESPACES,
  payload,
});
