import { takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './types';
import * as uh from '../../updatehub/api';
import tableSaga from '../../Table/sagaFactory';

export const loadOrganizations = tableSaga(
  uh.fetchOrganizations,
  actions.receive,
  actions.requestError
);

function* watchForLoadRequest() {
  yield takeEvery(types.REQUEST, loadOrganizations);
}

export default watchForLoadRequest;
