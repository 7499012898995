import { put, takeEvery, select } from 'redux-saga/effects';
import { fetchProduct } from '../updatehub/api';
import request from '../updatehub/effects';
import { PAGE_STATUS } from '../Page/models';
import * as actions from './actions';
import { getNamespaceUID, getProductUID } from './reducer';
import * as types from './types';

export function* getContext() {
  const namespaceUID = yield select(getNamespaceUID);
  const productUID = yield select(getProductUID);

  return { namespaceUID, productUID };
}

export function* getProduct({ payload }) {
  const { namespaceUID, productUID } = payload;
  const { result } = yield request(fetchProduct(namespaceUID, productUID));

  if (result) {
    yield put(actions.setProduct(result));
    yield put(actions.setPageStatus(PAGE_STATUS.LOADED));
  } else {
    yield put(actions.setPageStatus(PAGE_STATUS.NOT_FOUND));
  }
}

export const CONSTANT_STATES = ['app', 'auth', 'routing', 'refresher'];
export function* makeReset() {
  const oldState = yield select();
  const state = oldState.filter((_v, key) => CONSTANT_STATES.includes(key));

  yield put(actions.resetApp(state));
}

function* watchForLoadRequests() {
  yield takeEvery(types.REQUEST_LOAD, getProduct);
  yield takeEvery(types.UNSET_PRODUCT, makeReset);
}

export default watchForLoadRequests;
