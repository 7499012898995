import React from 'react';
import injectSheet from 'react-jss';
import { isPending } from './utils';

const style = {
  flexSpacer: {
    flexGrow: 1,
  },
  lineSpacer: {
    extend: 'flexSpacer',
    borderBottom: '1px solid #B8BAC0',
  },
  dashSpacer: {
    extend: 'flexSpacer',
    borderBottom: '1px dashed #B8BAC0',
  },
};

const getLineSpacerClass = (status, classes) => {
  if (isPending(status)) {
    return classes.dashSpacer;
  }
  return classes.lineSpacer;
};

const makeSpacerKey = task => `${task.uid}-spacer`;

export const EmptySpacer = injectSheet(style)(({ classes }) => (
  <div className={classes.flexSpacer} />
));

export const LineSpacer = injectSheet(style)(({ status, classes }) => {
  const spacerClass = getLineSpacerClass(status, classes);
  return <div className={spacerClass} />;
});

export const addSpacerBetweenTaskComponents = (tasks, Component, Spacer) =>
  tasks.reduce((curr, task, index) => {
    const component = <Component key={task.uid} task={task} />;
    if (index === 0) {
      return [component];
    }
    const spacer = <Spacer key={makeSpacerKey(task)} status={task.status} />;
    return [...curr, spacer, component];
  }, []);
