import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { ProductSettingsIcons } from '../Icons';
import { openModal } from '../Dashboard/actions';
import { getProductColor } from '../Theme/colors';
import ProductLogo from '../Theme/ProductLogo';

const styles = {
  box: {
    height: 64,
    width: 64,
    margin: '25px 70px 0px 71px',
  },
  innerBox: {
    position: 'absolute',
    height: 64,
    width: 64,
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
  },
};

class ProductSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.getStyle = this.getStyle.bind(this);
  }

  getStyle() {
    const { productUID } = this.props;
    return {
      border: `1px solid ${getProductColor(productUID)}`,
    };
  }

  mouseMove() {
    this.setState({ isActive: true });
  }

  mouseLeave() {
    this.setState({ isActive: false });
  }

  render() {
    const {
      classes,
      onOpenModal,
      productUID,
      productName,
      namespaceUID,
    } = this.props;
    const { isActive } = this.state;

    return (
      <div
        className={classes.box}
        onMouseMove={this.mouseMove}
        onMouseLeave={this.mouseLeave}
      >
        {isActive ? (
          <div
            className={classes.innerBox}
            onClick={onOpenModal}
            onKeyUp={onOpenModal}
            style={this.getStyle()}
            tabIndex="-1"
            role="button"
          >
            <ProductSettingsIcons />
          </div>
        ) : (
          <ProductLogo
            productUID={productUID}
            productName={productName}
            namespaceUID={namespaceUID}
          />
        )}
      </div>
    );
  }
}

ProductSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  productUID: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  namespaceUID: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onOpenModal() {
    dispatch(openModal());
  },
});

// classes
export default connect(
  null,
  mapDispatchToProps
)(injectSheet(styles)(ProductSettings));
