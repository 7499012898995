import * as types from './types';

export const requestLoad = () => ({
  type: types.REQUEST_LOAD,
});

export const loaded = () => ({
  type: types.LOADED,
});

export const loadError = payload => ({
  type: types.LOAD_ERROR,
  payload,
});

export const redirectLogin = () => ({
  type: types.REDIRECT_LOGIN,
});

export const setUser = payload => ({
  type: types.SET_USER,
  payload,
});
