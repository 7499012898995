import React from 'react';
import PropTypes from 'prop-types';
import RolloutTaskProgress from './RolloutTaskProgress';
import RolloutTaskStartButton from './RolloutTaskStartButton';
import { taskStatus, rolloutStatus } from './states';

const isRolloutAborted = rollout => rollout.status === rolloutStatus.aborted;

const isRolloutArchived = rollout => rollout.status === rolloutStatus.archived;

const isTaskWaiting = task => task.status === taskStatus.waiting;

const isRolloutStopped = rollout =>
  isRolloutAborted(rollout) || isRolloutArchived(rollout);

const RolloutTaskCardContent = ({ rollout, task }) => {
  if (isTaskWaiting(task) && !isRolloutStopped(rollout)) {
    return <RolloutTaskStartButton rollout={rollout} task={task} />;
  }
  return <RolloutTaskProgress rollout={rollout} task={task} />;
};

RolloutTaskCardContent.propTypes = {
  rollout: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
};

export default RolloutTaskCardContent;
