import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Content from './Content';
import Header from './Header';
import Amount from './Amount';
import History from './History';
import BackToBilling from './BackToBilling';
import Page from '../Page';
import * as actions from './actions';
import CancelButton from './CancelButton';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import ConfirmCancelModal from './ConfirmCancelModal';

const BillingDetailsPage = ({ onLoad }) => (
  <Page onLoad={onLoad}>
    <Content>
      <Header />
      <Amount />
      <History />
      <CancelButton />
      <CancelSubscriptionModal />
      <ConfirmCancelModal />
      <BackToBilling />
    </Content>
  </Page>
);

BillingDetailsPage.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  null,
  (dispatch, { match }) => ({
    onLoad() {
      dispatch(actions.requestDetails(match.params.organizationUID));
    },
  })
);

export default withRouter(reduxConnector(BillingDetailsPage));
