import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Section from './Section';

const styles = {
  title: {
    height: 34,
    width: 70,
    color: '#3E3E40',
    fontFamily: 'Fira Sans',
    fontSize: 24,
    fontWeight: 500,
  },
};

const Header = ({ classes }) => (
  <Section>
    <h1 className={classes.title}>Billing</h1>
  </Section>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Header);
