import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from './Typography';
import { grey200 } from './colors';

const style = {
  sectionTitle: {
    fontSize: 18,
    fontWeight: weight.light,
    borderBottom: `4px solid ${grey200}`,
    marginBottom: 18,
    paddingBottom: 9,
  },
};

const SectionTitle = ({ text, classes }) => (
  <h2 className={classes.sectionTitle}>{text}</h2>
);

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(SectionTitle);
