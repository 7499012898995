import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../../Theme/Typography';
import {
  EmptySpacer,
  addSpacerBetweenTaskComponents,
} from './RolloutTasksSpacer';

const style = {
  flexContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  taskName: {
    fontWeight: weight.medium,
    fontSize: 16,
    marginBottom: 10,
    width: 192,
  },
};

const TaskName = injectSheet(style)(({ task, classes }) => (
  <h4 className={classes.taskName}>{task.name}</h4>
));

const TasksNamesRow = ({ tasks, classes }) => {
  const tasksNamesRow = addSpacerBetweenTaskComponents(
    tasks,
    TaskName,
    EmptySpacer
  );
  return <div className={classes.flexContainer}>{tasksNamesRow}</div>;
};

TasksNamesRow.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(TasksNamesRow);
