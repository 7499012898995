import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchDeviceTags } from '../../updatehub/api';
import { makeRequest } from '../../updatehub/http';
import FilterGroup from './FilterGroup';
import SelectField from '../../SelectField';
import * as actions from '../actions';
import { getRawFilter } from '../reducer';
import { getNamespaceUID, getProductUID } from '../../Dashboard/reducer';
import { getAuthToken } from '../../Auth/reducer';

/* FIXME, make test for this, and review request mode */
/* eslint-disable jsx-a11y/label-has-for */
const TagFilter = ({ values, fetchData, rawFilterUpdate }) => (
  <FilterGroup>
    <label htmlFor="tags-filter" style={{ fontSize: 14 }}>
      Tag
      <SelectField
        id="tags-filter"
        inputPlaceholder="Choose tags"
        onChange={rawFilterUpdate('tags')}
        fetchData={fetchData}
        values={values}
      />
    </label>
  </FilterGroup>
);

TagFilter.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchData: PropTypes.func.isRequired,
  rawFilterUpdate: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  values: getRawFilter(state).get('tags'),
  fetchData(query, page) {
    const authToken = getAuthToken(state);
    const namespaceUID = getNamespaceUID(state);
    const productUID = getProductUID(state);
    const requestConfig = fetchDeviceTags(
      namespaceUID,
      productUID,
      query,
      page,
      {
        status: getRawFilter(state).get('status'),
      }
    );

    return makeRequest(requestConfig, authToken);
  },
});

export const mapDispatchToProps = dispatch => ({
  rawFilterUpdate(key) {
    return payload => {
      dispatch(actions.updateRawFilter(key, payload));
    };
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagFilter);
