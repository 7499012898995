import React from 'react';
import PropTypes from 'prop-types';
import { grey800 } from '../Theme/colors';
import {
  ArchiveIcon,
  RunningIcon,
  PausedIcon,
  AbortedIcon,
  WaitingIcon,
} from '../Icons';
import { rolloutStatus } from './states';

const RolloutStatusIcon = ({ status }) => {
  switch (status) {
    case rolloutStatus.stopped:
      return <PausedIcon />;
    case rolloutStatus.running:
      return <RunningIcon />;
    case rolloutStatus.waiting:
      return <WaitingIcon />;
    case rolloutStatus.archived:
      return <ArchiveIcon fill={grey800} />;
    case rolloutStatus.aborted:
    default:
      return <AbortedIcon />;
  }
};

RolloutStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default RolloutStatusIcon;
