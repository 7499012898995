import { Record } from 'immutable';

export const TaskModel = Record({
  uid: '',
  name: '',
  status: '',
  updatedDevices: 0,
  failedDevices: 0,
  remainingDevices: 0,
  totalDevices: 0,
  rolloutUID: '',
  startDate: '',
  startThreshold: 0,
  faultTolerance: 0,
});

const RolloutModel = Record({
  uid: '',
  name: '',
  version: '',
  creationDate: '',
  status: '',
  totalDevices: 0,
  remainingDevices: 0,
  updatedDevices: 0,
  failedDevices: 0,
  tasks: [],
  showArchiveModal: false,
  showConflictModal: false,
  forceSubmit: false,
  conflictError: null,
});

export default RolloutModel;
