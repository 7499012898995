import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import CircularProgress from 'material-ui/CircularProgress';
import Card from '../Theme/Card';
import { grey800 } from '../Theme/colors';
import SectionTitle from '../Theme/SectionTitle';
import { weight } from '../Theme/Typography';
import { fetchStatus } from './models';
import RolloutList from './DeviceRolloutList';

const style = {
  box: {
    color: grey800,
    display: 'flex',
    fontSize: 12,
    minHeight: 522,
    overflow: 'hidden',
  },
  boxDisabled: {
    extend: 'box',
    opacity: 0.5,
  },
  loadingBox: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: weight.light,
    flexDirection: 'column',
    height: 400,
    justifyContent: 'center',
    width: '100%',
  },
  error: {
    borderRadius: '50%',
    boxShadow: '0 0 12px 0 #E6E6E9',
    color: '#F8533D',
    fontSize: 24,
    height: 53,
    marginBottom: 18,
    lineHeight: '53px',
    textAlign: 'center',
    width: 53,
  },
};

const getBoxClass = (device, classes) => {
  if (device.status === 'active') {
    return classes.box;
  }
  return classes.boxDisabled;
};

const ContentLoading = ({ classes }) => (
  <div className={classes.loadingBox}>
    <CircularProgress />
  </div>
);

ContentLoading.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ContentError = ({ classes }) => (
  <div className={classes.loadingBox}>
    <div className={classes.error}>!</div>
    <p>Something went wrong. Try again later.</p>
  </div>
);

ContentError.propTypes = {
  classes: PropTypes.object.isRequired,
};

const DeviceRolloutActivity = ({ device, classes }) => {
  const { fetchRolloutsStatus, rollouts, uid } = device;
  let content;
  if (fetchRolloutsStatus === fetchStatus.fetching) {
    content = <ContentLoading classes={classes} />;
  } else if (fetchRolloutsStatus === fetchStatus.error) {
    content = <ContentError classes={classes} />;
  } else {
    content = <RolloutList rollouts={rollouts} deviceUID={uid} />;
  }
  return (
    <div>
      <SectionTitle text="Latest rollout updates" />
      <Card className={getBoxClass(device, classes)}>{content}</Card>
    </div>
  );
};

DeviceRolloutActivity.propTypes = {
  device: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(DeviceRolloutActivity);
