import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../Buttons';
import { AddIcon } from '../Icons';
import { openCreateInviteModal } from './actions';

const OpenModalButton = ({ openModal }) => (
  <Button onClick={openModal} label="invite new member" icon={<AddIcon />} />
);

OpenModalButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  openModal() {
    dispatch(openCreateInviteModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(OpenModalButton);
