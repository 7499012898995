import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from '../Page';
import * as actions from './actions';
import { getIsEmpty } from './reducer';
import ModalCreateProduct from './ModalCreateProduct';
import ProductsView from './ProductsView';
import EmptyProductsView from './EmptyProductsView';

const ProductListPage = ({ isEmpty, onLoad }) => (
  <Page onLoad={onLoad}>
    <div>
      <ModalCreateProduct />
      {isEmpty ? <EmptyProductsView /> : <ProductsView />}
    </div>
  </Page>
);

ProductListPage.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isEmpty: getIsEmpty(state),
});

const mapDispatchToProps = dispatch => ({
  onLoad() {
    dispatch(actions.requestPage());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListPage);
