import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import Menu from './Menu';
import MenuItem from './MenuItem';

const styles = {
  anchor: {
    width: '100%',
    minWidth: 261,
    position: 'relative',
  },
};

class Options extends React.Component {
  static getMenuItemStyle(focused = false) {
    if (focused) {
      return {
        backgroundColor: '#F4F1F7',
      };
    }

    return null;
  }

  constructor() {
    super();

    this.renderValues = this.renderValues.bind(this);
    this.onChange = this.onChange.bind(this);
    this.options = [];
  }

  componentDidUpdate(prevProps) {
    const { selectedIndex } = this.props;

    if (prevProps.selectedIndex !== selectedIndex) {
      this.ensureView();
    }
  }

  onChange(event, value) {
    event.preventDefault();
    event.stopPropagation();

    const { onChange } = this.props;
    onChange(event, value);
  }

  ensureView() {
    setTimeout(() => {
      const { selectedIndex } = this.props;

      if (!this.options[selectedIndex]) {
        return;
      }

      scrollIntoViewIfNeeded(this.options[selectedIndex]);
    });
  }

  renderValues() {
    const { values, paginate, selectedIndex, onPageEnd } = this.props;

    const menuItems = values.map((value, index) => (
      <div
        ref={c => {
          this.options[index] = c;
        }}
        key={value.value}
      >
        <MenuItem
          value={value}
          onClick={this.onChange}
          style={Options.getMenuItemStyle(index === selectedIndex)}
        >
          {value.label}
        </MenuItem>
      </div>
    ));

    if (paginate) {
      menuItems.push(<Waypoint key="waypoint" onEnter={onPageEnd} />);
    }

    return menuItems;
  }

  render() {
    const { classes, open, selectedIndex, values } = this.props;
    const { createComponent: CreateComponent } = this.props;
    return (
      <div>
        <div
          ref={ref => {
            this.anchor = ref;
          }}
          className={classes.anchor}
        />
        <Menu anchorEl={this.anchor} open={open}>
          {this.renderValues()}
          <CreateComponent
            onClick={this.onChange}
            style={Options.getMenuItemStyle(selectedIndex === values.length)}
          />
        </Menu>
      </div>
    );
  }
}

Options.defaultProps = {
  paginate: false,
  createComponent: () => null,
};

Options.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  paginate: PropTypes.bool,
  selectedIndex: PropTypes.number.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onPageEnd: PropTypes.func.isRequired,
  createComponent: PropTypes.func,
};

export default injectSheet(styles)(Options);
