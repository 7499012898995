import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { PageComponent as Page } from '../Page';
import { PAGE_STATUS } from '../Page/models';
import { getPageStatus } from './reducer';
import { requireAuth } from '../Auth';
import { DashboardHeader } from '../Header';
import * as actions from './actions';
import AppStatus from '../App/Status';
import NewProductModal from './NewProductModal';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

const styles = {
  dashboardWrapper: {
    display: 'flex',
    overflow: 'auto',
    minHeight: '100vh',
    flexDirection: 'row',
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
  },
};

class Dashboard extends React.Component {
  getChildContext() {
    const { match } = this.props;
    const { productUID, namespaceUID } = match.params;

    return {
      productUID: productUID || '',
      namespaceUID: namespaceUID || '',
    };
  }

  render() {
    const { classes, onLoad, onExit, status, route } = this.props;

    return (
      <div className={classes.dashboardWrapper}>
        <Sidebar />
        <div className={classes.wrapper}>
          <DashboardHeader />
          <AppStatus>
            <div className={classes.content}>
              <Page onLoad={onLoad} onExit={onExit} status={status}>
                {renderRoutes(route.routes)}
              </Page>
              <NewProductModal />
            </div>
            {status === 'loaded' && <Footer />}
          </AppStatus>
        </div>
      </div>
    );
  }
}

Dashboard.childContextTypes = {
  productUID: PropTypes.string,
  namespaceUID: PropTypes.string,
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  status: getPageStatus(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onLoad() {
    const { namespaceUID, productUID } = match.params;
    dispatch(actions.requestLoad({ namespaceUID, productUID }));
  },
  onExit() {
    dispatch(actions.setPageStatus(PAGE_STATUS.LOADING));
    dispatch(actions.unsetProduct());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireAuth(injectSheet(styles)(Dashboard)));
