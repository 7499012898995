import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { getOrders, getIsDownloading } from './reducer';
import {
  humanizePrice,
  humanizeHash,
  humanizeDate,
} from '../updatehub/humanizer';
import OrderEmpty from './OrderEmpty';
import ViewReportButton from './ViewReportButton';

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    height: 74,
    width: '20%',
    fontSize: 14,
    fontWeight: 500,
    color: '#3E3E40',
    textAlign: 'left',
    paddingLeft: 24,
  },
  tr: {
    height: 48,
    '&:nth-child(odd)': {
      background: '#FBFBFB',
    },
    '&:hover': {
      background: '#F7F7F7',
    },
  },
  td: {
    paddingLeft: 24,
    fontSize: 14,
  },
};

const OrderTable = ({ classes, orders, isDownloading }) => (
  <div>
    <table className={classes.table}>
      <thead>
        <tr>
          <th className={classes.th}>Date</th>
          <th className={classes.th}>Report ID</th>
          <th className={classes.th}>Status</th>
          <th className={classes.th}>Value</th>
          <th className={classes.th} />
        </tr>
      </thead>
      <tbody>
        {orders.map(order => (
          <tr key={order.uid} className={classes.tr}>
            <td className={classes.td}>{humanizeDate(order.createdAt)}</td>
            <td className={classes.td}>{humanizeHash(order.uid)}</td>
            <td className={classes.td}>{order.status}</td>
            <td className={classes.td}>{humanizePrice(order.totalPrice)}</td>
            <td className={classes.td}>
              <ViewReportButton uid={order.uid} disabled={isDownloading} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {orders.length > 0 ? null : <OrderEmpty />}
  </div>
);

OrderTable.propTypes = {
  classes: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired,
  isDownloading: PropTypes.bool.isRequired,
};

const reduxConnector = connect(state => ({
  orders: getOrders(state).toJS(),
  isDownloading: getIsDownloading(state),
}));

export default reduxConnector(injectSheet(styles)(OrderTable));
