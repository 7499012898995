import identity from 'lodash/fp/identity';
import { tableDecoder, checkRequiredKeys, tagToOptions } from '../utils';
import deviceTransactionSerializer from './deviceTransactionSerializer';
import { ROLLOUT_REQUESTS_TIMEOUT } from './rollout';
import { formErrors } from '../../Form/normalizers';

const objectToArray = object => {
  if (!object) {
    return [];
  }
  return Object.entries(object);
};

const formErrorsTag = data => formErrors(data.errors);

const assignTagDecoder = data => {
  checkRequiredKeys(data, ['tags']);
  return data;
};

const deviceVersionGraphDecoder = data => {
  checkRequiredKeys(data, ['data']);
  return data.data.map(device => {
    checkRequiredKeys(device, ['name', 'count', 'details']);
    return {
      name: device.name,
      count: device.count,
      details: device.details,
    };
  });
};

const deviceSimpleGraphDecoder = body => {
  checkRequiredKeys(body, ['data']);

  return body.data.map(version => {
    checkRequiredKeys(version, ['name', 'count']);
    return {
      name: version.name,
      value: version.count,
    };
  });
};

export const deleteDevice = (deviceUID, namespaceUID, productUID) => ({
  method: 'DELETE',
  path: `/devices/${deviceUID}`,
  accepts: [200],
  headers: { 'UH-Namespace': namespaceUID, 'UH-Product': productUID },
  decodeSuccess: identity,
});

const deviceListDecoder = tableDecoder('devices', data => {
  checkRequiredKeys(data, [
    'uid',
    'identity',
    'hardware',
    'version',
    'device_attributes',
  ]);
  return {
    uid: data.uid,
    identity: data.identity,
    hardware: data.hardware,
    version: data.version,
    attrs: data.device_attributes,
  };
});

const deviceRolloutListDecoder = data => {
  checkRequiredKeys(data, ['rollouts']);
  const rollouts = data.rollouts.map(rollout => {
    checkRequiredKeys(rollout, ['uid', 'name', 'from_version', 'to_version']);
    return {
      uid: rollout.uid,
      name: rollout.name,
      fromVersion: rollout.from_version,
      toVersion: rollout.to_version,
    };
  });
  return rollouts;
};

const deviceRolloutDecoder = data => {
  checkRequiredKeys(data, ['transaction']);
  const reports = data.transaction.map(report => {
    checkRequiredKeys(report, ['status', 'created_at']);
    return {
      status: report.status,
      date: report.created_at,
      log: report.message,
    };
  });
  return deviceTransactionSerializer(reports);
};

const deviceDecoder = data => {
  checkRequiredKeys(data, [
    'uid',
    'identity',
    'hardware',
    'version',
    'device_attributes',
    'status',
    'tags',
  ]);
  return {
    uid: data.uid,
    identity: objectToArray(data.identity),
    hardware: data.hardware,
    version: data.version,
    attributes: objectToArray(data.device_attributes),
    status: data.status,
    tags: data.tags.map(tag => tagToOptions(tag)),
  };
};

const deviceProbeInfo = data => {
  checkRequiredKeys(data, ['probe_interval_mean', 'last_probe']);
  return {
    probeIntervalMean: data.probe_interval_mean,
    lastProbe: data.last_probe,
  };
};

const deviceSelectOptionsDecoder = data => {
  checkRequiredKeys(data, ['total_count', 'options']);

  if (data.options.length) {
    checkRequiredKeys(data.options[0], ['label', 'value']);
  }

  return data;
};

export const fetchDeviceVersionsGraphData = (namespaceUID, uid) => ({
  path: '/devices/statistics/versions',
  params: { product_uid: uid },
  accepts: [200],
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceVersionGraphDecoder,
});

export const fetchDevices = (namespaceUID, uid, { page, per_page, body }) => ({
  /* eslint camelcase: 0 */
  method: 'POST',
  path: '/devices/filter',
  params: {
    page,
    per_page,
    order: 'desc',
    sort: 'created_at',
    count: true,
  },
  accepts: [200],
  headers: { 'UH-Namespace': namespaceUID },
  data: body,
  decodeSuccess: deviceListDecoder,
});

export const fetchDevice = (namespaceUID, productUID, deviceUID) => ({
  method: 'GET',
  path: `/devices/${deviceUID}`,
  headers: { 'UH-Namespace': namespaceUID, 'UH-Product': productUID },
  decodeSuccess: deviceDecoder,
});

export const fetchDeviceProbeInfo = (namespaceUID, deviceUID) => ({
  method: 'GET',
  path: `/stats/devices/${deviceUID}/probe_info`,
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceProbeInfo,
});

export const fetchDeviceRollouts = (namespaceUID, deviceUID, productUID) => ({
  method: 'GET',
  path: `/devices/${deviceUID}/rollouts`,
  params: {
    per_page: 10,
  },
  headers: { 'UH-Namespace': namespaceUID, 'UH-Product': productUID },
  decodeSuccess: deviceRolloutListDecoder,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});

export const fetchDeviceRollout = (
  namespaceUID,
  deviceUID,
  rolloutUID,
  productUID
) => ({
  method: 'GET',
  path: `/devices/${deviceUID}/rollouts/${rolloutUID}`,
  headers: { 'UH-Namespace': namespaceUID, 'UH-Product': productUID },
  decodeSuccess: deviceRolloutDecoder,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});

export const fetchDeviceIdentityKeys = (
  namespaceUID,
  productUID,
  q,
  page,
  { status = 'active', filters = [], scope = null } = {}
) => ({
  method: 'POST',
  path: '/devices/identity_keys',
  data: {
    product_uid: productUID,
    status,
    q,
    page,
    filters,
    scope,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceSelectOptionsDecoder,
});

export const fetchDeviceIdentityValues = (
  namespaceUID,
  productUID,
  key,
  q,
  page,
  { status = 'active', filters = [], scope = null } = {}
) => ({
  method: 'POST',
  path: '/devices/identity_values',
  data: {
    product_uid: productUID,
    status,
    key,
    q,
    page,
    filters,
    scope,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceSelectOptionsDecoder,
});

export const fetchDeviceAttributeKeys = (
  namespaceUID,
  productUID,
  q,
  page,
  { status = 'active', filters = [], scope = null } = {}
) => ({
  method: 'POST',
  path: '/devices/attrs_keys',
  data: {
    product_uid: productUID,
    status,
    q,
    page,
    filters,
    scope,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceSelectOptionsDecoder,
});

export const fetchDeviceAttributeValues = (
  namespaceUID,
  productUID,
  key,
  q,
  page,
  { status = 'active', filters = [], scope = null } = {}
) => ({
  method: 'POST',
  path: '/devices/attrs_values',
  data: {
    product_uid: productUID,
    status,
    key,
    q,
    page,
    filters,
    scope,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceSelectOptionsDecoder,
});

export const fetchDeviceHardwareList = (
  namespaceUID,
  productUID,
  q,
  page,
  { status = 'active', filters = [], scope = null } = {}
) => ({
  method: 'POST',
  path: '/devices/hardware_list',
  data: {
    product_uid: productUID,
    status,
    q,
    page,
    filters,
    scope,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceSelectOptionsDecoder,
});

export const fetchDeviceVersions = (
  namespaceUID,
  productUID,
  q,
  page,
  { status = 'active', filters = [], scope = null } = {}
) => ({
  method: 'POST',
  path: '/devices/versions',
  data: {
    product_uid: productUID,
    status,
    q,
    page,
    filters,
    scope,
  },
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceSelectOptionsDecoder,
});

export const fetchScopedDeviceVersionsGraphData = (namespaceUID, scope) => ({
  method: 'POST',
  path: '/devices/statistics/versions',
  data: {
    scope,
  },
  accepts: [200],
  headers: { 'UH-Namespace': namespaceUID },
  decodeSuccess: deviceSimpleGraphDecoder,
});

export const assignTagToDevice = (
  namespaceUID,
  deviceUID,
  productUID,
  tags
) => ({
  accepts: [201],
  method: 'POST',
  path: `/devices/${deviceUID}/tags`,
  headers: { 'UH-Namespace': namespaceUID, 'UH-Product': productUID },
  data: { tags },
  decodeSuccess: assignTagDecoder,
  decodeError: formErrorsTag,
});

export const fetchDeviceTags = (
  namespaceUID,
  productUID,
  q,
  page,
  { status = 'active', filters = [], scope = null } = {}
) => ({
  method: 'POST',
  path: '/devices/tags',
  data: {
    product_uid: productUID,
    status,
    q,
    page,
    filters,
    scope,
  },
  headers: { 'UH-Namespace': namespaceUID },
  accepts: [200],
  decodeSuccess: deviceSelectOptionsDecoder,
});
