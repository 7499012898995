import Callback from './Auth/Callback';
import App from './App';
import ProductListPage from './ProductListPage';
import SettingsPage from './SettingsPage';
import OrganizationDetailPage from './OrganizationDetailPage';
import OrganizationInvitationPage from './OrganizationInvitationPage';
import OverviewPage from './OverviewPage';
import RolloutsPage from './RolloutsPage';
import RolloutCreatePage from './RolloutCreatePage';
import RolloutDetailPage from './RolloutDetailPage';
import DevicesPage from './DevicesPage';
import DeviceDetailPage from './DeviceDetailPage';
import PackagesPage from './PackagesPage';
import PackageDetailPage from './PackageDetailPage';
import Dashboard from './Dashboard';
import BillingListPage from './BillingListPage';
import BillingSubscriptionPage from './BillingSubscriptionPage';
import BillingDetailsPage from './BillingDetailsPage';
import { NoMatch } from './Page/NotFound';
import { humanizeHash } from './updatehub/humanizer';

const routes = [
  {
    name: 'auth:callback',
    path: '/auth/callback',
    exact: true,
    component: Callback,
  },
  {
    name: 'product:index',
    displayName: 'Overview',
    path: '/:namespaceUID/products/:productUID',
    component: Dashboard,
    routes: [
      {
        name: 'product:dashboard',
        path: '/:namespaceUID/products/:productUID/',
        exact: true,
        component: OverviewPage,
      },
      {
        name: 'product:rollouts',
        displayName: 'Rollouts',
        path: '/:namespaceUID/products/:productUID/rollouts',
        exact: true,
        component: RolloutsPage,
        parent: 'product:index',
      },
      {
        name: 'product:rollouts:create',
        displayName: 'Create',
        path: '/:namespaceUID/products/:productUID/rollouts/new',
        exact: true,
        component: RolloutCreatePage,
        parent: 'product:rollouts',
      },
      {
        name: 'product:rollouts:detail',
        displayName: ({ rolloutUID }) => humanizeHash(rolloutUID),
        path: '/:namespaceUID/products/:productUID/rollouts/:rolloutUID',
        exact: true,
        component: RolloutDetailPage,
        parent: 'product:rollouts',
      },
      {
        name: 'product:devices',
        displayName: 'Devices',
        path: '/:namespaceUID/products/:productUID/devices',
        exact: true,
        component: DevicesPage,
        parent: 'product:index',
      },
      {
        name: 'product:devices:detail',
        displayName: ({ deviceUID }) => humanizeHash(deviceUID),
        path: '/:namespaceUID/products/:productUID/devices/:deviceUID',
        exact: true,
        component: DeviceDetailPage,
        parent: 'product:devices',
      },
      {
        name: 'product:packages',
        displayName: 'Packages',
        path: '/:namespaceUID/products/:productUID/packages',
        exact: true,
        component: PackagesPage,
        parent: 'product:index',
      },
      {
        name: 'product:packages:detail',
        displayName: ({ packageUID }) => humanizeHash(packageUID),
        path: '/:namespaceUID/products/:productUID/packages/:packageUID',
        exact: true,
        component: PackageDetailPage,
        parent: 'product:packages',
      },
      {
        component: NoMatch,
      },
    ],
  },
  {
    component: App,
    path: '/',
    routes: [
      {
        name: 'main:page',
        path: '/',
        exact: true,
        component: ProductListPage,
      },
      {
        name: 'settings',
        path: '/settings',
        exact: true,
        component: SettingsPage,
      },
      {
        name: 'settings:organization:invitation',
        path: '/settings/organizations/:organizationUID/invites/:token',
        exact: true,
        component: OrganizationInvitationPage,
      },
      {
        name: 'settings:organization:detail',
        path: '/settings/organizations/:organizationUID',
        exact: true,
        component: OrganizationDetailPage,
      },
      {
        name: 'billing:list',
        path: '/billing',
        exact: true,
        component: BillingListPage,
      },
      {
        name: 'billing:subscribe',
        path: '/billing/:organizationUID/subscribe',
        exact: true,
        component: BillingSubscriptionPage,
      },
      {
        name: 'billing:details',
        path: '/billing/:organizationUID/details',
        exact: true,
        component: BillingDetailsPage,
      },
      {
        component: NoMatch,
      },
    ],
  },
];

export default routes;
