import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OutlineButton, LinkButton } from '../Buttons';
import { CheckIcon } from '../Icons';
import { Modal, ModalContent, ModalTitle, ModalFooter } from '../Modal';
import * as actions from './actions';
import { getDeleteModalStatus, getButtonState } from './reducer';

const DeleteModal = ({ isOpened, onClose, onDelete, disabled }) => (
  <Modal isVisible={isOpened}>
    <ModalContent>
      <ModalTitle title="Are you sure you want to delete this package?" />
      <p>
        If you delete this package, all objects will be deleted from server and
        you will not be able to make rollouts using them anymore.
      </p>
    </ModalContent>
    <ModalFooter>
      <LinkButton label="Cancel" onClick={onClose} disabled={disabled} />
      <OutlineButton
        label="Yes, delete it!"
        icon={<CheckIcon />}
        onClick={onDelete}
        disabled={disabled}
      />
    </ModalFooter>
  </Modal>
);

DeleteModal.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isOpened: getDeleteModalStatus(state),
  disabled: getButtonState(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onClose() {
    dispatch(actions.closeModal());
  },
  onDelete() {
    const { namespaceUID, productUID, packageUID } = match.params;
    dispatch(actions.deletePackage(packageUID, namespaceUID, productUID));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeleteModal)
);
