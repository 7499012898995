import { call, put } from 'redux-saga/effects';
import request from '../updatehub/effects';

export default (
  requestDescriptor,
  receiveAction,
  requestErrorAction,
  setParameters = null
) =>
  /* eslint-disable func-names */
  function*({ payload }) {
    const { page, pageSize } = payload;
    let params = { page: page + 1, per_page: pageSize };

    if (setParameters) {
      params = yield call(setParameters, params);
    }

    let response;
    if (params instanceof Array) {
      response = yield request(requestDescriptor(...params));
    } else {
      response = yield request(requestDescriptor(params));
    }

    const { result, error } = response;
    if (result) {
      yield put(receiveAction(result));
    } else {
      yield put(requestErrorAction(error));
    }
  };
