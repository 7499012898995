import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalContent, ModalFooter } from '../Modal';
import { getDeleteOrganizationModalState, getOrganizationUID } from './reducer';
import CloseDeleteModal from './CloseDeleteOrganizationModalButton';
import DeleteOrganizationButton from './DeleteOrganizationButton';

const DeleteOrganizationModal = ({ organizationUID, isVisible }) => (
  <Modal isVisible={isVisible}>
    <ModalContent>
      Are you sure you want to delete this organization?
    </ModalContent>
    <ModalFooter>
      <CloseDeleteModal />
      <DeleteOrganizationButton organizationUID={organizationUID} />
    </ModalFooter>
  </Modal>
);

DeleteOrganizationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  organizationUID: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isVisible: getDeleteOrganizationModalState(state),
  organizationUID: getOrganizationUID(state),
});

export default connect(mapStateToProps)(DeleteOrganizationModal);
