import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ icon }) => {
  if (!icon) {
    return null;
  }
  return <span className="uh-btn-icon">{icon}</span>;
};

Icon.defaultProps = {
  icon: null,
};

Icon.propTypes = {
  icon: PropTypes.node,
};

export default Icon;
