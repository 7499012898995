import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { getDetails } from './reducer';
import AmountBox from './AmountBox';
import { ClockGrey } from '../Icons';
import { humanizeHour } from '../updatehub/humanizer';

const styles = {
  label: {
    display: 'block',
    color: '#3E3E40',
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 17,
  },
  synced: {
    color: '#A8A7A7',
    fontSize: 11,
    margin: '0 15px 0 4px',
  },
  iconSynced: {
    textAlign: 'right',
    marginBottom: 57,
  },
};

const Amount = ({ classes, details }) => (
  <div>
    <span className={classes.label}>Current cycle amount</span>
    <AmountBox />
    <div className={classes.iconSynced}>
      <ClockGrey />
      <span className={classes.synced}>
        Last synced:
        {humanizeHour(details.lastSynced)}
      </span>
    </div>
  </div>
);

Amount.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
};

const reduxConnector = connect(state => ({
  details: getDetails(state),
}));

export default reduxConnector(injectSheet(styles)(Amount));
