import * as types from './types';

export const registerRunner = (identifier, task) => ({
  type: types.REGISTER_RUNNER,
  identifier,
  payload: {
    task,
  },
});

export const registerToRefresh = (
  identifier,
  saga,
  emulatedAction,
  interval
) => ({
  type: types.REGISTER_TASK,
  identifier,
  payload: {
    saga,
    interval,
    emulatedAction,
  },
});

export const cleanRunners = () => ({
  type: types.CLEAN_RUNNERS,
});
