import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Task from './Task';
import AddTaskButton from './AddTaskButton';
import { getSelectedTaskIndex, getTasks } from '../reducer';

const styles = {
  box: {
    width: '100%',
  },
  divider: {
    width: '100%',
    border: '1px solid #F0F1F4',
    marginBottom: 2,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
};

/* eslint-disable react/no-array-index-key */
const TasksManager = props => {
  const {
    classes,
    tasks,
    selectedTask,
    onRemoveTaskClick,
    onSelectedTaskChange,
  } = props;

  return (
    <div className={classes.box}>
      <div className={classes.content}>
        {tasks.map((task, index) => (
          <Task
            key={`task-${index}`}
            active={index === selectedTask}
            onClick={onSelectedTaskChange(index)}
            onRemoveClick={onRemoveTaskClick(index)}
            name={task.name}
            devicesCount={task.devicesCount}
          />
        ))}
        <AddTaskButton />
      </div>
      <hr className={classes.divider} />
    </div>
  );
};

TasksManager.propTypes = {
  classes: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  selectedTask: PropTypes.number.isRequired,
  onSelectedTaskChange: PropTypes.func.isRequired,
  onRemoveTaskClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tasks: getTasks(state),
  selectedTask: getSelectedTaskIndex(state),
});

const mapDispatchToProps = dispatch => ({
  onSelectedTaskChange(newIndex) {
    return () => {
      dispatch(actions.changeSelectedTask(newIndex));
    };
  },
  onRemoveTaskClick(index) {
    return () => {
      dispatch(actions.requestRemoveTask(index));
    };
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(TasksManager));
