import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import StartRolloutTaskModal from '../StartRolloutTaskModal';
import ArchiveRolloutModal from './ArchiveRolloutModal';
import Header from './Header';
import TasksColumn from './TasksColumn';
import OverviewColumn from './OverviewColumn';
import ConflictModal from './ConflictModal';

const Content = ({ rollout }) => (
  <div>
    <ConflictModal rollout={rollout} />
    <StartRolloutTaskModal />
    <ArchiveRolloutModal rollout={rollout} />
    <Header rollout={rollout} />
    <Grid>
      <Row>
        <Col md={8}>
          <TasksColumn rollout={rollout} />
        </Col>
        <Col md={4}>
          <OverviewColumn rollout={rollout} />
        </Col>
      </Row>
    </Grid>
  </div>
);

Content.propTypes = {
  rollout: PropTypes.object.isRequired,
};

export default Content;
