import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EmptyStatusIcon from 'material-ui/svg-icons/toggle/radio-button-unchecked';
import FullStatusIcon from 'material-ui/svg-icons/image/lens';
import url from '../updatehub/urls';
import Card from '../Theme/Card';
import ProductLink from './ProductLink';
import {
  blue900,
  blue,
  green400,
  grey300,
  red,
  yellow200,
} from '../Theme/colors';
import { weight } from '../Theme/Typography';
import { ABORTED, IDLE, RUNNING, WAITING } from './states';

// Texts

export const RUNNING_ACTION_TEXT = 'SEE ROLLOUT PROGRESS';
export const WAITING_ACTION_TEXT = 'ACT ON ROLLOUT';
export const ABORTED_ACTION_TEXT = 'CHECK ROLLOUT ERROR';

export const setRunningText = activeRollouts =>
  `${activeRollouts} active rollout(s)`;
export const WAITING_TEXT = 'Rollout is paused';
export const ABORTED_TEXT = 'Rollout has failed';
export const IDLE_TEXT = 'No active rollout in this product';
export const UNKNOWN_TEXT = 'No information available';

// Product name

const productNameStyle = {
  color: blue900,
  display: 'block',
  fontWeight: weight.medium,
  fontSize: 16,
  textDecoration: 'none',
  textAlign: 'center',
  marginTop: 16,
};

export const ProductName = ({ name, namespaceUID, uid }) => {
  const productURL = url('product:dashboard', {
    namespaceUID,
    productUID: uid,
  });
  return (
    <Link style={productNameStyle} to={productURL}>
      {name}
    </Link>
  );
};

ProductName.propTypes = {
  name: PropTypes.string.isRequired,
  namespaceUID: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
};

// Status Icon

const statusIconStyle = {
  width: 12,
  marginLeft: 16,
  marginRight: 12,
};

export const StatusIcon = ({ status }) => {
  let Icon;
  let color;
  if (status === RUNNING) {
    [Icon, color] = [FullStatusIcon, green400];
  } else if (status === WAITING) {
    [Icon, color] = [FullStatusIcon, yellow200];
  } else if (status === ABORTED) {
    [Icon, color] = [FullStatusIcon, red];
  } else {
    [Icon, color] = [EmptyStatusIcon, grey300];
  }
  return <Icon color={color} style={statusIconStyle} />;
};

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

// Status text

const statusTextStyle = {
  fontSize: 14,
  fontWeight: weight.regular,
  marginTop: 25,
};

export const StatusText = ({ status, activeRollouts }) => {
  let text;
  if (status === RUNNING) {
    text = setRunningText(activeRollouts);
  } else if (status === WAITING) {
    text = WAITING_TEXT;
  } else if (status === ABORTED) {
    text = ABORTED_TEXT;
  } else if (status === IDLE) {
    text = IDLE_TEXT;
  } else {
    text = UNKNOWN_TEXT;
  }
  return <span style={statusTextStyle}>{text}</span>;
};

StatusText.propTypes = {
  status: PropTypes.string.isRequired,
  activeRollouts: PropTypes.number.isRequired,
};

// Status Action

const statusActionStyle = {
  display: 'block',
  fontWeight: weight.semiBold,
  fontSize: 12,
  marginTop: '0.5em',
  textDecoration: 'none',
  color: blue,
};

export const StatusAction = ({ status, uid, namespaceUID }) => {
  let action;
  if (status === RUNNING) {
    action = RUNNING_ACTION_TEXT;
  } else if (status === WAITING) {
    action = WAITING_ACTION_TEXT;
  } else if (status === ABORTED) {
    action = ABORTED_ACTION_TEXT;
  } else {
    return null;
  }
  const rolloutsURL = url('product:rollouts', {
    namespaceUID,
    productUID: uid,
  });
  return (
    <Link to={rolloutsURL} style={statusActionStyle}>
      {action}
    </Link>
  );
};

StatusAction.propTypes = {
  status: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  namespaceUID: PropTypes.string.isRequired,
};

// Product Card (finally...)

const cardWrapper = {
  position: 'relative',
};

const cardStyle = statusColor => ({
  flexFlow: 'column nowrap',
  height: 208,
  width: 205,
  marginBottom: 36,
  overflow: 'hidden',
  borderTop: `2px solid ${statusColor}`,
});

const statusBoxStyle = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  marginTop: 25,
};

const ProductCard = ({ name, uid, status, activeRollouts, namespaceUID }) => {
  let baseColor = blue900;
  if (status === WAITING) {
    baseColor = yellow200;
  } else if (status === ABORTED) {
    baseColor = red;
  }
  return (
    <div style={cardWrapper}>
      <Card style={cardStyle(baseColor)}>
        <div>
          <ProductLink
            productUID={uid}
            productName={name}
            namespaceUID={namespaceUID}
          />
          <ProductName uid={uid} name={name} namespaceUID={namespaceUID} />
        </div>
        <div style={statusBoxStyle}>
          <StatusIcon status={status} />
          <div>
            <StatusText status={status} activeRollouts={activeRollouts} />
            <StatusAction
              status={status}
              namespaceUID={namespaceUID}
              uid={uid}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  activeRollouts: PropTypes.number.isRequired,
  namespaceUID: PropTypes.string.isRequired,
};

export default ProductCard;
