import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import injectSheet from 'react-jss';
import urls from '../updatehub/urls';
import { LinkButton } from '../Buttons';

const styles = {
  button: {
    alignSelf: 'center',
  },
};

const ButtonSubscribe = ({ classes, onSubscribe, style }) => (
  <div className={classes.button}>
    <LinkButton
      onClick={onSubscribe}
      label="UNLOCK UNLIMITED USAGE"
      style={{ fontSize: 14, ...style }}
    />
  </div>
);

ButtonSubscribe.defaultProps = {
  style: {},
};

ButtonSubscribe.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  onSubscribe: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  null,
  (dispatch, { uid }) => ({
    onSubscribe: () => {
      dispatch(push(urls('billing:subscribe', { organizationUID: uid })));
    },
  })
);

export default reduxConnector(injectSheet(styles)(ButtonSubscribe));
