import * as types from './types';

export const requestPackages = () => ({
  type: types.REQUEST_PACKAGES,
});

export const requestPackagesError = () => ({
  type: types.REQUEST_PACKAGES_ERROR,
});

export const setPackages = payload => ({
  type: types.SET_PACKAGES,
  payload,
});

export const requestDevices = () => ({
  type: types.REQUEST_DEVICES,
});

export const requestDevicesError = () => ({
  type: types.REQUEST_DEVICES_ERROR,
});

export const setDevices = payload => ({
  type: types.SET_DEVICES,
  payload,
});
