import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import PlusIcon from 'material-ui/svg-icons/content/add';
import { blue } from '../../Theme/colors';

const styles = {
  placeholder: {
    top: '0px',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'center',

    '& > button': {
      height: 17,
      marginRight: 15,
      cursor: 'pointer',
      outline: 'none',
    },
  },
  buttonStyle: {
    border: 'none',
    background: 'none',
  },
  iconStyle: {
    width: '17px !important',
    height: '17px !important',
  },
};

const PlusPlaceholder = ({ classes, onClick }) => (
  <div className={classes.placeholder}>
    <button className={classes.buttonStyle} tabIndex="-1" onClick={onClick}>
      <PlusIcon color={blue} className={classes.iconStyle} />
    </button>
  </div>
);

PlusPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default injectSheet(styles)(PlusPlaceholder);
