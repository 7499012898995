import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { openCancelSubscriptionModal } from './actions';

const styles = {
  synced: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    color: '#A8A7A7',
    fontSize: 11,
    margin: '0 15px 0 4px',
  },
  iconSynced: {
    textAlign: 'right',
    marginBottom: 57,
  },
};

const CancelButton = ({ classes, openCancelModal }) => (
  <div className={classes.iconSynced}>
    <span className={classes.synced}>
      To cancel your subscription,
      <button className={classes.synced} onClick={openCancelModal}>
        click here.
      </button>
    </span>
  </div>
);

CancelButton.propTypes = {
  classes: PropTypes.object.isRequired,
  openCancelModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  openCancelModal() {
    dispatch(openCancelSubscriptionModal());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(injectSheet(styles)(CancelButton));
