import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { getStep } from './reducer';
import { CheckSuccessIcon } from '../Icons';
import {
  CUSTOMER_STEP,
  SUBSCRIPTION_STEP,
  FINALIZE_FAIL_STEP,
  CUSTOMER_FAIL_STEP,
} from './models';

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  borderStep: {
    height: 29,
    width: 29,
    boxSizing: 'border-box',
    borderRadius: '50%',
    border: '1px solid #5CA7D9',
  },
  borderStepSubmition: {
    extend: 'borderStep',
    border: '1px solid #51E475',
  },
  borderStepFail: {
    extend: 'borderStep',
    border: '1px solid #F8533D',
  },
  textIcon: {
    display: 'flex',
    height: 26,
    width: 26,
    fontSize: 16,
    fontWeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#5CA7D9',
  },
  textIconFail: {
    extend: 'textIcon',
    color: '#F8533D',
  },
  text: {
    color: '#3E3E40',
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 13,
    marginRight: 56,
  },
};

const StepInformation = ({ classes, value, step }) => {
  const text = value === 1 ? 'Billing Information' : 'Payment Details';
  let opacity = { opacity: 1 };
  let styleBoard = classes.borderStep;
  let icon = value;
  let styleIcon = classes.textIcon;

  switch (step) {
    case CUSTOMER_STEP:
      opacity = value === 1 ? { opacity: 1 } : { opacity: 0.5 };
      break;
    case SUBSCRIPTION_STEP:
      opacity = value === 2 ? { opacity: 1 } : { opacity: 0.5 };
      styleBoard =
        value === 1 ? classes.borderStepSubmition : classes.borderStep;
      icon = value === 1 ? <CheckSuccessIcon /> : value;
      break;
    case FINALIZE_FAIL_STEP:
      styleBoard =
        value === 1 ? classes.borderStepSubmition : classes.borderStepFail;
      icon = value === 1 ? <CheckSuccessIcon /> : '!';
      styleIcon = value === 1 ? classes.textIcon : classes.textIconFail;
      break;
    case CUSTOMER_FAIL_STEP:
      opacity = value === 1 ? { opacity: 1 } : { opacity: 0.5 };
      icon = value === 1 ? '!' : value;
      styleBoard = value === 1 ? classes.borderStepFail : classes.borderStep;
      styleIcon = value === 1 ? classes.textIconFail : classes.textIcon;
      break;
    default:
      break;
  }

  return (
    <div className={classes.box}>
      <div className={styleBoard}>
        <span className={styleIcon} style={opacity}>
          {icon}
        </span>
      </div>
      <span className={classes.text} style={opacity}>
        {text}
      </span>
    </div>
  );
};

StepInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.string.isRequired,
};

const reduxConnector = connect(state => ({
  step: getStep(state),
}));

export default reduxConnector(injectSheet(styles)(StepInformation));
