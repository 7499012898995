import React from 'react';
import humanizePlus from 'humanize-plus';
import PropTypes from 'prop-types';
import { weight } from '../../Theme/Typography';

const moreInfoStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: 245,
  height: '100%',
  backgroundColor: 'white',
  outline: 'none',
  paddingTop: 28,
  paddingBottom: 28,
  paddingRight: 20,
};

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

const iconStyle = {
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: 'red',
  float: 'left',
  marginRight: 9,
};

const titleStyle = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginBottom: 8,
  fontSize: 14,
  fontWeight: weight.medium,
};

const listStyle = {
  listStyle: 'none',
  paddingLeft: 13,
  borderLeft: '1px solid #EFF0F4',
  marginLeft: 4,
  flex: 1,
};

const liStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 4,
  fontSize: 12,
  whiteSpace: 'nowrap',
};

const percentageStyle = {
  width: 50,
  marginRight: 14,
  fontSize: 14,
  textAlign: 'right',
  fontWeight: weight.medium,
};

const liTitle = {
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const getIconStyle = color => ({
  ...iconStyle,
  backgroundColor: color,
});

const eventStopPropagation = e => e.stopPropagation();
const ShapeInfoOverlay = ({ version }) => {
  if (!version) {
    return null;
  }

  const detailData = version.details;
  const total = detailData.reduce((last, { count }) => last + count, 0);
  const unit = 100 / total;

  return (
    <div
      role="button"
      tabIndex="-1"
      style={moreInfoStyle}
      onKeyUp={eventStopPropagation}
      onClick={eventStopPropagation}
    >
      <div style={contentStyle}>
        <div style={titleStyle}>
          <div style={getIconStyle(version.color)} />
          <span>{version.name}</span>
        </div>
        <ul style={listStyle}>
          <li style={liStyle}>Hardware x Version</li>
          {detailData.map(({ count, name }) => (
            <li style={liStyle} key={`${name}-${count}`}>
              <span style={percentageStyle}>
                {humanizePlus.formatNumber(unit * count, 0)}%
              </span>
              <span style={liTitle}>{name}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ShapeInfoOverlay.defaultProps = {
  version: null,
};

ShapeInfoOverlay.propTypes = {
  version: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        count: PropTypes.number,
      })
    ),
  }),
};

export default ShapeInfoOverlay;
