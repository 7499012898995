import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuItem from 'material-ui/MenuItem';
import { SelectField, TextField } from 'redux-form-material-ui';
import { reduxForm, Field, reset } from 'redux-form/immutable';
import { getAuthToken } from '../Auth/reducer';
import { LinkButton, OutlineButton } from '../Buttons';
import { isEmail, required } from '../Form/validators';
import { CheckIcon } from '../Icons';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalLabel,
  ModalFormGroup,
} from '../Modal';
import { getCreateInviteModalState } from './reducer';
import * as actions from './actions';
import onSubmit from './submitInviteMemberForm';

export const formKey = 'invite-member';

const roles = [
  ['administrator', 'Administrator'],
  ['release_manager', 'Release Manager'],
  ['developer', 'Developer'],
];

const inputStyle = {
  fontSize: '14px !important',
};

const InviteMemberModal = ({
  isVisible,
  closeModal,
  handleSubmit,
  submitting,
  invalid,
  pristine,
}) => (
  <Modal isVisible={isVisible}>
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalFormGroup>
          <ModalLabel htmlFor="email" label="Member to invite email" />
          <Field
            id="email"
            name="email"
            type="email"
            validate={[required, isEmail]}
            component={TextField}
            disabled={submitting}
            style={inputStyle}
            fullWidth
          />
        </ModalFormGroup>
        <ModalFormGroup>
          <ModalLabel htmlFor="role" label="Member role" />
          <Field
            id="role"
            name="role"
            component={SelectField}
            disabled={submitting}
            validate={required}
            style={inputStyle}
            fullWidth
          >
            {roles.map(([value, label]) => (
              <MenuItem key={value} value={value} primaryText={label} />
            ))}
          </Field>
        </ModalFormGroup>
      </ModalContent>
      <ModalFooter>
        <LinkButton
          type="button"
          label="cancel"
          onClick={closeModal}
          disabled={submitting}
        />
        <OutlineButton
          label="send invite"
          icon={<CheckIcon />}
          disabled={pristine || submitting || invalid}
          type="submit"
        />
      </ModalFooter>
    </form>
  </Modal>
);

InviteMemberModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isVisible: getCreateInviteModalState(state),
  authToken: getAuthToken(state),
});

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(actions.closeCreateInviteModal());
    dispatch(reset(formKey));
  },
});

const formProps = {
  form: formKey,
  onSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm(formProps)(InviteMemberModal));
