import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, SelectField } from 'redux-form-material-ui';
import { reduxForm, Field, reset } from 'redux-form/immutable';
import { LinkButton, OutlineButton } from '../../Buttons';
import { getAuthToken } from '../../Auth/reducer';
import { required } from '../../Form/validators';
import { CheckIcon } from '../../Icons';
import {
  ModalContent,
  ModalFormGroup,
  ModalLabel,
  ModalFooter,
} from '../../Modal';
import { getNamespaces } from '../reducer';
import buildNamespaceOptions from '../../FormNamespacesOptions';
import * as actions from './actions';
import onSubmit from './submitHandler';

export const formKey = 'create-api-key';

const FormModal = ({
  closeModal,
  namespaces,
  handleSubmit,
  submitting,
  invalid,
  pristine,
}) => {
  const options = buildNamespaceOptions(namespaces);

  return (
    <form onSubmit={handleSubmit}>
      <ModalContent>
        <ModalFormGroup>
          <ModalLabel htmlFor="name" label="Name of the key (optional)" />
          <Field
            id="name"
            name="name"
            type="text"
            component={TextField}
            disabled={submitting}
            fullWidth
          />
        </ModalFormGroup>
        <ModalFormGroup>
          <ModalLabel htmlFor="owner" label="Choose the api key owner" />
          <Field
            id="owner"
            name="owner"
            component={SelectField}
            disabled={submitting}
            validate={required}
            fullWidth
          >
            {options}
          </Field>
        </ModalFormGroup>
      </ModalContent>
      <ModalFooter>
        <LinkButton
          type="button"
          label="cancel"
          onClick={closeModal}
          disabled={submitting}
        />
        <OutlineButton
          label="Save"
          icon={<CheckIcon />}
          disabled={pristine || submitting || invalid}
          type="submit"
        />
      </ModalFooter>
    </form>
  );
};

FormModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  namespaces: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  namespaces: getNamespaces(state).toJS(),
  authToken: getAuthToken(state),
});

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(actions.closeModal());
    dispatch(reset(formKey));
  },
});

const formProps = {
  form: formKey,
  onSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm(formProps)(FormModal));
