import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { blue } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';
import ValueSlider from './ValueSlider';
import FormGroup from './FormGroup';
import { getTask } from '../reducer';

const styles = {
  value: {
    color: blue,
    fontWeight: weight.semiBold,
    outline: 'none',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 48,
    fontWeight: weight.light,
    fontSize: 14,
    userSelect: 'none',
    marginBottom: 30,
  },
};

class StartPolicy extends React.Component {
  constructor() {
    super();

    this.toggleAutomaticFinish = this.toggleAutomaticFinish.bind(this);
    this.renderProgressMethod = this.renderProgressMethod.bind(this);
  }

  toggleAutomaticFinish(e) {
    const { automaticFinish, onAutomaticFinishChange } = this.props;

    onAutomaticFinishChange(e, !automaticFinish);
  }

  renderProgressMethod() {
    const { classes, automaticFinish } = this.props;

    if (automaticFinish) {
      return (
        <button
          tabIndex="-1"
          onClick={this.toggleAutomaticFinish}
          className={classes.value}
        >
          and it start automatically.
        </button>
      );
    }

    return (
      <button
        tabIndex="-1"
        onClick={this.toggleAutomaticFinish}
        className={classes.value}
      >
        and it will be started manually.
      </button>
    );
  }

  render() {
    const {
      classes,
      name,
      requiredSuccessRate,
      onRequiredSuccessRateChange,
    } = this.props;

    return (
      <FormGroup htmlFor="start-policy" label="Start Policy" bold>
        <div className={classes.box}>
          <span>
            Hold this task until <i>{name}</i> reaches&nbsp;
            <ValueSlider
              min={1}
              value={requiredSuccessRate}
              onChange={onRequiredSuccessRateChange}
            />{' '}
            of progress {this.renderProgressMethod()}
          </span>
        </div>
      </FormGroup>
    );
  }
}

StartPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  automaticFinish: PropTypes.bool.isRequired,
  requiredSuccessRate: PropTypes.number.isRequired,
  onAutomaticFinishChange: PropTypes.func.isRequired,
  onRequiredSuccessRateChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { taskIndex }) => {
  const task = getTask(state, taskIndex - 1);

  return {
    taskIndex,
    name: task.name,
    automaticFinish: task.automaticFinish,
    requiredSuccessRate: task.requiredSuccessRate,
  };
};

const mapDispatchToProps = (dispatch, { taskIndex }) => ({
  onAutomaticFinishChange(_e, value) {
    dispatch(actions.updateTask(taskIndex - 1, 'automaticFinish', value));
  },
  onRequiredSuccessRateChange(_e, value) {
    dispatch(actions.updateTask(taskIndex - 1, 'requiredSuccessRate', value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(styles)(StartPolicy));
