import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Page from '../Page';
import DevicesGraphCard from './DevicesGraphCard';
import RecentPackagesCard from './RecentPackagesCard';
import ActiveRolloutsWidget from './ActiveRolloutsWidget';
import { requestRollouts } from './ActiveRolloutsWidget/actions';

const gridStyle = {
  paddingTop: 31,
  width: 843,
};

const OverviewPage = ({ onLoad }) => (
  <Page onLoad={onLoad}>
    <Grid style={gridStyle}>
      <Row>
        <Col xs={12}>
          <ActiveRolloutsWidget />
        </Col>
        <Col xs={6}>
          <DevicesGraphCard />
        </Col>
        <Col xs={6}>
          <RecentPackagesCard />
        </Col>
      </Row>
    </Grid>
  </Page>
);

OverviewPage.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onLoad() {
    dispatch(requestRollouts());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(OverviewPage);
