import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getModalState } from './reducer';
import GenericErrorModal from './GenericErrorModal';
import ConflictModal from './ConflictModal';
import CreateErrorModal from './CreateErrorModal';

const ErrorModal = ({ isOpen, isConflict, isCreateError }) => {
  if (!isOpen) {
    return null;
  }

  if (isConflict) {
    return <ConflictModal />;
  }

  if (isCreateError) {
    return <CreateErrorModal />;
  }

  return <GenericErrorModal />;
};

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isConflict: PropTypes.bool.isRequired,
  isCreateError: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const { isOpen, conflictError, createError } = getModalState(state);

  return {
    isOpen,
    isConflict: conflictError !== null,
    isCreateError: createError !== null,
  };
};

export default connect(mapStateToProps)(ErrorModal);
