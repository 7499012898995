import {
  call,
  put,
  select,
  takeEvery,
  all,
  fork,
  cancel,
  delay,
} from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { getTaskByIdentifier, getInternalTasks } from './reducer';
import * as types from './types';
import * as actions from './actions';

export function* registerSagaWithAction(saga, identifier, interval, action) {
  yield put(actions.registerToRefresh(identifier, saga, action, interval));
}

export function* runner(saga, action, interval) {
  while (true) {
    yield delay(interval * 1000);
    yield call(saga, action);
  }
}

export function* createTask({ identifier, payload }) {
  const { saga, interval, emulatedAction } = payload;
  const runningTask = yield select(getTaskByIdentifier, identifier);
  if (runningTask) {
    yield cancel(runningTask.task);
  }

  const task = yield fork(runner, saga, emulatedAction, interval);
  yield put(actions.registerRunner(identifier, task));
}

export function* stopTasks() {
  const tasks = yield select(getInternalTasks);
  const cancelToTasks = tasks.valueSeq().map(({ task }) => cancel(task));

  yield all(cancelToTasks.toJS());
  yield put(actions.cleanRunners());
}

function* rootSaga() {
  yield takeEvery(types.REGISTER_TASK, createTask);
  yield takeEvery([LOCATION_CHANGE], stopTasks);
}

export default rootSaga;
