import curry from 'lodash/fp/curry';
import identity from 'lodash/fp/identity';
import { checkRequiredKeys, tableDecoder } from '../utils';

export const ROLLOUT_REQUESTS_TIMEOUT = 25000;

export const rolloutRequiredKeys = [
  'uid',
  'name',
  'version',
  'status',
  'updated_devices',
  'failed_devices',
  'remaining_devices',
  'total_devices',
  'created_at',
];

export const taskRequiredKeys = [
  'uid',
  'name',
  'status',
  'updated_devices',
  'failed_devices',
  'remaining_devices',
  'total_devices',
  'start_threshold',
  'started_at',
  'fault_tolerance',
];

const setHeader = namespaceUID => ({
  'UH-Namespace': namespaceUID,
});

const getRolloutStatus = rollout => {
  if (rollout.archived_at) {
    return 'archived';
  }
  return rollout.status;
};

const decodeTask = curry((rollout, task) => {
  checkRequiredKeys(task, taskRequiredKeys);
  return {
    uid: task.uid,
    name: task.name,
    status: task.status,
    updatedDevices: task.updated_devices,
    failedDevices: task.failed_devices,
    remainingDevices: task.remaining_devices,
    totalDevices: task.total_devices,
    rolloutUID: rollout.uid,
    startDate: task.started_at,
    startThreshold: task.start_threshold,
    faultTolerance: task.fault_tolerance,
  };
});

const decodeRollout = rollout => {
  checkRequiredKeys(rollout, rolloutRequiredKeys);
  return {
    uid: rollout.uid,
    name: rollout.name,
    version: rollout.version,
    status: getRolloutStatus(rollout),
    updatedDevices: rollout.updated_devices,
    failedDevices: rollout.failed_devices,
    remainingDevices: rollout.remaining_devices,
    creationDate: rollout.created_at,
    totalDevices: rollout.total_devices,
  };
};

const decodeRolloutWithTasks = rollout => {
  checkRequiredKeys(rollout, ['tasks']);
  return {
    ...decodeRollout(rollout),
    tasks: rollout.tasks.map(decodeTask(rollout)),
  };
};

const decodeRolloutList = tableDecoder('rollouts', rollout => {
  checkRequiredKeys(rollout, rolloutRequiredKeys);
  return decodeRollout(rollout);
});

const decodeActiveRolloutList = data => {
  checkRequiredKeys(data, ['rollouts']);
  return data.rollouts.map(decodeRolloutWithTasks);
};

const decodeFormErrors = data => {
  checkRequiredKeys(data, ['errors', 'is_recoverable']);
  return {
    errors: data.errors,
    isRecoverable: data.is_recoverable,
  };
};

export const createRollout = (namespaceUID, body) => ({
  method: 'POST',
  path: '/rollouts',
  accepts: [200, 422],
  headers: setHeader(namespaceUID),
  data: body,
  decodeSuccess: identity,
  decodeError: decodeFormErrors,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});

export const fetchActiveRollouts = (namespaceUID, productUID) => ({
  method: 'GET',
  path: '/rollouts',
  accepts: [200],
  headers: setHeader(namespaceUID),
  params: {
    status: ['running', 'waiting', 'aborted'],
    product_uid: productUID,
  },
  decodeSuccess: decodeActiveRolloutList,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});

export const fetchRollouts = (namespaceUID, uid, { page, per_page }) => ({
  /* eslint camelcase: 0 */
  path: '/rollouts',
  params: {
    page,
    per_page,
    order: 'desc',
    sort: 'created_at',
    product_uid: uid,
    count: true,
    with_archived: true,
  },
  accepts: [200],
  headers: setHeader(namespaceUID),
  decodeSuccess: decodeRolloutList,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});

export const fetchRollout = (namespaceUID, rolloutUID) => ({
  path: `/rollouts/${rolloutUID}`,
  method: 'GET',
  accepts: [200],
  headers: setHeader(namespaceUID),
  decodeSuccess: decodeRolloutWithTasks,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});

export const archiveRollout = (namespaceUID, rolloutUID) => ({
  method: 'DELETE',
  path: `/rollouts/${rolloutUID}`,
  accepts: [200],
  headers: setHeader(namespaceUID),
  decodeSuccess: identity,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});

export const startRolloutTask = (namespaceUID, rolloutUID, taskUID) => ({
  method: 'PUT',
  path: `/rollouts/${rolloutUID}/rollout_tasks/${taskUID}/start`,
  accepts: [200],
  headers: setHeader(namespaceUID),
  decodeSuccess: identity,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});

export const updateRolloutStatus = (
  namespaceUID,
  rolloutUID,
  status,
  forcedSubmit
) => ({
  method: 'PUT',
  path: `/rollouts/${rolloutUID}`,
  accepts: [200, 422],
  data: {
    status,
    forced_submit: forcedSubmit,
  },
  headers: setHeader(namespaceUID),
  decodeSuccess: identity,
  decodeError: decodeFormErrors,
  timeout: ROLLOUT_REQUESTS_TIMEOUT,
});
