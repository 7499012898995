import { Record } from 'immutable';

export const NamespaceModel = Record({
  uid: '',
  name: '',
  isOrganization: false,
});

const ListModel = Record({
  isEmpty: false,
  products: [],
  namespaces: [],
});

export default ListModel;
