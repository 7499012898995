import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import BillingValue from './BillingValue';
import BillingNotification from './BillingNotification';
import { isBlocked } from '../updatehub/api/billing';

const styles = {
  box: {
    boxShadow: '0 0 12px 0 #E5E6E8',
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
    marginBottom: 22,
  },
  boxDisabled: {
    extend: 'box',
    backgroundColor: 'rgba(216,216,216,0.1)',
  },
};

const BillingCard = ({ classes, billing, namespaceName, uid }) => (
  <div
    className={isBlocked(billing.status) ? classes.boxDisabled : classes.box}
  >
    {billing.notification && (
      <BillingNotification
        uid={uid}
        subscribed={billing.subscribed}
        {...billing.notification}
      />
    )}
    <BillingValue
      uid={uid}
      namespaceName={namespaceName}
      price={billing.price}
      closeIn={billing.closeIn}
      subscribed={billing.subscribed}
    />
  </div>
);

BillingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  namespaceName: PropTypes.string.isRequired,
  billing: PropTypes.object.isRequired,
};

export default injectSheet(styles)(BillingCard);
