import axios from 'axios';
import identity from 'lodash/fp/identity';
import { getApiURL } from '../config';
import { logError } from './utils';

export const APIContentType = 'application/vnd.updatehub-v1+json';

export const defaultTimeout = 8000;

export const defaultStatusValidator = status => status >= 200 && status < 500;

export const makeStatusValidator = validStatusList => status =>
  validStatusList.includes(status);

export const setHeaders = (authToken, customHeaders) => ({
  Authorization: `Bearer ${authToken}`,
  'Api-Content-Type': APIContentType,
  ...customHeaders,
});

export const setRequestConfig = (
  { path, accepts, headers, ...params },
  authToken
) => {
  const config = { ...params };
  if (!config.baseURL) {
    config.baseURL = getApiURL();
  }
  config.url = path;
  config.headers = setHeaders(authToken, headers);
  if (!config.transformResponse) {
    config.transformResponse = [JSON.parse];
  }
  config.timeout = params.timeout || defaultTimeout;
  config.validateStatus = accepts
    ? makeStatusValidator(accepts)
    : defaultStatusValidator;
  return config;
};

const makeDecodedResponse = () => ({
  result: null,
  error: null,
});

const decodeResponse = (response, decodeSuccess, decodeError = identity) => {
  const decodedResponse = makeDecodedResponse();
  if (response.status >= 200 && response.status < 300) {
    try {
      decodedResponse.result = decodeSuccess(response.data);
    } catch (err) {
      logError('Response decoding error', err);
    }
  } else {
    try {
      decodedResponse.error = decodeError(response.data, response.status);
    } catch (err) {
      logError('Request error decoding error', err);
    }
  }
  return decodedResponse;
};

export const makeRequest = (
  { decodeSuccess, decodeError, ...params },
  authToken
) => {
  const requestConfig = setRequestConfig(params, authToken);
  return axios
    .request(requestConfig)
    .then(response => decodeResponse(response, decodeSuccess, decodeError))
    .catch(error => {
      logError('Request error', error);
      return makeDecodedResponse();
    });
};
