import { Record } from 'immutable';

const PackageModel = Record({
  uid: '',
  version: '',
  supportedHardware: [],
  objects: [],
  uploadDate: '',
  packageSize: 0,
  status: '',
});

export default PackageModel;

export const DeleteModalModel = Record({
  isOpened: false,
  disabled: false,
});
