import React from 'react';
import PropTypes from 'prop-types';
import { fromNow } from '../updatehub/humanizer';
import {
  JustifiedPageHeader,
  PageTitle,
  HeaderInfo,
  FixedHeaderEntry,
} from '../PageHeader';
import OpenDeleteOrganizationModalButton from './OpenDeleteOrganizationModalButton';

const Header = ({ organization }) => (
  <JustifiedPageHeader>
    <PageTitle title="Organization">{organization.name}</PageTitle>
    <div style={{ display: 'flex' }}>
      <FixedHeaderEntry>
        <HeaderInfo label="Created" value={fromNow(organization.createdAt)} />
      </FixedHeaderEntry>
      <OpenDeleteOrganizationModalButton organizationUID={organization.uid} />
    </div>
  </JustifiedPageHeader>
);

Header.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default Header;
