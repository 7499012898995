import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import url from '../updatehub/urls';
import Table from '../Table';
import * as actions from './actions';
import { getTable, getTableColumns } from './reducer';

const DeviceTable = props => <Table {...props} />;

const mapStateToProps = state => {
  const tableData = getTable(state);

  return {
    // required because the state merge on reducer
    data: tableData.data.toJS(),
    totalCount: tableData.totalCount,
    columns: getTableColumns(state),
    page: tableData.page,
    pages: tableData.pages,
    loading: tableData.loading,
  };
};

const mapDispatchToProps = (dispatch, { match }) => ({
  getTrProps(args, row) {
    const { params } = match;

    return {
      onClick() {
        dispatch(
          push(
            url('product:devices:detail', {
              productUID: params.productUID,
              namespaceUID: params.namespaceUID,
              deviceUID: row.original.uid,
            })
          )
        );
      },
    };
  },

  onChange(payload) {
    dispatch(actions.request(payload));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeviceTable)
);
