import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '../Buttons';
import { TrashIcon } from '../Icons';
import { setMemberToRemove } from './actions';
import { modalConnector } from './RemoveMemberModal';

const OpenRemoveMemberModalButton = ({ onClick }) => (
  <IconButton onClick={onClick} icon={<TrashIcon />} />
);

OpenRemoveMemberModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { openModal, member }) => ({
  onClick() {
    dispatch(setMemberToRemove(member));
    openModal();
  },
});

const reduxConnector = connect(
  null,
  mapDispatchToProps
);

export default modalConnector(reduxConnector(OpenRemoveMemberModalButton));
