import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { weight } from '../Theme/Typography';
import PieLegend from './RolloutPieGraphLegend';
import { percent, green, red, grey } from './graph-utils';

const style = {
  rolloutPieGraph: {
    borderBottom: '2px solid #F0F1F4',
    fontSize: 12,
    padding: [15, 4, 30],
  },
  progress: {
    fill: '#3E3E40',
    fontSize: 24,
    fontWeight: weight.medium,
  },
};

const getPieData = rollout =>
  [rollout.updatedDevices, rollout.failedDevices, rollout.remainingDevices].map(
    value => ({
      value,
    })
  );

const getCurrentProgress = ({ updatedDevices, failedDevices, totalDevices }) =>
  percent(updatedDevices + failedDevices, totalDevices);

const RolloutPieGraph = ({ rollout, classes }) => {
  const data = getPieData(rollout);
  const currentProgress = getCurrentProgress(rollout);
  return (
    <div className={classes.rolloutPieGraph}>
      <PieChart width={230} height={84}>
        <text
          className={classes.progress}
          x={45}
          y={42}
          dy={8}
          textAnchor="middle"
        >
          {currentProgress}%
        </text>
        <Pie
          cx={40}
          data={data}
          dataKey="value"
          innerRadius={36}
          outerRadius={42}
          isAnimationActive={false}
        >
          <Cell fill={green} />
          <Cell fill={red} />
          <Cell fill={grey} />
        </Pie>
        <Legend
          align="right"
          verticalAlign="middle"
          layout="vertical"
          content={<PieLegend rollout={rollout} />}
        />
      </PieChart>
    </div>
  );
};

RolloutPieGraph.propTypes = {
  rollout: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutPieGraph);
