import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { grey600 } from '../Theme/colors';
import { weight } from '../Theme/Typography';
import ProductUID from './ProductUID';
import { getPageStatus } from '../Page/reducer';

const footerStyle = {
  footer: {
    color: grey600,
    width: '100%',
    flex: 'none',
    padding: '29px 40px',
    fontSize: 12,
    fontWeight: weight.regular,
    lineHeight: '19px',
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
};

const Footer = ({ classes, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className={classes.footer}>
      <span>Copyright © 2019 O.S. Systems</span>
      <ProductUID />
    </div>
  );
};

Footer.propTypes = {
  visible: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  visible: getPageStatus(state) === 'loaded',
});

export default connect(mapStateToProps)(injectSheet(footerStyle)(Footer));
