import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchDeviceIdentityKeys,
  fetchDeviceIdentityValues,
} from '../../../updatehub/api/device';
import { makeRequest } from '../../../updatehub/http';
import MultipleSelectField from '../../../MultipleSelectField';
import FormGroup from '../FormGroup';
import { getNamespaceUID, getProductUID } from '../../../Dashboard/reducer';
import { getAuthToken } from '../../../Auth/reducer';

const IdentityFilter = ({
  keys,
  values,
  fetchKeys,
  fetchValues,
  onKeysChange,
  onValuesChange,
}) => (
  <FormGroup htmlFor="device-identity" label="Device Identifier">
    <MultipleSelectField
      id="device-identity"
      placeholder="Add identifier filter"
      inputPlaceholder="Choose device identity"
      onKeysChange={onKeysChange}
      onValuesChange={onValuesChange}
      fetchKeys={fetchKeys}
      fetchValues={fetchValues}
      keys={keys}
      values={values}
    />
  </FormGroup>
);

IdentityFilter.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  fetchKeys: PropTypes.func.isRequired,
  fetchValues: PropTypes.func.isRequired,
  onKeysChange: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, { keys, values, filters, scope }) => ({
  keys,
  values,
  fetchKeys(q, page) {
    const authToken = getAuthToken(state);
    const productUID = getProductUID(state);
    const namespaceUID = getNamespaceUID(state);
    const requestConfig = fetchDeviceIdentityKeys(
      namespaceUID,
      productUID,
      q,
      page,
      { filters, scope }
    );

    return makeRequest(requestConfig, authToken);
  },
  fetchValues(key) {
    const authToken = getAuthToken(state);
    const productUID = getProductUID(state);
    const namespaceUID = getNamespaceUID(state);

    return (q, page) => {
      const requestConfig = fetchDeviceIdentityValues(
        namespaceUID,
        productUID,
        key,
        q,
        page,
        { filters, scope }
      );

      return makeRequest(requestConfig, authToken);
    };
  },
});

export default connect(mapStateToProps)(IdentityFilter);
