import * as types from './types';

export const requestLoad = payload => ({
  type: types.REQUEST_LOAD,
  payload,
});

export const setProduct = payload => ({
  type: types.SET_PRODUCT,
  payload,
});

export const unsetProduct = () => ({
  type: types.UNSET_PRODUCT,
});

export const setPageStatus = payload => ({
  type: types.SET_STATUS,
  payload,
});

export const openModal = payload => ({
  type: types.OPEN_MODAL,
  payload,
});

export const closeModal = payload => ({
  type: types.CLOSE_MODAL,
  payload,
});

export const resetApp = state => ({
  type: 'RESET',
  state,
});
