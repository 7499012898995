import { call, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './types';
import * as uh from '../updatehub/api';
import tableSaga from '../Table/sagaFactory';
import { getContext } from '../Dashboard/sagas';

export function* setParameters(actualParams) {
  const { namespaceUID, productUID } = yield call(getContext);

  return [namespaceUID, productUID, actualParams];
}

export const loadRollouts = tableSaga(
  uh.fetchRollouts,
  actions.receive,
  actions.requestError,
  setParameters
);

function* watchForLoadRequest() {
  yield takeEvery(types.REQUEST, loadRollouts);
}

export default watchForLoadRequest;
