import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeRequest } from '../updatehub/http';
import * as actions from './actions';
import { getAuthToken } from '../Auth/reducer';
import { getMemberSelectedToTransfer, getOrganizationUID } from './reducer';
import SelectField from '../SingleSelectField';
import { fetchAdministratorMembers } from '../updatehub/api/organization';

const SelectAdministrator = ({
  fetchData,
  selectedMemberNameChange,
  selectedName,
}) => (
  <label htmlFor="members-filter" style={{ fontSize: 14, fontWeight: 500 }}>
    Members
    <SelectField
      id="member-filter"
      placeholder="Choose new owner"
      inputPlaceholder="Choose new owner"
      fetchData={fetchData}
      onChange={selectedMemberNameChange}
      value={selectedName}
      single
    />
  </label>
);

SelectAdministrator.defaultProps = {
  selectedName: null,
};

SelectAdministrator.propTypes = {
  fetchData: PropTypes.func.isRequired,
  selectedMemberNameChange: PropTypes.func.isRequired,
  selectedName: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    uid: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  selectedName: getMemberSelectedToTransfer(state),
  fetchData() {
    const authToken = getAuthToken(state);
    const organizationUID = getOrganizationUID(state);
    const requestConfig = fetchAdministratorMembers(organizationUID);
    return makeRequest(requestConfig, authToken);
  },
});

const mapDispatchToProps = dispatch => ({
  selectedMemberNameChange(member) {
    dispatch(actions.setMemberToTransfer(member));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectAdministrator);
