import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { CheckBadge } from '../Icons';

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 55,
    marginTop: -35,
  },
  boxIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '50%',
    backgroundColor: '#51E475',
    border: '1px solid rgba(216,216,216,0.1)',
    height: 71,
    width: 71,
  },
};

const FinalizeHeader = ({ classes }) => (
  <div className={classes.box}>
    <div className={classes.boxIcon}>
      <CheckBadge />
    </div>
  </div>
);

FinalizeHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(FinalizeHeader);
