import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import injectSheet from 'react-jss';
import urls from '../updatehub/urls';
import { LinkButton } from '../Buttons';

const styles = {
  button: {
    alignSelf: 'center',
  },
};

const ButtonDetails = ({ classes, onSubscribe, style }) => (
  <div className={classes.button}>
    <LinkButton
      onClick={onSubscribe}
      label="SEE BILLING DETAILS"
      style={{ fontSize: 14, ...style }}
    />
  </div>
);

ButtonDetails.defaultProps = {
  style: {},
};

ButtonDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  onSubscribe: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  null,
  (dispatch, { uid }) => ({
    onSubscribe: () => {
      dispatch(push(urls('billing:details', { organizationUID: uid })));
    },
  })
);

export default reduxConnector(injectSheet(styles)(ButtonDetails));
