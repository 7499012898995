import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

const cardStyle = {
  card: {
    display: 'block',
    boxShadow: '0 0 12px 0 #E6E6E9',
    backgroundColor: 'white',
    borderRadius: 2,
  },
};

const Card = ({ classes, children, className, style }) => (
  <div className={`${classes.card} ${className}`} style={style}>
    {children}
  </div>
);

Card.defaultProps = {
  className: '',
  style: {},
};

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default injectSheet(cardStyle)(Card);
