import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import url from '../../updatehub/urls';
import { grey800 } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';
import { percent } from './utils';
import Card from '../../Theme/Card';
import ProgressOverview from './RolloutProgressOverview';
import ProgressBar from './RolloutProgressBar';
import ProgressBarCaption from './RolloutProgressBarCaption';
import ArchiveButton from './RolloutArchiveButton';
import Tasks from './RolloutTasks';
import TasksAborted from './RolloutTasksAborted';

const style = {
  box: {
    color: grey800,
    marginBottom: 16,
    padding: '39px 64px',
    position: 'relative',
  },
  header: {
    marginBottom: 14,
  },
  name: {
    display: 'inline-block',
    fontSize: 22,
    fontWeight: weight.medium,
    lineHeight: '32px',
    marginRight: 17,
  },
  nameLink: {
    color: grey800,
    textDecoration: 'none',
  },
};

const RolloutWidget = ({ rollout, classes }, { namespaceUID, productUID }) => {
  const {
    updatedDevices,
    failedDevices,
    remainingDevices,
    status,
    name,
    uid,
    tasks,
  } = rollout;

  const rolloutDetailURL = url('product:rollouts:detail', {
    rolloutUID: uid,
    namespaceUID,
    productUID,
  });

  // rollout progress data
  const total = updatedDevices + failedDevices + remainingDevices;
  const passed = updatedDevices + failedDevices;

  const progress = percent(passed, total);
  const updated = percent(updatedDevices, total);
  const failed = percent(failedDevices, total);
  const remaining = percent(remainingDevices, total);

  return (
    <Card className={classes.box}>
      <ArchiveButton status={status} rolloutUID={uid} />
      <div className={classes.header}>
        <h3 className={classes.name}>
          <Link className={classes.nameLink} to={rolloutDetailURL}>
            {name}
          </Link>
        </h3>
        <ProgressOverview status={status} progress={progress} />
      </div>
      <ProgressBar status={status} failed={failed} updated={updated} />
      <ProgressBarCaption
        status={status}
        failed={failed}
        updated={updated}
        remaining={remaining}
      />
      <TasksAborted tasks={tasks} status={status} />
      <Tasks tasks={tasks} status={status} />
    </Card>
  );
};

RolloutWidget.propTypes = {
  rollout: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

RolloutWidget.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
  productUID: PropTypes.string.isRequired,
};

export default injectSheet(style)(RolloutWidget);
