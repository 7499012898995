import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import { blue } from '../Theme/colors';
import { weight } from '../Theme/Typography';

const style = {
  color: blue,
  fontSize: 12,
  fontWeight: weight.semiBold,
  letterSpacing: '1px',
  textTransform: 'uppercase',
};

const CustomMenuItem = props => <MenuItem {...props} style={style} />;

export default CustomMenuItem;
