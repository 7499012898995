import { combineReducers } from 'redux-immutable';
import * as types from './types';
import DeviceModel, { fetchStatus, DeviceActionModel } from './models';

const setRolloutToDetail = (state, action) =>
  state.rollouts.filter(
    rollout => rollout.uid === action.payload.rolloutUID
  )[0];

export const deviceReducer = (state = DeviceModel(), action) => {
  switch (action.type) {
    case types.SET_DEVICE:
      return DeviceModel(action.payload.device).set(
        'fetchRolloutsStatus',
        fetchStatus.fetching
      );
    case types.SET_PROBE_INFO:
      return state
        .set('fetchProbeInfo', action.payload.fetchStatus)
        .set('lastProbe', action.payload.lastProbe)
        .set('probeIntervalMean', action.payload.probeIntervalMean);
    case types.SET_DEVICE_ROLLOUTS:
      return state
        .set('rollouts', action.payload.rollouts)
        .set('fetchRolloutsStatus', fetchStatus.done);
    case types.SET_DEVICE_ROLLOUTS_ERROR:
      return state
        .set('rollouts', [])
        .set('fetchRolloutsStatus', fetchStatus.error);
    case types.REQUEST_ROLLOUT_TRANSACTION:
      return state
        .set('rolloutToDetail', setRolloutToDetail(state, action))
        .set('fetchTransactionStatus', fetchStatus.fetching);
    case types.SET_ROLLOUT_TRANSACTION:
      return state
        .set('rolloutTransaction', action.payload.transaction)
        .set('fetchTransactionStatus', fetchStatus.done);
    case types.SET_ROLLOUT_TRANSACTION_ERROR:
      return state
        .set('rolloutTransaction', [])
        .set('fetchTransactionStatus', fetchStatus.error);
    case types.OPEN_DEVICE_LOG_MODAL:
      return state.set('isVisibleLogModal', true);
    case types.CLOSE_DEVICE_LOG_MODAL:
      return state.set('isVisibleLogModal', false);
    case types.SET_TAG:
      return state.set('tags', action.payload);
    default:
      return state;
  }
};

export const disableReducer = (state = DeviceActionModel(), action) => {
  switch (action.type) {
    case types.REQUEST_DEVICE_DISABLE:
      return state.withMutations(newState => newState.set('disabled', true));
    default:
      return state;
  }
};

export default combineReducers({
  deviceReducer,
  disableReducer,
});

// Selectors
export const getDevice = state =>
  state.getIn(['deviceDetail', 'deviceReducer']);

export const getDeviceUID = state =>
  state.getIn(['deviceDetail', 'deviceReducer', 'uid']);

export const getRollouts = state =>
  state.getIn(['deviceDetail', 'deviceReducer', 'rollouts']);

export const getRolloutToDetail = state =>
  state.getIn(['deviceDetail', 'deviceReducer', 'rolloutToDetail']);

export const getRolloutTransaction = state =>
  state.getIn(['deviceDetail', 'deviceReducer', 'rolloutTransaction']);

export const getRolloutTransactionStatus = state =>
  state.getIn(['deviceDetail', 'deviceReducer', 'fetchTransactionStatus']);

export const deviceLogModalIsVisible = state =>
  state.getIn(['deviceDetail', 'deviceReducer', 'isVisibleLogModal']);

export const getButtonState = state =>
  state.getIn(['deviceDetail', 'disableReducer', 'disabled']);

export const getTags = state =>
  state.getIn(['deviceDetail', 'deviceReducer', 'tags']);
