import React from 'react';
import PropTypes from 'prop-types';
import BaseButton from './BaseButton';
import Wrapper from './Wrapper';
import Label from './Label';
import Icon from './Icon';

const OutlineButton = ({ label, icon, ...props }) => (
  <BaseButton {...props} cls="uh-btn-outline">
    <Wrapper>
      <Icon icon={icon} />
      <Label text={label} />
    </Wrapper>
  </BaseButton>
);

OutlineButton.defaultProps = {
  icon: null,
};

OutlineButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
};

export default OutlineButton;
