import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import url from '../updatehub/urls';
import Logo from './Logo';
import { white, blue900 } from '../Theme/colors';
import { getProduct } from '../Dashboard/reducer';
import {
  DevicesIcon,
  OverviewIcon,
  PackagesIcon,
  RolloutsIcon,
} from '../Icons';
import Item from './Item';
import ProductSettings from './ProductSettings';

const getSidebarStyle = () => ({
  width: 208,
  backgroundColor: white,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  overflowX: 'hidden',
  minHeight: '100%',
});

const productTitleStyle = {
  fontSize: 24,
  marginTop: 12,
  marginBottom: 26,
  padding: '0 26px',
  textAlign: 'center',
  color: blue900,
};

const getMenuItems = params =>
  [
    [
      'Overview',
      OverviewIcon,
      { margin: '-2px 19px 0 5px', width: 18 },
      'product:index',
    ],
    [
      'Rollouts',
      RolloutsIcon,
      { margin: '-1px 17px 0 4px', width: 21 },
      'product:rollouts',
    ],
    [
      'Devices',
      DevicesIcon,
      { margin: '-3px 16px 0 2px', width: 24 },
      'product:devices',
    ],
    [
      'Packages',
      PackagesIcon,
      { margin: '-3px 19px 0 5px', width: 18 },
      'product:packages',
    ],
  ].map(([title, icon, iconStyle, linkName]) => ({
    title,
    icon,
    iconStyle,
    link: url(linkName, params),
  }));

const Sidebar = ({ productName }, { namespaceUID, productUID }) => {
  if (!productName) {
    return null;
  }

  const sidebarStyle = getSidebarStyle();
  const menuItems = getMenuItems({ namespaceUID, productUID });
  return (
    <div style={sidebarStyle}>
      <div>
        <Logo />
        <ProductSettings
          productUID={productUID}
          productName={productName}
          namespaceUID={namespaceUID}
        />
        <h1 style={productTitleStyle}>{productName}</h1>
        {menuItems.map(item => (
          <Item key={item.title} {...item} />
        ))}
      </div>
    </div>
  );
};

Sidebar.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
  productUID: PropTypes.string.isRequired,
};

Sidebar.propTypes = {
  productName: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  productName: getProduct(state).name,
});

export default withRouter(connect(mapStateToProps)(Sidebar));
