import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { percent, red, green, grey } from './graph-utils';

const style = {
  legend: {
    listStyle: 'none',
    marginTop: 10,
    '& li': {
      marginBottom: 12,
    },
  },
  circle: {
    borderRadius: '50%',
    display: 'inline-block',
    height: 8,
    margin: [0, 10],
    width: 8,
  },
  greenCircle: {
    extend: 'circle',
    background: green,
  },
  redCircle: {
    extend: 'circle',
    background: red,
  },
  greyCircle: {
    extend: 'circle',
    background: grey,
  },
  value: {
    display: 'inline-block',
    textAlign: 'right',
    width: 30,
  },
};

const PieLegend = ({ rollout, classes }) => {
  const {
    updatedDevices,
    failedDevices,
    remainingDevices,
    totalDevices,
  } = rollout;
  return (
    <ul className={classes.legend}>
      <li>
        <div className={classes.value}>
          {percent(updatedDevices, totalDevices)}%
        </div>
        <div className={classes.greenCircle} />
        Success
      </li>
      <li>
        <div className={classes.value}>
          {percent(failedDevices, totalDevices)}%
        </div>
        <div className={classes.redCircle} />
        Failed
      </li>
      <li>
        <div className={classes.value}>
          {percent(remainingDevices, totalDevices)}%
        </div>
        <div className={classes.greyCircle} />
        Remaining
      </li>
    </ul>
  );
};

PieLegend.propTypes = {
  rollout: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(PieLegend);
