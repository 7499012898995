import { checkRequiredKeys } from '../utils';

const userDecoder = data => {
  checkRequiredKeys(data, ['name', 'email', 'email_md5']);
  return {
    name: data.name,
    email: data.email,
    gravatarHash: data.email_md5,
  };
};

const namespaceListDecoder = data => {
  checkRequiredKeys(data, ['namespaces']);
  return data.namespaces.map(nm => {
    checkRequiredKeys(nm, ['name', 'type', 'uid']);
    return {
      name: nm.name,
      uid: nm.uid,
      isOrganization: Boolean(nm.type),
    };
  });
};

export const fetchMe = () => ({
  path: '/me',
  accepts: [200],
  decodeSuccess: userDecoder,
});

export const fetchNamespaces = () => ({
  path: '/namespaces',
  decodeSuccess: namespaceListDecoder,
});
