import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

class StatusFilter extends React.Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }

  onChange(event, key, payload) {
    const { onChange } = this.props;
    onChange(payload);
  }

  buildOptions() {
    const { statuses } = this.props;

    return statuses.map(({ value, label }) => (
      <MenuItem key={value} value={value} primaryText={label} />
    ));
  }

  render() {
    const { label, value } = this.props;

    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <div>
        <label htmlFor="filter" style={{ fontSize: 14 }}>
          {label}
          <SelectField
            id="filter"
            value={value}
            onChange={this.onChange}
            fullWidth
          >
            {this.buildOptions()}
          </SelectField>
        </label>
      </div>
    );
  }
}

StatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default StatusFilter;
