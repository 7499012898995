import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { BillingWarningIcon, BillingBlockedIcon } from '../Icons';
import { isDanger } from '../updatehub/api/billing';
import ButtonSubscribe from './ButtonSubscribe';
import ButtonDetails from './ButtonDetails';

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF',
    height: 58,
    borderBottom: '2px solid rgba(248,83,61,0.05)',
  },
  boxWarning: {
    extend: 'box',
    borderTop: '2px solid #F1C200',
  },
  boxDanger: {
    extend: 'box',
    borderTop: '2px solid #F8533D',
  },
  text: {
    marginRight: 8,
    marginLeft: 8,
    color: '#3E3E40',
    fontSize: 14,
  },
};

const buttonStyle = {
  height: 20,
};

const BillingNotification = ({ classes, uid, subscribed, type, message }) => {
  const NotificationIcon = isDanger(type)
    ? BillingBlockedIcon
    : BillingWarningIcon;

  return (
    <div className={isDanger(type) ? classes.boxDanger : classes.boxWarning}>
      <NotificationIcon />
      <span className={classes.text}>{message}</span>
      {subscribed ? (
        <ButtonDetails uid={uid} style={buttonStyle} />
      ) : (
        <ButtonSubscribe uid={uid} style={buttonStyle} />
      )}
    </div>
  );
};

BillingNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  subscribed: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default injectSheet(styles)(BillingNotification);
