import { SubmissionError } from 'redux-form/immutable';
import { createApiKey } from '../../updatehub/api';
import { makeRequest } from '../../updatehub/http';
import { openNotification } from '../../Notification';
import { request as requestApiKey } from '../ApiKeys/actions';
import { submitSuccess } from './actions';

export const CREATE_API_KEY_SUCCESS = 'Api key created successfully';
export const UNKNOWN_ERROR = 'Something went wrong. Try again later.';

const onSubmit = (formData, dispatch, { authToken, reset }) => {
  const requestConfig = createApiKey(
    formData.get('owner'),
    formData.get('name')
  );

  return makeRequest(requestConfig, authToken).then(({ result, error }) => {
    if (result) {
      dispatch(openNotification(CREATE_API_KEY_SUCCESS));
      dispatch(submitSuccess(result));
      dispatch(reset());
      dispatch(requestApiKey({ page: 0, pageSize: 5 }));
    } else if (error) {
      throw new SubmissionError(error);
    } else {
      dispatch(openNotification(UNKNOWN_ERROR));
    }
  });
};

export default onSubmit;
