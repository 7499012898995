export const requestAction = type => payload => ({
  type,
  payload,
});

export const receiveAction = type => payload => ({
  type,
  payload,
});

export const requestErrorAction = type => payload => ({
  type,
  payload,
});

export const resetAction = type => () => ({ type });
