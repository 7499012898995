import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '../../Buttons';
import { TrashIcon } from '../../Icons';
import { ActionsCell } from '../../Table';
import { openModal } from '../DeleteApiKeyModal/actions';

const APIKeysActionsCell = ({ openDeleteModal }) => (
  <ActionsCell>
    <IconButton icon={<TrashIcon />} onClick={openDeleteModal} />
  </ActionsCell>
);

APIKeysActionsCell.propTypes = {
  openDeleteModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { value }) => ({
  openDeleteModal() {
    dispatch(openModal(value));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(APIKeysActionsCell);
