import { Record, List } from 'immutable';

const DetailsModel = Record({
  namespace: '',
  namespaceUID: '',
  uid: '',
  closeIn: new Date(''),
  lastSynced: new Date(''),
  devicesPrice: 0.0,
  transferPrice: 0.0,
  storagePrice: 0.0,
  activationsPrice: 0.0,
  deploymentsPrice: 0.0,
  total: 0.0,
  orders: List(),

  // internal
  isDownloading: false,
  modalCancelSubscription: false,
  disabledCancelButton: true,
  modalConfirm: false,
});

export default DetailsModel;
