import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Popover from 'material-ui/Popover';
import PieLegend from '../RolloutDetailPage/RolloutPieGraphLegend';
import { grey900, white } from '../Theme/colors';

const styles = {
  box: {
    height: 148.28,
    backgroundColor: 'transparent',
  },
  legend: {
    height: 138,
    width: 172,
    borderRadius: 2,
    backgroundColor: white,
    border: '1px solid rgba(62, 62, 64, 0.22)',
  },
  legendData: {
    color: grey900,
    fontFamily: 'Fira Sans',
    fontSize: 12,
    marginLeft: 30,
  },
  legendTitle: {
    extend: 'legendData',
    marginTop: 10,
    marginLeft: 20,
  },
  bar: {
    height: 1,
    width: 133,
    backgroundColor: '#F2F2F2',
    margin: '10px 0px 9px 20px',
  },
  arrow: {
    width: '1rem',
    height: '0.5rem',
    margin: '0 .3rem',
    borderWidth: '.5rem .5rem 0',
    position: 'absolute',
    content: '""',
    borderColor: 'transparent',
    left: '45%',
    '&:after': {
      bottom: '1px',
      borderTopColor: '#fff',
      borderWidth: '.5rem .5rem 0',
      position: 'absolute',
      content: '""',
      borderColor: 'transparent',
      borderStyle: 'solid',
    },
    '&:before': {
      bottom: 0,
      borderTopColor: 'rgba(62, 62, 64, .22)',
      boxSizing: 'border-box',
      borderWidth: '.5rem .5rem 0',
      position: 'absolute',
      content: '""',
      borderColor: 'transparent',
      borderStyle: 'solid',
    },
  },
};

const popoverStyle = { backgroundColor: 'transparent', boxShadow: 'none' };
const anchorOrigin = { horizontal: 'middle', vertical: 'top' };
const targetOrigin = { horizontal: 'middle', vertical: 'bottom' };
const RolloutProgressBarLegend = ({ classes, rollout, open, anchor }) => (
  <Popover
    useLayerForClickAway={false}
    open={open}
    anchorEl={anchor}
    anchorOrigin={anchorOrigin}
    targetOrigin={targetOrigin}
    style={popoverStyle}
  >
    <div className={classes.box}>
      <div className={classes.legend}>
        <div className={classes.legendTitle}> {rollout.name} </div>

        <div className={classes.bar} />

        <div className={classes.legendData}>
          <PieLegend rollout={rollout} />
        </div>
      </div>
      <span className={classes.arrow} />
    </div>
  </Popover>
);

RolloutProgressBarLegend.defaultProps = {
  anchor: null,
};

RolloutProgressBarLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  rollout: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.object,
};

export default injectSheet(styles)(RolloutProgressBarLegend);
