import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { weight } from '../Theme/Typography';
import { blue900, grey900 } from '../Theme/colors';
import OpenModalButton from './ButtonOpenCreateProductModal';
import organizationNamespaceIcon from '../Icons/svg/organization-namespace-icon.svg';
import userNamespaceIcon from '../Icons/svg/user-namespace-icon.svg';
import { getProductsList } from './reducer';
import ProductCard from './ProductCard';
import EmptyProductCard from './EmptyProductCard';

// Products heading

const productsHeadingStyle = {
  fontSize: 18,
  fontWeight: weight.light,
  lineHeight: '22px',
  marginBottom: 14,
  color: grey900,
};

const productsHeadingIconStyle = {
  display: 'inline-block',
  paddingLeft: 4,
  width: 32,
};

export const ProductsHeading = ({ isOrganization, name }) => {
  let icon;
  let heading;
  if (isOrganization) {
    [heading, icon] = [name, organizationNamespaceIcon];
  } else {
    [heading, icon] = ['My Products', userNamespaceIcon];
  }
  return (
    <h2 style={productsHeadingStyle}>
      <div style={productsHeadingIconStyle}>
        <img src={icon} alt={heading} />
      </div>
      {heading}
    </h2>
  );
};

ProductsHeading.propTypes = {
  name: PropTypes.string.isRequired,
  isOrganization: PropTypes.bool.isRequired,
};

// Products list

export const ProductsList = ({ namespace, products }) => {
  products.sort((a, b) => a.name.localeCompare(b.name));
  let columns; // Must be an array of [card, key] arrays
  if (products.length <= 0) {
    columns = [
      [
        <EmptyProductCard namespace={namespace} />, // Card
        'empty-key', // Key
      ],
    ];
  } else {
    columns = products.map(product => [
      <ProductCard {...product} />, // Card
      product.uid, // Key
    ]);
  }
  return (
    <Row>
      {columns.map(([card, key]) => (
        <Col key={key} xs={12} sm={6} md={3}>
          {card}
        </Col>
      ))}
    </Row>
  );
};

ProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  namespace: PropTypes.object.isRequired,
};

// Products Wrapper

const gridStyle = {
  position: 'relative',
  paddingTop: 40,
};

const headingStyle = {
  fontSize: 24,
  fontWeight: weight.medium,
  lineHeight: '34px',
  marginBottom: 40,
  color: blue900,
};

const openModalButtonStyle = {
  float: 'right',
};

const ProductsView = ({ productsList }) => {
  function orderNamespaces(a, b) {
    if (!a.isOrganization || !b.isOrganization) return 0;
    return a.name.localeCompare(b.name);
  };

  productsList.sort((a, b) => orderNamespaces(a.namespace, b.namespace));
  const namespace = productsList.map(products => products.namespace);

  return (
    <Grid style={gridStyle}>
      <OpenModalButton namespace={namespace[0]} style={openModalButtonStyle} />
      <h1 style={headingStyle}>Products</h1>
      {productsList.map(products => (
        <div key={products.namespace.uid}>
          <ProductsHeading {...products.namespace} />
          <ProductsList {...products} />
        </div>
      ))}
    </Grid>
  );
};

ProductsView.propTypes = {
  productsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToSectionProps = state => ({
  productsList: getProductsList(state),
});

export default connect(mapStateToSectionProps)(ProductsView);
