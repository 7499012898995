import React from 'react';
import PropTypes from 'prop-types';

const getButtonClass = (baseClass, isFullWidth) => {
  const classes = ['uh-btn', baseClass];
  if (isFullWidth) {
    classes.push('uh-full-width');
  }
  return classes.join(' ');
};

const BaseButton = ({ fullWidth, cls, ...props }) => {
  const className = getButtonClass(cls, fullWidth);
  return <button {...props} className={className} />;
};

BaseButton.defaultProps = {
  fullWidth: false,
};

BaseButton.propTypes = {
  fullWidth: PropTypes.bool,
  cls: PropTypes.string.isRequired,
};

export default BaseButton;
