import React from 'react';
import ReactDOM from 'react-dom';
import createHistory from 'history/createBrowserHistory';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'react-router-redux';
import { renderRoutes } from './updatehub/urls';
import initializeStore from './store';
import rootSaga from './sagas';
import Theme from './Theme';
import Notification from './Notification';
import RefreshTokenFrame from './Auth/RefreshTokenFrame';
import FastSpringLoader from './FastSpringLoader';
import routes from './routes';

const history = createHistory();
const store = initializeStore(history);
store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <div>
      <FastSpringLoader />
      <Router history={history}>
        <Theme>
          {renderRoutes(routes)}
          <Notification />
          <RefreshTokenFrame />
        </Theme>
      </Router>
    </div>
  </Provider>,
  document.getElementById('app-container')
);
