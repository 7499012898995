import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '../../Buttons';
import { LeaveIcon, CogIcon } from '../../Icons';
import { ActionsCell } from '../../Table';
import { canAccessDetail } from './rules';
import { openModal } from '../LeaveOrganizationModal/actions';

const OrganizationsActionsCell = ({ openLeaveModal, data }) => (
  <ActionsCell>
    <IconButton icon={<LeaveIcon />} onClick={openLeaveModal} />
    {canAccessDetail(data.myRole) && <IconButton icon={<CogIcon />} />}
  </ActionsCell>
);

OrganizationsActionsCell.propTypes = {
  data: PropTypes.shape({
    uid: PropTypes.string,
    myRole: PropTypes.string,
  }).isRequired,
  openLeaveModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { data }) => ({
  openLeaveModal(event) {
    event.stopPropagation();
    dispatch(openModal(data.uid));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(OrganizationsActionsCell);
