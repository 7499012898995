import ModalModel from './models';
import { OPEN_MODAL, CLOSE_MODAL } from './types';

export const baseReducer = (state, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return state.set('isOpened', true);
    case CLOSE_MODAL:
      return state.set('isOpened', false);
    default:
      return state;
  }
};

const modalReducer = modalKey => (state = ModalModel(), action) => {
  if (action.modalKey !== modalKey) {
    return state;
  }
  return baseReducer(state, action);
};

export default modalReducer;
