import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../Theme/Typography';

const style = {
  columnEntry: {
    display: 'inline-block',
    fontSize: 14,
    paddingLeft: 20,
  },
  entryLabel: {
    fontWeight: weight.regular,
    marginBottom: 6,
  },
  entryValue: {
    fontWeight: weight.medium,
  },
};

const RolloutTaskCardFooterEntry = ({ label, value, classes }) => (
  <div className={classes.columnEntry}>
    <dl>
      <dt className={classes.entryLabel}>{label}</dt>
      <dd className={classes.entryValue}>{value}</dd>
    </dl>
  </div>
);

RolloutTaskCardFooterEntry.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(RolloutTaskCardFooterEntry);
