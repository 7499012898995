import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from './actions';
import Page from '../Page';
import { getStep } from './reducer';
import {
  CUSTOMER_STEP,
  SUBSCRIPTION_STEP,
  FINALIZE_STEP,
  FINALIZE_FAIL_STEP,
  CUSTOMER_FAIL_STEP,
} from './models';
import CustomerForm from './CustomerForm';
import SubmitPage from './SubmitPage';
import FinalizePage from './FinalizePage';
import FinalizeFail from './FinalizeFail';
import CustomerFail from './CustomerFail';
import Content from './Content';
import BackToBilling from './BackToBilling';
import Header from './Header';
import Steps from './Steps';

const BillingSubscriptionPage = ({ step, onLoad }) => {
  let children = null;
  let steps = <Steps />;
  let buttom = <BackToBilling />;

  switch (step) {
    case CUSTOMER_STEP:
      children = <CustomerForm />;
      break;
    case SUBSCRIPTION_STEP:
      children = <SubmitPage />;
      break;
    case FINALIZE_STEP:
      steps = null;
      buttom = null;
      children = <FinalizePage />;
      break;
    case FINALIZE_FAIL_STEP:
      children = <FinalizeFail />;
      break;
    case CUSTOMER_FAIL_STEP:
      children = <CustomerFail />;
      break;
    default:
      children = <h1>Invalid Step</h1>;
  }

  return (
    <Page onLoad={onLoad}>
      <Content>
        <Header />
        {steps}
        {children}
        {buttom}
      </Content>
    </Page>
  );
};

BillingSubscriptionPage.propTypes = {
  step: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  state => ({
    step: getStep(state),
  }),
  (dispatch, { match }) => ({
    onLoad: () => {
      dispatch(actions.subscribe(match.params.organizationUID));
    },
  })
);

export default withRouter(reduxConnector(BillingSubscriptionPage));
