import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DisableIcon, EnableIcon } from '../Icons';
import { disableDevice } from './actions';
import { Button } from '../Buttons';

const getButtonProps = device => {
  switch (device.status) {
    case 'active':
      return [<DisableIcon />, 'Disable', false];
    default:
      return [<EnableIcon />, 'Enable', true, null];
  }
};

const OpenModalButton = (
  { device, updateStatus },
  { namespaceUID, productUID }
) => {
  const [icon, label, disabled, status] = getButtonProps(device);
  if (device.status === 'active') {
    return (
      <Button
        onClick={updateStatus(status, namespaceUID, productUID)}
        disabled={disabled}
        icon={icon}
        label={label}
        fullWidth
      />
    );
  }
  return null;
};

OpenModalButton.contextTypes = {
  namespaceUID: PropTypes.string.isRequired,
  productUID: PropTypes.string.isRequired,
};

OpenModalButton.propTypes = {
  device: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { device }) => ({
  updateStatus(status, namespaceUID, productUID) {
    return () => dispatch(disableDevice(device.uid, namespaceUID, productUID));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(OpenModalButton);
