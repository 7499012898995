export const rolloutStatus = {
  running: 'running',
  stopped: 'stopped',
  aborted: 'aborted',
  waiting: 'waiting',
  archived: 'archived',
};

export const taskStatus = {
  pending: 'pending',
  waiting: 'waiting',
  running: 'running',
  aborted: 'aborted',
};
