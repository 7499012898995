import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { weight } from '../../Theme/Typography';

const styles = {
  entry: {
    '& > dt': {
      fontWeight: weight.medium,
      fontSize: 16,
      marginBottom: 5,
    },
    '& > dd': {
      fontSize: 14,
    },
  },
};

const Entry = ({ classes, label, value }) => (
  <dl className={classes.entry}>
    <dt>{label}</dt>
    <dd>{value}</dd>
  </dl>
);

Entry.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default injectSheet(styles)(Entry);
