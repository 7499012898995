import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import SectionTitle from '../Theme/SectionTitle';
import { grey800 } from '../Theme/colors';
import { weight } from '../Theme/Typography';
import DeviceProbeInfo from './DeviceProbeInfo';
import TagSelector from './TagSelector';

const style = {
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  boxDisabled: {
    extend: 'box',
    opacity: 0.5,
  },
  deviceInfo: {
    fontSize: 14,
    '& dt': {
      fontWeight: weight.medium,
      lineHeight: '19px',
      marginBottom: 5,
    },
    '& dd': {
      fontWeight: weight.regular,
      marginBottom: 45,
    },
  },
  footer: {
    color: grey800,
    fontSize: 14,
    fontStyle: 'italic',
    fontWeigth: weight.light,
    lineHeight: '19px',
  },
  tag: {
    marginBottom: 45,
  },
};

const attributesToList = attributes =>
  attributes.map(([key, value]) => [
    <dt key={`${key}-${value}`}>{key}</dt>,
    <dd key={`${value}-${key}`}>{value}</dd>,
  ]);

const getBoxClass = (device, classes) => {
  if (device.status === 'active') {
    return classes.box;
  }
  return classes.boxDisabled;
};

const DeviceInfo = ({ classes, device }) => {
  const attributes = attributesToList(device.attributes);
  return (
    <div className={getBoxClass(device, classes)}>
      <div className={classes.deviceInfo}>
        <SectionTitle text="State" />
        <dl>
          <dt>Version</dt>
          <dd>{device.version}</dd>
        </dl>
        {device.hardware && (
          <dl>
            <dt>Hardware</dt>
            <dd>{device.hardware}</dd>
          </dl>
        )}
        <div className={classes.tag}>
          <SectionTitle text="Device Tags" />
          <TagSelector />
        </div>
        {attributes.length > 0 && (
          <div>
            <SectionTitle text="Attributes" />
            <dl>{attributes}</dl>
          </div>
        )}
      </div>
      <div className={classes.footer}>
        <DeviceProbeInfo device={device} />
      </div>
    </div>
  );
};

DeviceInfo.propTypes = {
  device: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(DeviceInfo);
