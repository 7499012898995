import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Page from '../Page';
import * as actions from './actions';
import { getDevice } from './reducer';
import Header from './Header';
import DeviceInfo from './DeviceInfo';
import DeviceRolloutActivity from './DeviceRolloutActivity';
import DeviceLogModal from './DeviceLogModal';
import urls from '../updatehub/urls';

const DeviceDetailPage = ({ device, onLoad, match }) => (
  <Page
    onLoad={onLoad}
    goBackURL={urls('product:devices', {
      namespaceUID: match.params.namespaceUID,
      productUID: match.params.productUID,
    })}
  >
    <div>
      <DeviceLogModal />
      <Header device={device} />
      <Grid>
        <Row>
          <Col md={4}>
            <DeviceInfo device={device} />
          </Col>
          <Col md={8}>
            <DeviceRolloutActivity device={device} />
          </Col>
        </Row>
      </Grid>
    </div>
  </Page>
);

DeviceDetailPage.propTypes = {
  device: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onLoad: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  device: getDevice(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onLoad() {
    const { params } = match;
    const { deviceUID, namespaceUID, productUID } = params;

    dispatch(actions.requestDevice(deviceUID, namespaceUID, productUID));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeviceDetailPage)
);
