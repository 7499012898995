import { Record, List } from 'immutable';

export const TableModel = Record({
  columns: {},

  // State
  data: List([]),
  totalCount: 0,
  page: 0,
  pages: -1,
  pageSize: 10,
  loading: false,
});

export default (requestType, receiveType, resetType) => (
  state = TableModel(),
  action
) => {
  switch (action.type) {
    case requestType:
      return state.merge({
        ...action.payload,
        loading: true,
      });
    case receiveType:
      return state.merge({
        ...action.payload,
        loading: false,
      });
    case resetType:
      return TableModel();
    default:
      return state;
  }
};

export const selectorFactory = path => state => state.getIn(path);
