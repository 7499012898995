import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { DeviceErrorIcon } from '../Icons';

const styles = {
  box: {
    height: 285,
    paddingTop: 51,
    backgroundColor: '#FFF',
    borderRadius: '0 0 2px 2px',
    boxShadow: '0 0 12px 0 #E5E6E8',
    borderTop: '2px solid #F8533D',
  },
  label: {
    display: 'block',
    color: '#3E3E40',
    fontSize: 22,
    fontWeight: 500,
    marginLeft: 85,
    marginBottom: 75,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 28,
  },
  textAlert: {
    color: '#3E3E40',
    fontSize: 14,
  },
  icon: {
    height: 16,
    marginRight: 7,
  },
};

const CustomerFail = ({ classes }) => (
  <div className={classes.box}>
    <span className={classes.label}>Billing Information</span>
    <div className={classes.body}>
      <img src={DeviceErrorIcon} alt="error" className={classes.icon} />
      <span className={classes.textAlert}>
        There was a problem with your subscribe. Please try again later!
      </span>
    </div>
  </div>
);

CustomerFail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(CustomerFail);
