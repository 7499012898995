import { List, Record } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { RawFilter } from '../DevicesPage/reducer';
import * as types from './types';

export const FormModel = Record({
  scope: null,
  status: 'stopped',
  version: null,
  rawVersion: null,
  advancedMode: false,
  disabled: true,
  forceCreation: false,
  selectedTask: null,
  // Computed
  graphTitle: 'Versions',
  graphData: [],
  filters: [],
  remainingDevicesCount: 0,
  devicesCount: 0,
});

/* eslint-disable function-paren-newline */
export const formReducer = (state = FormModel(), action) => {
  switch (action.type) {
    case types.SET_VERSION:
      return state.withMutations(oldState => {
        if (action.payload) {
          oldState.set('version', action.payload.value);
        } else {
          oldState
            .set('version', null)
            .set('scope', null)
            .set('devicesCount', 0)
            .set('disabled', true);
        }

        return oldState.set('rawVersion', action.payload);
      });
    case types.SET_DEVICES_COUNT:
      return state.withMutations(oldState => {
        oldState.set('devicesCount', action.payload);

        if (action.payload > 0) {
          oldState.set('disabled', false);
        }

        return oldState;
      });
    case types.TOGGLE_ADVANCED_MODE:
      return state.set('advancedMode', !state.get('advancedMode'));
    case types.CHANGE_SELECTED_TASK:
      return state.set('selectedTask', action.payload);
    case types.SET_SCOPE:
      return state.set('scope', action.payload);
    case types.SET_FILTERS:
      return state.withMutations(oldState =>
        oldState
          .set('filters', action.payload)
          .set('disabled', action.payload.length === 0)
      );
    case types.SET_REMAINING_DEVICES:
      return state.set('remainingDevicesCount', action.payload);
    case types.SUBMIT:
      return state.set('status', 'stopped');
    case types.SUBMIT_START:
      return state.set('status', 'running');
    case types.SET_FORCE_CREATION:
      return state.set('forceCreation', action.payload);
    case types.CLOSE_ERROR_MODAL:
      return state.set('forceCreation', false);
    case types.SUBMIT_SUCCESS:
      return FormModel();
    case types.ADD_TASK:
    case types.REMOVE_TASK:
      return state.set('remainingDevicesCount', 0);
    case types.RESET_TASKS:
      return state.withMutations(oldState =>
        oldState
          .set('advancedMode', false)
          .set('graphData', [])
          .set('graphTitle', FormModel().graphTitle)
      );
    case types.UPDATE_GRAPH_TITLE:
      return state.set('graphTitle', action.payload);
    case types.UPDATE_GRAPH_DATA:
      return state.set('graphData', action.payload);
    default:
      return state;
  }
};

export const TaskModel = Record({
  name: '',
  automaticFinish: true,
  requiredSuccessRate: 100,
  faultTolerance: 0,
  rawFilter: RawFilter(),
  // Computed
  devicesCount: 0,
  filters: [],
  filter: null,
});

export const tasksReducer = (state = List([]), action) => {
  switch (action.type) {
    case types.ADD_TASK:
      return state.push(TaskModel({ name: `Task ${state.size + 1}` }));
    case types.UPDATE_TASK: {
      if (action.key instanceof Array) {
        return state.setIn([action.index, ...action.key], action.payload);
      }

      return state.setIn([action.index, action.key], action.payload);
    }
    case types.UPDATE_TASK_FILTER:
      return state.setIn([action.index, 'filter'], action.payload);
    case types.UPDATE_TASK_FILTERS:
      return state.setIn([action.index, 'filters'], action.payload);
    case types.REMOVE_TASK:
      return state.delete(action.index);
    case types.SUBMIT_SUCCESS:
    case types.RESET_TASKS:
      return List([]);
    default:
      return state;
  }
};

export const ErrorModalModel = Record({
  isOpen: false,
  errorMessage: '',
  conflictError: null,
  createError: null,
});

export const modalReducer = (state = ErrorModalModel(), action) => {
  switch (action.type) {
    case types.SET_ERROR:
      return state.withMutations(oldState =>
        oldState.set('isOpen', true).set('errorMessage', action.payload)
      );
    case types.SET_CONFLICT_ERROR:
      return state.withMutations(oldState =>
        oldState.set('isOpen', true).set('conflictError', action.payload)
      );
    case types.SET_CREATE_ERROR_MODAL:
      return state.withMutations(oldState =>
        oldState.set('isOpen', true).set('createError', action.payload)
      );
    case types.SUBMIT_SUCCESS:
    case types.CLOSE_ERROR_MODAL:
    case types.SUBMIT_START:
      return ErrorModalModel();
    default:
      return state;
  }
};

export default combineReducers({
  modal: modalReducer,
  form: formReducer,
  tasks: tasksReducer,
});

// Selectors
export const getForceCreation = state =>
  state.getIn(['rolloutCreate', 'form', 'forceCreation']);
export const getVersion = state =>
  state.getIn(['rolloutCreate', 'form', 'version']);
export const getScope = state =>
  state.getIn(['rolloutCreate', 'form', 'scope']);
export const getStatus = state =>
  state.getIn(['rolloutCreate', 'form', 'status']);
export const getDisabled = state =>
  state.getIn(['rolloutCreate', 'form', 'disabled']);
export const getTotalDevicesCount = state =>
  state.getIn(['rolloutCreate', 'form', 'devicesCount']);
export const getRemainingDevicesCount = state =>
  state.getIn(['rolloutCreate', 'form', 'remainingDevicesCount']);
export const getRawVersion = state =>
  state.getIn(['rolloutCreate', 'form', 'rawVersion']);
export const getAdvancedMode = state =>
  state.getIn(['rolloutCreate', 'form', 'advancedMode']);
export const getSelectedTaskIndex = state =>
  state.getIn(['rolloutCreate', 'form', 'selectedTask']);
export const getGraphTitle = state =>
  state.getIn(['rolloutCreate', 'form', 'graphTitle']);
export const getGraphData = state =>
  state.getIn(['rolloutCreate', 'form', 'graphData']);
export const getFilters = state =>
  state.getIn(['rolloutCreate', 'form', 'filters']);
export const getTasks = state => state.getIn(['rolloutCreate', 'tasks']);
export const getTask = (state, index) =>
  state.getIn(['rolloutCreate', 'tasks', index]);
export const getCurrentTask = state =>
  getTask(state, getSelectedTaskIndex(state));
export const getModalState = state => state.getIn(['rolloutCreate', 'modal']);
