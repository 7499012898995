import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromNow, humanizeHardware } from '../updatehub/humanizer';
import {
  JustifiedPageHeader,
  PageTitleWithHash,
  HeaderInfo,
} from '../PageHeader';
import { getPackage } from './reducer';
import PackageModel from './models';
import OpenModalButton from './OpenDeletePackageModalButton';

const Header = ({ pkg }) => (
  <JustifiedPageHeader>
    <PageTitleWithHash title="Package" hash={pkg.uid} />
    <HeaderInfo label="Version" value={pkg.version} />
    <HeaderInfo
      label="Supported Hardware"
      value={humanizeHardware(pkg.supportedHardware)}
    />
    <HeaderInfo label="Upload date" value={fromNow(pkg.uploadDate)} />
    <OpenModalButton status={pkg.status} />
  </JustifiedPageHeader>
);

Header.propTypes = {
  pkg: PropTypes.instanceOf(PackageModel).isRequired,
};

const mapStateToProps = state => ({
  pkg: getPackage(state),
});

export default connect(mapStateToProps)(Header);
