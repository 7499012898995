import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import Package from './Package';
import EmptyPackageList from './EmptyPackageList';
import { getRecentPackages } from '../reducer';

const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 203,
    padding: 16,
  },
};

const PackageList = ({ packages, classes }) => {
  if (!packages.length) {
    return <EmptyPackageList />;
  }

  return (
    <div className={classes.box}>
      {packages.map(pkg => (
        <Package key={pkg.uid} {...pkg} />
      ))}
    </div>
  );
};

PackageList.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

export const mapStateToProps = state => ({
  packages: getRecentPackages(state),
});

export default connect(mapStateToProps)(injectSheet(styles)(PackageList));
