import { OPEN_MODAL, CLOSE_MODAL } from './types';

export const openModal = modalKey => ({
  type: OPEN_MODAL,
  modalKey,
});

export const closeModal = modalKey => ({
  type: CLOSE_MODAL,
  modalKey,
});
