import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Modal, ModalTitle, ModalContent, ModalFooter } from '../Modal';
import { OutlineButton, Button } from '../Buttons';
import {
  cancelTransferOrganizationModal,
  transferOrganization,
} from './actions';
import {
  getTransferModal,
  getMemberSelectedToTransfer,
  getBillingToTransfer,
} from './reducer';
import SelectAdministrator from './SelectAdministrator';
import { humanizePrice } from '../updatehub/humanizer';

const styles = {
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  label: {
    display: 'block',
    marginBottom: 13,
    color: '#3E3E40',
    fontFamily: 'Fira Sans',
    fontSize: 14,
  },
  button: {
    height: 33,
    width: 70,
    border: '2px solid #5CA7DF',
    borderRadius: 20,
    color: '#5CA7DF',
    fontFamily: 'Fira Sans',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.93,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
};

const TransferModal = ({
  classes,
  closeModal,
  isVisible,
  selectedMember,
  confirmTransfer,
  total,
}) => {
  const title = 'Transfer organization';
  return (
    <Modal isVisible={isVisible}>
      <ModalContent>
        <ModalTitle title={title} />
        <span className={classes.label}>
          When transferring this organization, the resources used during the
          current period are going to be charged. The order will be of
          approximately {humanizePrice(total)}.
        </span>
        <SelectAdministrator />
      </ModalContent>
      <ModalFooter>
        <div className={classes.footer}>
          <Button label="cancel" onClick={closeModal} />
          <OutlineButton
            label="confirm"
            disabled={!selectedMember}
            onClick={confirmTransfer}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

TransferModal.defaultProps = {
  selectedMember: null,
};

TransferModal.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  selectedMember: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    uid: PropTypes.string,
  }),
  confirmTransfer: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isVisible: getTransferModal(state),
  selectedMember: getMemberSelectedToTransfer(state),
  total: getBillingToTransfer(state),
});

const mapDispatchToProps = {
  cancelTransferOrganizationModal,
  transferOrganization,
};

const mergeProps = (state, actions) => ({
  ...state,
  closeModal() {
    actions.cancelTransferOrganizationModal();
  },
  confirmTransfer() {
    actions.transferOrganization(state.selectedMember);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(injectSheet(styles)(TransferModal));
