import React from 'react';
import PropTypes from 'prop-types';
import SelectedOption from './SelectedOption';

const SelectedOptions = ({ values, onRemoveClick }) => (
  <div>
    {values.map(value => (
      <SelectedOption key={value.value} value={value} onClick={onRemoveClick} />
    ))}
  </div>
);

SelectedOptions.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default SelectedOptions;
