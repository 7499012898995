import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import url from '../updatehub/urls';
import MenuItem from './MenuItem';
import { blue } from '../Theme/colors';
import { SettingsIcon } from '../Icons';

const SettingsButton = ({ onClick }) => (
  <MenuItem
    leftIcon={<SettingsIcon color={blue} />}
    primaryText="settings"
    onClick={onClick}
  />
);

SettingsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { onClick }) => ({
  onClick() {
    dispatch(push(url('settings')));

    onClick();
  },
});

export default connect(
  null,
  mapDispatchToProps
)(SettingsButton);
