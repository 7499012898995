import React from 'react';
import PropTypes from 'prop-types';
import { weight } from '../Theme/Typography';

const h2Style = {
  fontSize: 16,
  fontWeight: weight.medium,
  display: 'inline-block',
};

export const H2 = ({ style, children }) => (
  <h2 style={{ ...style, ...h2Style }}>{children}</h2>
);

H2.defaultProps = {
  style: {},
};

H2.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

const h3Style = {
  fontSize: 14,
  fontWeight: weight.light,
};

export const H3 = ({ style, children }) => (
  <h3 style={{ ...style, ...h3Style }}>{children}</h3>
);

H3.defaultProps = {
  style: {},
};

H3.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};
