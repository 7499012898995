import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import {
  DisableIcon,
  RunningIconSmall,
  EnableIcon,
  DeviceErrorIcon,
  Unrecognized,
  PendingIcon,
} from '../Icons';

const styles = {
  block: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
  },
  status: {
    marginLeft: 10,
    display: 'flex',
  },
};

export const humanizeStatus = status => {
  switch (status) {
    case 'done':
      return 'Available to rollout';
    case 'receiving':
      return 'Received metadata';
    case 'removed':
      return 'Removed';
    case 'pending_check':
      return 'Pending to server check';
    case 'error':
      return 'Corrupted package';
    default:
      return 'Unrecognized status';
  }
};

const PackageStatusIcons = ({ classes, status }) => {
  const textStatus = humanizeStatus(status);

  let icon;
  switch (status) {
    case 'done':
      icon = <EnableIcon />;
      break;
    case 'removed':
      icon = <DisableIcon width={13} />;
      break;
    case 'error':
      icon = <img src={DeviceErrorIcon} alt="error" width={13} />;
      break;
    case 'receiving':
      icon = <RunningIconSmall />;
      break;
    case 'pending_check':
      icon = <PendingIcon width={12} />;
      break;
    default:
      icon = <Unrecognized width={15} />;
  }

  return (
    <div className={classes.block}>
      <div className={classes.icon}>{icon}</div>
      <div className={classes.status}>{textStatus}</div>
    </div>
  );
};

PackageStatusIcons.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default injectSheet(styles)(PackageStatusIcons);
