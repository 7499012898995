import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { grey800, green200, red } from '../../Theme/colors';
import { weight } from '../../Theme/Typography';
import { isActive } from './utils';

const style = {
  caption: {
    fontSize: 12,
    fontWeight: weight.regular,
    listStyle: 'none',
    letterSpacing: '0.2px',
    marginBottom: 10,
    marginLeft: 5,
    overflow: 'auto',
    '& li': {
      float: 'left',
      marginRight: 20,
    },
  },
  inactiveCaption: {
    extend: 'caption',
    opacity: 0.5,
  },
  captionIcon: {
    background: grey800,
    borderRadius: 4,
    display: 'inline-block',
    height: 4,
    margin: '2px 7px 2px 0',
    width: 4,
  },
  iconSuccess: {
    extend: 'captionIcon',
    background: green200,
  },
  iconFailed: {
    extend: 'captionIcon',
    background: red,
  },
  iconRemaining: {
    extend: 'captionIcon',
    background: '#B8BAC0',
  },
};

const Caption = ({ status, updated, failed, remaining, classes }) => {
  const captionClass = isActive(status)
    ? classes.caption
    : classes.inactiveCaption;
  const remainingText = isActive(status) ? 'Remaining' : 'Not updated';
  return (
    <ul className={captionClass}>
      <li>
        <div className={classes.iconSuccess} />
        {updated}% Updated
      </li>
      <li>
        <div className={classes.iconFailed} />
        {failed}% Failed
      </li>
      <li>
        <div className={classes.iconRemaining} />
        {remaining}% {remainingText}
      </li>
    </ul>
  );
};

Caption.propTypes = {
  status: PropTypes.string.isRequired,
  updated: PropTypes.number.isRequired,
  failed: PropTypes.number.isRequired,
  remaining: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(style)(Caption);
