import { connect } from 'react-redux';
import getDisplayName from 'react-display-name';
import { openModal, closeModal } from './actions';

const connectModal = (modalKey, selector) => InnerComponent => {
  const mapStateToProps = state => ({
    isOpened: selector(state).isOpened,
  });
  const mapDispatchToProps = dispatch => ({
    openModal() {
      dispatch(openModal(modalKey));
    },
    closeModal() {
      dispatch(closeModal(modalKey));
    },
  });
  const comp = connect(
    mapStateToProps,
    mapDispatchToProps
  )(InnerComponent);
  comp.displayName = `ModalConnect(${getDisplayName(InnerComponent)})`;
  return comp;
};

export default connectModal;
