import React from 'react';
import ShowingFilter from './ShowingFilter';
import IdentityFilter from './IdentityFilter';
import HardwareFilter from './HardwareFilter';
import VersionFilter from './VersionFilter';
import AttributesFilter from './AttributesFilter';
import TagFilter from './TagFilter';

const deviceFilterStyle = {
  width: '100%',
  paddingLeft: 56,
};

const DeviceFilter = () => (
  <div style={deviceFilterStyle}>
    <ShowingFilter />
    <IdentityFilter />
    <TagFilter />
    <HardwareFilter />
    <VersionFilter />
    <AttributesFilter />
  </div>
);

export default DeviceFilter;
