import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import injectSheet from 'react-jss';
import { parse as parseQuery } from 'qs';
import { Modal, ModalTitle, ModalContent, ModalFooter } from '../Modal';
import { OutlineButton } from '../Buttons';
import { weight } from '../Theme/Typography';
import { grey800 } from '../Theme/colors';
import { CopiableText, CopiableBlock } from '../Copiable';
import { getProduct, getModal } from './reducer';
import { openModal, closeModal } from './actions';

const style = {
  productUID: {
    color: grey800,
    fontSize: 14,
    lineHeight: '20px',
    margin: '20px 0px 10px 0px',
    textAlign: 'justify',
  },
  block: {
    extend: 'productUID',
    display: 'block',
    fontFamily: 'Courier, monospace, Courier New',
    fontWeight: weight.regular,
    fontSize: 12,
    margin: '3px 0px 0px 0px',
    padding: '0.85em 1em 0.85em 1em',
    backgroundColor: '#f7f7f7',
    width: 'auto',
  },
  blockYocto: {
    extend: 'block',
    marginBottom: 15,
    textAlign: 'left',
    borderLeft: '5px solid #D5D7D8',
  },
  blockInLine: {
    extend: 'block',
    display: 'inline-block',
    width: 'auto',
    margin: '0px 2px 0px 5px',
    padding: '2px 5px 2px 5px',
  },
  subtitle: {
    extend: 'productUID',
    lineHeight: '19px',
    fontWeight: 300,
    margin: '25px 0px 9px 0px',
    display: 'block',
  },
  footer: {
    textAlign: 'right',
    width: '100%',
  },
};

class NewProductModal extends Component {
  componentWillMount() {
    const { location, onOpenModal } = this.props;
    if (!('new' in parseQuery(location.search.slice(1)))) {
      return;
    }

    onOpenModal();
  }

  render() {
    const { classes, product, isVisible, onCloseModal, location } = this.props;
    const yoctoUid = `UPDATEHUB_PRODUCT_UID = "${product.uid}"`;

    const title =
      'new' in parseQuery(location.search.slice(1))
        ? 'Product created successfully!'
        : 'Product UID';

    return (
      <Modal isVisible={isVisible}>
        <ModalContent large>
          <ModalTitle title={title} />

          <div className={classes.productUID}>
            <p>
              {`The product unique identifier is required to allow the device to
              communicate with the system. The identifier generated for
              ${product.name}
              is:`}
            </p>
            <span className={classes.subtitle}>PRODUCT UID </span>
            <span className={classes.block}>
              <CopiableText text={product.uid} />
            </span>
          </div>

          <div className={classes.productUID}>
            <p>
              If you are creating a product based on the Yocto Project, you
              should use the
              <span className={classes.blockInLine}>UPDATEHUB_PRODUCT_UID</span>
              variable inside your distribution, or the
              <span className={classes.blockInLine}>conf/local.conf</span>,
              configuration file to set the product unique identifier to be
              used, as follows:
            </p>
            <span className={classes.subtitle}>CONFIGURATION </span>
            <span className={classes.blockYocto}>
              <CopiableBlock text={yoctoUid}>
                <span>
                  UPDATEHUB_PRODUCT_UID = &quot;
                  {product.uid}
                  &quot;
                </span>
              </CopiableBlock>
            </span>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className={classes.footer}>
            <OutlineButton label="Ok" onClick={onCloseModal} />
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

NewProductModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isVisible: getModal(state).isVisible,
  product: getProduct(state),
});

const mapDispatchToProps = dispatch => ({
  onOpenModal() {
    dispatch(openModal());
  },
  onCloseModal() {
    dispatch(closeModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectSheet(style)(NewProductModal)));
