import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchDeviceHardwareList } from '../../../updatehub/api/device';
import { makeRequest } from '../../../updatehub/http';
import SelectField from '../../../SelectField';
import FormGroup from '../FormGroup';
import { getProductUID, getNamespaceUID } from '../../../Dashboard/reducer';
import { getAuthToken } from '../../../Auth/reducer';

const VersionFilter = ({ fetchData, values, onChange }) => (
  <FormGroup htmlFor="hardware-filter" label="Hardware">
    <SelectField
      id="hardware-filter"
      inputPlaceholder="Choose hardware"
      onChange={onChange}
      fetchData={fetchData}
      values={values}
    />
  </FormGroup>
);

VersionFilter.propTypes = {
  fetchData: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { values, filters, scope }) => ({
  values,
  fetchData(q, page) {
    const authToken = getAuthToken(state);
    const namespaceUID = getNamespaceUID(state);
    const productUID = getProductUID(state);
    const requestConfig = fetchDeviceHardwareList(
      namespaceUID,
      productUID,
      q,
      page,
      { filters, scope }
    );

    return makeRequest(requestConfig, authToken);
  },
});

export default connect(mapStateToProps)(VersionFilter);
